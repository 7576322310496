import axios from 'axios'

export default {
  namespaced: true,
  state: {
    marquees: []
  },

  mutations: {
    SET_MARQ (state, data) {
      state.marquees =  data
    }
  },

  getters: {},

  actions: {
    async get ({ commit }) {
      let data = await axios.get('/settings/marquee')
      commit('SET_MARQ', data.data)
    },

    async update ({ commit }, { id, data }) {
      return axios.patch(`/settings/marquee/${id}`, { data })
    },

    async create ({ commit }, data) {
      return axios.post(`/settings/marquee/`, data)
    },

    async delete ({ commit }, id) {
      return axios.delete(`/settings/marquee/${id}`)
    }
  }
}
