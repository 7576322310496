<template>
  <div class="input-suggestion">
    <input
      v-model="search"
      type="text"
      v-if="!value"
      :placeholder="placeholder || null"
      @focus="initialSuggestion"
      @input="searchEvent"
      @keypress.enter="searchEvent"
    />

    <div
      class="input-selected"
      v-else
    >
      <slot ref="selected" name="selected"></slot>
      <i
        ref="reset"
        class="ico--reset-data"
        @click="clear"
      >
      </i>
    </div>

    <ul
      class="suggestion-list"
      ref="suggestion-list"
      v-show="focused"
    >
      <slot name="suggestion"></slot>
    </ul>
  </div>
</template>

<script>
import _ from 'lodash'
import $ from 'jquery'

export default {
  name: 'InputSuggestion',

  props: [
    'value',
    'placeholder'
  ],

  data () {
    return {
      search: '',
      focused: false,
      selected: null
    }
  },

  watch: {
    focused (val) {
      $('.suggestion-list').scrollTop(0)
    }
  },

  created () {
    this.$on('selected', (message) => {
      this.$emit('input', message)
      this.focused = false
    })

    let self = this

    window.addEventListener('click', (e) => {
      if (e.target.parentNode !== this.$el) {
        this.focused = false
      }
    })
  },

  methods: {
    clear () {
      this.$emit('input', null)
    },

    initialSuggestion () {
      this.focused = true
      this.$emit('search', this.value)
    },

    searchEvent: _.debounce(function (e) {
      this.$emit('search', e.target.value)
    }, 100)
  }
}
</script>
