import axios from 'axios'

export default {
  namespaced: true,
  state: {
    users: [],
    user: null,
    referrals: [],
    count: 0,
    refCount: 0,
    memo: [],

    accesslogs: [],
    accesslogsCount: 1
  },

  mutations: {
    ADD_USER (state, user) {
      const find = state.users.find(e => {
        return parseInt(e.id) === parseInt(user.id)
      })

      user.level = {
        name: user.lvl
      }

      user.agent = {
        name: user.agent_name
      }

      user.code = {
        agent_id: user.agent_id,
        id: user.code_id,
        code: user.code
      }
      if (!find) {
        state.count += 1
        state.users.push(user)
      }
      state.users.__ob__.dep.notify()
    },

    REMOVE_USER (state, id) {
      const find = state.users.filter(e => {
        return parseInt(e.id) === parseInt(id)
      })
      if (find) {
        state.users = state.users.filter(e => {
          return parseInt(e.id) !== parseInt(id)
        })
        state.count -= 1
      }
      state.users.__ob__.dep.notify()
    },

    SET_USERS (state, users) {
      state.users = users
    },

    SET_USERCOUNT (state, count) {
      state.count = count
    },

    SET_USER (state, user) {
      state.user = user
    },

    SET_REFERRAL_CODE (state, data) {
      state.user.referral_code = data.code
      state.user.__ob__.dep.notify()
    },

    SET_ACCESS_LOGS (state, data) {
      state.accesslogs = data.logs
      state.accesslogsCount = data.count[0].total
    }
  },

  actions: {
    async get ({ commit }, { status, params }) {
      try {
        const users = await axios.get(`/users/${status}`, { params })
        commit('SET_USERS', users.data)
      } catch (err) {
        console.log(err)
        throw err
      }
    },

    async getCount ({ commit }, { status, params }) {
      try {
        const count = await axios.get(`/users/${status}/count`, { params })
        commit('SET_USERCOUNT', count.data)
      } catch (err) {
        console.log(err)
        throw err
      }
    },

    async find ({ commit }, { id }) {
      try {
        const user = await axios
          .get(`/users/${id}/user`)
        commit('SET_USER', user.data[0])
      } catch (err) {
        console.log(err)
        throw err
      }
    },

    create ({ commit }, data) {
      return axios.post('/users', data)
    },

    async update ({ commit }, params) {
      const user = await axios.patch(`/users/${params.id}`, params.data)

      if (params.data.bet_limits) {
        params.user.bet_limits = params.data.bet_limits
      }

      if (params.data.settings) {
        params.user.settings = params.data.settings
      }

      commit('SET_USER', params.user)
      return user
    },

    async delete ({ commit }, params) {
      const user = await axios.delete(`/users/${params.uid}`, { test: 1 })
      commit('SET_USER', params.user)
      return user
    },

    async setAgent ({ commit }, { user_id, agent_id, code_id }) {
      await axios.patch(`/agents/user/update/${user_id}`, { agent_id, code_id })
    },

    async generateReferralCode ({ commit }, id) {
      const result = await axios.post(`/users/genrate-referral/${id}`, { data: id })
      commit('SET_REFERRAL_CODE', result.data)
    },

    async removeReferralCode ({ commit }, id) {
      const result = await axios.patch(`/users/remove-referral/${id}`, { data: id })
      commit('SET_REFERRAL_CODE', result.data)
    },

    async getAccessLogs ({ commit }, { params }) {
      const result = await axios.get('/users/access/logs', { params })
      commit('SET_ACCESS_LOGS', result.data)
    },

    async forceBan ({ commit }, { uid }) {
      try {
        await axios.post('/users/force-ban', { uid })
      } catch (err) {
        console.log(err)
      }
    }
  }
}
