<!--
  @USE AS:
  import Checkbox from '@/components/base/Checkbox'

  components: { Checkbox }

  <checkbox>
    <input type="checkbox" />
  </checkbox>
-->

<template>
  <label :class="setType">
    <div
      class="check-inner"
      v-if="type === 'checkbox'"
    >
      <slot></slot>

      <span
        class="check--box"
        aria-hidden="true"
      >
      </span>

      <span
        class="check--lbl"
        v-if="label"
      >
        {{ label }}
      </span>
    </div>

    <div
      class="toggle-inner"
      v-if="type === 'toggle'"
    >
      <slot></slot>

      <div class="check--box" aria-hidden="true">
        <div class="toggle-a">
          <span>{{ toggleA }}</span>
        </div>

        <div class="toggle-circle" v-if="!removeCircle"></div>

        <div class="toggle-b">
          <span>{{ toggleB }}</span>
        </div>
      </div>
    </div>
  </label>
</template>

<script>
export default {
  name: 'checkbox',

  props: {
    label: String,

    type: {
      type: [String],
      default: 'checkbox'
    },

    toggleA: [String, Number, Boolean],

    toggleB: [String, Number, Boolean],

    removeCircle: false
  },

  computed: {
    setType () {
      let type

      if (this.type === 'checkbox') {
        type = 'check-wrap'
      }

      if (this.type === 'toggle') {
        type = this.removeCircle ? 'toggle-wrap is-disabled' : 'toggle-wrap'
      }

      return type
    },

    setDisplay () {
      return this.removeCircle === 'true' ? true : false
    }
  }
}
</script>
