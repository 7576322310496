import axios from 'axios'

export default {
  namespaced: true,

  state: {
    countries: [],
    listCountries: [],
    count: 0
  },

  getters: {
    countries (state) {
      return state.countries
    },

    listCountries (state) {
      return state.listCountries
    },

    keyedCountries (state) {
      return _.keyBy(state.countries, 'id')
    }
  },

  mutations: {
    SET_COUNTRIES (state, countries) {
      state.countries = countries
    },

    LIST_COUNTRIES (state, countries) {
      state.listCountries = countries
    },

    SET_COUNT (state, count) {
      state.count = count[0].total
    },

    UPDATE_COUNTRY (state, country) {
      let index = state.countries
        .findIndex((row) => { return row.id === country.id })
      if (index !== -1) {
        state.countries[index] = country
      }
    },

    DELETE_COUNTRY (state, id) {
      let index = state.countries
        .findIndex((row) => { return row.id === id })

      if (index !== -1) {
        state.countries.splice(index, 1)
      }
    }
  },

  actions: {
    async get ({ commit }, { status, params }) {
      if (params && params.filter_by === 'country') params.filter_by = 'name'
      let countries = await axios.get(`/countries/${status}`, { params })
      let count = await axios.get(`/countries/${status}/count`, { params })

      commit('SET_COUNTRIES', countries.data)
      commit('SET_COUNT', count.data)
    },
    async getMatchCountries ({ commit }, { status, game_id, league_id, params }) {
      let countries = await axios.get(`/countries/${status}/game/${game_id}/league/${league_id}`, { params })
      commit('SET_COUNTRIES', countries.data)
    },
    async list ({ commit }, { status, params }) {
      if (params && params.filter_by === 'country') params.filter_by = 'name'
      let countries = await axios.get(`/countries/${status}`, { params })

      commit('LIST_COUNTRIES', countries.data)
    },

    async create ({ commit }, countries) {
      try {
        let data = new FormData()

        for (const key in countries) {
          if (!countries.hasOwnProperty(key)) {
            continue
          }

          data.append(key, countries[key])
        }

        let result = await axios.post('/countries', data)
        return result
      } catch (err) {
        return { status: 422 }
      }
    },

    async update ({ commit }, country) {
      let data = new FormData()

      for (const key in country) {
        if (!country.hasOwnProperty(key)) {
          continue
        }

        data.append(key, country[key])
      }

      let result = await axios
        .patch(`/countries/${country.id}`, data)

      commit('UPDATE_COUNTRY', country)
      return result
    },

    async delete ({ commit }, ids) {
      try {
        let result
        for (let a = 0; a < ids.length; a++) {
          result = await axios
            .delete(`/countries/${ids[a]}/`)
          commit('DELETE_COUNTRY', ids[a])
        }
        return result
      } catch (err) {
        console.log(err.response.data)
        return err.response.data
      }
    },

    async recover ({ commit }, countries) {
      let result
      for (let a = 0; a < countries.length; a++) {
        result = await axios
          .patch(`/countries/${countries[a].id}/restore`, countries[a].data)
      }
      return result
    },
  }
}
