import { common } from '@/assets/js/translations/Common'

export const en = {
  en: {
    from: `from`,
    to: `to`,

    all: `all`,
    none: `none`,
    live: `live`,
    virtual: `virtual`,
    slot: `slot`,
    casino: `casino`,
    mini: `mini`,
    sport: `sport`,

    ongoing: `ongoing`,
    result: `result`,
    cancelled: `cancelled`,
    deleted: `deleted`,

    country: `country`,
    game_name: `game name`,
    league: `league`,
    home_team: `home team`,
    away_team: `away team`,
    login_id: `login id`,
    login_name: `login name`,
    bet_amount: `bet amount`,
    win_amount: `win amount`,

    play_date: `play date`,
    bet_date: `bet date`,
    result_date: `result date`
  }
}
