import axios from 'axios'

export default {
  namespaced: true,

  state: {
    userImportResponse: {}
  },

  mutations: {
    SET_IMPORT_RESPONSE (state, value) {
      state.userImportResponse = value
    }
  },

  actions: {
    async importUserData ({commit}) {
      try {
        let {data} = await axios
          .get(`/imports/users`)

        commit('SET_IMPORT_RESPONSE', data)
      } catch (err) {
        console.log(err.response)
        return this.$alert('error', 'Something went wrong!', {}, {}, 'Error 500!')
      }
    }
  }
}
