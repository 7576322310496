export const kr = {
  kr: {
    all: '전체',
    none: '선택없음',
    live: '인플레이',
    virtual: '가상게임',
    slot: '슬롯게임',
    casino: '카지노게임',
    mini: '미니게임',
    sport: '스포츠게임',

    ongoing: '진행중',
    result: '결과',
    // cancelled: '취소',
    deleted: '삭제',

    user_cancel: '회원 취소',
    admin_cancel: '관리자 취소',

    country: '국가',
    game_name: '게임명',
    league: '리그명',

    home_team: '홈팀',
    away_team: '원정팀',
    login_id: '아이디',
    login_name: '닉네임',
    bet_amount: '배팅금액',
    win_amount: '당첨금액',

    play_date: '경기일자',
    result_date: '결과일자',
    bet_date: '배팅일자',
    cancelled_date: '취소일자',
    deleted_date: '삭제일자',

    actual_odds: '실제 배당',
    estimated_odds: '예상 배당',
    estimated_win: '예상 당첨금',

    cancel_bet: '배팅취소',

    goalserve: '파싱연동',
    disable_goalserve: '체크시 연동 해제 및 수정 가능',
    home_odd: '홈팀 배당',
    draw_odd: '무베당 / 기준',
    away_odd: '원정 배당',
    confirm: '수정',
    cancel: '취소',

    from: '에서',
    to: '까지',
    type: '경기 유형',
    game: '경기 종류',
    lost_exception: '낙첨제외 (총 배팅금액)',
    bet_count: '배팅수',
    modify: '배당수정',
    sel_modify: '선택수정',
    odds: '배당',
    exp_win: '예상 당첨금',
    win_50: '50% 당첨',
    lost_50: '50% 미당첨',
    empty: '값 없음',
    betting_status: '배팅 상태',
    active: '정상',
    inactive: '중지',
    game_sel: '게임 종류',

    'special-games': '스페셜',
    crossbet: '크로스',
    manual_history: '수동마감',
    provider: '제공사',

    search_user: '회원검색',

    end_money: '최종금액',
    start_money: '이전금액',
    b: '대기중',
    bet365: 'bet365',
    bet_ip: '접속 IP',
    cancel_ip: '취소IP',
    bet_rate: '배당',
    c: '완료',
    token: '토큰게임',
    bonus: '보너스',

    canceller_user: '배팅취소',
    canceller_admin: '관리자 배팅취소',

    this_bet_is_already_cancelled: '이미 취소된 배팅입니다',
    bet_cancel_success: '배팅을 취소 하였습니다.',

    leagues: '리그명',
    hold: '수동변경',
    unhold_all: '자동변경',

    pending: '대기',
    win: '적중',
    lose: '미적중',
    cancelled: '취소',
    void: '빈',

    sports: '스포츠',
    sports_kor: '스포츠 <small>(한국형)</small>',
    in_play: '인플레이',
    mini_games: '미니게임',

    horse_racing: '경마',
    greyhounds: '개경주',
    soccer: '가상축구',
    goldenhill_park: '골든힐 파크',
    festival_downs: '페스티발 다운',
    euro_cup: '유로 컵',
    premiership: '프리미어',
    superleague: '슈퍼리그',
    britannia_way: '브리타니아 웨이',
    hillside_park: '힐사이드 파크'
  }
}
