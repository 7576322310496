var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading)?_c('div',{staticClass:"body--inner",class:[
    {
      'is-details' : _vm.$route.name === 'user-details-page' || _vm.$route.name === 'requests-details-page',
      'is-login' : _vm.$route.name === 'login',
      'is-logout' : _vm.$route.name === 'logout'
    },
    'lang-' + _vm.config.lang
  ],attrs:{"id":"app"}},[(_vm.hideNav)?_c('router-view'):_vm._e(),(!_vm.hideNav)?_c('div',{staticClass:"contents"},[_c('div',{staticClass:"contents__items contents__sidebar"},[_c('info-panel'),_c('navigation')],1),_c('div',{staticClass:"contents__items contents__body"},[_c('statistics'),_c('div',{staticClass:"wrap"},[(_vm.tabs)?_c('div',{staticClass:"tabs"},_vm._l((_vm.tabs),function(tab,i){return _c(tab.tag,{key:i,tag:"component",class:((tab.tag === 'div' ? 'tabs__item' : 'btn--default') + "\n            " + (tab.active === 'true' ? ' is-active' : ''))},[_c('div',{staticClass:"tab__link",on:{"click":function($event){_vm.triggerRefresh(tab.path),
                _vm.$router.push(tab.path)}}}),_c('span',[_vm._v(" "+_vm._s(_vm.translate(_vm.translations, tab.name))+" ")])])}),1):_vm._e(),_c('router-view',{key:_vm.counter})],1)],1)]):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }