import { common } from '@/assets/js/translations/Common'

export const en = {
  en: {
    from: `from`,
    to: `to`,
    created_date: `created`,

    all: `type`,
    level: `level`,
    bet: `bet`,
    win: `win`,
    lost: `lost`,
    first_deposit: `first deposit`,
    second_deposit: `second deposit`,
    third_deposit: `third deposit`,
    daily_first_deposit: `daily first deposit`,
    daily_second_deposit: `daily second deposit`,
    daily_third_deposit: `daily third deposit`,
    deposit: `deposit`,
    conversion: `conversion`,
    manual_in: `manual in`,
    cashback: `cashback`,
    events: `events`,
    referral: `referral`,
    manual_withdraw: `manual withdraw`,
    manual_out: `manual out`,

    amount: `amount`,

    no: `no`,
    created_date: `created`,
    old_point: `old point`,
    point: `point`,
    new_point: `new point`,
    type: `type`,
    status: `status`,
    memo: `memo`,
    admin_ip: `admin ip`,
    admin: `admin`,

    confirmed: `confirmed`,
    deleted: `deleted`,
    rejected: `rejected`
  }
}
