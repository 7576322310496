import axios from 'axios'
import _ from 'lodash'

export default {
  namespaced: true,

  state: {
    settings: []
  },

  getters: {
    grouped (state) {
      return _.groupBy(state.settings, 'game_name_id')
    }
  },

  mutations: {
    SET_SETTINGS (state, settings) {
      if (settings instanceof Array) {
        state.settings = settings
      } else {
        state.settings.push(settings)
      }
    },

    UPDATE_SETTINGS (state, params) {
      let index = state.settings.findIndex((row) => row.id === params.id)
      state.settings[index] = params
    }
  },

  actions: {
    async get ({ commit }) {
      let { data } = (await axios.get('/settings/goalserve'))
      commit('SET_SETTINGS', data)
    },

    async create ({ commit }, params) {
      let { data } = (await axios.post('/settings/goalserve', params))
      params.id = data[0]
      commit('SET_SETTINGS', params)
    },

    async update({ commit }, params) {
      await axios.patch(`/settings/goalserve/${params.id}`, params)
    }
  }
}