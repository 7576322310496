<template>
  <div
    class="statistics"
    :class="{ 'is-active': showStatistics }"
  >
    <div
      v-if="usersInfo"
      class="stat-panel"
    >
      <div class="stat-panel__inner">
        <div class="logs-count">
          <div class="logs-count__lbl">
            {{ translate(translations, 'users') }}
          </div>

          <div
            v-if="usersInfo"
            class="logs-count__data"
          >
            {{ ((usersInfo && usersInfo.total) || 0) | FormatAmount('whole') }}
          </div>
        </div>

        <div class="logs-count">
          <div class="logs-count__lbl">
            {{ translate(translations, 'new') }}
          </div>

          <div
            class="logs-count__data"
          >
            {{ ((usersInfo && usersInfo.total_new) || 0) | FormatAmount('whole') }}
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="usersInfo"
      class="stat-panel"
    >
      <div class="stat-panel__inner">
        <div class="logs-count">
          <div class="logs-count__lbl">
            {{ translate(translations, 'balance') }}
          </div>

          <div class="logs-count__data">
            <template v-if="usersInfo.total_balance">
              {{ usersInfo.total_balance | FormatAmount('whole') }}
            </template>
            <template v-else>
              0
            </template>
          </div>
        </div>

        <div class="logs-count">
          <div
            class="logs-count__link"
            @click="
              triggerRefresh('/points/all')
              $router.push('/points/all')
            "
          >
          </div>

          <div class="logs-count__lbl">
            {{ translate(translations, 'point') }}
          </div>

          <div class="logs-count__data">
            <template v-if="usersInfo.total_point">
              {{ ((usersInfo && usersInfo.total_point) || 0) | FormatAmount('whole') }}
            </template>
            <template v-else>
              0
            </template>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="cashInfo"
      class="stat-panel"
    >
      <div class="stat-panel__inner">
        <div class="logs-count">
          <div class="logs-count__lbl">
            {{ translate(translations, 'in') }}
            <i>
              ({{ (cashInfo.cash_in_user_id.length || 0) | FormatAmount('whole') }})
            </i>
          </div>

          <div class="logs-count__data">
            {{ (cashInfo.total_cash_in || 0) | FormatAmount('whole') }}
          </div>
        </div>

        <div class="logs-count">
          <div class="logs-count__lbl">
            {{ translate(translations, 'out') }}
            <i>
              ({{ (cashInfo.cash_out_user_id.length || 0) | FormatAmount('whole') }})
            </i>
          </div>

          <div class="logs-count__data">
            <template v-if="cashInfo.total_cash_out">
              {{ (cashInfo.total_cash_out || 0) | FormatAmount('whole') }}
            </template>
            <template v-else>
              0
            </template>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="betInfo"
      class="stat-panel"
    >
      <div class="stat-panel__inner">
        <div class="logs-count">
          <div
            class="logs-count__link"
            @click="
              triggerRefresh('/bet-history-update/sports/all')
              $router.push('/bet-history-update/sports/all')
            "
          >
          </div>

          <div class="logs-count__lbl">
            {{ translate(translations, 'betting') }}
            <i>
              ({{ (betInfo.total_bet_users.length || 0) | FormatAmount('whole') }})
            </i>
          </div>

          <div class="logs-count__data">
            {{ (betInfo.total_bet || 0) | FormatAmount('whole') }}
          </div>
        </div>

        <div class="logs-count">
          <div class="logs-count__lbl">
            {{ translate(translations, 'betting ctn') }}
          </div>

          <div class="logs-count__data">
            <template v-if="cashInfo && cashInfo.count_users_bet">
              {{ (cashInfo.count_users_bet || 0) | FormatAmount('whole') }}
            </template>
            <template v-else>
              0
            </template>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="betInfo"
      class="stat-panel"
    >
      <div class="stat-panel__inner">
        <div class="logs-count">
          <div class="logs-count__lbl">
            {{ translate(translations, 'bet waiting') }}
          </div>

          <div
            class="logs-count__data"
          >
            {{ (betInfo.total_bet_waiting || 0) | FormatAmount('whole') }}
          </div>
        </div>

        <div class="logs-count">
          <div class="logs-count__lbl">
            {{ translate(translations, 'bet users') }}
          </div>

          <div class="logs-count__data">
            <template v-if="betInfo.total_bet_users">
              {{ (betInfo.total_bet_users.length || 0) | FormatAmount('whole') }}
            </template>
            <template v-else>
              0
            </template>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="betInfo"
      class="stat-panel"
    >
      <div class="stat-panel__inner">
        <div class="logs-count">
          <div class="logs-count__lbl">
            {{ translate(translations, 'win amount') }}
          </div>

          <div class="logs-count__data">
            <template v-if="betInfo.total_win">
              {{ (betInfo.total_win || 0) | FormatAmount('whole') }}
            </template>
            <template v-else>
              0
            </template>
          </div>
        </div>

        <div class="logs-count">
          <div class="logs-count__lbl">
            {{ translate(translations, 'betting net') }}
          </div>

          <div class="logs-count__data">
            <template v-if="betInfo.total_net">
              {{ (betInfo.total_net || 0) | FormatAmount('whole') }}
            </template>

            <template v-else>
              0
            </template>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="pointsInfo"
      class="stat-panel"
    >
      <div class="stat-panel__inner">
        <div class="logs-count">
          <div
            class="logs-count__link"
            @click="
              triggerRefresh('/transactions/points/all')
              $router.push('/transactions/points/all')
            "
          >
          </div>

          <div class="logs-count__lbl">
            {{ translate(translations, 'point auto') }}
          </div>

          <div
            class="logs-count__data"
          >
            {{ (pointsInfo.auto_point_in || 0) | FormatAmount('whole') }}
          </div>
        </div>

        <div class="logs-count">
          <div
            class="logs-count__link"
            @click="
              triggerRefresh('/transactions/points/all')
              $router.push('/transactions/points/all')
            "
          >
          </div>
          <div class="logs-count__lbl">
            {{ translate(translations, 'point manual') }}
          </div>

          <div class="logs-count__data">
            {{ (pointsInfo.manual_point_in || 0) | FormatAmount('whole') }}
          </div>
        </div>
      </div>
    </div>

    <button
      class="btn--refresh"
      @click="refresh()"
    >
      <i class="ico--refresh"></i>
    </button>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { messages, boards, transfers, bets, points, user } from '@/socket'

//= filters
import FormatAmount from '@/assets/js/filters/FormatAmount'

//= mixins
import { vueLocalStorage } from '@/assets/js/mixins/base/VueLocalStorage'

//= translation
import { translations } from '@/assets/js/translations/Statistics'

import _find from 'lodash/find'
import _get from 'lodash/get'

export default {
  name: 'Statistics',

  filters: {
    FormatAmount
  },

  data () {
    return {
      translations,

      drawer: false,
      masterhead_h: null
    }
  },

  computed: {
    ...mapState(['showStatistics']),
    ...mapState('config', ['config']),
    ...mapState('meta', [
      'allTotal',
      'cashInfo',
      'usersInfo',
      'notif',
      'betInfo',
      'pointsInfo'
    ]),

    loginName () {
      return vueLocalStorage.getItem('loginName')
    },

    level () {
      return vueLocalStorage.getItem('level')
    }
  },

  async created () {
    user.on('update', message => {
      message = JSON.parse(message)
      if (message.login_id) {
        this.setUserInfo('new-user')
      }
    })
    if (this.hasToken) {
      await this.getMeta()
      this.theme = this.config.theme
      this.getBetInfo()
      this.getCashInfo()
      this.getUsersInfo()
      this.getPointsInfo()
    }
  },

  async refresh () {
    if (this.hasToken) {
      await this.getMeta()
    }
  },

  mounted () {
    messages.on('superdropip', data => {
      data = JSON.parse(data)
      if (_find(data, d => (d === vueLocalStorage.getItem('loginId')))) {
        window.history.go()
      }
    })

    messages.on('deleteadmin', data => {
      data = JSON.parse(data)
      if (_find(data, d => (d === vueLocalStorage.getItem('loginId')))) {
        this.$router.push({ path: '/logout' })
      }
    })

    bets.on('new-bet', data => {
      data = JSON.parse(data)
      this.setBetInfo({
        event: 'new-bet',
        data: data
      })

      this.setNotif([{
        key: 'newbet',
        value: true
      }])
    })

    boards.on('new', data => {
      this.setNotif([{
        key: 'newboard',
        value: true
      }])
    })

    points.on('manual', async () => {
      await this.getUsersInfo()
      await this.getPointsInfo()
    })

    transfers.on('manual', async message => {
      await this.getUsersInfo()
      await this.getCashInfo()
      await this.getPointsInfo()
    })

    transfers.on('update-status', async message => {
      await this.getUsersInfo()
      await this.getCashInfo()
      await this.getPointsInfo()
    })

    this.$nextTick(() => {
      if (this.$refs.masterhead) {
        this.masterhead_h = this.$refs.masterhead.offsetHeight
      }
    })
  },

  beforeDestroy () {
    bets.removeListener('new-bet')
  },

  methods: {
    ...mapActions('meta', { getMeta: 'get', setUserInfo: 'setUserInfo' }),

    ...mapActions('meta', ['getCashInfo',
      'getUsersInfo',
      'getBetInfo',
      'setBetInfo',
      'setNotif',
      'getPointsInfo'
    ]),

    ...mapMutations(['SET_SHOWSTATISTICS']),

    _get,

    async refresh () {
      if (this.hasToken) {
        await this.getMeta()
        this.theme = this.config.theme

        this.getBetInfo()
        this.getCashInfo()
        this.getUsersInfo()
        this.getPointsInfo()
      }
    }
  }
}
</script>
