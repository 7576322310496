<template>
  <div class="nav">
    <ul class="nav-list">
      <li
        v-for="(nav, i) in navList"
        :key="i"
        class="nav__items"
        :class="[
          setNavClass(nav.name),
          nav.active ? 'is-active' : null
        ]"
      >
        <div
          class="nav__title nav__title--main"
          @click="
            triggerRefresh(nav.path),
            $router.push(nav.path)
          "
        >
          <span>
            <i :class="nav.icon"></i>
            {{ translate(translations, nav.name) }}
          </span>
        </div>

        <ul
          v-if="!_isEmpty(nav.subNav)"
          class="subnav"
        >
          <li
            v-for="(subnav, j) in nav.subNav"
            :key="j"
            class="subnav__items"
            :class="{ 'is-active-sub': subnav.active }"
            @click="
              triggerRefresh(subnav.path),
              $router.push(subnav.path)
            "
          >
            <div class="nav__title nav__title--sub">
              <span>
                {{ translate(translations, subnav.name) }}
              </span>
            </div>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'

//= lib
import _isEmpty from 'lodash/isEmpty'

//= mixins
import { vueLocalStorage } from '@/assets/js/mixins/base/VueLocalStorage'
import { metaTitle } from '@/assets/js/mixins/base/MetaTitle'

//= translation
import { translations } from '@/assets/js/translations/Navigation'

export default {
  name: 'Navigation',

  mixins: [
    metaTitle
  ],

  data () {
    return {
      translations,

      navItems: []
    }
  },

  computed: {
    ...mapState('config', ['config']),

    navList () {
      const currPath = this.$route.path
      const list = []

      if (this.validateNav('user')) {
        const data = {
          name: 'user management',
          icon: 'ico--users',
          active: false,
          path: '',
          defaultPath: [],
          subNav: [
            {
              name: 'user list',
              path: '/users/confirmed',
              defaultPath: ['/users/online', '/users/confirmed', '/users/banned', '/users/deleted']
            },
            {
              name: 'referrals list',
              path: '/agents/referrals',
              defaultPath: ['/agents']
            }
          ]
        }

        list.push(data)
      }

      if (this.validateNav('cash & point')) {
        const data = {
          name: 'cash & point history',
          icon: 'ico--transactions',
          active: false,
          path: '',
          defaultPath: [],
          subNav: [
            {
              name: 'cash history',
              path: '/transactions/all/all',
              defaultPath: ['/transactions/all', '/transactions/cash', '/transactions/bet', '/transactions/win']
            },
            {
              name: 'point history',
              path: '/points/all',
              defaultPath: ['/points/all']
            }
          ]
        }

        list.push(data)
      }

      if (this.validateNav('bet')) {
        const data = {
          name: 'bet management',
          icon: 'ico--bethistory',
          active: false,
          path: '/bet-history-update/sports/all',
          defaultPath: ['/bet-history-update'],
          subNav: []
        }

        list.push(data)
      }

      if (this.validateNav('code')) {
        const data = {
          name: 'code management',
          icon: 'ico--agent',
          active: false,
          path: '/codes/active',
          defaultPath: ['/codes'],
          subNav: []
        }

        list.push(data)
      }

      if (this.validateNav('account')) {
        const data = {
          name: 'account management',
          icon: 'ico--accounting',
          active: false,
          path: '/account/all',
          defaultPath: ['/account'],
          subNav: []
        }

        list.push(data)
      }

      list.push({
        name: 'commission history',
        icon: 'ico--transactions',
        active: false,
        path: '/commission-history',
        defaultPath: ['/commission-history'],
        subNav: []
      })

      list.forEach(nav => {
        const mainDPath = nav.defaultPath || currPath
        const subNav = nav.subNav
        nav.active = false

        if (!nav.path) {
          nav.path = subNav[0].path
        }

        if (mainDPath) {
          nav.defaultPath.forEach(path => {
            if (path && currPath.indexOf(path) > -1) {
              nav.active = true
            }
          })
        }

        if (subNav) {
          subNav.forEach(subnav => {
            const subDPath = subnav.defaultPath || currPath
            subnav.active = false

            if (subDPath) {
              subnav.defaultPath.forEach(subpath => {
                if (subpath && currPath.indexOf(subpath) > -1) {
                  subnav.active = true
                  nav.active = true
                }
              })
            }
          })
        }
      })

      return list
    }
  },

  watch: {
    '$route' (route) {
      this.metaTitle(route)
    },

    lang () {
      this.metaTitle(this.$route)
    }
  },

  mounted () {
    this.$nextTick(() => {
      this.getConfig()
      this.metaTitle(this.$route)
    })
  },

  methods: {
    ...mapActions('config', { getConfig: 'get' }),

    _isEmpty,

    setNavClass (name) {
      return name.replace(/[^A-Z0-9][ ]/ig, '').replace(/[ ]/g, '-')
    }
  }
}
</script>
