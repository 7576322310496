/**
  Description:
  import RemoveNonAlphaNum from '@/assets/js/filters/RemoveNonAlphaNum'

  filters: { RemoveNonAlphaNum }

  returns full status name
**/

export default function (value) {
  return value.replace(/[_-]/g, " ")
}
