/**
  Description: [can be used as]

  :: without parameter ::
    {{ variable | formatAmount }}
  :: with parameter ::
    {{ variable | formatAmont('param') }}

  Parameter:
    • whole - returns the value as a whole number without rounding
    • round - returns the rounded value as a whole number

  P.S. default return value is in floating/decimal format
**/
export default function (value, format) {
  let amount = ''
  let decimal = []
  let result = 0

  if (value != null) {
    amount = value.toString()

    if (format === 'whole') {
      if (amount.indexOf('.') !== -1) {
        amount = amount.split('.')
        amount[0] = amount[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        result = amount[0]
      } else {
        result = amount.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
    } else if (format === 'round') {
      amount = (Math.round(parseFloat(amount))).toString()
      result = amount.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    } else {
      if (amount.indexOf('.') !== -1) {
        amount = amount.split('.')
        amount[0] = amount[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        decimal = amount[1].split('')

        if (decimal.length > 2) {
          amount[1] = amount[1].slice(0, 2)
        }

        if (decimal.length === 1) {
          amount[1] = amount[1] + '0'
        }

        result = amount.join('.')
      } else {
        amount = amount.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        result = amount + '.00'
      }
    }
  }

  return result
}
