export const en = {
  en: {
    all: `all`,
    none: `none`,
    live: `inplay`,
    virtual: `virtual`,
    slot: `slot`,
    casino: `casino`,
    mini: `mini`,
    sport: `sport`,

    ongoing: `ongoing`,
    result: `result`,
    cancelled: `cancelled`,
    deleted: `deleted`,
    user_cancel: `user cancel`,

    country: `country`,
    game_name: `game name`,
    league: `league`,
    home_team: `home team`,
    away_team: `away team`,
    login_id: `login id`,
    login_name: `login name`,
    bet_amount: `bet amount`,
    win_amount: `win amount`,

    result_date: `result date`,
    bet_date: `bet date`,
    cancelled_date: `cancelled date`,

    actual_odds: `actual odds`,
    estimated_odds: `estimated odds`,
    estimated_win: `estimated win`,

    cancel_bet: `cancel`,

    goalserve: `Goalserve / On, Off`,
    disable_goalserve: `Selected release to goalserve`,
    play_date: `play date`,
    home_odd: `home odd`,
    draw_odd: `draw odd / middle odd`,
    away_odd: `away odd`,
    confirm: `confirm`,
    cancel: `cancel`,

    from: 'from',
    to: 'to',
    type: 'type',
    game: 'game',
    lost_exception: 'lost exception',
    bet_count: 'bet count',
    modify: 'modify',
    sel_modify: 'sel modify',
    round: 'round',
    odds: 'odds',
    exp_win: 'exp win',
    win_50: '50% win',
    lost_50: '50% lost',
    empty: 'empty',
    betting_status: `bet status`,
    active: 'active',
    inactive: 'inactive',
    game_sel: 'game',

    'special-games': 'Special Games',
    crossbet: 'Crossbet',
    manual_history: 'manual history',

    canceller_user: 'Cancelled: User',
    canceller_admin: 'Cancelled: Admin'
  }
}
