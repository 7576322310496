<template>
  <li
    class="select-dd--list__items"
    ref="selectOption"
    :style="`margin-top: ${option_h}px`"
    @click="getSelected(value)"
  >
    <span ref="selectData"><slot></slot></span>
  </li>
</template>

<script>
export default {
  name: 'select-option',

  props: {
    value: [String, Number, Object, Array, Boolean],
    name: [String, Number, Boolean]
  },

  inject: {
    handleSelected: {}
  },

  data () {
    return {
      selected: false,
      reset: false,

      option_h: null
    }
  },

  watch: {
    name () {
      if (!this.handleSelected.reset) {
        if (this.handleSelected.value === this.value) {
          this.handleSelected.name = this.name
        }
      }
    }
  },

  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.getOptionHeight)
      this.getOptionHeight()
    })
  },

  methods: {
    getOptionHeight () {
      // let selectOption = this.$refs.selectOption
      //
      // if (selectOption) {
      //   this.option_h = Math.abs(parseInt(selectOption.offsetHeight)) * -1
      // }
    },

    getSelected (value) {
      this.selected = true
      this.handleSelected.reset = false

      this.handleSelected.value = this.value
      this.handleSelected.name = this.name

      this.$parent.modifiedData = true
    }
  }
}
</script>
