import axios from 'axios'
import ObjToFormData from '@/assets/js/common/ObjToFormData.js'

export default {
  namespaced: true,
  state: {
  	board: null
  },
  getters: {},
  mutations: {
  	SET_BOARD (state, data)  {
  		state.board = data
  	}
  },

  actions: {

    async create ({ commit, state }, params) {
      try {
        let data = await axios.post('/freeboards', ObjToFormData(params))
        return data.data
      } catch (err) {
        console.log(err)
      }
    },

    async update ({ commit }, { id, data }) {
      console.log("=======================")
      console.log(data)
      await axios.patch(`/freeboards/${id}`, ObjToFormData(data))
    },

  	async get ({ commit, state }, id) {
  		try {
  			let response = await axios.get(`/freeboards/${id}/board`)
  			commit('SET_BOARD', response.data[0])
  		} catch (err) {

  		}
  	}
  }
}
