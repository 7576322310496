import { common } from '@/assets/js/translations/Common'

export const jp = {
  jp: {
    from: `から`,
    to: `まで`,

    user_detail_info: `ユーザー詳細情報`,
    created_date: `登録日時`,
    deleted_date: `削除日時`,
    last_login: `最後のログイン`,
    updated_date: `修正日時`,

    // level
    'lv.1': `レベル1`,
    'lv.2': `レベル2`,
    'lv.3': `レベル3`,
    'lv.4': `レベル4`,
    'lv.5': `レベル5`,
    'lv.6': `レベル6`,
    'lv.7': `レベル7`,
    admin: `管理者`,

    //= status
    stat_p: `要請`,
    stat_c: `正常`,
    stat_b: `中止`,
    stat_r: `拒否`,
    stat_d: `削除`,
    stat_w: `待機`,

    //= sidenav
    referral: `推薦人`,
    code: `コード`,
    agent: `店舗`,
    login_id: `ID`,
    login_name: `ニックネーム`,
    level: `レベル`,
    phone: `電話番号`,
    password: `パスワード`,
    status: `状態`,
    money: `保有金額`,
    cash_in: `入金`,
    cash_out: `出金`,
    cash_net: `入/出 損益`,
    manual_in: `支給`,
    manual_out: `回収`,
    manual_net: `支給/回収 損益`,
    point: `ポイント`,
    point_in: `ポイント支給`,
    point_out: `ポイント回収`,
    point_conversion: `ポイント転換`,
    total_betting: `ベッティング`,
    now_betting: `ベッティング結果待機`,
    total_win: `勝ち`,
    commission_type: `コミッションタイプ`,
    commission: `コミッション`,

    // commission type
    cash: `入金`,
    bet: `ベッティング`,
    lost: `負け`,

    //= tabs
    info: `情報`,
    transaction_history: `保有金額内訳`,
    cash_history: `入/出金 内訳`,
    manual_history: `支給/回収 内訳`,
    point_history: `ポイント内訳`,
    bet_history: `ベッティング内訳`,
    help_history: `問い合わせ内訳`,
    message_history: `メッセージ内訳`,
    referral_list: `推薦人リスト`,
    referral_code: `推薦人コード発給`,

    //= code details
    code_details: `コード情報`,
    code: `コード`,

    users_count: `ユーザー数`,
    banned: `中止`,
    confirmed: `正常`,
    pending: `要請`,
    waiting: `待機`,
    deleted: `削除`,
    rejected: `拒否`,
    total: `全体`,

    confirm: `全体処理`,
    reject: `拒否処理`,
    ban: `中止処理`,
    delete: `削除処理`,

    bank_details: `銀行情報`,
    bank_account: `口座名義`,
    bank_number: `口座番号`,
    bank_name: `銀行名`,

    //= modify phone
    modify_phone: `電話番号修正`,
    current_phone: `現在の電話番号`,
    new_phone: `新しい電話番号`,

    //= modify password
    modify_password: `パスワード修正`,
    current_password: `現在のパスワード`,
    new_password: `新しいパスワード`,

    //= manual cash
    manual_cash: `自動支給(支給/回収)`,
    current_balance: `現在保有金額`,
    amount: `金額`,
    memo: `メモ`,
    content_here: `ここにメモの内容を入力してください`,

    //= edit commission
    edit_commission: `ベッティング スポーツゲーム`,
    current_point: `現在のポイント`,
    memo: `メモ`,

    //= total betting
    bet_sport: `ベッティング スポーツゲーム`,
    bet_mini: `ベッティング ミニゲーム`,
    bet_casino: `ベッティング カジノゲーム`,
    bet_slot: `ベッティング スロットゲーム`,
    bet_virtual: `ベッティング 仮想ゲーム`,

    //= now betting
    betting_sport: `ベッティング`,
    betting_mini: `ベッティング ミニゲーム`,
    betting_casino: `ベッティング カジノゲーム`,
    betting_slot: `ベッティング スロットゲーム`,
    betting_virtual: `ベッティング 仮想ゲーム`,

    //= total win
    win_sport: `勝ち スポーツゲーム`,
    win_mini: `勝ち ミニゲーム`,
    win_casino: `勝ち カジノゲーム`,
    win_slot: `勝ち スロットゲーム`,
    win_virtual: `勝ち 仮想ゲーム`,

    //= commission
    manual_commission: `コミッション修正`,
    commission: `現在のコミッション`,
    new_commission: `新しいコミッション`,
    value_in_decimal: `コミッション`,

    error_amount: `入力額が0で無いか確認してください`,
    not_enough_money: `保有金額が不足しています.`,
    not_enough_points: `ポイントが不足しています`,

    success_manual_transaction: `処理が完了しましたか？`,
    continue_manual_transaction: `処理を進行いたしますか？?`,
    deposit: `支給`,
    withdraw: `回収`,
    clear: `訂正`,
    save: `保存`,
    title_point_inout: `ポイント支給/回収`,
    title_cash_inout: `支給/回収`,
    user_delete: `会員削除`,
    user_modify: `会員修正`,
    coupon_history: 'クーポン内訳',
    settings: '会員別設定',
    coupon: 'クーポン',
    manual_coupon: 'クーポン手動支給/回収',
    stamp_history: '出席内訳',
    logout: 'logout',
    force_logout: 'Force Logout',

    current_coupon: 'Current Coupon'
  }
}
