import axios from 'axios'

export default {
  namespaced: true,
  state: {
    list: []
  },

  mutations: {
    SET_LIST (state, data) {
      state.list =  data
    }
  },

  getters: {},

  actions: {
    async get ({ commit }) {
      let data = await axios.get('/settings/events')
      commit('SET_LIST', data.data)
    },

    async update ({ commit }, { id, data }) {
      return axios.patch(`/settings/events/${id}`, { data })
    }
  }
}
