import axios from 'axios'

export default {
  namespaced: true,
  state: {
    memos: [],
    count: 1
  },
  mutations: {
  },
  getters: {
  },
  actions: {
    async get({ commit, state }, { id, params, status }) {
      try  {
        let response =  await axios.get(`/users/${id}/memo/${status}`, { params })
        state.memos = response.data.data
        state.count = response.data.count[0].total
      } catch (err) {
        console.log(err)
      }
    },
    async create ({ commit, state }, { id, memo }) {
      try {
        await axios.post(`/users/${id}/memo`, { memo })
      } catch (err) {
        console.log(err)
      }
    },
    getCount () {

    },
    async update ({ commit, state }, {uid, mid, data}) {
      try {
        await axios.patch(`/users/${uid}/memo/${mid}`, { data })
      } catch (err) {
        console.log(err)
      }
    },
    async delete ({ commit, state }, { uid, mid }) {
      try {
        await axios.delete(`/users/${uid}/memo/${mid}`)
      } catch (err) {
        console.log(err)
      }
    }
  }
}
