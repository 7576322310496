/**
  import { hasToken } from '@/assets/js/mixins/common/HasToken'

  mixins: [
    hasToken
  ]
**/

import { mapState, mapMutations } from 'vuex'
import { vueLocalStorage } from '@/assets/js/mixins/base/VueLocalStorage'
import { parsify } from '@/assets/js/common/Parsify'

import _pickBy from 'lodash/pickBy'

export const hasToken = {
  data () {
    return {
      siteName: process.env.VUE_APP_NAME || 'LOGO',
      imgPath: process.env.VUE_APP_AWS_URL || process.env.VUE_APP_STATIC_ASSET_PATH || '/'
    }
  },

  computed: {
    ...mapState('settings-menu', {
      navigationSettings: 'navigation',
      menuList: 'menu'
    }),

    hasToken () {
      const localStorage = vueLocalStorage.getItem('token') ? vueLocalStorage.getItem('token') : null

      if (this.$route.query && this.$route.query.r) {
        return null
      }

      return localStorage
    },

    permissionKeys () {
      if (vueLocalStorage.getItem('permission')) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.permissionsKeys = JSON.parse(vueLocalStorage.getItem('permission'))
        return Object.keys(this.permissionsKeys)
      }
      return false
    },

    checkReadWrite () {
      const permission = JSON.parse(vueLocalStorage.getItem('permission'))
      let obj

      for (const key in permission) {
        obj = permission[key]
      }

      return obj === 'rw' ? 1 : ''
    },

    activeNavigation () {
      const list = process.env.VUE_APP_NAV || JSON.stringify(['user', 'cash & point', 'bet', 'code', 'account'])
      return parsify(list)
    }
  },

  methods: {
    ...mapMutations('navTrigger', ['TRIGGER_COUNT']),

    triggerRefresh (path) {
      const to = JSON.stringify(path)
      const current = JSON.stringify(this.$route.path)

      if (to === current) {
        this.TRIGGER_COUNT()
      }
    },

    hideMenu (item) {
      const list = this.menuList
      const menu = list.find(e => e.menu === item)

      return menu && !menu.is_hide
    },

    checkPermission (data) {
      let idx

      if (this.permissionKeys) {
        idx = this.permissionKeys.indexOf(data)
      }

      return idx > -1 ? 1 : ''
    },

    validateNav (data) {
      return this.activeNavigation.indexOf(data) !== -1
    },

    $_sort (sortBy) {
      const params = JSON.parse(JSON.stringify(this.$route.query))

      params.sort = params.sort === 'asc' ? 'desc' : 'asc'
      params.sort_by = sortBy

      this.$router.push({ query: _pickBy(params, Boolean) })
    },

    $_trigger (e) {
      if (e && e.clear) {
        return this.$router.push({ query: {} })
      }

      const query = _pickBy({ ...this.$route.query, ...e }, Boolean)

      return this.$router.push({ query })
    }
  }
}
