import { transfers } from '@/socket'
import { mapActions } from 'vuex'

export default {
  mounted () {
    transfers.on('cash-in', async (message) => {
      message = JSON.parse(message)
      this.setCashInfo(message)
      this.setMeta('cashin')
      this.setNotif([ { key:'cashin', value:  true } ])
    })

    transfers.on('cash-out', async (message) => {
      this.setMeta('cashout')
      this.setNotif([ { key:'cashout', value:  true } ])
    })

    transfers.on('update-status', async (message) => {
      message = JSON.parse(message)
      if (message.data.status.toLowerCase() === 'c') {
        this.setUserInfo({
          event: 'confirm-cash',
          amount: parseInt(message.item.amount),
          user: message.user_id
        })
      }
    })
  },

  methods: {
    ...mapActions('transfer', ['get']),
    ...mapActions('meta', { getAllMeta: 'get', getCashInfo: 'getCashInfo' }),
    ...mapActions('meta', ['getCashInfo', 'setNotif', 'setMeta', 'getManualInfo', 'setCashInfo', 'getTotalInfo']),
    ...mapActions('meta', ['setUserInfo']),
    ...mapActions('user', { getUserInfo: 'get' })
  }
}