import axios from 'axios'
import _ from 'lodash'

export default {
  namespaced: true,

  state: {
    settings: []
  },

  getters: {
    oddsSetting (state) {
      return state.settings
    },
  },

  mutations: {
    SET_SETTINGS (state, settings) {
      state.settings = settings
    }
  },

  actions: {
    async get ({ commit }, params) {
      let { data } = await axios.get(`/settings/goalserveoddssettings/${params.type}`)
      commit('SET_SETTINGS', data[0])

    },
    async update({ commit }, data) {
      let params = {
        id: data.settingId,
        setting_type: data.settingType ? 'g' : 'i'
      }
      await axios.patch(`/settings/goalserveoddssettings/${data.settingId}`, params)
    }
  }
}