<template>
  <modal
    class="modal--tbl-settings"
    :title="translations ? translate(translations, 'edit table settings') : 'edit table settings'"
    v-if="show"
    :activate="show"
    @activate="closeModal"
  >
    <template slot="body">
      <div class="form">
        <div class="form__body">
          <div
            class="form__item"
            v-for="col in allCols"
            :key="col.value"
          >
            <checkbox :label="translations ? translate(translations, col.display) : col.display">
              <input
                type="checkbox"
                v-model="modConfig.tableConfig[tblSetting]"
                :value="col.value"
              />
            </checkbox>
          </div>
        </div>
      </div>
    </template>

    <template slot="footer">
      <button
        class="btn--modify"
        @click="modify"
      >
        <i class="ico--save"></i>
        <span>
          {{ translate(translations, 'modify') }}
        </span>
      </button>
    </template>
  </modal>
</template>

<script>
import { mapMutations } from 'vuex'

//= jquery
import $ from 'jquery'

//= components
import Checkbox from '@/components/base/Checkbox'
import Modal from '@/components/base/Modal'

//= translation
import { translate } from '@/assets/js/translations/Translate'

export default {
  name: 'TableSettings',

  props: {
    activate: Boolean,
    showTblSettings: Boolean,
    allCols: [String, Array, Object],
    tblSetting: [String, Array, Object],
    translations: [Array, Object, String]
  },

  components: {
    Checkbox,
    Modal
  },

  mixins: [
    translate
  ],

  data () {
    return {
      isDraggable: false,
      show: false,

      headerClass: '',
      title_w: '',
      toolbar_w: 'auto',

      modalBody: 'auto'
    }
  },

  watch: {
    activate () {
      if (this.activate) {
        this.modalActive()
      }

      if (!this.activate) {
        this.closeModal()
      }
    }, // activate

    config: {
      handler () {
        if (this.config[this.tblSetting]) {
          this.$emit('input', this.config[this.tblSetting])
        }
      },
      deep: true
    }
  },

  mounted () {
    this.show = this.showTblSettings

    if (this.activate) {
      this.modalActive()
    }
  },

  methods: {
    ...mapMutations('config', { setConfig: 'SET_CONFIG' }),

    modify () {
      this.updateConfig()
      this.closeModal()
      this.$emit('load')
    },

    modalActive () {
      let keys = Object.keys(this.modConfig.tableConfig)
      if (!keys.length || keys.indexOf(this.tblSetting) === -1) {
        let copy = Object.assign({}, this.modConfig)

        let cols = this.allCols.map((e) => {
          return e.value
        })

        copy.tableConfig[this.tblSetting] = cols
        this.setConfig(copy)
      }

      this.show = true
    }, // modalActive

    closeModal () {
      this.show = false
      this.$emit('activate', this.show)
      $('body').removeAttr('style')
      $('body').removeClass('modal--open')
    } // closeModal
  }
}
</script>

<style scoped>
  .check-wrap { margin-right: 10px; }
  label {
    display: inline-block;
  }
</style>
