<template>
  <option
    :value="value"
  >
    <slot></slot>
  </option>
</template>

<script>
export default {
  name: 'option2',

  props: {
    value: [String, Number, Object, Array, Boolean],
    name: [String, Number, Boolean]
  }
}
</script>
