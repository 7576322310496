<template>
  <div
    :class="[
      {'is-test-user': logs && logs.isTester},
      `${logs.game === 1 ? 'betslip--sports' : ''}`
    ]"
    class="betslip"
  >
    <div class="betslip__head">
      <div class="betslip__head-items -user">
        <span
          v-if="logs && logs.isTester"
          class="is-test-user"
          v-text="translate(translations, 'test user')"
        >
        </span>

        <!-- If have users -->
        <template v-if="logs.login_id || logs.login_name">
          <div class="user-color">
            <span class="mr-1">
              {{ translate(translations, 'user') }}:
            </span>

            <span
              class="betslip__login-id mr-1"
              @click="openUser(logs.user_id, 'bet-history/all/all')"
              v-text="logs.login_id"
            >
            </span>
            /
            <span
              class="betslip__login-name mx-1"
              @click="openUser(logs.user_id, 'bet-history/all/all')"
              v-text="logs.login_name"
            >
            </span>
          </div>
        </template>

        <!-- `isNewGame` is based on a prop, `logs.category` is from api-->
        <template v-if="isNewGame || newGames.includes(logs.category)">
          <span class="btn--create ml-1">
            {{ translate(translations, logs.category) }}
          </span>
        </template>

        <template v-else>
          <span
            :class="[
              { 'btn--modify': logs.game === 20 },
              { 'btn--create': logs.game !== 1 && logs.game !== 20 }
            ]"
            class="btn--default"
          >
            <!-- {{ translate(translations, displayGame(logs.game)) }} -->
            {{ translate(translations, 'game ' + logs.game) }}
          </span>
        </template>

        <div
          v-if="(logs.bet_group_id || logs.id) || logs.txn_id || logs.ubgID"
          class="bet-group-id"
        >
          <template v-if="logs.category === 'token'">
            #{{ logs.txn_id }}
          </template>

          <template v-else-if="logs.category === 'bet365'">
            #{{ logs.bet_group_id || logs.ubgID }}
          </template>

          <template v-else>
            #{{ logs.bet_group_id || logs.id }}
          </template>
        </div>
      </div>

      <div class="betslip__head-items betslip__date">
        <div class="betslip__date-item -delete">
          <span></span>
          <span>
            <template
              v-if="logs.bet_deleted_at"
              class="betslip__date--deleted"
            >
              <strong>{{ translate(translations, 'deleted date') }}:</strong>
              {{ logs.bet_deleted_at | date }}
            </template>
          </span>
        </div>

        <div class="betslip__date-item">
          <span>
            <template v-if="logs.cancel_ip">
              <strong>{{ translate(translations, 'cancel ip') }}:</strong>
              {{ logs.cancel_ip }}
            </template>
          </span>

          <span>
            <template v-if="logs.bet_cancel_time">
              <strong>{{ translate(translations, 'cancelled date') }}:</strong>
              {{ logs.bet_cancel_time | date }}
            </template>

            <template v-if="logs.is_cancel && logs.category === 'token'">
              <strong>{{ translate(translations, 'cancelled date') }}:</strong>
              {{ logs.updated_at | date }}
            </template>
          </span>
        </div>

        <div class="betslip__date-item">
          <span>
            <!-- <template v-if="logs.bet_ip">
              <strong>{{ translate(translations, 'bet ip') }}:</strong>
              {{ logs.bet_ip || '' }}
            </template> -->
          </span>

          <span>
            <template v-if="logs.bet_time">
              <strong>{{ translate(translations, 'bet date') }}:</strong>
              {{ logs.bet_time || '' | date }}
            </template>
          </span>
        </div>
      </div>

      <div
        v-if="$route.name !== 'requests-details-page' && $route.name !== 'freeboard-details'"
        class="betslip__head-items betslip__button"
      >
        <template v-if="(isNewGame && ['bet365', 'token', 'casino'].indexOf(game) !== -1) && !logs.bet_cancel_time && logs.status === 'waiting'">
          <button
            v-if="checkReadWrite"
            class="btn--delete"
            @click="updateCard('cancel', logs)"
          >
            <span>
              {{ translate(translations, 'cancel') }}
            </span>
          </button>
        </template>

        <template v-else-if="(!isNewGame || ['bet365', 'token', 'casino'].indexOf(game) === -1) && !logs.bet_cancel_time && modifyStatus(logs) === 'waiting'">
          <button
            v-if="checkReadWrite"
            class="btn--delete"
            @click="updateCard('cancel', logs)"
          >
            <span>
              {{ translate(translations, 'cancel') }}
            </span>
          </button>
        </template>
      </div>
    </div>

    <div class="betslip__body">
      <template v-if="category === 'bet365'">
        <div class="betslip__body-item">
          <div class="bet__items bet__items--date">
            {{ logs.korea_time | date('MM/DD HH:mm') }}
          </div>

          <div class="bet__items bet__items--match-type">
            {{ translate(translations, logs.sports) }}
          </div>

          <!-- gametype if soccer -->
          <template v-if="logs.sports === 'Soccer'">
            <div class="bet__items bet__items--league-name with-round">
              {{ translate(translations, logs.stadium) }}

              <span class="bet__items-round">
                {{ logs.local_time | date('HH.mm') }}
              </span>
            </div>

            <div
              class="bet__items bet__items--bettables bet__items--home"
              :class="{
                '-selected' : logs.betKey === 0
              }"
            >
              <div class="bet__items-team">
                <template v-if="lang === 'en'">
                  {{ logs.home_team }}
                </template>

                <template v-if="lang === 'kr'">
                  {{ logs.home_team_kor }}
                </template>
              </div>

              <div class="bet__items-odds odds-lbl">
                {{ modifyOdds({ odds: logs.betOdds, gameType: logs.sports, game: 'bet365', location: 'home' }) }}
              </div>
            </div>

            <div
              class="bet__items bet__items--middle"
              :class="{
                '-selected' : logs.betKey === 1
              }"
            >
              <div class="bet__items-team">
                {{ modifyOdds({ odds: logs.betOdds, gameType: logs.sports, game: 'bet365', location: 'draw' }) }}
              </div>
            </div>

            <div
              class="bet__items bet__items--bettables bet__items--away"
              :class="{
                '-selected' : logs.betKey === 2
              }"
            >
              <div class="bet__items-odds odds-lbl odds-lbl--away">
                {{ modifyOdds({ odds: logs.betOdds, gameType: logs.sports, game: 'bet365', location: 'away' }) }}
              </div>

              <div class="bet__items-team">
                <template v-if="lang === 'en'">
                  {{ logs.away_team }}
                </template>

                <template v-if="lang === 'kr'">
                  {{ logs.away_team_kor }}
                </template>
              </div>
            </div>
          </template>

          <template v-else>
            <div class="bet__items bet__items--league-name with-round">
              {{ translate(translations, logs.stadium) }}

              <span class="bet__items-round">
                {{ logs.local_time | date('HH.mm') }}
              </span>
            </div>

            <div
              class="bet__items bet__items--bettables bet__items--home -selected"
            >
              <div class="bet__items-team">
                {{ logs.jsonBetName }}
              </div>

              <div class="bet__items-odds odds-lbl">
                {{ logs.jsonBetOdds }}
              </div>
            </div>

            <div
              class="bet__items bet__items--middle"
            >
              <div class="bet__items-team">
                VS
              </div>
            </div>

            <div
              class="bet__items bet__items--bettables bet__items--away"
            >
              <div class="bet__items-team">
                {{ translate(translations, logs.result_string && logs.result_string.replace(/,/, '. ') || '') }}
              </div>
            </div>
          </template>

          <div class="bet__items bet__items--result">
            <div class="bet__result bet__result--score">
              {{ bet365Score({ game: logs.sports, betType: logs.betKey, amount: logs.win_amount, result: logs.result_status, odds: logs.betOdds, winResult: logs.win_result }) }}
            </div>

            <div
              class="bet__result bet__result--status"
              :class="displayStatus(logs.result_status)"
            >
              {{ translate(translations, displayStatus(logs.result_status)) }}
            </div>
          </div>
        </div>
      </template>

      <template v-else-if="category === 'token'">
        <div class="betslip__body-item">
          <div class="bet__items bet__items--date">
            {{ logs.created_at | date('MM-DD HH:mm') }}
          </div>

          <div class="bet__items bet__items--match-type">
            <template v-if="lang === 'en'">
              {{ logs.name }}
            </template>

            <template v-if="lang === 'kr'">
              {{ logs.kor || logs.name }}
            </template>
          </div>

          <div class="bet__items bet__items--bettables with-round">
            <span class="bet__items-round">
              <template v-if="lang === 'en'">
                {{ translate(translations, 'round') }}: {{ logs.round_id }}
              </template>

              <template v-if="lang === 'kr'">
                {{ logs.round_id }} {{ translate(translations, 'round') }}
              </template>
            </span>
          </div>

          <div
            class="bet__items bet__items--bettables bet__items--home"
            :class="{
              '-selected':
                (logs.name === 'baccarat' && (logs.bet_type.toLowerCase() === 'player' || logs.bet_type.toLowerCase() === 'ppair' || logs.bet_type.toLowerCase() === 'bpair')) ||
                (logs.name === 'roulette' && logs.bet_type.toLowerCase() === 'red') ||
                (logs.name === 'hilo' && (logs.bet_type.toLowerCase() === 'twonine' || logs.bet_type.toLowerCase() === 'hi' || logs.bet_type.toLowerCase() === 'lo' || logs.bet_type.toLowerCase() === 'joker' || logs.bet_type.toLowerCase() === 'red'))
            }"
          >
            <div class="bet__items-team">
              <template v-if="logs.name === 'baccarat'">
                <template v-if="logs.bet_type.toLowerCase() === 'player' || logs.bet_type.toLowerCase() === 'tie' || logs.bet_type.toLowerCase() === 'banker'">
                  {{ translate(translations, 'Player') }}
                </template>

                <template v-if="logs.bet_type.toLowerCase() === 'ppair'">
                  {{ translate(translations, 'Player Pair') }}
                </template>

                <template v-if="logs.bet_type.toLowerCase() === 'bpair'">
                  {{ translate(translations, 'Banker Pair') }}
                </template>
              </template>

              <template v-if="logs.name === 'roulette'">
                {{ translate(translations, 'red') }}
              </template>

              <template v-if="logs.name === 'hilo'">
                <template v-if="logs.bet_type.toLowerCase() === 'twonine' || logs.bet_type.toLowerCase() === 'jqka'">
                  3 ~ 8
                </template>

                <template v-if="logs.bet_type.toLowerCase() === 'lo' || logs.bet_type.toLowerCase() === 'hi'">
                  {{ translate(translations, logs.bet_type) }}
                </template>

                <template v-if="logs.bet_type.toLowerCase() === 'joker'">
                  {{ translate(translations, 'joker') }}
                </template>

                <template v-if="logs.bet_type.toLowerCase() === 'black' || logs.bet_type.toLowerCase() === 'red'">
                  {{ translate(translations, 'red') }}
                </template>
              </template>
            </div>

            <div class="bet__item-odds">
              <template v-if="logs.name === 'baccarat'">
                <template v-if="logs.bet_type.toLowerCase() === 'player' || logs.bet_type.toLowerCase() === 'tie' || logs.bet_type.toLowerCase() === 'banker'">
                  {{ tokenOdds({ game: logs.name, betType: 'player' }) }}
                </template>

                <template v-if="logs.bet_type.toLowerCase() === 'ppair'">
                  {{ tokenOdds({ game: logs.name, betType: 'ppair' }) }}
                </template>

                <template v-if="logs.bet_type.toLowerCase() === 'bpair'">
                  {{ tokenOdds({ game: logs.name, betType: 'bpair' }) }}
                </template>
              </template>

              <template v-if="logs.name === 'roulette'">
                {{ tokenOdds({ game: logs.name, betType: 'red' }) }}
              </template>

              <template v-if="logs.name === 'hilo'">
                <template v-if="logs.bet_type.toLowerCase() === 'twonine' || logs.bet_type.toLowerCase() === 'jqka'">
                  {{ tokenOdds({ game: logs.name, betType: '3-8' }) }}
                </template>

                <template v-if="logs.bet_type.toLowerCase() === 'lo' || logs.bet_type.toLowerCase() === 'hi'">
                  {{ tokenOdds({ game: logs.name, betType: logs.bet_type, oddsData: logs.bet_rate }) }}
                </template>

                <template v-if="logs.bet_type.toLowerCase() === 'joker'">
                  {{ tokenOdds({ game: logs.name, betType: 'joker' }) }}
                </template>

                <template v-if="logs.bet_type.toLowerCase() === 'black' || logs.bet_type.toLowerCase() === 'red'">
                  {{ tokenOdds({ game: logs.name, betType: 'red' }) }}
                </template>
              </template>
            </div>
          </div>

          <div
            class="bet__items bet__items--bettables bet__items--middle"
            :class="{
              '-selected':
                (logs.name === 'baccarat' && logs.bet_type.toLowerCase() === 'tie') ||
                (logs.name === 'roulette' && logs.bet_type.toLowerCase() === 'green')
            }"
          >
            <template v-if="logs.name === 'baccarat'">
              <template v-if="logs.bet_type.toLowerCase() === 'player' || logs.bet_type.toLowerCase() === 'tie' || logs.bet_type.toLowerCase() === 'banker'">
                8
              </template>
              <template v-else>
                VS
              </template>
            </template>

            <template v-if="logs.name === 'roulette'">
              10
            </template>

            <template v-if="logs.name === 'hilo'">
              <template v-if="logs.bet_type.toLowerCase() === 'joker' || logs.bet_type.toLowerCase() === 'lo' || logs.bet_type.toLowerCase() === 'hi'">
                VS
              </template>

              <template v-else>
                0
              </template>
            </template>
          </div>

          <div
            class="bet__items bet__items--bettables bet__items--away"
            :class="{
              '-selected':
                (logs.name === 'baccarat' && logs.bet_type.toLowerCase() === 'banker') ||
                (logs.name === 'roulette' && logs.bet_type.toLowerCase() === 'black') ||
                (logs.name === 'hilo' && (logs.bet_type.toLowerCase() === 'jqka' || logs.bet_type.toLowerCase() === 'black'))
            }"
          >
            <div class="bet__item-odds">
              <template v-if="logs.name === 'baccarat'">
                <template v-if="logs.bet_type.toLowerCase() === 'player' || logs.bet_type.toLowerCase() === 'tie' || logs.bet_type.toLowerCase() === 'banker'">
                  {{ tokenOdds({ game: logs.name, betType: 'banker' }) }}
                </template>
              </template>

              <template v-if="logs.name === 'roulette'">
                {{ tokenOdds({ game: logs.name, betType: 'black' }) }}
              </template>

              <template v-if="logs.name === 'hilo'">
                <template v-if="logs.bet_type.toLowerCase() === 'twonine' || logs.bet_type.toLowerCase() === 'jqka'">
                  {{ tokenOdds({ game: logs.name, betType: 'jqka' }) }}
                </template>

                <!-- <template v-if="logs.bet_type.toLowerCase() === 'lo' || logs.bet_type.toLowerCase() === 'hi'">
                  {{ tokenOdds({ game: logs.name, betType: 'lo' }) }}
                </template> -->

                <template v-if="logs.bet_type.toLowerCase() === 'black' || logs.bet_type.toLowerCase() === 'red'">
                  {{ tokenOdds({ game: logs.name, betType: 'black' }) }}
                </template>
              </template>
            </div>

            <div class="bet__items-team">
              <template v-if="logs.name === 'baccarat'">
                <template v-if="logs.bet_type.toLowerCase() === 'player' || logs.bet_type.toLowerCase() === 'tie' || logs.bet_type.toLowerCase() === 'banker'">
                  {{ translate(translations, 'Banker') }}
                </template>
              </template>

              <template v-if="logs.name === 'roulette'">
                {{ translate(translations, 'black') }}
              </template>

              <template v-if="logs.name === 'hilo'">
                <template v-if="logs.bet_type.toLowerCase() === 'twonine' || logs.bet_type.toLowerCase() === 'jqka'">
                  J, Q, K, A
                </template>

                <template v-if="logs.bet_type.toLowerCase() === 'lo' || logs.bet_type.toLowerCase() === 'hi'">
                  <!-- {{ translate(translations, 'hi') }} -->
                </template>

                <template v-if="logs.bet_type.toLowerCase() === 'black' || logs.bet_type.toLowerCase() === 'red'">
                  {{ translate(translations, 'black') }}
                </template>
              </template>
            </div>
          </div>

          <div class="bet__items bet__items--result">
            <div class="bet__result bet__result--score">
              {{ tokenScore(logs) }}
            </div>

            <div
              class="bet__result bet__result--status"
              :class="tokenStatus({ logs: logs, overview: false })"
            >
              {{ translate(translations, tokenStatus({ logs: logs, overview: false })) }}
            </div>
          </div>
        </div>
      </template>

      <template v-else>
        <slot></slot>
      </template>
    </div>

    <div class="betslip__overview">
      <template v-if="(isNewGame && game === 'bet365') || category === 'bet365'">
        <div class="betslip__overview-item betslip__overview-item--amount">
          <div class="betslip__label">
            {{ translate(translations, 'bet amount') }}
          </div>

          <div class="betslip__data">
            {{ (logs.amount || logs.bet_amount) | FormatAmount('whole') }}
          </div>
        </div>

        <div class="betslip__overview-item betslip__overview-item--odds">
          <div class="betslip__label">
            {{ translate(translations, 'odds') }}
          </div>

          <div class="betslip__data">
            {{ logs.jsonBetOdds }}
          </div>
        </div>

        <div class="betslip__overview-item betslip__overview-item--bonus"></div>

        <div class="betslip__overview-item betslip__overview-item--est-win">
          <div class="betslip__label">
            {{ translate(translations, 'estimated win') }}
          </div>

          <div class="betslip__data">
            {{ logs.est_amount | FormatAmount('whole') }}
          </div>
        </div>

        <div class="betslip__overview-item betslip__overview-item--win">
          <div class="betslip__label">
            {{ translate(translations, 'new bet win') }}
          </div>

          <div class="betslip__data">
            {{ logs.win_amount | FormatAmount('whole') }}
          </div>
        </div>

        <div
          class="betslip__overview-item betslip__overview-item--status"
          :class="`is-${displayStatus(logs.result_status) === 'void' ? 'win' : displayStatus(logs.result_status)}`"
        >
          <div class="betslip__label">
            {{ translate(translations, 'status') }}
          </div>

          <div class="betslip__data">
            {{ translate(translations, displayStatus(logs.result_status) === 'void' ? 'win' : displayStatus(logs.result_status)) }}
          </div>
        </div>
      </template>

      <template v-else-if="(isNewGame && game === 'token') || category === 'token'">
        <div class="betslip__overview-item betslip__overview-item--amount">
          <div class="betslip__label">
            {{ translate(translations, 'bet amount') }}
          </div>

          <div class="betslip__data">
            {{ logs.bet_amount | FormatAmount('whole') }}
          </div>
        </div>

        <div class="betslip__overview-item betslip__overview-item--odds">
          <div class="betslip__label">
            {{ translate(translations, 'odds') }}
          </div>

          <div class="betslip__data">
            {{ logs.bet_rate }}
          </div>
        </div>

        <div class="betslip__overview-item betslip__overview-item--bonus"></div>

        <div class="betslip__overview-item betslip__overview-item--est-win"></div>

        <div class="betslip__overview-item betslip__overview-item--win">
          <div class="betslip__label">
            {{ translate(translations, 'new bet win') }}
          </div>

          <div class="betslip__data">
            {{ logs.result_amount | FormatAmount('whole') }}
          </div>
        </div>

        <div
          class="betslip__overview-item betslip__overview-item--status"
          :class="`is-${tokenStatus({ logs: logs, overview: true })}`"
        >
          <div class="betslip__label">
            {{ translate(translations, 'status') }}
          </div>

          <div class="betslip__data">
            {{ translate(translations, tokenStatus({ logs: logs, overview: true })) }}
          </div>
        </div>
      </template>

      <template v-else-if="(isNewGame && game === 'casino') || category === 'casino'">
        <div class="betslip__overview-item betslip__overview-item--amount">
          <div class="betslip__label">
            {{ translate(translations, 'bet amount') }}
          </div>

          <div class="betslip__data">
            {{ logs.debit_amount | FormatAmount }}
          </div>
        </div>

        <div class="betslip__overview-item betslip__overview-item--win">
          <div class="betslip__label">
            {{ translate(translations, 'win amount') }}
          </div>

          <div class="betslip__data">
            {{ logs.win_amount | FormatAmount('whole') }}
          </div>
        </div>

        <div class="betslip__overview-item betslip__overview-item--odds">
          <div class="betslip__label">
            {{ translate(translations, 'transaction number') }}
          </div>

          <div class="betslip__data">
            {{ logs.txn_id }}
          </div>
        </div>

        <div
          class="betslip__overview-item betslip__overview-item--status"
          :class="[
            `is-${logs.status}`,
            `${logs.bet_cancel_time ? 'is-cancelled' : ''}`
          ]"
        >
          <div class="betslip__label">
            {{ translate(translations, 'status') }}
          </div>

          <div class="betslip__data is-amount">
            {{ translate(translations, logs.status === 'tie' ? 'draw' : logs.status) }}
          </div>
        </div>
      </template>

      <template v-else>
        <div class="betslip__overview-item betslip__overview-item--amount">
          <div class="betslip__label">
            {{ translate(translations, 'bet amount') }}
          </div>

          <div class="betslip__data">
            {{ logs.bet_amount | FormatAmount('whole') }}
          </div>
        </div>

        <div class="betslip__overview-item betslip__overview-item--odds">
          <div class="betslip__label">
            {{ translate(translations, 'odds') }}
          </div>

          <div class="betslip__data">
            {{ logs.est_odds || 0.00 }}
          </div>
        </div>

        <div class="betslip__overview-item betslip__overview-item--bonus">
          <template v-if="logs.bonus && logs.bonus !== 1">
            <div class="betslip__label">
              {{ translate(translations, 'bonus') }}
            </div>

            <div class="betslip__data">
              {{ logs.bonus || 1 }}
            </div>
          </template>
        </div>

        <div class="betslip__overview-item betslip__overview-item--est-win">
          <div class="betslip__label">
            {{ translate(translations, 'estimated win') }}
          </div>

          <div class="betslip__data">
            {{ (logs.est_amount || 0.00) | FormatAmount('whole') }}
          </div>
        </div>

        <div class="betslip__overview-item betslip__overview-item--win">
          <div class="betslip__label">
            {{ translate(translations, 'win') }}
          </div>

          <div class="betslip__data">
            <template v-if="logs.bet_cancel_time">
              {{ logs.bet_amount | FormatAmount('whole') }}
            </template>

            <template v-else>
              {{ logs.win_amount | FormatAmount('whole') }}
            </template>
          </div>
        </div>

        <div
          class="betslip__overview-item betslip__overview-item--status"
          :class="[
            `is-${modifyStatus(logs)}`,
            `${logs.bet_cancel_time ? 'is-cancelled' : ''}`
          ]"
        >
          <div class="betslip__label">
            {{ translate(translations, 'status') }}
          </div>

          <div class="betslip__data is-amount">
            <template v-if="logs.bet_cancel_time">
              {{ translate(translations, `${logs.cancel_user_type} cancel`) }}
            </template>

            <template v-else>
              {{ translate(translations, modifyStatus(logs)) }}
            </template>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { mapState, mapActions } from 'vuex'

//= lib
import _isEmpty from 'lodash/isEmpty'

//= mixins
import siteDefaults from '@/assets/js/mixins/base/SiteDefaults'

//= components
import FormatAmount from '@/assets/js/filters/FormatAmount'

//= translation
import { translations } from '@/assets/js/translations/Betslip'

const getKey = (key, obj) => obj[key] === undefined ? obj.default : obj[key]

export default {

  filters: {
    FormatAmount
  },

  mixins: [
    siteDefaults
  ],

  props: {
    logs: {
      type: Object,
      required: true,
      default: () => ({})
    },
    game: {
      type: String,
      default: ''
    },
    showButtons: [Boolean],
    checkbox: [Boolean]
  },

  data () {
    return {
      translations,
      select: [],
      newGames: ['casino', 'token', 'bet365']
    }
  },

  computed: {
    category () {
      return this.logs.category || 'default'
    },

    isNewGame () {
      return this.newGames.includes(this.game)
    },

    estTotalOdds () {
      let totalOdds = 1
      this.logs.userBets.forEach(data => {
        if (parseInt(data.match_game.match_table_id) !== 1 && parseInt(data.match_game.match_table_id) !== 20) {
          totalOdds *= parseFloat(data[`${data.betArea}_val`])
        } else {
          totalOdds *= parseFloat(data.value)
        }
      })
      return Math.floor(totalOdds * 100) / 100
    },

    actualOdds () {
      let actualOdds = 1

      this.logs.userBets.forEach(data => {
        actualOdds *= parseFloat(data.result_modifier).toFixed(2)
      })

      return Math.floor((actualOdds * 2) / 2)
    },

    estTotalWins () {
      return Math.round(this.logs.est_val * this.logs.bet_amount * Number(this.logs.bonus || 1))
    },

    checkStatus () {
      let status = ''
      if (this.logs.isBetting && !this.logs.isResult && !this.logs.isCancel) {
        status = 'Waiting'
      }

      if (!this.logs.isBetting && !this.logs.isResult && !this.logs.isCancel) {
        status = 'Ongoing'
      }
      if (!this.logs.isBetting && this.logs.isResult && !this.logs.isCancel) {
        status = 'Done'
      }

      if (this.logs.isCancel) {
        status = 'Cancelled'
      }

      return status
    }
  },

  watch: {
    selected (val) {
      this.select = val
    }
  },

  methods: {
    ...mapActions('bet-history', ['get', 'restore', 'cancel', 'delete']),

    _isEmpty,

    // eslint-disable-next-line require-await
    async updateCard (action, log) {
      let swalTitle

      if (action === 'cancel') {
        swalTitle = this.translate(translations, 'cancel bet')
      } else {
        swalTitle = action
      }

      const swalMsg = ''

      this.$alert('prompt', swalMsg, '', async () => {
        const data = {
          action: action,
          id: log.id
        }

        const params = {}
        params.id = data.id
        if (data.action === 'cancel') {
          params.isCancel = 1
          await this.cancel(params)
          log.isCancel = 1
          // eslint-disable-next-line camelcase
          log.cancelled_at = new Date()
          location.reload()
        }

        if (data.action === 'delete') {
          await this.delete(params)
          // eslint-disable-next-line camelcase
          log.deleted_at = new Date()
        }

        if (data.action === 'restore') {
          params.isHide = 0
          await this.restore(params)
        }
      }, swalTitle)
    },

    emit (action, id) {
      const data = {
        action: action,
        id: id.id,
        data: id
      }
      this.$emit('update', data)
    },

    modifyOdds ({ odds, gameType = null, game = null, location = null }) {
      location = location.toLowerCase()

      if (!odds) {
        return
      }

      switch (location) {
        case 'home':
          location = 0
          break
        case 'middle':
          location = 1
          break
        case 'away':
          location = 2
          break
        default:
          location = 1
      }

      odds = typeof odds === 'string' ? JSON.parse(odds) : odds
      odds = odds.map(e => {
        if (!Array.isArray(e)) {
          e = e.split(':')
        }
        return e
      })

      if (gameType) {
        gameType = gameType.toLowerCase()
      }

      let currOdds = []

      if (gameType === 'soccer') {
        currOdds = odds[location][1]
      }

      return currOdds
    },

    displayStatus (name) {
      if (name) {
        name = name.toLowerCase()
      }

      return getKey(name, {
        d: 'done',
        l: 'lose',
        p: 'pending',
        c: 'cancelled',
        v: 'void',
        w: 'win'
      })
    },

    modifyStatus (logs) {
      const betInfo = logs.bet_info || []
      const betInfoLength = betInfo.length
      let status = logs.status

      if (!_isEmpty(betInfo.filter(e => e.status === 'ongoing'))) {
        status = 'ongoing'
      }

      if (_isEmpty(betInfo.filter(e => ['waiting', 'ongoing', 'lost'].indexOf(e.status) !== -1))) {
        status = 'win'
      } else {
        status = 'ongoing'
      }

      if (!_isEmpty(betInfo.filter(e => ['cancel', 'waiting'].indexOf(e.status) !== -1)) && (betInfo.filter(e => ['cancel', 'waiting'].indexOf(e.status) !== -1).length === betInfoLength)) {
        status = 'waiting'
      }

      if (!_isEmpty(betInfo.filter(e => e.status === 'waiting')) && (betInfo.filter(e => e.status === 'waiting').length === betInfoLength)) {
        status = 'waiting'
      }

      if (!_isEmpty(betInfo.filter(e => e.status === 'cancel')) && (betInfo.filter(e => e.status === 'cancel').length === betInfoLength)) {
        if (logs.win_amount > 0) {
          status = 'win'
        } else {
          status = 'lost'
        }
      }

      if (!_isEmpty(betInfo.filter(e => e.status === 'lost'))) {
        status = 'lost'
      }

      return status
    },

    bet365Score ({ game, betType, amount, result, odds, winResult }) {
      let response = 'no score'

      result = this.displayStatus(result)
      game = game && game.toLowerCase()

      odds = typeof odds === 'string' ? JSON.parse(odds) : odds
      odds = odds.map(e => {
        if (!Array.isArray(e)) {
          e = e.split(':')
        }

        return e.indexOf(winResult)
      })

      const winIndex = odds.findIndex(e => e !== -1)

      if (game === 'soccer') {
        if (result === 'lose') {
          if (betType === 0) {
            response = '0 : 1'
          }

          if (betType === 1) {
            response = '1 : 0'

            if (winIndex === 0) {
              response = '1 : 0'
            }

            if (winIndex === 1) {
              response = '1 : 1'
            }

            if (winIndex === 2) {
              response = '0 : 1'
            }
          }

          if (betType === 2) {
            response = '1 : 0'
          }
        }

        if (result === 'win') {
          if (betType === 0) {
            response = '1 : 0'
          }

          if (betType === 1) {
            response = '1 : 1'
          }

          if (betType === 2) {
            response = '0 : 1'
          }
        }
      } else {
        if (result === 'lose') {
          response = '0 : 1'
        } else {
          response = '1 : 0'
        }
      }

      if (result === 'void' || result === 'pending' || result === 'cancelled') {
        response = '0 : 0'
      }

      return response
    },

    tokenStatus ({ logs, overview = true }) {
      const betAmount = logs.bet_amount
      const winAmount = logs.result_amount
      const result = logs.result
      const status = logs.status
      const betStatus = logs.bet_status

      let response

      if (!overview) {
        if (betStatus === 'void') {
          return betStatus
        }
      } else {
        if (betStatus === 'void') {
          return 'win'
        }
      }

      if (result === null || betStatus === 'rollback') {
        return 'waiting'
      }

      if (!overview) {
        if (Number(betAmount) === Number(winAmount)) {
          return 'void'
        }
      }

      if (winAmount === 0) {
        response = 'lose'
      }

      if (winAmount > 0) {
        response = 'win'
      }

      return response
    },

    tokenScore (logs) {
      const betType = logs.bet_type.toLowerCase()
      const betAmount = logs.bet_amount
      const winAmount = logs.result_amount
      const result = typeof logs.result === 'string' ? JSON.parse(logs.result) : logs.result
      const status = logs.status
      const betStatus = logs.bet_status

      let response

      if (betStatus === 'void') {
        return '1 : 1'
      }

      if (betStatus === 'rollback') {
        return '0 : 0'
      }

      if (result) {
        const win = typeof result.win === 'number' ? Number(result.win) : result.win.toLowerCase()

        if (win === 0 || win === 'tie' || result.status === 'tie') {
          response = '1 : 1'

          if (betAmount === winAmount) {
            return '1 : 1'
          }
        }

        if (winAmount === 0) {
          const home = ['player', 'bpair', 'ppair', 'twonine', 'lo', 'hi', 'red', 'joker']
          const away = ['banker', 'black', 'jqka']
          const middle = ['tie', 'green']

          if (home.indexOf(betType) !== -1) {
            response = '0 : 1'
          }

          if (away.indexOf(betType) !== -1) {
            response = '1 : 0'
          }

          if ((win === 'player' || win <= 7) && middle.indexOf(betType) !== -1) {
            response = '1 : 0'
          }

          if ((win === 'banker' || win >= 8) && middle.indexOf(betType) !== -1) {
            response = '0 : 1'
          }

          if (betType === 'twonine' || betType === 'jqka') {
            if (win.indexOf('2') !== -1 || win.indexOf('9') !== -1 || win.indexOf('10') !== -1) {
              response = '1 : 1'
            }
          }

          if (win === 'tie' && betType === 'tie') {
            response = '1 : 1'
          }

          if ((win === 0 && betType === 'green') || win === 0) {
            response = '1 : 1'
          }

          if (win === 'joker' && ['jqka', 'twonine', 'red', 'black'].indexOf(betType) !== -1) {
            response = '1 : 1'
          }
        }

        if (winAmount > 0) {
          const home = ['player', 'bpair', 'ppair', 'twonine', 'lo', 'hi', 'red', 'joker']
          const away = ['banker', 'black', 'jqka']
          const middle = ['tie', 'green']

          if (home.indexOf(betType) !== -1) {
            response = '1 : 0'
          }

          if (away.indexOf(betType) !== -1) {
            response = '0 : 1'
          }

          if ((win === 'player' || win <= 7) && middle.indexOf(betType) !== -1) {
            response = '0 : 1'
          }

          if ((win === 'banker' || win >= 8) && middle.indexOf(betType) !== -1) {
            response = '1 : 0'
          }

          if ((win === 0 && betType === 'green') || win === 0) {
            response = '1 : 1'
          }

          if (win === 'tie' && betType === 'tie') {
            response = '1 : 1'
          }
        }
      }

      return response
    },

    tokenOdds ({ game, betType, oddsData = null }) {
      game = game && game.toLowerCase()
      betType = betType && betType.toLowerCase()

      let odds

      if (game === 'baccarat') {
        if (betType === 'tie') {
          odds = 8
        }

        if (betType === 'player') {
          odds = 1.98
        }

        if (betType === 'banker') {
          odds = 1.94
        }

        if (betType === 'bpair') {
          odds = 11
        }

        if (betType === 'ppair') {
          odds = 11
        }
      }

      if (game === 'roulette') {
        if (betType === 'black') {
          odds = 1.95
        }

        if (betType === 'red') {
          odds = 1.95
        }

        if (betType === 'green') {
          odds = 10
        }
      }

      if (game === 'hilo') {
        if (betType === '3-8') {
          odds = 1.6
        }

        if (betType === 'red') {
          odds = 1.95
        }

        if (betType === 'black') {
          odds = 1.95
        }

        if (betType === 'jqka') {
          odds = 2.5
        }
        if (betType === 'joker') {
          odds = 20
        }

        if (betType === 'hi' || betType === 'lo') {
          odds = oddsData
        }
      }
      return odds
    },

    displayGame (game) {
      let gameName = null

      if (game === 1) {
        gameName = 'sports'
      }

      if ((game > 1 && game < 20) || (game > 20 && game < 65)) {
        gameName = 'mini games'
      }

      if (game === 20) {
        gameName = 'inplay'
      }

      return gameName
    }
  }
}
</script>

<style scoped>
  code {
    font-weight: 900;
    text-decoration: underline;
  }

  .-date {
    width: auto !important;
    display: flex !important;
    flex-direction: row !important;
    text-align: right !important;
    align-items: center !important;
    justify-content: flex-end !important;
  }

  .mr-1 {
    margin-right: 5px;
  }

  .mx-1 {
    margin-right: 5px;
    margin-left: 5px;
  }

  .ml-1 {
    margin-left: 5px;
  }
</style>
