import axios from 'axios'

export default {
  namespaced: true,

  state: {
    notices: [],
    listNotices: [],
    count: 0
  },

  mutations: {
    SET_NOTICES (state, notices) {
      state.notices = notices
    },

    LIST_NOTICES (state, notices) {
      state.listNotices = notices
    },

    UPDATE_NOTICE (state, notice) {
      let index = state.notices
        .findIndex((row) => { return row.id === notice.id })
      state.notices[index] = Object.assign(state.notices[index], notice)
    },

    SET_COUNT (state, count) {
      state.count = count[0].total
    }
  },

  getters: {
    notices (state) {
      return state.notices
    },

    listNotices (state) {
      return state.listNotices
    },

    count (state) {
      return state.count
    }
  },

  actions: {
    async get ({ commit }, { status, params }) {
      let notices = await axios
        .get(`/notices/${status}`, { params })

      let count = await axios
        .get(`/notices/${status}/count`, { params })

      commit('SET_NOTICES', notices.data)
      commit('SET_COUNT', count.data)

      return notices.data
    },

    async list ({ commit }, { status, params }) {
      let notices = await axios
        .get(`/notices/${status}`, { params })

      commit('LIST_NOTICES', notices.data)
    },

    async create ({ commit }, params) {
      let data = await axios.post('/notices', params)
      commit('CLEAR_COMMENTS')
      return data.data
    },

    async delete ({ commit }, id) {
      await axios.delete(`/notices/${id}`)
    },

    async update ({ commit }, notice) {
      let sql = await axios.patch(`/notices/${notice.id}`, notice)
      commit('UPDATE_NOTICE', notice)
    }
  }
}