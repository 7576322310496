import axios from 'axios'
import ObjToFormData from '@/assets/js/common/ObjToFormData.js'

export default {
  namespaced: true,
  state: {
    popupSettings: null,
    couponSettings: null
  },

  mutations: {
    SET_DATA (state, data) {
      data.count_settings = typeof data.count_settings === 'string' ? JSON.parse(data.count_settings) : data.count_settings
      state.couponSettings = data
    },

    SET_POPUP_DATA (state, data) {
      data.popup_event_settings = typeof data.popup_event_settings === 'string' ? JSON.parse(data.popup_event_settings) : data.popup_event_settings
      state.popupSettings = data.popup_event_settings
    }
  },

  getters: {},

  actions: {
    async get ({ commit }, data) {
      let response = await axios.get('/settings/coupon')
      commit('SET_POPUP_DATA', response.data)
      commit('SET_DATA', response.data)
    },

    async update ({ commit }, data) {
      console.log(data, '::')
      return axios.post('/settings/coupon', data)
    }
  }
}
