<template>
  <div class="login-inner">
    <div class="login-content">
      <h2> 매장 로그인</h2>

      <form
        class="form"
        @submit.prevent="handleLoginResult"
      >
        <div class="form__item">
          <InputWrap
            v-model="user.u"
            :placeholder="translate(translations, 'username')"
          />
        </div>

        <div class="form__item">
          <InputWrap
            v-model="user.p"
            :placeholder="translate(translations, 'password')"
            type="password"
          />
        </div>

        <div class="form__item form__item--btn">
          <button
            :disabled="!user.u || !user.p"
            class="btn--login"
          >
            <div
              v-if="dataStatus.loading"
              class="loader-wrap"
            >
            </div>

            <span>
              {{ translate(translations, 'login') }}
            </span>
          </button>

          <template v-if="errorObject.message">
            <div class="login-error">
              {{ translate(translations, errorObject.message) }}
            </div>
          </template>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

//= lib
import axios from 'axios'
import _get from 'lodash/get'

//= mixins
import { jsonTokenParser } from '@/assets/js/mixins/common/JsonTokenParser'
import { vueLocalStorage } from '@/assets/js/mixins/base/VueLocalStorage'
import { isSuperAdmin } from '@/assets/js/mixins/base/isSuperAdmin'
import DetectDeviceV2 from '@/assets/js/mixins/base/DetectDeviceV2'

//= translations
import { translations } from '@/assets/js/translations/Login'

const getKey = (key, obj) => obj[key] === undefined ? obj.default : obj[key]

export default {
  name: 'Login',

  mixins: [
    isSuperAdmin,
    jsonTokenParser,
    DetectDeviceV2
  ],

  data () {
    return {
      translations,

      user: {
        u: 'otp',
        p: '112233'
      },

      dataStatus: {
        loading: false
      },

      errorObject: {
        message: null,
        status: null
      }
    }
  },

  computed: {
    ...mapState('logins', [
      'token',
      'err'
    ])
  },

  created () {
    vueLocalStorage.removeItem('token')
    vueLocalStorage.removeItem('loginid')
    vueLocalStorage.removeItem('loginname')
    vueLocalStorage.removeItem('adminLang')
    vueLocalStorage.removeItem('permission')
    vueLocalStorage.removeItem('level')
  },

  methods: {
    ...mapActions('logins', {
      login: 'login'
    }),

    async handleLoginResult () {
      try {
        this.dataStatus.loading = true

        await this.login({
          username: this.user.u,
          password: this.user.p,
          device: this.$_device.type,
          browser: this.$_device.browser,
          os: this.$_device.os,
          host: window.location.host
        })

        const defaultPath = this.isSuperAdmin() ? '/admins' : null

        const path = this.$router.query
          ? this.$router.query.f
          : defaultPath || '/users/confirmed'

        if (this.token) {
          this.dataStatus.loading = false
          vueLocalStorage.setItem('token', this.token)
          axios.defaults.headers.common.Authorization = `Bearer ${vueLocalStorage.getItem('token')}`

          const token = this.tokenParser(this.token)

          vueLocalStorage.setItem('loginName', token.login_name)
          vueLocalStorage.setItem('loginId', token.login_id)
          vueLocalStorage.setItem('level', token.level)
          vueLocalStorage.setItem('permission', JSON.stringify(token.permission))
          this.$router.push({ path })
        }
      } catch (error) {
        const messageResponse = _get(error, 'response.data.msg')
        const status = _get(error, 'response.data.status')

        const message = getKey(messageResponse, {
          IP_NOT_FOUND: 'ip not found',
          IP_INACTIVE: 'ip inactive',
          ACCOUNT_INACTIVE: 'account inactive',
          INVALID_CREDENTIALS: 'invalid credentials',
          VALIDATION_ERROR: 'Validation Error',
          default: 'internal server error'
        })

        this.errorObject = { status, message }
        this.dataStatus.loading = false
        vueLocalStorage.removeItem('token')
      }
    }
  }
}
</script>

<style>
  .log_message {
    text-align: center;
  }
</style>
