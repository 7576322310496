import { common } from '@/assets/js/translations/Common'

export const kr = {
  kr: {
    from: '에서',
    to: '까지',

    all: '전체',
    none: '선택없음',
    live: '인플레이',
    live_game: '라이브',
    virtual: '가상게임',
    slot: '슬롯게임',
    casino: '카지노',
    live_casino: '라이브카지노',
    mini: '미니게임',
    sport: '스포츠',

    ongoing: '진행중',
    result: '결과',
    cancelled: '취소',
    deleted: '삭제',

    country: '국가',
    game_name: '게임명',
    league: '리그명',
    home_team: '홈팀',
    away_team: '원정팀',
    login_id: '아이디',
    login_name: '닉네임',
    bet_amount: '배팅금액',
    win_amount: '당첨금액',

    play_date: '경기일자',
    bet_date: '배팅일자',
    token: '토큰게임',
    result_date: '결과일자'
  }
}
