import axios from 'axios'
import _ from 'lodash'

export default {
  namespaced: true,

  state: {
    settings: [],
    names: [],
    count: 0
  },

  getters: {
    grouped (state) {
      return state.settings
    },
  },

  mutations: {
    SET_SETTINGS (state, settings) {
      if (settings instanceof Array) {
        state.settings = settings
      } else {
        state.settings.push(settings)
      }
    },

    SET_DATA (state, settings) {
      state.names = settings
    },

    SET_COUNT (state, data) {
      state.count = data.total
    }
  },

  actions: {
    async get ({ commit }, { status, params, game_name_id }) {
      // params['game_name_id'] = game_name_id
      // params.page = 1
      let { data } = await axios.get(`/settings/goalservemarket/${status}`, {params})
      commit('SET_SETTINGS', data)
    },

    async getCount ({ commit }, { status, params, game_name_id }) {
      // params['game_name_id'] = game_name_id
      let total = await axios.get(`/settings/goalservemarket/${status}/count`, {params})
      commit('SET_COUNT', total.data)
    },

    async update({ commit }, params) {
      let response = await axios.patch(`/settings/goalservemarket/${params.id}`, params)

      return response
    }
  }
}
