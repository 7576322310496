export const en = {
  en: {
    result: 'result',
    user: 'user',
    total_bets: 'total bets',
    total_odds: 'total odds',
    win: 'win',
    status: 'status',
    round: 'round',
    lost: 'lost',
    name: 'Name',

    asian_handicap: 'Asian Handicap',
    match_winner: 'Match Winner',
    '3way_result': '3way Result',
    handicap: 'Handicap',
    special_games: 'Special Games',

    ongoing: 'Ongoing',
    waiting: 'Waiting',
    done: 'Done',
    void: 'Void',

    home: 'Home',
    tie: 'Tie',
    tie_1: 'Tie',
    away: 'Away',
    draw: 'Draw',

    over: 'Over',
    under: 'Under',

    low: 'Low',
    high: 'High',

    small: 'Small',
    middle: 'Middle',
    big: 'Big',

    yes: 'Yes',
    no: 'No',

    odd: 'Odd',
    even: 'Even',

    fail: 'Fail',
    success: 'Success',

    red: 'Red',
    blue: 'Blue',
    green: 'Green',

    blue_low: 'Blue Low',
    blue_high: 'Blue High',
    blue_odd: 'Blue Odd',
    blue_even: 'Blue Even',
    red_low: 'Red Low',
    red_high: 'Red High',
    red_odd: 'Red Odd',
    red_even: 'Red Even',
    blue_pair: 'Blue Pair',
    red_pair: 'Red Pair',

    left_start: 'Left Start',
    right_start: 'Right Start',

    ato: 'Ato',
    saki: 'Saki',

    right: 'Right',
    left: 'Left',

    no_goal: 'No goal',
    nogoal: 'No goal',
    out: 'Out',

    power_even: 'Power Even',
    power_odd: 'Power Odd',
    power_over: 'Power Over',
    power_under: 'Power Under',

    finish_first: 'Finish first',
    finish_second: 'Finish second',
    finish_third: 'Finish third',
    finish: 'Finish',
    place: 'Place',

    home_run: 'home run',
    or_better: 'or better',
    orbetter: 'orbetter',
    or_better1: 'or better',
    player: 'Player',
    set: 'Set',
    sets: 'Sets',
    or: 'or',
    goal: 'goal',
    goals: 'goals',
    to_win: 'to win',
    towin: 'towin',
    after_extra_points: 'after extra points',
    afterextrapoints: 'afterextrapoints',
    points: 'Points',
    or_more_points: 'or more points',
    svr: 'svr',
    line_1: 'line',
    line_2: 'line',
    more: 'more',

    player1: 'Player 1',
    player2: 'Player 2',

    to_win_1st_set_and_lost_match: 'to win 1st set and lost match',
    to_win_2nd_set_and_lost_match: 'to win 2nd set and lost match',
    to_win_3rd_set_and_lost_match: 'to win 3rd set and lost match',

    to_win_1st_set_and_win_match: 'to win 1st set and win match',
    to_win_2nd_set_and_win_match: 'to win 2nd set and win match',
    to_win_3rd_set_and_win_match: 'to win 3rd set and win match',

    not_to_score_in_1st_half: 'Not to score in 1st half',
    not_to_score_in_2nd_half: 'Not to score in 2nd half',
    not_to_score_in_3rd_half: 'Not to score in 3rd half',

    to_score_in_1st_half: 'to score in 1st half',
    to_score_in_2nd_half: 'to score in 2nd half',
    to_score_in_3rd_half: 'to score in 3rd half',

    to_score: 'to score',
    not_to_score: 'Not to score',

    run_exactly: 'runs exactly',
    runs_draw: 'runs draw',
    more_runs: 'more runs',

    no_1st_goal: 'No 1st goal',
    no1stgoal: 'No 1st goal',
    no_2nd_goal: 'No 2nd goal',
    no2ndgoal: 'No 2nd goal',
    no_3rd_goal: 'No 3rd goal',
    no3rdgoal: 'No 3rd goal',
    no_4th_goal: 'No 4th goal',
    no4thgoal: 'No 4th goal',
    no_5th_goal: 'No 5th goal',
    no5thgoal: 'No 5th goal',
    no_6th_goal: 'No 6th goal',
    no6thgoal: 'No 6th goal',
    no_7th_goal: 'No 7th goal',
    no7thgoal: 'No 7th goal',
    no_8th_goal: 'No 8th goal',
    no8thgoal: 'No 8th goal',
    no_9th_goal: 'No 9th goal',
    no9thgoal: 'No 9th goal',
    no_10th_goal: 'No 10th goal',
    no10thgoal: 'No 10th goal',
    no_11th_goal: 'No 11th goal',
    no11thgoal: 'No 11th goal',

    first_half: 'First half',
    '1st_half': '1st half',
    '1sthalf': '1st half',

    second_half: 'second half',
    '2nd_half': '2nd half',
    '2ndhalf': '2nd half',

    half_1set: '1st half',
    half_2nd: '2nd half',

    half_time: 'Half Time',

    '1st_quarter': '1st qtr',
    '2nd_quarter': '2nd qtr',
    '3rd_quarter': '3rd qtr',
    '4th_quarter': '4th qtr',
    quarter: 'qtr',

    '1q': '1Q',
    '2q': '2Q',
    '3q': '3Q',
    '4q': '4Q',

    '1q_pause': `
      <span class="game-period__quarter">1Q</span>
      <span class="game-period__pause">F</span>
    `,
    '2q_pause': `
      <span class="game-period__quarter">2Q</span>
      <span class="game-period__pause">F</span>
    `,
    '3q_pause': `
      <span class="game-period__quarter">3Q</span>
      <span class="game-period__pause">F</span>
    `,
    '4q_pause': `
      <span class="game-period__quarter">4Q</span>
      <span class="game-period__pause">F</span>
    `,
    not_started: 'Not Started',
    finished: 'Finished',

    '1st_period': '1st Period',
    '2nd_period': '1st Period',
    '3rd_period': '1st Period',
    '4th_period': '1st Period',

    '1st_5_innings': '1st 5 Innings',
    '1st_set': '1st set',
    '1st_soccer_half': '1st half',

    new_bet_win: 'win',
    game_waiting: 'waiting',

    game_1: 'sports',
    game_2: 'mini game',
    game_3: 'mini game',
    game_4: 'mini game',
    game_5: 'mini game',
    game_6: 'mini game',
    game_7: 'mini game',
    game_8: 'mini game',
    game_9: 'mini game',
    game_14: 'mini game',
    game_15: 'mini game',
    game_16: 'mini game',
    game_17: 'mini game',
    game_18: 'mini game',
    game_19: 'mini game',
    game_20: 'inplay',
    game_21: 'mini game',
    game_22: 'mini game',
    game_23: 'mini game',
    game_24: 'mini game',
    game_25: 'mini game',
    game_26: 'mini game',
    game_27: 'mini game',
    game_28: 'mini game',
    game_29: 'mini game',
    game_30: 'mini game',
    game_36: 'mini game',
    game_37: 'mini game',
    game_64: 'mini game',

    game_76: 'mini game',
    game_77: 'mini game',
    game_78: 'mini game',
    game_79: 'mini game',
    game_80: 'mini game',
    game_81: 'mini game',
    game_82: 'mini game'
  }
}
