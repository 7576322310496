import axios from 'axios'

export default {
  namespaced: true,
  state: {
    matches: [],
    match: {},
    matchGames: [],
    matchesCount: 0
  },

  getters: {},

  mutations: {
    SET_MATCHES (state, matches) {
      state.matches = matches
    },

    SET_MATCHES_COUNT (state, matches) {
      state.matchesCount = matches
    },

    SET_MATCH (state, match) {
      state.match = match
    },

    SET_MATCH_GAMES (state, matchgames) {
      state.matchGames = matchgames
    }
  },

  actions: {
    async getMatches ({ commit }, { type, params }) {
      type = type || 'all'
      let mData = await axios.get(`/goalserve/matches/${type}`, { params })

      commit('SET_MATCHES', mData.data.list)
      /*
      let mcData = await axios.get(
        `/goalserve/matches/${type}/count`, { params }
      )
      */
      commit('SET_MATCHES_COUNT', mData.data.count)
    },

    async getMatch ({ commit }, id) {
      let match = await axios.get(`/goalserve/match/${id}`)
      commit('SET_MATCH', match.data)
      let matchGames = await axios.get(`/goalserve/match/${id}/match-games`)
      commit('SET_MATCH_GAMES', matchGames.data)
    },

    async copyToLive ({ commit }, ids) {
      await axios.post('/goalserve/match/copy/v2', { ids })
    },

    async delete ({ commit }, ids) {
      await axios.post('/goalserve/match/delete/v2', { ids })
    }
  }
}
