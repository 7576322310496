import axios from 'axios'

export default {
  namespaced: true,

  state: {
    auth: undefined
  },

  mutations: {
    SET_STATE (state, payload) {
      state[payload.state] = payload.value
    }
  },

  actions: {
    async get ({ commit }) {
      try {
        const response = await axios.get('/authentication')

        commit('SET_STATE', {
          state: 'auth',
          value: response.data
        })

        return response.data
      } catch (error) {
        console.log(error)
        throw error
      }
    }
  }
}
