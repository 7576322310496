/*
import { isNumber } from '@/assets/js/mixins/base/IsNumber'

mixins: [ isNumber ]
*/

export var isNumber = {
  methods: {
    isNumber (e, type = 'float') {
      e = (e) || window.event
      let charCode = (e.which) ? e.which : e.keyCode

      if (type === 'float') {
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
          e.preventDefault()
        } else {
          return true
        }
      }

      if (type === 'int') {
        if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
          e.preventDefault()
        } else {
          return true
        }
      }
    }
  }
}
