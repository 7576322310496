/**
  Description:
  import Status from '@/assets/js/filters/Status'

  filters: { Status }

  returns full status name
**/

export default function (stat) {
  let status = ''
  switch (stat.toLowerCase()) {
    case 'c':
      status = 'confirmed'
      break
    case 'r':
      status = 'rejected'
      break
    case 'w':
      status = 'waiting'
      break
    case 'p':
      status = 'pending'
      break
    case 'b':
      status = 'banned'
      break
    case 'd':
      status = 'deleted'
      break
  }

  return status
}
