import axios from 'axios'
import _ from 'lodash'

export default {
  namespaced: true,

  state: {
    games: [],
    gameList: [],
    types: [],
    count: 0
  },

  getters: {
    all (state) {
      return state.games
    },

    gameList (state) {
      return state.gameList
    },

    keyedGames (state) {
      return _.keyBy(state.games, 'id')
    },

    types (state) {
      return state.types
    },

    getCount (state) {
      return state.count
    }
  },

  mutations: {
    SET_GAMES (state, games) {
      state.games = games
    },

    LIST_GAMES (state, games) {
      state.gameList = games
    },

    SET_COUNT (state, count) {
      state.count = count
    },

    SET_GAME_TYPES (state, types) {
      state.types = types
    },

    UPDATE_GAME (state, game) {
      let index = state.games.findIndex((row) => { return row.id === game.id })
      if (index !== -1) {
        state.games[index] = game
      }
    },

    DELETE_GAME (state, id) {
      let index = state.games.findIndex((row) => { return row.id === id })

      if (index !== -1) {
        state.games.splice(index, 1)
      }
    }
  },

  actions: {
    async get ({ commit }, { params, status, type, league_id, venue_id }) {
      commit('SET_GAMES', [])
      let url = `/games`

      if (status) {
        url += `/status/${status}`
      }

      if (type) {
        url += `/type/${type}`
      }

      if (league_id && venue_id) {
        url += `/league/${league_id}/venue/${venue_id}`
      }

      if (params && params.filter_by === 'game') {
        params.filter_by = 'name'
      }

      let games = await axios.get(url, params ? { params } : params)

      commit('SET_GAMES', games.data)
    },


    async list ({ commit }, { params, status, type }) {
      let url = `/games`

      if (status) {
        url += `/status/${status}`
      }

      if (type) {
        url += `/type/${type}`
      }

      let games = await axios.get(url, params ? { params } : params)
      commit('LIST_GAMES', games.data)
    },

    async create ({ commit }, games) {
      let data = new FormData()

      for (const key in games) {
        if (!games.hasOwnProperty(key)) {
          continue
        }

        data.append(key, games[key])
      }

      await axios.post('/games', data)
    },

    async update ({ commit }, params) {
      let data = new FormData()
      let id = params.data.id
      params.data = Object.assign({}, params.data, { id: undefined })

      for (const key in params.data) {
        if (!params.data.hasOwnProperty(key) || params.data[key] === undefined) {
          continue
        }

        data.append(key, params.data[key])
      }


      await axios.patch(`/games/${params.game}/${id}/`, data)
      commit('UPDATE_GAME', data)
    },

    async delete ({ commit }, data) {
      await axios.delete(`/games/${data.game}/${data.id}`)
      commit('DELETE_GAME', data.id)
    },

    async getTypes ({ commit }, params) {
      let types = await axios.get('/games/types', params)
      commit('SET_GAME_TYPES', types.data)
    },

    async getCount ({ commit }, { params, status, type }) {
      let url = `/games/count`

      if (status) {
        url += `/status/${status}`
      }

      if (type) {
        url += `/type/${type}`
      }

      try {
        let count = await axios.get(url, params ? { params } : params)
        commit('SET_COUNT', count.data)
      } catch(err) {
        console.log(err)
      }
    },

    async getInplay ({ commit }, { params, status, type }) {
      commit('SET_GAMES', [])
      let url = `/games/inplay`

      if (status) {
        url += `/status/${status}`
      }

      if (type) {
        url += `/type/${type}`
      }

      if (params && params.filter_by === 'game') params.filter_by = 'name'
      let games = await axios.get(url, params ? { params } : params)
      commit('SET_GAMES', games.data)
    }
  }
}
