import io from 'socket.io-client'

const url = process.env.VUE_APP_SOCKET_URL || 'http://localhost:4001'
const config = { 'transports': ['websocket', 'polling'] }
/**
 * Make socket connections here
 *
 */

export const root = io(`${url}/`, config)
export const scraper = io(`${url}/scraper`, config)
export const transfers = io(`${url}/transfers`, config)
export const messages = io(`${url}/messages`, config)
export const user = io(`${url}/user`, config)
export const boards = io(`${url}/boards`, config)
export const bets = io(`${url}/bets`, config)
export const points = io(`${url}/points`, config)
export const matchesInplay = io(`${url}/matches-inplay`, config)
export const matchGamesInplay = io(`${url}/matchgames-inplay`, config)
export const matchGames = io(`${url}/match-games`, config)
export const coupons = io(`${url}/coupons`, config)

export default { root, scraper, transfers, messages, user, boards, points, matchesInplay, matchGamesInplay, matchGames, coupons }
