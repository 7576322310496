import { common } from '@/assets/js/translations/Common'

export const en = {
  en: {
    from: `from`,
    to: `to`,

    user_detail_info: `user detail info`,
    created_date: `created`,
    deleted_date: `deleted`,
    last_login: `last login`,
    updated_date: `updated`,

    lv: 'Lv',
    admin: `admin`,

    //= status
    stat_p: `pending`,
    stat_c: `confirmed`,
    stat_b: `banned`,
    stat_r: `rejected`,
    stat_d: `deleted`,
    stat_w: `waiting`,

    //= sidenav
    referral: `referral`,
    code: `code`,
    agent: `agent`,
    login_id: `login id`,
    login_name: `login name`,
    level: `Level`,
    phone: `phone`,
    password: `password`,
    status: `status`,
    money: `money`,
    cash_in: `cash in`,
    cash_out: `cash out`,
    cash_net: `cash net`,
    manual_in: `manual in`,
    manual_out: `manual out`,
    manual_net: `manual net`,
    point: `point`,
    point_in: `point in`,
    point_out: `point out`,
    point_conversion: `point conversion`,
    total_betting: `total betting`,
    now_betting: `now betting`,
    total_win: `total win`,
    commission_type: `commission type`,
    commission: `commission`,

    // commission type
    cash: `cash`,
    bet: `bet`,
    lost: `lost`,

    //= tabs
    info: `info`,
    transaction_history: `transaction history`,
    cash_history: `cash history`,
    manual_history: `manual history`,
    point_history: `point history`,
    bet_history: `bet history`,
    help_history: `help history`,
    message_history: `message history`,
    referral_list: `referral list`,
    referral_code: `referral code`,

    //= code details
    code_details: `code details`,
    code: `code`,

    users_count: `users count`,
    banned: `banned`,
    confirmed: `confirmed`,
    pending: `pending`,
    waiting: `waiting`,
    deleted: `deleted`,
    rejected: `rejected`,
    total: `total`,

    confirm: `confirm`,
    reject: `reject`,
    ban: `ban`,
    delete: `delete`,

    bank_details: `bank details`,
    bank_account: `bank account`,
    bank_number: `bank number`,
    bank_name: `bank name`,

    //= modify phone
    modify_phone: `modify phone`,
    current_phone: `current phone`,
    new_phone: `new phone`,

    //= modify password
    modify_password: `modify password`,
    current_password: `current password`,
    new_password: `new password`,

    //= manual cash
    manual_cash: `manual cash (deposit/withdraw)`,
    current_balance: `current balance`,
    amount: `amount`,
    memo: `memo`,
    content_here: `content here...`,

    //= edit commission
    edit_commission: `edit commission`,
    current_point: `current point`,
    memo: `memo`,

    //= total betting
    bet_sport: `bet sport`,
    bet_mini: `bet mini`,
    bet_casino: `bet casino`,
    bet_slot: `bet slot`,
    bet_virtual: `bet virtual`,

    //= now betting
    betting_sport: `betting sport`,
    betting_mini: `betting mini`,
    betting_casino: `betting casino`,
    betting_slot: `betting slot`,
    betting_virtual: `betting virtual`,

    //= total win
    win_sport: `win sport`,
    win_mini: `win mini`,
    win_casino: `win casino`,
    win_slot: `win slot`,
    win_virtual: `win virtual`,

    //= commission
    manual_commission: `manual commission`,
    commission: `commission`,
    new_commission: `new commission`,
    value_in_decimal: `value in decimal`,

    error_amount: `Make sure amount is not 0.`,
    not_enough_money: `Not enough money. Please check.`,
    not_enough_points: `Not enough points. Please check.`,

    success_manual_transaction: `You have successfully made a transaction.`,
    continue_manual_transaction: `Do you want to proceed with the processing?`,
    deposit: `deposit`,
    withdraw: `withdraw`,
    clear: `clear`,
    save: `save`,
    title_point_inout: `point deposit/withdraw`,
    title_cash_inout: `cash deposit/withdraw`,
    user_delete: `delete user`,
    user_modify: `modify user`,
    coupon_history: 'coupon history',
    settings: 'setting',
    coupon: 'coupon',
    manual_coupon: 'manual coupon',
    stamp_history: 'stamp history',
    logout: 'logout',
    force_logout: 'Force Logout',

    current_coupon: 'Current Coupon',

    login_name_char: 'The nickname can only be exclude Special Characters + number combination.',
    login_name_min: 'Please enter at least 3 characters.',

    login_name_exist: 'Login name already exist.'
  }
}
