<!--
  @USE AS:
  import InputWrap from '@/components/base/InputWrap'

  components: { InputWrap }

  <InputWrap
    placeholder="test"
    v-model="data"
  />
-->

<template>
  <div class="input-wrap">
    <input
      :type="type"
      v-bind="$attrs"
      :value="value"
      v-on="$listeners"
      @input="$emit('update', $event.target.value)"
    />
  </div>
</template>

<script>
export default {
  name: 'InputWrap',

  inheritAttrs: false,

  model: {
    prop: 'value',
    event: 'update'
  },

  props: {
    value: {
      type: [String, Number],
      default: null
    },

    type: {
      type: [String],
      default: 'text'
    }
  }
}
</script>
