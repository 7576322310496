import axios from 'axios'

export default {
  namespaced: true,

  state: {
    matches: [],
    types: [],
    match: {},
    count: 0
  },

  getters: {
    all (state) {
      return state.matches
    },

    match (state) {
      return state.match
    },

    types (state) {
      return state.types
    },

    count (state) {
      return state.count
    },

    tae (state) {
      return state.tae
    }
  },

  mutations: {
    SET_MATCHES (state, matches) {
      state.matches = matches
    },

    SET_MATCH (state, match) {
      state.match = match
    },

    UPDATE_MATCH (state, match) {
      let index = state.matches
        .findIndex((row) => { return row.id === match.id })
      if (index !== -1) {
        state.matches[index] = match
      }
    },

    DELETE_MATCH (state, id) {
      let index = state.matches
        .findIndex((row) => { return row.id === id })

      if (index !== -1) {
        state.matches.splice(index, 1)
      }
    },

    DELETE_MATCH_BULK (state, ids) {
      const { matches } = state
      state.matches = matches
    },

    SET_COUNT (state, count) {
      state.count = count
    },

    SET_TYPES (state, types) {
      state.types = types
    }
  },

  actions: {
    async get ({ commit }, { status, params }) {
      let matches = await axios.get(`/provider-matches/${status}`, { params })
      commit('SET_MATCHES', matches.data)
    },

    async find ({ commit }, id) {
      let match = await axios.get(`/provider-matches/${id}`)
      await commit('SET_MATCH', match.data)
    },

    async create ({ commit }, matches) {
      await axios.post('/provider-matches', matches)
    },

    async update ({ commit }, params) {
      await axios
        .patch(`/provider-matches/${params.id}`, params.data)
    },

    async delete ({ commit }, id) {
      await axios.delete(`/provider-matches/${id}`)
      commit('DELETE_MATCH', id)
    },

    async deleteBulk ({ commit }, ids) {
      await axios.delete(`/provider-matches`, { data: ids })
      commit('DELETE_MATCH_BULK', ids)
    },

    async getCount ({ commit }, { status, params }) {
      let count = await axios.get(`/provider-matches/${status}/count`, { params })
      commit('SET_COUNT', count.data)
    },

    async getTypes ({ commit }) {
      let types = await axios.get('/provider-matches/types')
      commit('SET_TYPES', types.data)
    },

    async transfer ({ commit }, ids) {
      await axios.post('/provider-matches/transfers', { data: ids })
    },

    async scrape ({ commit }, cmd) {
      await axios.get(`/scrapers/${cmd}`)
    }
  }
}
