import axios from 'axios'

export default {
  namespaced: true,

  state: {
    teams: [],
    count: 0
  },

  mutations: {
    SET_TEAMS (state, data) {
      state.teams = data
    },

    SET_COUNT (state, count) {
      state.count = count[0].total
    }
  },

  actions: {
    async get ({ commit }, { type, params }) {
      try {
        let response = await axios.get(`/game-team/${type}`, { params })
        let count = await axios.get(`/game-team/${type}/count`, { params })

        commit('SET_TEAMS', response.data)
        commit('SET_COUNT', count.data)

      } catch (e) {
        console.log(e)
      }
    },

    async update ({ commit },  { type, params }) {
      let result = await axios.patch(`/game-team/${type}/${params.id}`, params)

      return result
    }
  }
}
