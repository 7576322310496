<!--
  @Description:
  import '@/components/base/select/Select'

  <select-list
    :title="common[lang]['home']"
    :withReset="true"
    v-model="test"
  >
    <select-option
      value="1"
      :name="common[lang]['popular']"
    >
      {{ common[lang]['popular'] }}
    </select-option>
    <select-option
      value="2"
      :name="common[lang]['live_games']"
    >
      {{ common[lang]['live_games'] }}
    </select-option>
  </select-list>
-->

<template>
  <div
    class="select-dd"
    ref="select"
    :class="[setClass, setPosition]"
    @click.stop="setDropdown"
  >
    <div class="select-dd--lbl">
      <div class="select-dd--inner">
        <span
          :class="[{'-with-reset' : withReset}, bindClass ? selected.name : '']"
        >
          <template v-if="!hideLabels">
            {{ selected.name }}
          </template>
        </span>

        <i
          v-if="canReset"
          class="ico--reset-data"
          @click.stop="resetData()"
        >
        </i>
        <i class="ico--arr-down"></i>
      </div>
    </div>

    <ul
      class="select-dd--list"
      ref="selectList"
      @mouseup.stop
    >
      <slot ref="selectData"></slot>
    </ul>
  </div>
</template>

<script>
import Vue from 'vue'

//= lodash
import _ from 'lodash'
import VueLodash from 'vue-lodash'

//= jquery
import $ from 'jquery'

//= mixins
import { ready } from '@/assets/js/mixins/base/Ready'
import { getOffset } from '@/assets/js/mixins/base/GetOffset'

Vue.use(VueLodash, _)

export default {
  name: 'select-list',

  mixins: [
    ready,
    getOffset
  ],

  props: {
    index: [ Number ],

    title: {
      type: [String, Number, Object, Array],
      default: 'choose type'
    },

    value: {
      type: [String, Number, Object, Array, Boolean]
    },

    bindClass: {
      type: [Boolean, String],
      default: true
    },

    selectType: {
      type: [Boolean, String],
      default: true
    },

    withReset: {
      type: [Boolean, String],
      default: false
    },

    hideLabels: {
      type: [Boolean, String],
      default: false
    }
  },

  provide () {
    const handleSelected = this.handleSelected
    return { handleSelected }
  },

  data () {
    return {
      selection: {
        parentTable: false,
        viewportTop: null,
        viewportBottom: null,
        spaceTop: null,
        spaceBottom: null
      },

      handleSelected: {
        value: null,
        name: null,
        reset: false
      },

      initial: {
        active: '',
        title: '',
        value: null
      },

      selected: {
        name: '',
        value: ''
      },

      dropdown: false,
      reset: false,
      active: '',
      invert: false,
      modifiedData: false // checks if actual list data is modified.
    }
  },

  computed: {
    setClass () {
      return this.active
    },

    canReset () {
      let resetData = false

      if (!this.reset) {
        if (this.withReset && this.selectType) {
          if (this.handleSelected.value !== undefined && this.handleSelected.value !== null) {
            if (this.initial.title !== this.handleSelected.name) {
              resetData = true
            }
          }
        }
      }

      return resetData
    },

    setPosition () {
      let setClass

      if (this.invert) {
        setClass = 'is-inverted'
      } else {
        setClass = ''
      }

      return setClass
    },

    getParent () {
      let parent

      if (this.$parent) {
        var td = this.$parent
        var isTd = td.$el.classList.contains('td')

        if (isTd) {
          let tableBody = td.$parent.$parent
          parent = tableBody.$el
        } else {
          parent = window
        }
      }

      return parent
    }
  },

  watch: {
    title () {
      if (!this.handleSelected.name) {
        this.initial.title = this.title
        this.selected.name = this.title
      }
    },

    reset () {
      if (this.reset) {
        this.resetData()

        this.selected.name = this.initial.title
        this.selected.value = this.initial.value
        this.$emit('input', this.initial.value)
        this.$emit('reset', true)

        this.reset = false
      }
    },

    modifiedData () {
      if (this.modifiedData) {
        if (this.selectType) {
          if (this.selected.name !== this.handleSelected.name) {
            this.selected.name = this.handleSelected.name
            this.selected.value = this.handleSelected.value

            this.$emit('input', this.handleSelected.value)
          }
        }
      }
      this.$nextTick(() => { this.modifiedData = false })
    },

    dropdown () {
      let type = this.selected.name
      let active
      let setClass

      setClass = ''

      if (typeof type === 'undefined') {
        type = ''
      } else {
        type = type.split(' ').join('-')
      }

      if (!this.dropdown) {
        $('body').removeClass('dp--open')
        active = ''
      } else {
        $('body').addClass('dp--open')
        active = 'is-open '
      }

      if (this.bindClass) {
        setClass = active + type
      } else {
        setClass = active
      }

      this.active = setClass

      return this.active
    }
  }, // watch

  mounted () {
    this.$nextTick(() => {
      window.addEventListener('click', e => { this.setDropdown(e) })

      window.addEventListener('mouseup', e => {
        if (e.target !== this.$refs.select) {
          this.dropdown = false
        }
      })

      window.addEventListener('resize', this.getItemsHeight)
      this.getItemsHeight()

      // initialize title
      try {
        this.initial.title = JSON.parse(JSON.stringify(this.title))
        this.initial.value = JSON.parse(JSON.stringify(this.value))
        this.selected.name = JSON.parse(JSON.stringify(this.title))
      } catch (err) {
        console.log(this.title, this.value, this.title, '====================', err)
      }
    })

    window.addEventListener('scroll', this.checkPosition)
    window.addEventListener('resize', this.checkPosition)
    this.checkPosition()

    if (this.getParent) {
      if (this.selection.parentTable) {
        this.selection.parentTable = true
        this.getParent.addEventListener('scroll', this.checkPosition)
      }
    }
  },

  methods: {
    resetData () {
      this.reset = true

      var resetHandleSelected = {
        name: null,
        value: null
      }

      Object.assign(this.$data.handleSelected, resetHandleSelected)
      this.dropdown = false
      this.active = ''

      this.handleSelected.reset = true
    },

    setDropdown (e) {
      var listHeight = 0

      if (this.dropdown) {
        this.getItemsHeight()
      } else {
        for (var i = 0; i < _.size(this.$refs.selectItems); i++) {
          listHeight = listHeight + this.$refs.selectItems[i].offsetHeight // gets list height
          $(this.$refs.selectItems[i]).removeAttr('style')
        }
      }

      if (e.target === this.$refs.select) {
        this.dropdown = !this.dropdown
      } else {
        this.dropdown = false
      }

      this.checkPosition()
    },

    checkPosition () {
      if (this.$refs.select) {
        let select = this.$refs.select
        let selectList = this.$refs.selectList
        let height = select.offsetHeight + selectList.offsetHeight
        let selectOffset = this.getOffset(select)

        var viewport = {}

        if (this.getParent) {
          if (this.selection.parentTable) {
            viewport = {
              top: this.getParent.scrollTop,
              bottom: (this.getParent.scrollTop) + this.getParent.scrollHeight
            }
          } else {
            viewport = {
              top: window.pageYOffset || document.documentElement.scrollTop,
              bottom: (window.pageYOffset || document.documentElement.scrollTop) + window.innerHeight
            }
          }
        }

        let checkSpaceAbove = viewport.top < (selectOffset.top - height)
        let checkSpaceBelow = viewport.bottom > (selectOffset.top + height + 10)
        let isInverted = select.classList.contains('is-inverted')

        this.selection.viewportTop = viewport.top
        this.selection.viewportBottom = viewport.bottom
        this.selection.spaceTop = (selectOffset.top - height)
        this.selection.spaceBottom = (selectOffset.top + height + 10)

        if (!checkSpaceBelow && checkSpaceAbove && !isInverted) {
          this.invert = true
        } else if (!checkSpaceAbove && checkSpaceBelow && isInverted) {
          this.invert = false
        }
      }
    },

    getItemsHeight () {
      // this.$loaded(() => {
      //   for (var i = 0; i < _.size(this.$refs.selectItems); i++) {
      //     $(this.$refs.selectItems[i]).css({
      //       'margin-top': (this.$refs.selectItems[i].offsetHeight * -1) + 'px',
      //       'z-index': (i + 1)
      //     })
      //   }
      // }, 1000)
    } // getItemsHeight
  } // methods
}
</script>
