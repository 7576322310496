import axios from 'axios'
import ObjToFormData from '@/assets/js/common/ObjToFormData'

export default {
  namespaced: true,

  state: {
    banks: [],
    bankTypes: [],
    count: 0
  },

  getters: {},

  mutations: {
    SET_BANKS (state, bank) {
      state.banks = bank
    },

    SET_COUNT (state, bankCount) {
      state.count = bankCount[0].total
    },

    SET_BANK_TYPES (state, types) {
      state.bankTypes = types
    },

    UPDATE_BANK (state, banks) {
      state.banks.forEach(data => {
        banks.forEach(bank => {
          if (data.id === bank.id) {
            data.name = bank.name
            data.status = bank.status === 1
            data.type_id = bank.type_id
          }
        })
      })
    },

    DELETE_BANK (state, ids) {
      state.banks.forEach(data => {
        ids.forEach((v, k) => {
          if (data.id === v) {
            data.deleted_at = Date.now()
          }
        })
      })
    },

    RESTORE_BANK (state, banks) {
      state.banks.forEach(data => {
        banks.forEach(bank => {
          if (data.id === bank.id) {
            data.deleted_at = null
          }
        })
      })
    }
  },

  actions: {
    async get ({ commit }, { status, type, params }) {
      const banks = await axios
        .get(`/banks/${status}/${type}`, { params })

      const count = await axios
        .get(`/banks/${status}/${type}/count`, { params })

      const types = await axios
        .get('/banks/all/all/types')

      commit('SET_BANKS', banks.data)
      commit('SET_COUNT', count.data)
      commit('SET_BANK_TYPES', types.data)
      return banks.data
    },

    async create ({ commit }, bank) {
      const result = await axios
        .post('/banks', ObjToFormData(bank))

      return result
    },

    async update ({ commit }, banks) {
      let result
      for (let a = 0; a < banks.length; a++) {
        result = await axios
          .patch(`/banks/${banks[a].id}`, ObjToFormData(banks[a]))
      }
      return result
    },

    async delete ({ commit }, ids) {
      let result
      for (let a = 0; a < ids.length; a++) {
        result = await axios.delete(`/banks/${ids[a]}`)
      }
      return result
    },

    async recover ({ commit }, banks) {
      let result
      for (let a = 0; a < banks.length; a++) {
        result = await axios
          .patch(`/banks/${banks[a].id}`, banks[a].data)
      }
      return result
    },

    async getBankTypes ({ commit }) {
      const types = await axios
        .get('/banks/all/all/types')
      commit('SET_BANK_TYPES', types.data)
    }
  }
}
