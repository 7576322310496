<template>
  <div class="wrap__contents user-cash-history">
    <div class="toolbar">
      <div class="toolbar__items subnav">
        <select2 v-model="selectedType">
          <option2
            value="all"
            :selected="$route.params.subtype === 'all'"
          >
            {{ translate(translations, 'select_cash') }}
          </option2>

          <option2
            value="cash-in"
            :selected="$route.params.subtype === 'cash-in'"
          >
            {{ translate(translations, 'cash in') }}
          </option2>

          <option2
            value="cash-out"
            :selected="$route.params.subtype === 'cash-out'"
          >
            {{ translate(translations, 'cash out') }}
          </option2>
        </select2>
      </div>

      <div class="toolbar__items select">
        <select2 v-model="selectedStatus">
          <option2
            value="all"
            selected
          >
            {{ translate(translations, 'select_status') }}
          </option2>

          <option2 value="confirm">
            {{ translate(translations, 'confirmed') }}
          </option2>

          <option2 value="rejected">
            {{ translate(translations, 'rejected') }}
          </option2>
        </select2>
      </div>

      <div class="toolbar__items searchbar">
        <search
          :translations="translations"
          :date-by="dateBy"
          :filter-by="filterBy"
          @trigger="trigger"
          @refresh="search"
          @clear="search"
        />
      </div>
    </div>

    <div class="wrap__inner">
      <check-data-list
        v-if="isDataLoading || isEmpty"
        :is-loading="isDataLoading"
        :is-empty="isEmpty"
      />

      <tbl
        v-else
        ref="tbl"
        :class="{'data-loading': showDataLoading}"
        :data-loading="isDataLoading"
      >
        <template slot="head">
          <tbl-td
            :sort="true"
            name="no"
            @click.native="sort('id')"
          >
            {{ translate(translations, 'no') }}
          </tbl-td>

          <tbl-td
            :sort="true"
            name="request-date"
            @click.native="sort('created_at')"
          >
            {{ translate(translations, 'request date') }}
          </tbl-td>

          <tbl-td
            :sort="true"
            name="before-transfer"
            @click.native="sort('old_balance')"
          >
            {{ translate(translations, 'before transfer') }}
          </tbl-td>

          <tbl-td
            :sort="true"
            name="amount"
            @click.native="sort('amount')"
          >
            {{ translate(translations, 'amount') }}
          </tbl-td>

          <tbl-td
            :sort="true"
            name="new-balance"
            @click.native="sort('new_balance')"
          >
            {{ translate(translations, 'new balance') }}
          </tbl-td>

          <tbl-td
            :sort="true"
            name="user-ip"
            @click.native="sort('type_id')"
          >
            {{ translate(translations, 'type') }}
          </tbl-td>

          <tbl-td
            :sort="true"
            name="status"
            @click.native="sort('status')"
          >
            {{ translate(translations, 'status') }}
          </tbl-td>

          <tbl-td
            :sort="true"
            name="confirm-date"
            @click.native="sort('updated_at')"
          >
            {{ translate(translations, 'confirm date') }}
          </tbl-td>
        </template>

        <template slot="body">
          <tbl-row
            v-for="(transfer, x) in transferDetails"
            :key="transfer.id"
          >
            <tbl-td name="no">
              {{ generateNumber(x, params.page, params.rows, params.sort, count || 0) }}
            </tbl-td>

            <tbl-td name="request-date">
              {{ transfer.created_at | date }}
            </tbl-td>

            <tbl-td name="before-transfer">
              {{ transfer.old_balance | FormatAmount('whole') }}
            </tbl-td>

            <tbl-td name="amount">
              {{ transfer.amount | FormatAmount('whole') }}
            </tbl-td>

            <tbl-td name="new-balance">
              {{ transfer.new_balance | FormatAmount('whole') }}
            </tbl-td>

            <tbl-td name="user-ip">
              {{ transfer.type_id === 1 ? translate(translations, 'cash in') : translate(translations, 'cash out') }}
            </tbl-td>

            <tbl-td name="status">
              {{ translate(translations, transfer.status, 'Status') }}
            </tbl-td>

            <tbl-td name="confirm-date">
              <template
                v-if="transfer.status.toLowerCase() !== 'p' &&
                  transfer.status.toLowerCase() !== 'w' && transfer.status.toLowerCase() !== 'r'"
              >
                {{ transfer.updated_at | date }}
              </template>
              <template v-else>
                -
              </template>
            </tbl-td>
          </tbl-row>
        </template>

        <template slot="footer">
          <pagination
            v-model="params.page"
            :total="count"
            :rows="params.rows"
            @page="trigger"
          />
        </template>
      </tbl>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { transfers } from '@/socket'

//= mixins
import { getListStatus } from '@/assets/js/mixins/common/GetListStatus'

//= components
import Modal from '@/components/base/Modal'
import Search from '@/components/base/search/Search'
import CheckDataList from '@/components/base/CheckDataList'
import Pagination from '@/components/base/Pagination'

//= filters
import FormatAmount from '@/assets/js/filters/FormatAmount'
import Status from '@/assets/js/filters/Status'

//= translation
import { translations } from '@/assets/js/translations/UsersCashHistory'

export default {
  name: 'UserCashHistory',

  components: {
    Modal,
    Search,
    CheckDataList,
    Pagination
  },

  filters: {
    FormatAmount
  },

  mixins: [
    getListStatus
  ],

  data () {
    const params = Object.assign(
      {
        rows: 50,
        page: 1,
        filter_by: '',
        q: '',
        sort_by: 'id',
        sort: 'desc',
        date_from: '',
        date_to: ''
      },
      this.$route.query
    )

    return {
      translations,
      params,
      items: [0, 0, 0, 0, 0],

      isDataLoading: true,
      isEmpty: false,
      showDataLoading: false,

      filterBy: [],

      dateBy: [
        {
          value: 'created_at',
          display: 'request date'
        },

        {
          value: 'updated_at',
          display: 'confirm date'
        }
      ]
    }
  },

  watch: {
    $route ($router) {
      if (!this.showDataLoading) {
        this.showDataLoading = true
      }
      this.params = this.generate($router.query)
      this.params.rows = 10

      if (this.params.filter_by === 'status') {
        this.params.q = this.params.q.length ? this.params.q[0] : ''
      }

      this.search()
    }
  },

  computed: {
    ...mapState('transfer', ['transferDetails', 'count'])
  },

  created () {
    this.search()

    transfers.on('cash-in', async message => {
      this.search()
    })

    transfers.on('cash-out', async message => {
      this.search()
    })

    transfers.on('update-status', async message => {
      this.getUser({ id: this.$route.params.id })
    })
  },

  methods: {
    ...mapActions('transfer', ['getDetail', 'update']),
    ...mapActions('user', { getUser: 'find' }),

    tempTotal (transfer) {
      if (transfer.status.toLowerCase() !== 'c') {
        return 0
      }
      if ([1, 3].indexOf(transfer.type_id) > -1) {
        return transfer.amount + transfer.old_balance
      }
      return transfer.old_balance - transfer.amount
    },

    async search (query) {
      this.params = query || this.params
      this.params.manual = false
      // == flags reset
      this.isEmpty = false
      if (!this.showDataLoading) {
        this.isDataLoading = true
      }

      let type = 'all'
      if (this.$route.params.subtype === 'cash-in') {
        type = 'cashin'
      }
      if (this.$route.params.subtype === 'cash-out') {
        type = 'cashout'
      }

      const stat = this.$route.params.status
      await this.getDetail({
        type,
        status: stat,
        uid: this.$route.params.id,
        params: this.params
      })

      this.isDataLoading = false
      this.showDataLoading = false
      if (!this.transferDetails.length) {
        this.isEmpty = true
      }
    },

    trigger (query) {
      this.params = query || this.params
      this.$router.push({ query: this.params })
    },

    async sort (sortBy) {
      if (this.params.sort === 'asc') {
        this.params.sort = 'desc'
      } else {
        this.params.sort = 'asc'
      }

      this.params.sort_by = sortBy

      if (!this.showDataLoading) {
        this.showDataLoading = true
      }

      this.$router.push({ query: this.params })
    },

    updateStatus (transfer, status) {
      const swalMsg = ''

      this.$alert('prompt', swalMsg, '', async () => {
        await this.update({
          id: transfer.id,
          status,
          item: transfer
        })

        transfer.updated_at = new Date()

        const swalMsg = 'success modify'
        this.$alert('success', swalMsg)
        transfer.status = status
        this.search()
      }, 'modify')
    }
  }
}
</script>
