import axios from 'axios'

export default {
  namespaced: true,
  state: {
    transfers: [],
    transferDetails: [],
    count: 1
  },
  mutations: {
    SET_TRANSFERS (state, trans) {
      state.transfers = trans
    },
    SET_COUNT (state, count) {
      state.count = count
    },
    SET_TRANSDETAILS (state, data) {
      state.transferDetails = data
    }
  },
  getters: {},
  actions: {
    async get ({ commit }, { type, status, params }) {
      let transfers = await axios.get(`/transfers/${type}/${status}`, { params })
      let count = await axios.get(`/transfers/${type}/${status}/count`, { params })
      commit('SET_TRANSFERS', transfers.data)
      commit('SET_COUNT', count.data)
      return transfers
    },

    async getDetail ({ commit }, { type, status, uid, params }) {
      let transfer = await axios.get(`/transfers/${type}/user/${uid}/${status}`, { params })
      commit('SET_TRANSDETAILS', transfer.data)
      let count = await axios.get(`/transfers/${type}/user/${uid}/${status}/count`, { params })
      commit('SET_COUNT', count.data)
      return transfer
    },

    async getAgentDetail ({ commit }, { type, status, uid, params }) {
      let transfer = await axios.get(`/transfers/${type}/${status}/agent/${uid}`, { params })
      commit('SET_TRANSDETAILS', transfer.data)
      return transfer
    },

    async getAgentDetailCount ({ commit }, { type, status, uid, params }) {
      let count = await axios.get(`/transfers/${type}/${status}/agent/${uid}/count`, { params })
      commit('SET_COUNT', count.data)
    },

    async create ({ commmit }, data) {
      return axios.post('/transfers', data)
    },

    async update ({ commit }, data) {
      return axios.patch(`/transfers/${data.id}`, {
        data: {
          status: data.status
        },
        item: data.item,
        user_id: data.item.user_id,
        transaction_id: data.item.id,
        type: data.item.type_id
      })

    }
  }
}
