import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import { mapState } from 'vuex'

// lib
import axios from 'axios'
import VeeValidate from 'vee-validate'

// style
import '@/assets/sass/style.scss'

// filters
import FormatDate from '@/assets/js/filters/FormatDate'

// components
import { quillEditor } from 'vue-quill-editor'
import '@/components/base/select/Select'
import '@/components/base/select2/Select'
import '@/components/base/table/Table'
import '@/components/common/table-settings/TableSettings'
import '@/components/base/input/InputSuggestion'
import InputAmount from '@/components/base/InputAmount'
import InputWrap from '@/components/base/InputWrap'

// mixins
import '@/registerServiceWorker'
import Querify from '@/assets/js/mixins/base/Querify'
import Query from '@/assets/js/mixins/base/Query'
import NumberGenerator from '@/assets/js/mixins/base/NumberGenerator'
import Status from '@/assets/js/filters/Status'
import swal from '@/assets/js/mixins/base/Swal'
import { translate } from '@/assets/js/translations/Translate'
import { hasToken } from '@/assets/js/mixins/common/HasToken'
import { vueLocalStorage } from '@/assets/js/mixins/base/VueLocalStorage'

// sockets
import Message from '@/assets/js/mixins/ws/Message'
import Transfer from '@/assets/js/mixins/ws/Transfer'
import User from '@/assets/js/mixins/ws/User'

// eslint-disable-next-line no-extend-native
String.prototype.replaceAll = function (search, replacement) {
  const target = this
  return target.replace(/,/g, replacement)
}

// axios default configurations
axios.defaults.baseURL = process.env.VUE_APP_API_URL || 'http://localhost:4000'
axios.defaults.headers.common.Authorization = `Bearer ${vueLocalStorage.getItem('token')}`

// request interceptor, if the resource responds with 401, then redirect
axios.interceptors.response.use(config => { return config }, err => {
  if (err.response.status === 401 && router.currentRoute.name !== 'login') {
    router.push(`/login?r=401&f=${router.currentRoute.name}`)
  }

  return Promise.reject(err)
})

Vue.config.productionTip = false
Vue.use(VeeValidate)

Vue.component(InputAmount.name, InputAmount)
Vue.component(InputWrap.name, InputWrap)

Vue.mixin(FormatDate)
Vue.mixin(Querify)
Vue.mixin(Query)
Vue.mixin(NumberGenerator)
Vue.mixin(translate)
Vue.mixin(hasToken)
Vue.mixin(swal)

Vue.mixin({
  data () {
    return {
      isAgent: process.env.VUE_APP_AGENT
    }
  }
})

Vue.appUrl = Vue.prototype.appUrl = window.location.origin ? window.location.origin : process.env.VUE_APP_URL

new Vue({
  router,

  store,

  components: {
    'quill-editor': quillEditor
  },

  filters: {
    Status
  },

  mixins: [
    Message,
    Transfer,
    User
  ],

  computed: {
    ...mapState('config', ['config'])
  },

  render: h => h(App)
}).$mount('#app')
