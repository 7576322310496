import axios from 'axios'

export default {
  namespaced: true,

  state: {
    messages: [],
    listMessages: [],
    requests: [],
    listRequests: [],
    messageCount: 0,
    requestCount: 0
  },

  getters: {},

  mutations: {
    SET_MESSAGES (state, data) {
      state.messages = data.list
    },

    LIST_MESSAGES (state, data) {
      state.listMessages = data.list
    },

    SET_REQUESTS (state, data) {
      state.requests = data.list
    },

    LIST_REQUESTS (state, data) {
      state.listRequests = data.list
    },

    SET_MESSAGE_COUNT (state, data) {
      state.messageCount = data.count
    },

    SET_REQUEST_COUNT (state, data) {
      state.requestCount = data.count
    },

    UPDATE_MESSAGES (state, data) {
      let index = state.messages
        .findIndex((row) => { return row.id === data.id })

      if (index !== -1) {
        state.messages[index] = data
      }
    },

    UPDATE_REQUESTS (state, data) {
      let index = state.requests
        .findIndex((row) => { return row.id === data.id })
      data.status = 'R'

      if (index !== -1) {
        state.requests[index] = data
      }
    },

    DELETE_MESSAGES (state, ids) {
      for (let a = 0; a < ids.length; a++) {
        state.messages.forEach((row) => {
          if (row.id === ids[a]) {
            row.deleted_at = new Date()
          }
        })
      }
    },

    DELETE_REQUESTS (state, ids) {
      for (let a = 0; a < ids.length; a++) {
        state.requests.forEach((row) => {
          if (row.id === ids[a]) {
            row.deleted_at = new Date()
          }
        })
      }

      state.requests = state.requests.filter(e => {
        return e.deleted_at === null || !e.deleted_at
      })
    },

    RESTORE_MESSAGES (state, ids) {
      for (let a = 0; a < ids.length; a++) {
        state.messages.forEach((row) => {
          if (row.id === ids[a]) {
            row.deleted_at = null
          }
        })
      }
    },

    RESTORE_REQUESTS (state, ids) {
      for (let a = 0; a < ids.length; a++) {
        state.requests.forEach((row) => {
          if (row.id === ids[a]) {
            row.deleted_at = null
          }
        })
      }
    }

    // SET_NEW_REQUEST (state, data) {
    //   let newRequest = JSON.parse(data)

    //   newRequest.user = { login_name: newRequest.user }
    //   state.requests.pop()
    //   state.requests.unshift(newRequest)
    // }
  },

  actions: {
    async create ({ commit }, data) {
      try {
        let result = await axios.post('/support-messages/multitple', data)
        return result
      } catch (err) {
        return 422
      }
    },

    async get ({ commit }, { id }) {
      return axios.get(`/support-messages/${id}/message`)
    },

    async createMessages ({ commit }, data) {
      return axios.post('/support-messages', data)
    },

    async createRequests ({ commit }, data) {
      return axios.post('/support-requests', data)
    },

    async getMessages ({ commit }, { status, params, uid }) {
      commit('SET_MESSAGES', [])
      commit('SET_MESSAGE_COUNT', 1)

      let result = await axios.get(`/support-messages/${status}`, { params })

      commit('SET_MESSAGES', result.data)
      commit('SET_MESSAGE_COUNT', result.data)
    },

    async listMsgs ({ commit }, { status, params, uid }) {
      let result = await axios.get(`/support-messages/${status}`, { params })

      commit('LIST_MESSAGES', result.data)
    },

    async getRequests ({ commit }, { status, params, uid }) {
      let result
      if (uid) {
        result = await axios.get(`/support-requests/${status}/${uid}`, { params })
      } else {
        result = await axios.get(`/support-requests/${status}`, { params })
      }

      commit('SET_REQUESTS', result.data)
      commit('SET_REQUEST_COUNT', result.data)
    },

    async listReqs ({ commit }, { status, params, uid }) {
      let result
      if (uid) {
        result = await axios.get(`/support-requests/${status}/${uid}`, { params })
      } else {
        result = await axios.get(`/support-requests/${status}`, { params })
      }

      commit('LIST_REQUESTS', result.data)
    },

    async updateMessages ({ commit }, params) {
      let result = await axios
        .patch(`/support-messages/${params.id}`, params)

      commit('UPDATE_MESSAGES', params)
      return result
    },

    async updateRequests ({ commit }, params) {
      let result = await axios
        .patch(`/support-requests/${params.id}`, params)

      commit('UPDATE_REQUESTS', params)
      return result
    },

    async deleteMessages ({ commit }, params) {
      let result = await axios.delete('/support-messages', { data: params })

      if (result.status === 202) {
        commit('DELETE_MESSAGES', params)
      }

      return result
    },

    async deleteRequests ({ commit }, params) {
      let result = await axios.delete('/support-requests', { data: params })

      commit('DELETE_REQUESTS', params)
      return result
    },

    async restoreMessages ({ commit }, params) {
      let result
      if (params.id) {
        result = await axios.patch(`/support-messages/restore/${params.id}`, params.ids)
      } else {
        result = await axios.patch('/support-messages/restore/', params)
      }

      commit('RESTORE_MESSAGES', params)
      return result
    },

    async restoreRequests ({ commit }, params) {
      let result = await axios.patch('/support-requests', params)

      commit('RESTORE_REQUESTS', params)
      return result
    },

    async getRecipients ({ commit }, { msgId, params }) {
      return axios.get(`/support-messages/${msgId}/recipients`, { params })
    },

    async getReaders ({ commit }, { msgId, params }) {
      return axios.get(`/support-messages/${msgId}/readers`, { params })
    },

    async getUserMessages ({ commit }, { userId, params }) {
      let result = await axios.get(`/support-messages/${userId}/user`, { params })

      commit('SET_MESSAGES', result.data)
      commit('SET_MESSAGE_COUNT', result.data)
    },

    async getUserMessagesByStatus ({ commit }, { userId, params, status }) {
      let result = await axios.get(`/support-messages/${userId}/user/${status}`, { params })

      commit('SET_MESSAGES', result.data)
      commit('SET_MESSAGE_COUNT', result.data)
    }
  }
}
