import { common } from '@/assets/js/translations/Common'

export const jp = {
  jp: {
    from: `から`,
    to: `まで`,

    select_cash: `区分`,
    select_status: `状態`,
    cash_out: `出金`,
    cash_in: `入金`,
    bet: `ベッティング`,
    point: `ポイント`,
    rollback: `回転`,
    win: `勝ち`,
    commission: `コミッション`,
    manual_in: `支給`,
    manual_out: `回収`,
    point_bet: `point bet`,
    point_bet_cancel: `point bet cancel`,
    confirmed: `confirmed`,
    pending: `正常`,
    rejected: `拒否`,
    waiting: `待機`,
    deleted: `削除`,

    ip: `IP`,

    no: `ナンバー`,
    request_date: `要請日時`,
    before_transfer: `以前の金額`,
    amount: `金額`,
    new_balance: `変更金額`,
    type: `区分`,
    status: `状態`,
    confirm_date: `承認日時`,
    user_ip: `ユーザーIP`,
    admin: `管理者`,
    match_rollback: `結果復元`,
    true: `確認`,
    created_date: `出席日時`,
    login_id: `ID`,
    login_name: `ニックネーム`
  }
}
