import { common } from '@/assets/js/translations/Common'

export const en = {
  en: {
    delete: `delete`,
    restore: `restore`,
    modify: `modify`,
    create: `create`,
    success: `success`,
    error: `error`,
    remove: `remove`,
    cancel: `cancel`,

    proceed: `Are you sure you want to proceed?`,
    success_modify: `You have successfully modified data.`,
    success_create: `You have successfully created data.`,
    success_delete: `You have successfully deleted data.`,
    success_restore: `You have successfully restored data.`,
    success_remove: `You have successfully removed data.`,
    success_cancel: `You have successfully cancelled data.`,

    try_again: `Please try again.`,
    data_exist: `Data already exist.`,
    fields_required: `All fields are required.`,

    dual_logged_in: `Your account is logged in elsewhere.`,
    account_banned: `Your account has beed banned.`,
    account_deleted: `Your account has beed deleted.`,
    back_to_login: `back to login`,

    yes: `ok`,
    confirm: `ok`,
    cancel: `cancel`
  }
}
