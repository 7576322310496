import axios from 'axios'

export default {
  namespaced: true,
  state: {
    vipList: []
  },
  mutations: {
    SET_LIST (state, data) {
      state.vipList = data
    }
  },
  getters: {},
  actions: {
    async get ({ commit }) {
      let data = await axios.get('/vips')
      commit('SET_LIST', data.data)
    }
  }
}
