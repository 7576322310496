import { common } from '@/assets/js/translations/Common'

export const jp = {
  jp: {
    from: `から`,
    to: `まで`,
    created_date: `登録日時`,

    all: `区分`,
    level: `レベル`,
    bet: `ベッティング ポイント`,
    win: `勝ちポイント`,
    lost: `負けポイント`,
    first_deposit: `加入後最初のチャージポイント`,
    second_deposit: `2回目のチャージポイント`,
    third_deposit: `3回目のチャージポイント`,
    daily_first_deposit: `本日最初のチャージポイント`,
    daily_second_deposit: `本日2度目のチャージポイント`,
    daily_third_deposit: `本日3度目のチャージポイント`,
    deposit: `毎回チャージポイント`,
    conversion: `換金`,
    manual_in: `支給`,
    cashback: `キャッシュバック`,
    events: `イベントポイント`,
    referral: `推薦人ポイント`,
    manual_withdraw: `回収`,
    manual_out: `回収`,

    amount: `金額`,

    no: `ナンバー`,
    created_date: `登録日時`,
    old_point: `以前のポイント`,
    point: `ポイント`,
    new_point: `変更ポイント`,
    type: `区分`,
    status: `状態`,
    memo: `メモ`,
    admin_ip: `管理者 IP`,
    admin: `管理者`,

    confirmed: `正常`,
    deleted: `削除`,
    rejected: `拒否`
  }
}
