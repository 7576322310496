import axios from 'axios'

export default {
  namespaced: true,

  state: {
    promo_games: []
  },

  mutations: {
    SET_PROMOTIONS (state, data) {
      state.promo_games =  data
    },

    UPDATE_PROMOTIONS (state, game) {
      let index = state.promo_games.findIndex((row) => { return row.id === game.id })
      if (index !== -1) {
        state.promo_games[index] = game
      }
    }
  },

  getters: {},

  actions: {
    async get ({ state, commit }, params) {
      try {
        let response = await axios.get('/settings/promotions')
        commit('SET_PROMOTIONS', response.data)
      } catch (err) {
        console.log(err)
      }
    },

    async updateSettings ({ commit }, params) {
      const game = params.game
      delete params.game

      params = typeof params.settings === 'string' ? params.settings : JSON.stringify(params.settings)

      console.log(params)

      return axios.patch(`/settings/promotions/${game}/`, {data: params})

      commit('UPDATE_PROMOTIONS', params)
    },

    async updateSettingsBy ({ commit }, { prop, data }) {
      return axios.patch(`/settings/promotions/by/${prop}/`, { data })
    }
  }
}
