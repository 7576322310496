import axios from 'axios'

export default {
  namespaced: true,
  state: {
    matches: [],
    matchGames: [],
    matchesCount: 0
  },

  getters: {},

  mutations: {
    SET_MATCHES (state, matches) {
      state.matches = matches
    },

    SET_MATCHES_COUNT (state, count) {
      state.matchesCount = count
    },

    SET_MATCH_GAMES (state, matchgames) {
      state.matchGames = matchgames
    }
  },

  actions: {
    async getMatches ({ commit }, { type, params }) {
      type = type || 'all'
      let mData = await axios.get(`/goalserve/inplay/matches/${type}`, { params })
      commit('SET_MATCHES', mData.data)
      let mcData = await axios.get(
        `/goalserve/inplay/matches/${type}/count`, { params }
      )
      commit('SET_MATCHES_COUNT', mcData.data)
    },

    async copyToLive ({ commit }, ids) {
      await axios.post('/goalserve/inplay/match/copy', { ids })
    }
  }
}