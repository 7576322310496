import axios from 'axios'

import _isEmpty from 'lodash/isEmpty'

export default {
  namespaced: true,

  state: {
    list: [],
    count: 0,
    types: []
  },

  mutations: {
    SET_STATE (state, data) {
      state[data.state] = data.value
    }
  },

  getters: {
    commissionTxnList (state) {
      const list = JSON.parse(JSON.stringify(state.list))

      if (_isEmpty(list)) {
        return {}
      }

      const types = [
        'personal-auto-deposit',
        'referral-auto-deposit',
        'withdraw-personal',
        'withdraw-referral'
      ]

      const sum = list.reduce((acc, curr) => {
        types.forEach(type => {
          acc.details[type] = acc.details[type] || 0
          curr.details[type] = curr.details[type] || 0

          acc.details[type] += curr.details[type]
        })

        return {
          agent: {},
          category: {},
          details: acc.details,
          amount: acc.amount + curr.amount
        }
      }, { details: {}, amount: 0 })

      return [...list, sum]
    }
  },

  actions: {
    async get ({ commit }, params) {
      try {
        const response = await axios.get('/agent-commission-transactions', { params })

        commit('SET_STATE', { state: 'list', value: response.data.list })
        commit('SET_STATE', { state: 'count', value: response.data.count })

        return response.data
      } catch (error) {
        console.log(error)
        throw error
      }
    },

    async getTypes ({ commit }, params) {
      try {
        const response = await axios.get('/agent-commission-transactions/types', { params })

        commit('SET_STATE', { state: 'types', value: response.data })

        return response.data
      } catch (error) {
        console.log(error)
        throw error
      }
    },

    async withdraw ({ commit }, { agent_id: agentId, ...payload }) {
      try {
        const response = await axios.post(`/agent-commission-transactions/${agentId}/withdraw`, payload)

        return response.data
      } catch (error) {
        console.log(error)
        throw error
      }
    },

    async requestWithdraw (_, { agent_id: agentId, ...payload }) {
      try {
        const response = await axios.post('/agent-commission-transactions/request/withdraw', payload)

        return response.data
      } catch (error) {
        console.log(error)
        throw error
      }
    }

    // store (_, data) {
    //   try {
    //     const response = axios.post('/sample', data)

    //     return response.data
    //   } catch (error) {
    //     console.log(error)
    //     throw error
    //   }
    // },

    // update (_, data) {
    //   try {
    //     const response = axios.patch('/sample', data)

    //     return response.data
    //   } catch (error) {
    //     console.log(error)
    //     throw error
    //   }
    // },

    // delete (_, data) {
    //   try {
    //     const response = axios.delete('/sample', data)

    //     return response.data
    //   } catch (error) {
    //     console.log(error)
    //     throw error
    //   }
    // }
  }
}
