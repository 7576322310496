import axios from 'axios'

export default {
  namespaced: true,
  state: {
    pointList: [],
    count: 1,
    types: []
  },
  mutations: {
    SET_POINTS (state, data) {
      state.pointList = data
    },

    SET_COUNT (state, data) {
      state.count = (data && data.total) || 0
    },

    SET_TYPES (state, data) {
      state.types = data
    }
  },
  getters: {},
  actions: {
    async create ({ commit, state }, { uid, data }) {
      let response = await axios.post(`/points/${uid}`, { data })    
    },

    async get ({ commit, state }, { uid, params, type }) {
      let findType = state.types.find((e) => {
        return e.name === type
      })

      //if (type === 'all' || !type) {
        findType = {
          id: type
        }
      //}

      let response = null

      if (uid) {
        response = await axios.get(`/points/list/${findType.id}/${uid}`, { params })
      } else {
        response = await axios.get(`/points/list/${findType.id}`, { params })
      }

      commit('SET_POINTS', response.data.list)
      commit('SET_COUNT', response.data.count[0])
    },

    async getTypes ({ commit, state }) {
      let response = await axios.get(`/points/types`)
      commit('SET_TYPES', response.data)
    }

  }
}
