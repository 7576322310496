<template>
  <div class="wrap__contents user-bet-history">
    <div class="toolbar">
      <div class="toolbar__items subnav">
        <select2
          ref="toolbarNav"
          v-model="selectedType"
        >
          <option2
            value="all"
            :selected="$route.params.subtype === 'all'"
          >
            {{ translate(translations, 'all') }}
          </option2>

          <option2
            v-for="type in gameTypes"
            :key="type.id"
            :value="type.name"
            :selected="$route.params.subtype === type.name"
          >
            {{ translate(translations, type.name) }}
          </option2>
        </select2>
      </div>

      <div class="toolbar__items subnav">
        <select2
          ref="toolbarNav"
          v-model="selectedStatus"
        >
          <option2
            value="all"
            :selected="$route.params.status === 'all'"
          >
            {{ translate(translations, 'all') }}
          </option2>

          <option2
            value="ongoing"
            :selected="$route.params.status === 'ongoing'"
          >
            {{ translate(translations, 'ongoing') }}
          </option2>

          <option2
            value="result"
            :selected="$route.params.status === 'result'"
          >
            {{ translate(translations, 'result') }}
          </option2>

          <option2
            value="cancelled"
            :selected="$route.params.status === 'cancelled'"
          >
            {{ translate(translations, 'cancelled') }}
          </option2>

        </select2>
      </div>

      <div class="toolbar__items searchbar">
        <search
          :translations="translations"
          :dateBy="dateBy"
          :filterBy="filterBy"
          @refresh="search"
          @trigger="trigger"
          @clear="search"
        >
        </search>
      </div>
    </div>

    <div class="wrap__inner">
      <check-data-list
        v-if="isDataLoading || isEmpty"
        :isLoading="isDataLoading"
        :isEmpty="isEmpty"
      />

      <div
        class="betslip-wrap"
        :class="{'data-loading': showDataLoading }"
        v-else
      >
        <template v-if="!isDataLoading">
          <betslip-card
            v-for="(slip, index) in betHistory"
            :key="index"
            :logs="slip"
            @update="update($event)"
            :showButtons="true"
          >
            <template v-if="(slip.bet_info && slip.bet_info.length) > 0">
              <bets-card
                v-for="(bets, idx) in slip.bet_info"
                :key="idx"
                :logs="bets"
                :slip="slip"
              >
              </bets-card>
            </template>
          </betslip-card>
        </template>
      </div>

      <pagination
        :total="count"
        v-model="params.page"
        :rows="params.rows"
        @page="trigger"
      >
      </pagination>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
//= mixins
import { getListStatus } from '@/assets/js/mixins/common/GetListStatus'
//= components
import Modal from '@/components/base/Modal'
import Search from '@/components/base/search/Search'
import BetslipCard from '@/components/common/betslip/BetslipCard'
import BetsCard from '@/components/common/betslip/BetsCard'
import CheckDataList from '@/components/base/CheckDataList'
import Pagination from '@/components/base/Pagination'
//= translation
import { translations } from '@/assets/js/translations/UsersBetHistory'

export default {
  name: 'UserBetHistory',
  components: {
    Modal,
    Search,
    BetslipCard,
    BetsCard,
    CheckDataList,
    Pagination
  },

  mixins: [
    getListStatus
  ],

  computed: {
    ...mapState('bet-history', [
      'betHistory',
      'count'
    ]),
    ...mapState('game', { gameTypes: 'types'} )
  },

  async created () {
    this.params = this.generate(this.params)
    await this.getTypes()
    this.search()
  },

  data () {
    return {
      translations,
      params: {
        page: (this.$route.query && this.$route.query.page) || 1,
        rows: 20,
        sort_by: 'id',
        sort: 'desc',
        filter_by: '',
        q: '',
        date_from: '',
        date_to: '',
        user_id: this.$route.params.id
      },

      isDataLoading: true,
      showDataLoading: false,
      isEmpty: false,

      selectedType: 'all',

      filterBy: [
        {
          value: 'game_name',
          display: 'game name'
        },
        {
          value: 'league',
          display: 'league'
        },
        {
          value: 'home_team',
          display: 'home team'
        },
        {
          value: 'away_team',
          display: 'away team'
        }
      ],

      dateBy: [
        {
          value: 'play_date',
          display: 'play date'
        },
        {
          value: 'bet_date',
          display: 'bet date'
        },
        {
          value: 'result_date',
          display: 'result date'
        }
      ],

      isDataLoading: true,
      isEmpty: false,
      showDataLoading: false
    }
  },

  watch: {
    '$route' ($router) {
      if (!this.showDataLoading) {
        this.showDataLoading = true
      }
      this.search(this.generate($router.query))
    }
  },

  methods: {
    ...mapActions('bet-history', ['get']),
    ...mapActions('game', ['getTypes']),

    async search (q) {
      this.params = q || this.params
      this.params.user_id = this.$route.params.id

      // == flags reset
      this.isEmpty = false
      this.isDataLoading = !this.showDataLoading ? true : false

      let type = this.gameTypes.find(e =>  e.name === this.$route.params.subtype)

      type = type ? type.id : 'all'

      await this.get({
        type,
        status: this.$route.params.status,
        params: this.params
      })

      this.isDataLoading = false
      this.showDataLoading = false

      if (this.betHistory) {
        this.isEmpty = !this.betHistory.length ? true : false
      }
    },

    trigger (query) {
      this.params = query || this.params
      this.$router.push({ query: this.params })
    },

    clear () {}
  }
}
</script>
