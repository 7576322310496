export const kr = {
  kr: {
    result: '결과',
    user: '유저',
    total_bets: '배팅금',
    total_odds: '배당률',
    success: '성공',
    win: '적중',
    status: '상태',
    round: '라운드',
    lost: '미적중',
    name: '이름',

    asian_handicap: '핸디캡',
    match_winner: '승무패',
    '3way_result': '승무패',
    handicap: 'Handicap',
    special_games: 'Special Games',

    ongoing: '경기중',
    waiting: '대기',
    done: '완료',
    void: '적중특례',

    home: '홈팀',
    tie_1: '타이',
    away: '원정팀',
    draw: '무',

    over: '오버',
    under: '언더',

    low: '로우',
    lo: '로우',
    high: '하이',
    hi: '하이',
    joker: '조커',

    small: '소',
    middle: '중',
    big: '대',

    yes: '예',
    no: '아니오',

    odd: '홀',
    even: '짝',

    fail: '실패',

    red: '레드',
    blue: '블루',
    green: '그린',
    black: '블랙',

    blue_low: '블루로우',
    blue_high: '블루하이',
    blue_odd: '블루홀',
    blue_even: '블루짝',
    red_low: '레드로우',
    red_high: '레드하이',
    red_odd: '레드홀',
    red_even: '레드짝',
    blue_pair: '블루페어',
    red_pair: '레드페어',

    left_start: '좌출',
    right_start: '우출',

    ato: '아도',
    saki: '사끼',

    right: '우',
    left: '좌',

    no_goal: '노골',
    nogoal: '노골',
    out: '아웃',

    power_even: '파워 짝',
    power_odd: '파워 홀',
    power_over: '파워 오버',
    power_under: '파워 언더',

    finish_first: '도착1위',
    finish_second: '도착2위',
    finish_third: '도착3위',
    finish: '도착',
    place: '위',

    home_run: '홈런',
    or_better: '이상 점수차',
    orbetter: '이상 점수차',
    or_better1: '이상 점수차',

    player: '플레이어',
    player_pair: '플레이어 페어',
    banker: '뱅커',
    banker_pair: '뱅커 페어',
    tie: '타이',

    set: '세트',
    sets: '세트',
    or: '또는',
    goal: '골',
    goals: '골',
    to_win: '승리',
    towin: '승리',
    after_extra_points: '추가 포인트',
    afterextrapoints: '추가 포인트',
    points: '포인트',
    or_more_points: '또는 많음',
    svr: '서브',
    line_1: '줄',
    line_2: '라인',
    more: '이상',

    player1: '플레이어 1',
    player2: '플레이어 2',

    to_win_1st_set_and_lost_match: '우승 1세트 그리고 경기 패',
    to_win_2nd_set_and_lost_match: '우승 2세트 그리고 경기 패',
    to_win_3rd_set_and_lost_match: '우승 3세트 그리고 경기 패',

    to_win_1st_set_and_win_match: '우승 1세트 그리고 경기 승',
    to_win_2nd_set_and_win_match: '우승 2세트 그리고 경기 승',
    to_win_3rd_set_and_win_match: '우승 3세트 그리고 경기 승',

    not_to_score_in_1st_half: 'not to score in 1st half',
    not_to_score_in_2nd_half: 'not to score in 2nd half',
    not_to_score_in_3rd_half: 'not to score in 3rd half',

    to_score_in_1st_half: 'to score in 1st half',
    to_score_in_2nd_half: 'to score in 2nd half',
    to_score_in_3rd_half: 'to score in 3rd half',

    to_score: '포인트 득점 성공',
    not_to_score: '포인트 득점 실패',

    run_exactly: 'runs exactly',
    runs_draw: 'runs draw',
    more_runs: 'more runs',

    no_1st_goal: '노골 ',
    no1stgoal: '노골 ',
    no_2nd_goal: '노골 ',
    no2ndgoal: '노골 ',
    no_3rd_goal: '노골 ',
    no3rdgoal: '노골 ',
    no_4th_goal: '노골 ',
    no4thgoal: '노골 ',
    no_5th_goal: '노골 ',
    no5thgoal: '노골 ',
    no_6th_goal: '노골 ',
    no6thgoal: '노골 ',
    no_7th_goal: '노골 ',
    no7thgoal: '노골 ',
    no_8th_goal: '노골 ',
    no8thgoal: '노골 ',
    no_9th_goal: '노골 ',
    no9thgoal: '노골 ',
    no_10th_goal: '노골 ',
    no10thgoal: '노골 ',
    no_11th_goal: '노골 ',
    no11thgoal: '노골 ',

    first_half: '전반',
    '1st_half': '전반',
    '1sthalf': '전반',

    second_half: '후반전',
    '2nd_half': '후반',
    '2ndhalf': '후반',

    half_1set: '전반전',
    half_2nd: '후반전',

    half_time: '하프타임',

    '1st_quarter': '1쿼터',
    '2nd_quarter': '2쿼터',
    '3rd_quarter': '3쿼터',
    '4th_quarter': '4쿼터',
    quarter: '쿼터',
    inning: '회',

    '1q': '1Q',
    '2q': '2Q',
    '3q': '3Q',
    '4q': '4Q',

    '1q_pause': `
      <span class="game-period__quarter">1Q</span>
      <span class="game-period__pause">F</span>
    `,
    '2q_pause': `
      <span class="game-period__quarter">2Q</span>
      <span class="game-period__pause">F</span>
    `,
    '3q_pause': `
      <span class="game-period__quarter">3Q</span>
      <span class="game-period__pause">F</span>
    `,
    '4q_pause': `
      <span class="game-period__quarter">4Q</span>
      <span class="game-period__pause">F</span>
    `,
    not_started: '준비중',
    finished: '게임종료',

    hometowin: '홈승리',
    awaytowin: '원정승리',

    period: '피리어드',
    '1st_period': '1피리어드',
    '2nd_period': '2피리어드',
    '3rd_period': '3피리어드',
    '4th_period': '4피리어드',

    '1_period': '1피리어드',
    '2_period': '2피리어드',
    '3_period': '3피리어드',
    '4_period': '4피리어드',

    '1st_5_innings': '1~5이닝',
    '1st_set': '1세트',
    '1st_soccer_half': '전반전',

    new_bet_win: '당첨금',
    game_waiting: '준비중',

    game_1: '스포츠',
    game_2: '미니게임',
    game_3: '미니게임',
    game_4: '미니게임',
    game_5: '미니게임',
    game_6: '미니게임',
    game_7: '미니게임',
    game_8: '미니게임',
    game_9: '미니게임',
    game_14: '미니게임',
    game_15: '미니게임',
    game_16: '미니게임',
    game_17: '미니게임',
    game_18: '미니게임',
    game_19: '미니게임',
    game_20: '인플레이',
    game_21: '미니게임',
    game_22: '미니게임',
    game_23: '미니게임',
    game_24: '미니게임',
    game_25: '미니게임',
    game_26: '미니게임',
    game_27: '미니게임',
    game_28: '미니게임',
    game_29: '미니게임',
    game_30: '미니게임',
    game_36: '미니게임',
    game_37: '미니게임',
    game_64: '미니게임',

    game_76: '미니게임',
    game_77: '미니게임',
    game_78: '미니게임',
    game_79: '미니게임',
    game_80: '미니게임',
    game_81: '미니게임',
    game_82: '미니게임',

    horse_racing: '경마',
    greyhounds: '개경주',
    soccer: '축구',
    goldenhill_park: '골든힐 파크',
    festival_downs: '페스티발 다운',
    euro_cup: '유로 컵',
    premiership: '프리미어',
    superleague: '슈퍼리그',
    britannia_way: '브리타니아 웨이',
    hillside_park: '힐사이드 파크',
    victoria_park: '빅토리아 파크'
  }
}
