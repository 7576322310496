import axios from 'axios'

export default {
  namespaced: true,

  state: {
    faqs: [],
    listFaqs: [],
    count: 0
  },

  getters: {},

  mutations: {
    SET_FAQS (state, faq) {
      state.faqs = faq.list
    },

    LIST_FAQS (state, faq) {
      state.listFaqs = faq.list
    },

    SET_COUNT (state, data) {
      state.count = data.count
    },

    UPDATE_FAQ (state, faqs) {
      state.faqs.forEach((data) => {
        faqs.forEach((faq) => {
          if (data.id === faq.id) {
            data.title = faq.title
            data.content = faq.content
            data.is_hidden = faq.is_hidden
          }
        })
      })
    },

    RESTORE_FAQ (state, ids) {
      for (let a = 0; a < ids.length; a++) {
        state.faqs.forEach((row) => {
          if (row.id === ids[a]) {
            row.deleted_at = null
          }
        })
      }
    }
  },

  actions: {
    async get ({ commit }, { status, params }) {
      let result = await axios
        .get(`/faqs/${status}`, { params })

      commit('SET_FAQS', result.data)
      commit('SET_COUNT', result.data)
      return result.data
    },

    async list ({ commit }, { status, params }) {
      let result = await axios
        .get(`/faqs/${status}`, { params })

      commit('LIST_FAQS', result.data)
    },

    async create ({ commit }, faq) {
      let result = await axios
        .post('/faqs', faq)

      return result
    },

    async update ({ commit }, faqs) {
      let result

      for (let a = 0; a < faqs.length; a++) {
        result = await axios
          .patch(`/faqs/${faqs[a].id}`, faqs[a])
      }

      commit('UPDATE_FAQ', faqs)
      return result
    },

    async delete ({ commit }, params) {
      return await axios.delete(`/faqs`, params)
    },

    async restore ({ commit }, params) {
      console.log(params)
      let result = await axios.patch('/faqs', params)

      commit('RESTORE_FAQ', params)
      return result
    }
  }
}
