import axios from 'axios'

export default {
  namespaced: true,
  state: {
    transfers: [],
    listTransfers: [],
    transferDetails: [],
    count: 1,

    transactionDetails: []
  },
  mutations: {
    SET_DETAILS (state, data) {
      state.transactionDetails = data
    },
    LIST_TRANSACTIONS (state, data) {
      state.listTransfers = data
    },
    SET_TRANSACTIONS (state, trans) {
      state.transfers = trans
    },
    SET_COUNT (state, count) {
      state.count = count
    },
    SET_TRANSDETAILS (state, data) {
      state.transferDetails = data
    }
  },
  getters: {},
  actions: {
    async get ({ commit }, { type, status, params }) {
      let transfers = await axios.get(`/transactions/${type}/${status}`, { params })
      let count = await axios.get(`/transactions/count/${type}/${status}`, { params })
      commit('SET_TRANSACTIONS', transfers.data)
      commit('SET_COUNT', count.data[0].total)
      return transfers
    },

    async list ({ commit }, { type, status, params }) {
      let transfers = await axios.get(`/transactions/${type}/${status}`, { params })
      commit('LIST_TRANSACTIONS', transfers.data)
    },

    async getDetail ({ commit }, { type, status, uid, params }) {
      // params.status=status
      let response = await axios.get(`/transactions/${type}/${status}/${uid}`, { params })
      commit('SET_DETAILS', response.data)
      let count = await axios.get(`/transactions/count/${type}/${status}/${uid}`, { params })
      commit('SET_COUNT', count.data[0].total)
    },

    async getAgentDetail ({ commit }, { type, id, params, status }) {
      let response = await axios.get(`/transactions/${type}/${status}/agent/${id}`, { params })
      commit('SET_DETAILS', response.data)
    },

    async getAgentListCount ({ commit }, { type, id, params, status }) {
      let count = await axios.get(`/transactions/count/${type}/${status}/agent/${id}`, { params })
      commit('SET_COUNT', count.data[0].total)
    },

    async create ({ commmit }, data) {
    },

    async update ({ commit }, data) {
    }
  }
}
