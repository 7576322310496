<template>
  <div class="contents detail-info">
    <div class="contents__items contents__header">
      <div class="contents__header-item">
        <h2>
          <i class="ico--users"></i>
          {{ translate(translations, 'user detail info') }}
        </h2>
      </div>

      <div class="contents__header-item">
        <div class="time-info">
          <div class="time-info__item">
            <div class="time-info__label">
              {{ translate(translations, 'created date') }}
            </div>

            <div class="time-info__data">
              <div class="time-info__data-inner">
                {{ user.confirmed_at | date }}
              </div>
            </div>
          </div>

          <div
            v-if="user.last_login"
            class="time-info__item"
          >
            <div class="time-info__label">
              {{ translate(translations, 'last login') }}
            </div>

            <div class="time-info__data">
              <div class="time-info__data-inner">
                {{ user.last_login | date }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="!dataLoading"
      class="contents__items contents__body"
    >
      <div class="wrap__sidebar">
        <div class="form">
          <div class="form__item">
            <div class="form__label">
              {{ translate(translations, 'referral') }}
            </div>

            <div class="form__data">
              <div class="form__data-inner">
                {{ user.referrer_login_id || 'n/a' }}
              </div>
            </div>
          </div>

          <div class="form__item">
            <div class="form__label">
              {{ translate(translations, 'referral points') }}
            </div>

            <div class="form__data">
              <div class="form__data-inner">
                {{ user.total_referral_points | FormatAmount('whole') }}
              </div>
            </div>
          </div>

          <div class="form__item">
            <div class="form__label">
              {{ translate(translations, 'code') }}
            </div>

            <div class="form__data">
              <div class="form__data-inner">
                <template v-if="user.code">
                  {{ user.code }}
                </template>
              </div>
            </div>
          </div>

          <div class="form__item">
            <div class="form__label">
              {{ translate(translations, 'login id') }}
            </div>

            <div class="form__data">
              <div
                class="form__data-inner"
                :class="{ 'is-test-user' : user.isTester }"
              >
                <template v-if="user.login_id">
                  {{ user.login_id }}
                </template>
              </div>
            </div>
          </div>

          <div class="form__item">
            <div class="form__label">
              {{ translate(translations, 'login name') }}
            </div>

            <div class="form__data">
              <div
                class="form__data-inner"
                :class="{ 'is-test-user' : user.isTester }"
              >
                <template v-if="user.login_name">
                  {{ user.login_name }}
                </template>
              </div>
            </div>
          </div>

          <div class="form__item">
            <div class="form__label">
              {{ translate(translations, 'level') }}
            </div>

            <div class="form__data">
              <div
                v-if="user.level_id"
                class="form__data-inner"
              >
                {{ translate(translations, user.lvl) }}
              </div>
            </div>
          </div>

          <div class="form__item">
            <div class="form__label">
              {{ translate(translations, 'status') }}
            </div>
            <div class="form__data">
              <div
                class="form__data-inner"
                :class="{ 'is-test-user' : user.isTester }"
              >
                <template v-if="user.status">
                  {{ translate(translations, user.status, 'Status') }}
                </template>
              </div>
            </div>
          </div>

          <div class="form__item">
            <div class="form__label">
              {{ translate(translations, 'money') }}
            </div>

            <div class="form__data">
              <div class="form__data-inner">
                {{ user.amount | FormatAmount('whole') }}
              </div>
            </div>
          </div>
          <div class="form__item">
            <div class="form__label">
              {{ translate(translations, 'point') }}
            </div>

            <div class="form__data">
              <div class="form__data-inner">
                {{ user.point | FormatAmount('whole') }}
              </div>
            </div>
          </div>

          <div class="form__item">
            <div class="form__label">
              {{ translate(translations, 'cash in') }}
            </div>

            <div class="form__data">
              <div class="form__data-inner">
                {{ user.total_cash_in | FormatAmount('whole') }}
              </div>
            </div>
          </div>

          <div class="form__item">
            <div class="form__label">
              {{ translate(translations, 'cash out') }}
            </div>

            <div class="form__data">
              <div class="form__data-inner">
                <template v-if="user.manuals">
                  {{ user.total_cash_out| FormatAmount('whole') }}
                </template>
              </div>
            </div>
          </div>

          <div class="form__item">
            <div class="form__label">
              {{ translate(translations, 'cash net') }}
            </div>

            <div class="form__data">
              <div class="form__data-inner">
                {{ user.total_cash_net | FormatAmount('whole') }}
              </div>
            </div>
          </div>

          <div class="form__item">
            <div class="form__label">
              {{ translate(translations, 'total betting') }}
            </div>

            <div class="form__data">
              <div class="form__data-inner">
                {{ user.total_bet | FormatAmount('whole') }}
              </div>
            </div>
          </div>

          <div class="form__item">
            <div class="form__label">
              {{ translate(translations, 'total win') }}
            </div>

            <div class="form__data">
              <div class="form__data-inner">
                {{ user.total_win | FormatAmount('whole') }}
              </div>
            </div>
          </div>

          <div class="form__item">
            <div class="form__label">
              {{ translate(translations, 'bet net') }}
            </div>

            <div class="form__data">
              <div class="form__data-inner">
                {{ user.total_bet - user.total_win | FormatAmount('whole') }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="wrap">
        <div class="tabs">
          <div
            v-for="(tab, i) in tabs"
            :key="i"
            class="tabs__item"
            :class="{ 'is-active' : $route.params.tab === tab.display }"
          >
            <router-link
              :key="`/user/${$route.params.id}/${tab.route}`"
              :to="`/user/${$route.params.id}/${tab.route}`"
            />
            <span>
              {{ translate(translations, tab.display.replace(/[_-]/g, " ")) }}
            </span>
          </div>
        </div>

        <div class="wrap__contents wrap__contents-main">
          <component
            :is="currentTab"
            :ref="currentTab"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { transfers, points, bets } from '@/socket'
import { mapState, mapActions } from 'vuex'
import _ from 'lodash'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

//= components
import Pagination from '@/components/base/Pagination'
import Modal from '@/components/base/Modal'

// tabs
import UserCashHistory from '@/components/common/user-details/UserCashHistory'
import UserPointHistory from '@/components/common/user-details/UserPointHistory'
import UserBetHistory from '@/components/common/user-details/UserBetHistory'

//= filters
import FormatAmount from '@/assets/js/filters/FormatAmount'
import Status from '@/assets/js/filters/Status'
import RemoveNonAlphaNum from '@/assets/js/filters/RemoveNonAlphaNum'

//= translation
import { translate } from '@/assets/js/translations/Translate'
import { translations } from '@/assets/js/translations/UserDetail'

export default {
  name: 'UserDetails',

  components: {
    Pagination,
    Modal,
    UserCashHistory,
    UserPointHistory,
    UserBetHistory
  },

  filters: {
    FormatAmount,
    Status,
    RemoveNonAlphaNum
  },

  mixins: [
    translate
  ],

  data () {
    const params = Object.assign(
      {
        rows: 5,
        page: 1,
        filter_by: '',
        q: '',
        sort_by: 'id',
        sort: 'desc',
        status: 'all'
      },
      this.$route.query
    )

    for (const key in params) {
      if (Object.prototype.hasOwnProperty.call(params, key) && !isNaN(parseFloat(params[key]))) {
        params[key] = parseFloat(params[key])
      }
    }

    return {
      translations,
      params,
      tabs: [
        {
          route: 'cash-history/all/all',
          display: 'cash-history'
        },
        {
          route: 'point-history/all',
          display: 'point-history'
        },
        {
          route: 'bet-history/all/all',
          display: 'bet-history'
        }
      ],

      newPassword: '',
      newPhoneNumber: '',
      changePass: false,
      user: {},
      showCreate: false,
      showCashinOut: false,
      showPhone: false,
      showPoint: false,
      showCommission: false,

      isCashin: false,
      amount: 0,

      commission: 0,
      commissionTypes: [
        {
          val: 'C',
          display: 'Cash'
        },
        {
          val: 'B',
          display: 'Bet'
        },
        {
          val: 'L',
          display: 'Lost'
        }
      ],

      wallet: {
        bank_account_name: '',
        bank_id: 1,
        currency_id: 1,
        bank_number: '',
        bank_password: ''
      },

      manualData: {
        amount: 0,
        type: 3,
        memo: ''
      },

      showUpdate: false,

      pointAmt: 0,
      pointMemo: '',

      pointsLoad: false,
      cashLoad: false,

      miniData: {},
      selectedMini: false,
      showInfo: false,

      codeId: null,
      showManualCoupon: false,
      coupon: 0,

      quillPointConfig: {
        placeholder: 'Content here...',
        modules: {
          toolbar: [
            [{ align: [] }],
            ['bold', 'italic', 'underline', 'strike'],
            [
              { list: 'ordered' },
              { list: 'bullet' }
            ],
            [
              { color: [] },
              { background: [] }
            ]
          ]
        }
      },

      quillMoneyConfig: {
        placeholder: 'Content here...',
        modules: {
          toolbar: [
            [{ align: [] }],
            ['bold', 'italic', 'underline', 'strike'],
            [
              { list: 'ordered' },
              { list: 'bullet' }
            ],
            [
              { color: [] },
              { background: [] }
            ]
          ]
        }
      },

      dataLoading: true
    }
  },

  computed: {
    ...mapState('user', { u: 'user' }),
    ...mapState('vip', ['vipList']),
    ...mapState('level', ['levels']),

    currentTab () {
      const currentRoute = this.$route.params.tab
      let currentTab

      for (let i = 0; i < this.tabs.length; i++) {
        if (currentRoute === this.tabs[i].display) {
          currentTab = this.tabs[i].display
        }
      }

      return 'user-' + currentTab
    },
    showPassword () {
      return this.user.temp
        ? window.atob(this.user.temp)
        : ''
    },

    stats (e) {
      let status = []
      switch (this.user.status.toLowerCase()) {
        case 'c':
          status = ['B']
          break
        case 'w':
        case 'p':
          status = ['C', 'R', 'W']
          break
        case 'b':
          status = ['C']
          break
        case 'r':
          status = ['P', 'W']
          break
        default:
          status = []
          break
      }

      return status
    }
  },

  watch: {
    u (val) {
      this.user = Object.assign({}, val)
    },

    pointAmt (val) {
      if (!val) return
      const number = val.replace(/[,]/g, '')
      const amount = number.toString().split('.')
      let amountString = number.toString()
      let decimal = []

      amount[0] = amount[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')

      if (amountString.indexOf('.') !== -1) {
        amountString = amountString.split('.')

        if (_.size(amount) > 2) {
          for (let a = _.size(amount); a > 2; a--) {
            amount.pop()
          }
        }
        decimal = amountString[1].split('')
        if (decimal.length > 2) amountString[1] = amountString[1].slice(0, 2)
        amountString[0] = amount[0]
        this.pointAmt = amountString.join('.')
      }

      if (amountString.indexOf('.') === -1) {
        this.pointAmt = amount.join('.')
      }
    },

    'manualData.amount' (val) {
      const number = val.replace(/[,]/g, '')
      const amount = number.toString().split('.')
      let amountString = number.toString()
      let decimal = []

      amount[0] = amount[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')

      if (amountString.indexOf('.') !== -1) {
        amountString = amountString.split('.')

        if (_.size(amount) > 2) {
          for (let a = _.size(amount); a > 2; a--) {
            amount.pop()
          }
        }
        decimal = amountString[1].split('')
        if (decimal.length > 2) amountString[1] = amountString[1].slice(0, 2)
        amountString[0] = amount[0]
        this.manualData.amount = amountString.join('.')
      }

      if (amountString.indexOf('.') === -1) {
        this.manualData.amount = amount.join('.')
      }
    }
  },

  async created () {
    transfers.on('cash-in', message => {
      this.find({ id: this.$route.params.id })
    })

    transfers.on('cash-out', message => {
      this.find({ id: this.$route.params.id })
    })

    transfers.on('update-status', message => {
      this.find({ id: this.$route.params.id })
    })

    points.on('convert', data => {
      this.find({ id: this.$route.params.id })
    })

    bets.on('new-bet', async data => {
      await this.find({ id: this.$route.params.id })
      this.setInfoData(this.selectedMini)
    })

    await this.find({ id: this.$route.params.id })
    this.dataLoading = false

    this.user = Object.assign({}, this.u)
    this.tempPass = this.user.password

    await this.getVips()
    await this.getLevels()
  },

  mounted () {
    document.addEventListener('click', e => {
      if (e.target.className !== 'ico--info' &&
          this.showInfo === true && this.selectedMini !== null) {
        this.showInfo = false
        this.selectedMini = null
      }
    })
  },

  beforeDestroy () {
    document.removeEventListener('click', () => {
      //
    })
  },

  methods: {
    ...mapActions({
      createManual: 'transfer/create',

      patch: 'user/update',
      forceBan: 'user/forceBan',
      find: 'user/find',
      delete: 'user/delete',
      generateReferralCode: 'user/generateReferralCode',
      removeReferralCode: 'user/removeReferralCode',

      createWallet: 'userWallet/create',
      updateWallet: 'userWallet/update',

      createUserPoints: 'points/create',

      transaction: 'transfer/create',

      getVips: 'vip/get',

      getLevels: 'level/get',

      createCouponTransact: 'coupons/create'
    }),

    removeReferral (id) {
      const success = async () => {
        await this.removeReferralCode(id)
      }

      const swalMsg = ''
      this.$alert('prompt', swalMsg, {}, success, 'remove referral code')
    },

    defaultPointModel () {
      this.pointAmt = 0
      this.pointMemo = ''
    },

    defaultMoneyModel () {
      this.manualData.amount = 0
      this.manualData.memo = ''
    },

    openUserPopup () {
      if (!this.user.referral_id) return
      window.open(
        `${this.appUrl}/user/${this.user.referral_id}/info/undeleted`,
        '',
        `width=${1200},height=${window.innerHeight}`
      )
    },

    openAgentPopup () {
      window.open(
        `${this.appUrl}/agent/${this.user.aid}/info/undeleted`,
        '',
        `width=${1200},height=${window.innerHeight}`
      )
    },

    async update () {
      await (new Promise(res => {
        const swalMsg = ''
        this.$alert('prompt', swalMsg, {}, () => res(true), 'modify')
      }))

      if (this.changePass && !this.newPassword) {
        alert('pls enter password')
        return
      }

      const data = {}

      const keys = [
        'login_id',
        'login_name',
        'phone',
        'email',
        'level_id',
        'vip_id',
        'status',
        'deleted_at',
        'commission',
        'commission_type',
        'commission'
      ]

      if (this.newPhoneNumber) {
        this.user.phone = this.newPhoneNumber
      }

      for (const key in this.user) {
        if (keys.indexOf(key) > -1) {
          if (key === 'commission') {
            data[key] = this.commission / 100
          } else {
            data[key] = this.user[key]
          }
        }
      }

      if (this.status !== 'D') {
        this.user.deleted_at = null
      }

      if (this.changePass) {
        data.password = this.newPassword.trim()
        data.temp = this.newPassword.trim()
        this.user.temp = window.btoa(this.newPassword)
      }

      try {
        await this.patch({ data: data, id: this.$route.params.id, user: this.user })
        this.changePass = false
        this.showPhone = false
        this.showCommission = false

        const swalMsg = 'success modify'
        this.$alert('success', swalMsg)
        await this.find({ id: this.$route.params.id })
      } catch (err) {
        this.user = JSON.parse(JSON.stringify(this.u))
      }
    },

    setUpdate () {
      this.$nextTick(() => {
        if ((this.user.level_id !== this.u.level_id) ||
          this.user.status !== this.u.status || this.user.commission_type !== this.u.commission_type) {
          this.showUpdate = true
        } else {
          this.showUpdate = false
        }
      })
    },

    del () {
      const success = async () => {
        try {
          await this.delete({ uid: this.$route.params.id, user: this.user })
          this.user.deleted_at = new Date()
          this.user.updated_at = new Date()

          const swalTitle = 'success delete'
          this.$alert('success', swalTitle)
        } catch (err) {
          this.user = this.u
        }
      }

      const swalMsg = ''
      this.$alert('prompt', swalMsg, {}, { success }, 'delete')
    },

    restore () {
      const success = async () => {
        try {
          this.user.deleted_at = null
          this.user.updated_at = new Date()
          await this.patch({
            id: this.$route.params.id,
            data: {
              deleted_at: null
            },
            user: this.user
          })

          const swalMsg = 'success restore'
          this.$alert('success', swalMsg)
        } catch (err) {
          this.user = this.u
        }
      }

      const swalMsg = ''
      this.$alert('prompt', swalMsg, {}, { success }, 'restore')
    },

    createTransact (type) {
      if (parseInt(this.manualData.amount) <= 0) {
        this.cashLoad = false

        const swalMsg = this.translate(translations, 'error amount')
        this.$alert('error', swalMsg)
        return
      }

      this.manualData.type = type === 'cashout' ? 4 : 3

      let amount = typeof this.manualData.amount === 'string'
        ? this.manualData.amount.replaceAll(',', '') : this.manualData.amount

      if (!isNaN(amount)) {
        amount = parseInt(amount)
      }

      if (this.user.amount < amount && type === 'cashout') {
        this.cashLoad = false
        const swalMsg = this.translate(translations, 'not enough money')
        this.$alert('error', swalMsg)
        return
      }

      if (this.manualData.memo !== '' && this.manualData.memo.replace(/(<([^>]+)>)/ig, ' ').trim() === '') {
        // 'all fields required'
        const swalMsg = 'try again'
        this.$alert('error', swalMsg)
        return
      }

      if (this.cashLoad) return
      this.cashLoad = true

      const success = async () => {
        const data = {
          old_balance: this.user.amount,
          type_id: this.manualData.type,
          memo: this.manualData.memo,
          user_id: this.user.id,
          status: 'C',
          amount
        }

        try {
          await this.createManual(data)

          const swalMsg = this.translate(translations, 'success manual transaction')
          const type_name = this.manualData.type === 3 ? this.translate(translations, 'manual in') : this.translate(translations, 'manual out')

          this.$alert('success', type_name + ' ' + swalMsg)
          await this.find({ id: this.$route.params.id })

          this.showCashinOut = false
          this.$router.push({ path: `/user/${this.$route.params.id}/manual-history/all` })

          if (this.$refs.manualHistory) {
            this.$refs.manualHistory.search()
          }
          if (this.$refs.transactionHistory) {
            this.$refs.transactionHistory.search()
          }
        } catch (err) {
          const swalMsg = err.response.data.errors || 'Cannot Complete Transaction'
          this.$alert('error', swalMsg)
        }

        this.cashLoad = false
        this.manualData.amount = 0
        this.manualData.memo = ''
      }

      const cancel = () => {
        this.cashLoad = false
      }

      const type_name = this.manualData.type === 3 ? this.translate(translations, 'manual in') : this.translate(translations, 'manual out')
      let swalMsg = this.translate(translations, 'continue manual transaction')
      swalMsg = type_name + ' ' + swalMsg
      this.$alert('prompt', swalMsg, {}, { success, cancel }, type_name + ' ' + this.manualData.amount)
    },

    async createPoint (type) {
      let amt = typeof this.pointAmt === 'string' ? this.pointAmt.replaceAll(',', '') : this.pointAmt

      if (parseInt(amt) <= 0) {
        const swalMsg = this.translate(translations, 'error amount')
        this.$alert('error', swalMsg)
        return
      }

      if (this.user.point < amt && type === 'withdraw') {
        this.pointsLoad = false
        const swalMsg = this.translate(translations, 'not enough points')
        this.$alert('error', swalMsg)
        return
      }

      if (this.pointMemo !== '' && this.pointMemo.replace(/(<([^>]+)>)/ig, ' ').trim() === '') {
        // 'all fields required'
        const swalMsg = 'try again'
        this.$alert('error', swalMsg)
        return
      }

      if (this.pointsLoad) {
        return
      }
      this.pointsLoad = true

      await (new Promise(res => {
        const type_name = type !== 'withdraw' ? this.translate(translations, 'manual in') : this.translate(translations, 'manual out')
        let swalMsg = this.translate(translations, 'continue manual transaction')
        swalMsg = type_name + ' ' + swalMsg
        this.$alert('prompt', swalMsg, {}, () => res(true), type_name + ' ' + this.pointAmt)
      }))

      if (type === 'withdraw') {
        amt = amt * (-1)
      }

      await this.createUserPoints({
        uid: this.$route.params.id,
        data: {
          amount: amt,
          memo: this.pointMemo,
          type: type
        }
      })

      const swalMsg = this.translate(translations, 'success manual transaction')
      this.$alert('success', swalMsg)
      await this.find({ id: this.$route.params.id })

      if (this.$refs.pointHistory) {
        this.$refs.pointHistory.search()
      }

      this.showPoint = false
      this.pointsLoad = false
      this.pointAmt = 0
      this.pointMemo = ''

      this.$router.push({ path: `/user/${this.$route.params.id}/point-history/all` })
    },

    getInfo (type) {
      if (this.selectedMini === type) {
        this.showInfo = false
        this.selectedMini = null
        return
      }
      this.selectedMini = type
      this.showInfo = true
      this.setInfoData(type)
    },

    setInfoData (type) {
      switch (type) {
        case 'total-bet':
          this.miniData = {
            'bet-sport': this.user.total_bet_sports,
            'bet-mini': this.user.total_bet_mini,
            'bet-casino': this.user.total_bet_casino,
            'bet-slot': this.user.total_bet_slot,
            'bet-virtual': this.user.total_bet_virtual
          }

          break
        case 'total-win':
          this.miniData = {
            'win-sport': this.user.total_win_sports,
            'win-mini': this.user.total_win_mini,
            'win-casino': this.user.total_win_casino,
            'win-slot': this.user.total_win_slot,
            'win-virtual': this.user.total_win_virtual
          }
          break
        case 'now-bet':
          this.miniData = {
            'betting-sport': 0,
            'betting-mini': 0,
            'betting-casino': 0,
            'betting-slot': 0,
            'betting-virtual': 0
          }

          this.user.now_betting.forEach(e => {
            const keys = Object.keys(this.miniData)
            if (keys.indexOf(`betting-${e.game_type_name}`) > -1) {
              this.miniData[`betting-${e.game_type_name}`] = e.total
            }
          })
          break

        default:
          this.miniData = {}
          break
      }
    },

    async ban (data) {
      this.dataLoading = true
      await this.forceBan(data)
      await this.find({ id: this.$route.params.id })
      this.user = Object.assign({}, this.u)
      this.user.__ob__.dep.notify()
      this.$alert('success')
      this.dataLoading = false
    },

    async createCoupon (type) {
      await this.createCouponTransact({
        uid: this.$route.params.id,
        data: {
          type,
          amount: this.coupon
        }
      })

      const swalMsg = 'success modify'
      this.$alert('success', swalMsg)
      await this.find({ id: this.$route.params.id })
      this.showManualCoupon = false
      this.coupon = 0
    }
  }
}
</script>
