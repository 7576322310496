/**
 * Description:
 * Safely parse JSON strings. Returns undefined if invalid, parsed json otherwise
 *
 * Usage:
 * import { parsify } from '@/assets/js/common/Parsify'
 *
 * parsify('validJson', { default: 'hey' }) // { default: 'hey' }
 * parsify('{ success: 'hey' }', { default: 'hey' }) // { success: 'hey' }
 *
 */

export const parsify = (str, def) => {
  if (typeof str === 'object') {
    return str
  }

  try {
    return JSON.parse(str)
  } catch (error) {
    console.trace(new Error().stack)
    return def
  }
}
