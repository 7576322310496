import axios from 'axios'
import ObjToFormData from '@/assets/js/common/ObjToFormData'

export default {
  namespaced: true,

  state: {
    leagues: [],
    leagueList: [],
    count: 0
  },

  getters: {
    all (state) {
      return state.leagueList
    },

    leagueList (state) {
      return state.leagueList
    },

    count (state) {
      return state.count
    },

    keyedLeagues (state) {
      return _.keyBy(state.leagues, 'id')
    }
  },

  mutations: {
    SET_LEAGUES (state, leagues) {
      state.leagues = leagues
    },

    LIST_LEAGUES (state, leagues) {
      state.leagueList = leagues
    },

    UPDATE_LEAGUE (state, league) {
      let index = state.leagues
        .findIndex((row) => { return row.id === league.id })
      if (index !== -1) {
        league.game = typeof league.game === 'string' ? JSON.parse(league.game) : league.game
        league.venue = typeof league.venue === 'string' ? JSON.parse(league.venue) : league.venue
        league.updated_at = new Date()
        state.leagues[index] = league
      }
    },

    DELETE_LEAGUE (state, id) {
      let index = state.leagues
        .findIndex((row) => { return row.id === id })

      if (index !== -1) {
        state.leagues.splice(index, 1)
      }
    },

    SET_COUNT (state, count) {
      state.count = count
    }
  },

  actions: {
    async get ({ commit }, { params, status, game, venue }) {
      let url = `/leagues/status/${status || 'all'}`

      if (game) {
        url += `/game/${game}`
      }

      if (venue) {
        url += `/venue/${venue}`
      }

      if (params && params.filter_by === 'league') params.filter_by = 'name'
      let leagues = await axios.get(url, { params })
      commit('SET_LEAGUES', leagues.data)
    },

    async list ({ commit }, { params, status, game, venue }) {
      let url = `/leagues/status/${status || 'all'}`

      if (game) {
        url += `/game/${game}`
      }

      if (venue) {
        url += `/venue/${venue}`
      }
      if (params && params.filter_by === 'league') params.filter_by = 'name'
      let leagues = await axios.get(url, { params })

      commit('LIST_LEAGUES', leagues.data)
    },
    async create ({ commit }, league) {
      return axios.post('/leagues', ObjToFormData(league))
    },

    async update ({ commit }, { league, type }) {
      let result
      if (type === 'sports') {
        result = await axios.patch(`/leagues/${league.id}`, ObjToFormData(league))
      }  else {
        result = await axios.patch(`/leagues/inplay/${league.id}`, ObjToFormData(league))
      }
      commit('UPDATE_LEAGUE', league)
      return result
    },

    async updateBulk ({ commit }, { ids, type, action }) {
      let result
      if (type === 'sports') {
        result = await axios.patch(`/leagues/sports/bulk/${action}`, {
          data: ids
        })
      }  else {
        result = await axios.patch(`/leagues/inplay/bulk/${action}`, {
          data: ids
        })
      }
      return result
    },

    async restore ({ commit }, id) {
      let result = await axios.patch(`/leagues/${id}/restore`)
      return result
    },

    async remove ({ commit }, id) {
      await axios.delete(`/leagues/${id}`)
      commit('DELETE_LEAGUE', id)
    },

    async getCount ({ commit }, { params, status, game }) {
      let url = `/leagues/count/status/${status || 'all'}`

      if (game) {
        url += `/game/${game}`
      }

      let count = await axios.get(url, { params })
      commit('SET_COUNT', count.data)
    },

    async listInplay ({ commit }, { params, status, game }) {
      let url = `/leagues/inplay/status/${status || 'all'}`

      if (game) {
        url += `/game/${game}`
      }

      if (params && params.filter_by === 'league') params.filter_by = 'name'
      let leagues = await axios.get(url, { params })
      commit('LIST_LEAGUES', leagues.data)
    },

    async getCountInplay ({ commit }, { params, status, game }) {
      let url = `/leagues/inplay/count/status/${status || 'all'}`

      if (game) {
        url += `/game/${game}`
      }

      let count = await axios.get(url, { params })
      commit('SET_COUNT', count.data)
    },
  }
}
