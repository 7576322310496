/**
 * Convert POJO to FormData
 *
 * @param json
 * @returns {FormData}
 */
export default (json) => {
  let data = new FormData()

  // make common function to convert json to formData
  for (const key in json) {
    if (!json.hasOwnProperty(key)) {
      continue
    }

    if (typeof json[key] === 'boolean') {
      json[key] = json[key] ? 1 : 0
    }

    if (json[key] === null) {
      json[key] = ''
    }

    if (json[key] && typeof json[key] === 'object'
      && !(json[key] instanceof File) && typeof json[key] !== 'undefined') {
      json[key] = JSON.stringify(json[key])
    }

    if (typeof json[key] !== 'undefined') {
      data.append(key, json[key])
    }
  }

  return data
}
