import axios from 'axios'

export default {
  namespaced: true,

  state: {
    teams: []
  },

  getters: {
    teams (state) {
      return state.teams
    }
  },

  mutations: {
    SET_TEAMS (state, teams) {
      state.teams = teams
    },

    UPDATE_TEAM (state, team) {
      let index = state.teams
        .findIndex((row) => { return row.id === team.id })
      if (index !== -1) {
        state.teams[index] = team
      }
    },

    DELETE_TEAM (state, id) {
      let index = state.teams
        .findIndex((row) => { return row.id === id })

      if (index !== -1) {
        state.teams.splice(index, 1)
      }
    }
  },

  actions: {
    async get ({ commit }, { params, status }) {
      let gameTypeId = params.gameTypeId || ''

      if (gameTypeId) {
        params = Object.assign(params, { gameTypeId: undefined })
      }

      let teams = await axios.get(`/teams/${gameTypeId}`, { params })
      commit('SET_TEAMS', teams.data)
    },

    async create ({ commit }, team) {
      let data = new FormData()

      // make common function to convert json to formData
      for (const key in team) {
        if (!team.hasOwnProperty(key)) {
          console.log(key)
          continue
        }

        if (typeof team[key] === 'boolean') {
          team[key] = team[key] ? 1 : 0
        }

        data.append(key, team[key])
      }

      await axios.post('/teams', data)
    },

    async update ({ commit }, team) {
      let data = new FormData()

      for (const key in team) {
        if (!team.hasOwnProperty(key)) {
          continue
        }

        data.append(key, team[key])
      }

      await axios.patch(`/teams/${team.id}`, data)

      commit('UPDATE_TEAM', team)
    },

    async delete ({ commit }, id) {
      await axios.delete(`/teams/${id}`)
      commit('DELETE_TEAM', id)
    },

    async getInplayTeams ({ commit }, params) {
      let gameTypeId = params.gameTypeId || ''

      if (gameTypeId) {
        params = Object.assign(params, { gameTypeId: undefined })
      }

      let teams = await axios.get(`/teams/inplay/${gameTypeId}`, { params })
      commit('SET_TEAMS', teams.data)
    }
  }
}
