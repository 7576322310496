<template>
  <div class="betslip__body-item">
    <div class="bet__items bet__items--date">
      {{ logs.play_date }}
    </div>

    <div class="bet__items bet__items--match-type">
      <template v-if="lang === 'en'">
        {{ logs.game_type.eng }}
      </template>

      <template v-if="lang === 'kr'">
        {{ logs.game_type.kor ? logs.game_type.kor : logs.game_type.eng }}
      </template>
    </div>

    <div
      class="bet__items bet__items--league-name"
      :class="{ 'with-round' : logs.round }"
    >
      <template v-if="lang === 'en'">
        <span v-html="logs.league.eng.split('_').join(' ')"></span>
      </template>

      <template v-if="lang === 'kr'">
        <template v-if="logs.league.kor">
          <span v-html="logs.league.kor">
          </span>
        </template>

        <template v-else>
          <span v-html="logs.league.eng.split('_').join(' ')"></span>
        </template>
      </template>

      <span
        v-if="logs.round"
        class="bet__items-round"
      >
        <template v-if="lang === 'en'">
          {{ translate(translations, 'round') }}
          {{ logs.round }}
        </template>

        <template v-if="lang === 'kr'">
          {{ logs.round }}
          {{ translate(translations, 'round') }}
        </template>
      </span>
    </div>

    <div
      class="bet__items bet__items--bettables bet__items--home"
      :class="{
        '-selected' : logs.info.Home.sel === 1 || logs.area2 === 'Over' || logs.area2 === 'Home'
      }"
    >
      <div class="bet__items-team">
        <span
          v-if="lang === 'en'"
          v-html="logs.home.eng"
        >
        </span>

        <span
          v-if="lang === 'kr'"
          v-html="logs.home.kor ? logs.home.kor : logs.home.eng"
        >
        </span>

        <!-- <span
          v-if="logs.match_game_type !== 'odd' && (logs.game_type.eng.indexOf('Handicap') === -1 && logs.game_type.eng.indexOf('Over/Under') === -1)"
          class="bet-highlight"
        >
          ({{ logs.info.Home.name }})
        </span>

        <span
          v-if="logs.match_game_type === 'odd' && logs.info.Home.name !== 'Home'"
          class="bet-highlight"
        >
          ({{ logs.info.Home.name }})
        </span> -->
      </div>

      <div
        class="bet__items-odds odds-lbl odds-lbl--home"
        :class="{ 'has-icon' : logs.game_type.eng.indexOf('Handicap') !== -1 || logs.game_type.eng.indexOf('Over/Under') !== -1 }"
      >
        <i
          v-if="logs.game_type.eng.indexOf('Handicap') !== -1"
          class="ico--handicap"
        >
        </i>

        <i
          v-if="logs.game_type.eng.indexOf('Over/Under') !== -1"
          class="ico--over-under is-over"
        >
        </i>

        <template v-if="logs.info.Home.odd">
          {{ logs.info.Home.odd }}
        </template>

        <template v-else>
          {{ showOdds(logs.bet_odds, logs.area1 || logs.match_game_type, 'home') }}
        </template>
      </div>
    </div>

    <div
      class="bet__items"
      :class="{
        '-selected' : logs.info.Draw.sel === 1,
        'bet__items--middle': !logs.manual_adjust && !logs.info.Draw.middle, 'bet__items--bettables': logs.manual_adjust || logs.info.Draw.middle
      }"
    >
      <template v-if="!logs.manual_adjust && !logs.info.Draw.middle">
        <template v-if="logs.game_type.eng.indexOf('Over/Under') !== -1 || logs.game_type.eng.indexOf('Handicap') !== -1">
          {{ logs.area1 | FormatOdds(logs.game_type.eng) }}
        </template>

        <template v-else>
          {{ parseInt(logs.info.Draw.odd) ? logs.info.Draw.odd : 'VS' | FormatOdds(logs.game_type.eng) }}
        </template>
      </template>

      <template v-else>
        <div class="bet__items-team">
          <template v-if="lang === 'en'">
            <template v-if="logs.draw">
              {{ logs.draw.eng }}
            </template>
            <template v-else>
              {{ logs.info.Draw.name }}
            </template>
          </template>

          <template v-if="lang === 'kr'">
            <template v-if="logs.draw">
              {{ logs.draw && logs.draw.kor ? logs.draw.kor : logs.draw.eng }}
            </template>
            <template v-else>
              {{ translate(translations, logs.info.Draw.name) }}
            </template>
          </template>
        </div>

        <div class="bet__items-odds">
          <template v-if="logs.info.Draw.odd">
            {{ logs.info.Draw.odd | FormatOdds(logs.game_type.eng) }}
          </template>
        </div>
      </template>
    </div>

    <div
      class="bet__items bet__items--bettables bet__items--away"
      :class="{
        '-selected' : logs.info.Away.sel === 1 || logs.area2 === 'Under' || logs.area2 === 'Away'
      }"
    >
      <div
        class="bet__items-odds odds-lbl odds-lbl--away"
        :class="{ 'has-icon' : logs.game_type.eng.indexOf('Handicap') !== -1 || logs.game_type.eng.indexOf('Over/Under') !== -1 }"
      >
        <template v-if="logs.info.Away.odd">
          {{ logs.info.Away.odd }}
        </template>

        <template v-else>
          {{ showOdds(logs.bet_odds, logs.area1 || logs.match_game_type, 'away') }}
        </template>

        <i
          v-if="logs.game_type.eng.indexOf('Handicap') !== -1"
          class="ico--handicap"
        >
        </i>

        <i
          v-if="logs.game_type.eng.indexOf('Over/Under') !== -1"
          class="ico--over-under is-under"
        >
        </i>
      </div>

      <div class="bet__items-team">
        <span
          v-if="lang === 'en'"
          v-html="logs.away.eng"
        >
        </span>

        <span
          v-if="lang === 'kr'"
          v-html="logs.away.kor ? logs.away.kor : logs.away.eng"
        >
        </span>

        <!-- <span
          v-if="logs.match_game_type !== 'odd' && (logs.game_type.eng.indexOf('Handicap') === -1 && logs.game_type.eng.indexOf('Over/Under') === -1)"
          class="bet-highlight"
        >
          ({{ logs.info.Away.name }})
        </span>
        <span
          v-if="logs.match_game_type === 'odd' && logs.info.Away.name !== 'Away'"
          class="bet-highlight"
        >
          ({{ logs.info.Away.name }})
        </span> -->
      </div>
    </div>

    <div class="bet__items bet__items--result">
      <div class="bet__result bet__result--score">
        <template v-if="logs.home_score && logs.away_score">
          {{ logs.home_score }} : {{ logs.away_score }}
        </template>

        <template v-else>
          -
        </template>
      </div>

      <div
        class="bet__result bet__result--status"
        :class="logs.status"
      >
        {{ translate(translations, logs.status) }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

//= lib
import _get from 'lodash/get'

//= translation
import { translations } from '@/assets/js/translations/Betslip'

//= mixins
import FormatOdds from '@/assets/js/filters/FormatOdds'

export default {
  name: 'BetsCard',

  filters: {
    totalScore (data) {
      data = typeof data === 'string' ? JSON.parse(data) : data
      if (data === null || data.scores === undefined) {
        return '0 : 0'
      }
      return `${data.final.Home ? data.final.Home : 0} : ${data.final.Away ? data.final.Away : 0}`
    },

    FormatOdds
  },

  props: {
    logs: {},
    slip: {}
  },

  data () {
    const temp = this.generate(this.$route.query)
    return {
      translations,
      temp,
      imgPath: process.env.VUE_APP_AWS_URL || process.env.VUE_APP_STATIC_ASSET_PATH || '/'
    }
  },

  computed: {
    ...mapState('config', ['config']),

    sportsBet () {
      const odds = typeof this.logs.match_game.odds === 'string' ? JSON.parse(this.logs.match_game.odds) : this.logs.match_game.odds
      const betArea = this.logs.betArea
      const bets = []

      for (const key in odds) {
        for (const key2 in odds[key]) {
          if (`${key}@${odds[key][key2].N}` === betArea) {
            let bettables = Object.keys(odds[key])

            bettables = bettables.map(e => { return `${key}@${e}` })

            if (bettables.length > 3) {
              while (bettables.length) {
                bets.push(bettables.splice(0, 2))
              }
            } else {
              bets.push(bettables)
            }
          }
        }
      }

      /* bets = bets.find(items => {
        return items.indexOf(betArea) !== -1
      }) */

      return bets
    },

    value () {
      const odds = this.logs.match_game.odds

      for (const key in odds) {
        for (const key2 in odds[key]) {
          if (`${key}@${key2}` === this.logs.betArea && key !== 'odd') {
            return key
          }
        }
      }

      return false
    },

    hometeam () {
      const { lang } = this.config

      if (this.logs.match_game.league.indexOf('snail') > -1) {
        return this.translate(translations, 'red')
      }

      if (this.logs.match_game.game_type === 'mini') {
        if (lang === 'kr') {
          return this.logs.match_game.match_type_kor.split('/')[0]
        }

        if (lang === 'en') {
          return this.logs.match_game.match_type.split('/')[0]
        }
      }

      return this.logs.match_game.home_team
    },

    awayteam () {
      if (this.logs.match_game.league.indexOf('snail') > -1) {
        return this.translate(translations, 'green')
      }

      if (this.logs.match_game.game_type === 'mini') {
        const { lang } = this.config

        if (lang === 'kr') {
          return this.logs.match_game.match_type_kor.split('/')[2] ||
            this.logs.match_game.match_type_kor.split('/')[1]
        }

        if (lang === 'en') {
          return this.logs.match_game.match_type.split('/')[2] ||
            this.logs.match_game.match_type.split('/')[1]
        }
      }

      return this.logs.match_game.away_team
    },

    vsteam () {
      if (this.logs.match_game.league.indexOf('snail') > -1) {
        return this.config.lang === 'kr' ? '블루' : 'blue'
      }

      if (this.logs.match_game.game_type === 'mini') {
        const { lang } = this.config

        if (lang === 'kr') {
          return this.logs.match_game.match_type_kor.split('/')[1]
        }

        if (lang === 'en') {
          return this.logs.match_game.match_type.split('/')[1]
        }
      }

      return this.logs.match_game.away_team
    }
  },

  async created () {
    if (!this.config) {
      await this.getConfig()
    }
  },

  methods: {
    ...mapActions('config', {
      getConfig: 'get'
    }),

    showOdds (odds, oddKey, location) {
      odds = typeof odds === 'string' ? JSON.parse(odds) : odds

      if (_get(odds, 'odds')) {
        odds = _get(odds, 'odds')
      }

      location = location === 'home' ? 0 : 1

      if (odds && odds[oddKey]) {
        const odd = odds[oddKey]
        const oddKeys = Object.keys(odd)
        const value = odd[oddKeys[location]].V

        return value
      }

      return '-'
    },

    getMiddle () {
      const betOdds = JSON.parse(this.logs.bet_odds)
      let keyVal = ''
      for (const key in betOdds) {
        for (const key2 in betOdds[key]) {
          keyVal = key
        }
      }
      if (keyVal === 'odd') {
        keyVal = 'VS'
      }
      return keyVal
    }
  }
}
</script>
