import { vueLocalStorage } from '@/assets/js/mixins/base/VueLocalStorage'

const check = () => {
  return vueLocalStorage.getItem('permission')
}

const checkPermission = async (to, from, next) => {
  try {
    const permission = await check()

    if (permission) {
      const parsePerm = JSON.parse(permission)
      const metaKey = to.meta.dbKey.toLowerCase()
      const allowedRoutes = Object.keys(parsePerm)

      if (allowedRoutes.find(e => {
        return e === metaKey ? 1 : ''
      })) {
        next()
      } else {
        if (allowedRoutes[0] === 'bet_history') {
          next({ path: '/bet-history' })
        }

        next({ path: `/${allowedRoutes[0]}` })
      }
      next()
    } else {
      vueLocalStorage.clear({ except: ['theme'] })
      next('/login')
    }
  } catch (error) {
    vueLocalStorage.clear({ except: ['theme'] })
    next('/login')
  }
}

export { checkPermission, check }
