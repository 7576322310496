import axios from 'axios'

export default {
  namespaced: true,

  state: {
    domains: [],
    listDomains: [],
    count: 0
  },

  getters: {},

  mutations: {
    SET_DOMAINS (state, data) {
      state.domains = data
    },

    LIST_DOMAINS (state, data) {
      state.listDomains = data
    },

    SET_COUNT (state, data) {
      state.count = data
    }
  },

  actions: {
    async get ({ commit }, { status, params }) {
      let result = await axios
        .get(`/domains/${status}`, { params: {...params } })
      let count = await axios
        .get(`/domains/${status}/count`, { params: {...params} })

      commit('SET_DOMAINS', result.data)
      commit('SET_COUNT', count.data)
    },

    async list ({ commit }, { status, params }) {
      let result = await axios
        .get(`/domains/${status}`, { params: {...params } })
      let count = await axios
        .get(`/domains/${status}/count`, { params: {...params} })

      commit('LIST_DOMAINS', result.data)
    },

    async create ({ commit }, domain) {
      return axios.post(`/domains`, domain)
    },

    async update ({ commit }, domains) {
      let result

      for (let a = 0; a < domains.length; a++) {
        result = await axios
          .patch(`/domains/${domains[a].id}`, domains[a])
      }

      return result
    },

    async delete ({ commit }, params) {
      return axios.delete(`/domains`, params)
    },

    async restore ({ commit }, domains) {
      let result

      for (let a = 0; a < domains.length; a++) {
        result = await axios
          .patch(`/domains/${domains[a].id}/restore`, domains[a].data)
      }

      return result
      // return axios.patch(`/domains/${id}`, data)
    }
  }
}
