import axios from 'axios'

export default {
  namespaced: true,

  state: {
    matchGames: [],
    count: 0,

    errs: {}
  },

  getters: {
    matchGames (state) {
      return state.matchGames.sort((a, b) => {
        if (a.id < b.id) {
          return -1
        } else if (a.id > b.id) {
          return 1
        }

        return 0
      })
    },

    matchGamesMini (state) {
      return state.matchGames.sort((a) => {
        if (!a.isBetting && a.isShow) {
          return -1
        }

        if (a.isBetting && a.isShow) {
          return 1
        }
        return 0
      })
    },

    countMatchGames (state) {
      return state.count
    }
  },

  mutations: {
    SET_MATCH_GAMES (state, matchGames) {
      state.matchGames = matchGames.map((row) => {
        row.shouldEdit = false
        row.odds = typeof row.odds === 'string' ? JSON.parse(row.odds) : row.odds

        if (row.meta instanceof Array && !row.meta.length) {
          row.meta = {
            bet_1: 0,
            bet_2: 0,
            bet_3: 0,
            win_1: 0,
            win_2: 0,
            win_3: 0
          }
        }
        return row
      })
    },

    SET_COUNT_MATCH_GAMES (state, data) {
      //state.count = data[0].total
      state.count = data
    },

    UPDATE_MATCH_GAME (state, matchGame) {
      let index = state.matchGames
        .findIndex((row) => { return row.id === matchGame.id })
      if (index !== -1) {
        matchGame.odds = typeof matchGame.odds === 'string' ? JSON.parse(matchGame.odds) : matchGame.odds
        state.matchGames[index] = matchGame
      }
    },

    DELETE_MATCH_GAME (state, id) {
      let index = state.matchGames
        .findIndex((row) => { return row.id === id })

      if (index !== -1) {
        state.matchGames.splice(index, 1)
      }
    },

    SET_ERRORS (state, { message, gameType, id}) {
      state.errs[id] = {
        [gameType]: message
      }

      // setTimeout(() => {
      //   state.errs = {}
      // }, 6000)
    }
  },

  actions: {
    async get ({ commit }, params) {
      let matchGames = await axios.get('match-games', { params })
      commit('SET_MATCH_GAMES', matchGames.data)
    },

    async getInplay ({ commit }, params) {
      let matchGames = await axios.get('match-games/inplay', { params })
      commit('SET_MATCH_GAMES', matchGames.data)
    },

    async create ({ commit }, matchGame) {
      await axios.post('match-games', matchGame)
    },

    async update ({ commit }, matchGame) {
      await axios.patch(`/match-games/${matchGame.id}`, matchGame)
      commit('UPDATE_MATCH_GAME', matchGame)
    },

    async updateManual({ commit }, matchGame) {
      try {
        await axios
          .patch(`/match-games/inplay/${matchGame.id}/disable-enable/odd/update`, matchGame)

        commit('UPDATE_MATCH_GAME', matchGame)
      } catch (err) {
        console.log('Error update manual match-game modules')
        console.log(err)
      }
    },

    async updateInplay ({ commit }, matchGame) {
      await axios.patch(`/match-games/inplay/${matchGame.id}`, matchGame)
      commit('UPDATE_MATCH_GAME', matchGame)
    },

    async updateMatchMini ({ commit }, { id, data }) {
      await axios.patch(`/match-games/mini/${id}`, data)
      // commit('UPDATE_MATCH_GAME', matchGame)
    },

    async delete ({ commit }, id) {
      await axios.delete(`/match-games/${id}`)
      commit('DELETE_MATCH_GAME', id)
    },

    async deleteInplay ({ commit }, id) {
      await axios.delete(`/match-games/inplay/${id}`)
      commit('DELETE_MATCH_GAME', id)
    },

    async result ({ commit }, params) {
      try {
        let result = await axios.patch(`/match-games/${params.id}/result`, params)
        params = Object.assign(params, result)
        commit('UPDATE_MATCH_GAME', params)
      } catch (err) {
        let message = err.response.data.split('|')[0]
        let gameType = err.response.data.split('|')[1]
        let id = err.response.data.split('|')[2]

        commit('SET_ERRORS', { message, gameType, id })
      }
    },

    async resultInplay ({ commit }, params) {
      try {
        let result = await axios.patch(`/match-games/inplay/${params.id}/result`, params)
        params = Object.assign(params, result)
        commit('UPDATE_MATCH_GAME', params)
      } catch (err) {
        let message = err.response.data.split('|')[0]
        let gameType = err.response.data.split('|')[1]
        let id = err.response.data.split('|')[2]

        commit('SET_ERRORS', { message, gameType, id })
      }
    },

    async rollback ({ commit }, { game }) {
      await axios.patch(`/match-games/${game.id}/rollback`, game)
      commit('UPDATE_MATCH_GAME', { id: game.id, status: 'N' })
    },

    async rollbackInplay ({ commit }, { game }) {
      await axios.patch(`/match-games/inplay/${game.id}/rollback`, game)
      commit('UPDATE_MATCH_GAME', { id: game.id, status: 'N' })
    },

    async rollbackMatchOdd ({ commit }, { matchId, matchGameId, data }) {
      let result = await axios.patch(`/match-games/odd/${matchId}/rollback/${matchGameId}`, { data })
    },

    async getMini ({ commit }, { id, league, params }) {
      let result = await axios
        .get(`/match-games/${id}/league/${league}`, { params })

      commit('SET_MATCH_GAMES', result.data.list)
      commit('SET_COUNT_MATCH_GAMES', result.data.count)
    },

    async resultMini ({ commit }, params) {
      let result = await axios
        .patch(`/match-games/${params.round}/result/mini`, params)
      params = Object.assign(params, result)
      commit('UPDATE_MATCH_GAME', params)
    },

    async rollbackMini ({ commit }, params) {
      let result = await axios
        .patch(`/match-games/${params.round}/rollback/mini`, params)
      params = Object.assign(params, result)
      commit('UPDATE_MATCH_GAME', params)
    }
  }
}
