<template>
  <div
    ref="td"
    class="table__td"
    :class="[ name, { 'sorter-wrap' : sort } ]"
    @click="triggerSort(name)"
  >
    <div
      v-if="sort"
      :key="sortKey"
      class="td__sort"
    >
      <div class="td__text">
        <slot>
          {{ text }}
        </slot>
      </div>

      <div
        ref="sort"
        class="sort"
      >
        <i
          class="ico--sort-asc"
          :class="{ 'is-active' : tblConfig.td_name === name && tblConfig.asc }"
        >
        </i>
        <i
          class="ico--sort-desc"
          :class="{ 'is-active' : tblConfig.td_name === name && !tblConfig.asc }"
        >
        </i>
      </div>
    </div>

    <template v-else>
      <div
        v-if="!isHtml"
        ref="td-text-normal"
        class="td__text"
      >
        <slot>
          {{ text }}
        </slot>
      </div>

      <div
        v-else
        ref="td-text-html"
        class="td__text td__text-ellipsis"
        v-html="logsDisplay"
      >
      </div>

      <slot
        v-if="hasInner"
        name="inner"
      >
      </slot>
    </template>
  </div>
</template>

<script>
export default {
  name: 'TblTd',

  inject: {
    tblConfig: {}
  },

  props: {
    sort: {
      type: Boolean,
      default: false
    },

    name: {
      type: [String],
      default: ''
    },

    isHtml: {
      type: Boolean,
      default: false
    },

    logs: {
      type: [String],
      default: ''
    },

    text: {
      type: [String, Number],
      default: ''
    }
  },

  data () {
    return {
      sortKey: 1
    }
  },

  computed: {
    hasInner () {
      return this.$slots['inner']
    },

    logsDisplay () {
      if (this.logs) {
        try {
          const logsStr = this.logs.replace(/<p><p>/g, '<p>').replace(/<\/p><\/p>/g, '</p>')
          return logsStr
        } catch (err) {
          return this.logs
        }
      } else {
        return this.logs
      }
    }
  },

  methods: {
    triggerSort (name) {
      if (this.sort) {
        this.tblConfig.td_name = name
        this.tblConfig.asc = !this.tblConfig.asc
        this.sortKey++

        this.$emit('click', true)
      }
    }
  }
}
</script>
