/**
  @Description:

  let type = ''
  let swalMsg = ''
  let options = {}
  let callback = {}
  let swalTitle = ''

  this.$alert(type, swalMsg, options, callback, swalTitle)
**/

import $ from 'jquery'
import _merge from 'lodash/merge'
import swal from 'sweetalert2/dist/sweetalert2.js'

import { translations } from '@/assets/js/translations/Alerts'

export default {
  methods: {
    $alert (type, swalMsg = '', options = {}, callback = {}, swalTitle = '') {
      swalTitle = swalTitle.toLowerCase()
      type = type.toLowerCase()

      if ((type === 'success' || type === 'error') && swalTitle === '') {
        swalTitle = type.toLowerCase()
      }

      if (swalMsg === '' || swalMsg === null) {
        if (type === 'prompt') {
          swalMsg = this.translate(translations, 'proceed')
        }

        if (swalTitle.indexOf('error') > -1) {
          swalMsg = this.translate(translations, 'try again')
        }
      }

      swalTitle = this.translate(translations, swalTitle)
      swalMsg = this.translate(translations, swalMsg)

      let swalOption = {
        customClass: 'swal--' + type,
        text: null,
        type: null,
        confirmButtonText: this.translate(translations, 'confirm'),
        cancelButtonText: this.translate(translations, 'cancel'),
        showCancelButton: false,
        reverseButtons: false,
        allowOutsideClick: false,
        html: `
        <div class="swal__header">
          <div class="swal__title">
            ${swalTitle}
          </div>
        </div>

        <div class="swal__body">
          <p>
            ${swalMsg}
          </p>
        </div>
        `
      }

      if (type === 'prompt') {
        const additionalOptions = { showCancelButton: true }

        swalOption = _merge(swalOption, additionalOptions)

        if (Object.keys(options).length > 0) {
          swalOption = _merge(swalOption, options)
        }
      }

      if (!callback) {
        swal(swalOption)
      } else {
        swal(swalOption).then(result => {
          if (result.value) {
            if (typeof callback === 'function') {
              callback()
            }
            if (typeof callback.success === 'function') {
              callback.success()
            }
          }

          if (!result.value) {
            if (typeof callback.cancel === 'function') {
              callback.cancel()
            }
          }
        })
      }

      if (!swalMsg) {
        $('.swal__body').hide()
      }

      $('.swal2-confirm').html('<span>' + this.translate(translations, 'yes') + '</span>')
      $('.swal2-cancel').html('<span>' + this.translate(translations, 'cancel') + '</span>')
    },

    $showAlert ({ type, text, title, success, cancel }) {
      return this.$alert(type, text, {}, { success, cancel }, title)
    }
  }
}
