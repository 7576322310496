import axios from 'axios'
import ObjToFormData from '@/assets/js/common/ObjToFormData.js'

export default {
  namespaced: true,
  state: {
    levels: []
  },
  mutations: {
    SET_LIST (state, data) {
      state.levels = data
    }
  },
  getters: {},
  actions: {
    async get ({ commit }) {
      let data = await axios.get('/levels')
      commit('SET_LIST', data.data)
    },

    async update ({ commit }, { lid, data }) {
      let result = await axios
        .patch(`/levels/${lid}`, ObjToFormData(data))

      return result
    }
  }
}
