import { common } from '@/assets/js/translations/Common'

export const jp = {
  jp: {
    total_info: `全体情報`,
    user_info: `使用者情報`,
    cash_info: `入出金情報`,
    betting_info: `ベッティング情報`,
    point_info: `ポイント情報`,
    manual_info: `支給情報`,
    comission_info: `コミッション情報`,
    sports_info: `スポーツ情報`,
    mini_games_info: `ミニゲーム情報`,
    in_play_info: `インプレー情報`,
    virtual_sports_info: `virtual sports info`,
    life_info: `life info`,

    cash_net: `損益`,
    manual_net: `支給回収 損益`,
    betting_net: `ベッティング収益`,

    balance: `保有金額`,
    users: `ユーザー数`,
    online: `オンラインユーザー`,
    new: `新規ユーザー`,

    waiting: `待機`,
    in: `チャージ`,
    out: `両替`,
    net: `利益`,
    bet: `ベッティング`,
    win: `勝ち`,
    auto: `自動`,
    give: `手動支給`,
    refund: `自動回収`,

    agent: `店舗 手数料`,
    referral: `推薦手数料`
  }
}
