import { common } from '@/assets/js/translations/Common'

export const kr = {
  kr: {
    commission: '보유수수료',
    logout: '로그아웃',
    cash_in: '입금',
    cash_out: '출금',
    cash_in_wait: '입금 대기',
    cash_out_wait: '출금 대기',
    cash_wait: '입출 대기',
    new_user: '신규 회원',
    help: '고객센터',
    wait_user: '가입대기회원',
    dark: '어두운 테마',
    en: 'en',
    jp: 'jp',
    zh: 'zh',
    kr: 'kr',
    total_commission: '총 커미션',
    withdraw: '총 출금',
    current_commission: '보유커미션'
  }
}
