import axios from 'axios'

export default {
  namespaced: true,

  state: {
    codes: [],
    count: 0
  },

  mutations: {
    SET_CODES (state, codes) {
      state.codes = codes
    },
    SET_COUNT (state, count) {
      state.count = count
    }
  },

  actions: {
    async get ({ commit }, { id, status, params }) {
      commit('SET_CODES', [])
      let codes = null
      if (id) {
        codes = await axios.get(`/codes/${status}/${id}`, { params })
      } else {
        codes = await axios.get(`/codes/${status}`, { params })
        const count = await axios.get(`/codes/${status}/count`, { params })
        commit('SET_COUNT', count.data)
      }
      commit('SET_CODES', codes.data)
    },

    create (_, data) {
      return axios.post('/codes', data)
    },

    update (_, { id, data }) {
      return axios.patch(`/codes/${id}`, data)
    },

    async delete ({ commit }, ids) {
      let result
      for (let a = 0; a < ids.length; a++) {
        result = await axios.delete(`/codes/${ids[a]}`)
      }
      return result
    },

    async recover ({ commit }, codes) {
      let result
      for (let a = 0; a < codes.length; a++) {
        result = await axios
          .patch(`/codes/${codes[a].id}/restore`, codes[a].data)
      }
      return result
    },

    async archive ({ commit }) {
      const result = await axios.get('/codes/archive')

      commit('SET_CODES', result.data)
    }
  }
}
