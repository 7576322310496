import { common } from '@/assets/js/translations/Common'

export const jp = {
  jp: {
    from: `から`,
    to: `まで`,

    all: `全体`,
    none: `選択なし`,
    live: `リアルタイムスポーツ`,
    virtual: `仮想ゲーム`,
    slot: `スロットゲーム`,
    casino: `カジノゲーム`,
    mini: `ミニゲーム`,
    sport: `スポーツゲーム`,

    ongoing: `進行中`,
    result: `結果`,
    cancelled: `取消`,
    deleted: `削除`,

    country: `国`,
    game_name: `ゲーム名`,
    league: `リーグ名`,
    home_team: `ホームチーム`,
    away_team: `アウェイチーム`,
    login_id: `ID`,
    login_name: `ニックネーム`,
    bet_amount: `ベッティング金額`,
    win_amount: `勝ち金額`,

    play_date: `play date`,
    bet_date: `bet date`,
    result_date: `result date`
  }
}
