export var getListStatus = {
  data () {
    return {
      selectedStatus: null,
      selectedType: null
    }
  },

  watch: {
    selectedStatus (val) {
      if (this.$route.params.tab === 'transaction-history'||
          this.$route.params.tab === 'cash-history' ||
          this.$route.params.tab === 'codes' ||
          this.$route.params.tab === 'user-list') {
        this.$router.push({
          params: {
            status : val
          }
        })
        return
      }

      this.$router.push({
        params: {
          status: val
        }
      })
    },

    selectedType (val) {
      if (this.$route.params.tab !== undefined &&
          this.$route.params.tab !== 'null') {
        this.$router.push({
          params: {
            subtype : val
          }
        })
        return
      }

      this.$router.push({
        params: {
          type: val
        }
      })
    }
  },

  created () {
    if (this.$route.params.status === 'null') {
      this.selectedStatus = ''
    }

    if (!this.$route.params.tab &&
        this.$route.params.type === 'null') {
      this.selectedType = ''
    }
  }
}
