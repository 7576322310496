export var getOffset = {
  methods: {
    getOffset (el) {
      var _x = 0
      var _y = 0
      var _bottom = 0

      while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
        _x += el.offsetLeft - el.scrollLeft
        _y += el.offsetTop - el.scrollTop
        el = el.offsetParent
      }

      return { top: _y, left: _x, bottom: _bottom }
    }
  }
}
