/**
 * Generate number depending on page, number of rows, sort order
 */

import _pickBy from 'lodash/pickBy'

export default {
  methods: {
    generateNumber (index, page, row, order, max) {
      return order === 'desc' ?  max - page * row + row - index
        : max - (max - index - 1) + row * (page - 1)
    },

    $_showNumber (key, total) {
      const query = _pickBy(this.$route.query, Boolean)
      const params = _pickBy(this.params, Boolean)
      const obj = { ...params, ...query }
      const page = obj.page || 1
      const row = obj.rows || 50

      return obj.sort === 'desc'
        ? total - ((page - 1) * row) - key
        : total - (total - key - 1) + row * (page - 1)
    }
  }
}
