import axios from 'axios'

export default {
  namespaced: true,

  state: {
    userExportData : ''
  },

  mutations: {
    SET_USER_EXPORT_DATA (state, value) {
      state.userExportData = value
    }
  },

  actions: {
    async getUserExportData ({commit}, params) {
      console.log(params)
      try {
        let {data} = await axios
          .get(`/exports/user`, { params })
          
        return commit('SET_USER_EXPORT_DATA', data)
      } catch (err) {
        if (!err.response) return console.log(err)
        return console.log(err.response)
      }
    }
  }
}
