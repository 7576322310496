import { common } from '@/assets/js/translations/Common'

export const jp = {
  jp: {
    delete: `削除日`,
    restore: `お知らせ`,
    modify: `復元`,
    create: `生成`,
    success: `成功`,
    error: `エラー`,
    remove: `削除`,
    cancel: `取消`,

    proceed: `本当に、このまま進行いたしますか?`,
    success_modify: `修正に成功しました`,
    success_create: `生成に成功しました.`,
    success_delete: `削除に成功しました.`,
    success_restore: `復元に成功しました.`,
    success_remove: `削除に成功しました.`,
    success_cancel: `削除を取り消しました.`,

    try_again: `もう一度お試しください.`,
    data_exist: `データが既に存在しています.`,
    fields_required: `すべての項目に入力してください.`,

    dual_logged_in: `他の場所で、同一アカウントがログインされました.`,
    account_banned: `アカウントに問題があります。顧客センターにお問い合わせください.`,
    account_deleted: `アカウントに問題があります。顧客センターにお問い合わせください.`,
    back_to_login: `ログイン画面に戻ります`,

    yes: `はい`,
    confirm: `確認`,
    cancel: `いいえ`
  }
}
