import axios from 'axios'
import _ from 'lodash'

export default {
  namespaced: true,

  state: {
    providers: []
  },

  getters: {
    keyedProviders (state) {
      return _.keyBy(state.providers, 'id')
    }
  },

  mutations: {
    SET_PROVIDERS (state, providers) {
      if (providers instanceof Array) {
        state.providers = providers
      } else {
        state.providers.push(providers)
      }
    }
  },

  actions: {
    async getProviders ({ commit }) {
      let { data } = (await axios.get('/providers/name'))
      commit('SET_PROVIDERS', data)
    }
  }
}