var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"betslip__body-item"},[_c('div',{staticClass:"bet__items bet__items--date"},[_vm._v(" "+_vm._s(_vm.logs.play_date)+" ")]),_c('div',{staticClass:"bet__items bet__items--match-type"},[(_vm.lang === 'en')?[_vm._v(" "+_vm._s(_vm.logs.game_type.eng)+" ")]:_vm._e(),(_vm.lang === 'kr')?[_vm._v(" "+_vm._s(_vm.logs.game_type.kor ? _vm.logs.game_type.kor : _vm.logs.game_type.eng)+" ")]:_vm._e()],2),_c('div',{staticClass:"bet__items bet__items--league-name",class:{ 'with-round' : _vm.logs.round }},[(_vm.lang === 'en')?[_c('span',{domProps:{"innerHTML":_vm._s(_vm.logs.league.eng.split('_').join(' '))}})]:_vm._e(),(_vm.lang === 'kr')?[(_vm.logs.league.kor)?[_c('span',{domProps:{"innerHTML":_vm._s(_vm.logs.league.kor)}})]:[_c('span',{domProps:{"innerHTML":_vm._s(_vm.logs.league.eng.split('_').join(' '))}})]]:_vm._e(),(_vm.logs.round)?_c('span',{staticClass:"bet__items-round"},[(_vm.lang === 'en')?[_vm._v(" "+_vm._s(_vm.translate(_vm.translations, 'round'))+" "+_vm._s(_vm.logs.round)+" ")]:_vm._e(),(_vm.lang === 'kr')?[_vm._v(" "+_vm._s(_vm.logs.round)+" "+_vm._s(_vm.translate(_vm.translations, 'round'))+" ")]:_vm._e()],2):_vm._e()],2),_c('div',{staticClass:"bet__items bet__items--bettables bet__items--home",class:{
      '-selected' : _vm.logs.info.Home.sel === 1 || _vm.logs.area2 === 'Over' || _vm.logs.area2 === 'Home'
    }},[_c('div',{staticClass:"bet__items-team"},[(_vm.lang === 'en')?_c('span',{domProps:{"innerHTML":_vm._s(_vm.logs.home.eng)}}):_vm._e(),(_vm.lang === 'kr')?_c('span',{domProps:{"innerHTML":_vm._s(_vm.logs.home.kor ? _vm.logs.home.kor : _vm.logs.home.eng)}}):_vm._e()]),_c('div',{staticClass:"bet__items-odds odds-lbl odds-lbl--home",class:{ 'has-icon' : _vm.logs.game_type.eng.indexOf('Handicap') !== -1 || _vm.logs.game_type.eng.indexOf('Over/Under') !== -1 }},[(_vm.logs.game_type.eng.indexOf('Handicap') !== -1)?_c('i',{staticClass:"ico--handicap"}):_vm._e(),(_vm.logs.game_type.eng.indexOf('Over/Under') !== -1)?_c('i',{staticClass:"ico--over-under is-over"}):_vm._e(),(_vm.logs.info.Home.odd)?[_vm._v(" "+_vm._s(_vm.logs.info.Home.odd)+" ")]:[_vm._v(" "+_vm._s(_vm.showOdds(_vm.logs.bet_odds, _vm.logs.area1 || _vm.logs.match_game_type, 'home'))+" ")]],2)]),_c('div',{staticClass:"bet__items",class:{
      '-selected' : _vm.logs.info.Draw.sel === 1,
      'bet__items--middle': !_vm.logs.manual_adjust && !_vm.logs.info.Draw.middle, 'bet__items--bettables': _vm.logs.manual_adjust || _vm.logs.info.Draw.middle
    }},[(!_vm.logs.manual_adjust && !_vm.logs.info.Draw.middle)?[(_vm.logs.game_type.eng.indexOf('Over/Under') !== -1 || _vm.logs.game_type.eng.indexOf('Handicap') !== -1)?[_vm._v(" "+_vm._s(_vm._f("FormatOdds")(_vm.logs.area1,_vm.logs.game_type.eng))+" ")]:[_vm._v(" "+_vm._s(_vm._f("FormatOdds")(parseInt(_vm.logs.info.Draw.odd) ? _vm.logs.info.Draw.odd : 'VS',_vm.logs.game_type.eng))+" ")]]:[_c('div',{staticClass:"bet__items-team"},[(_vm.lang === 'en')?[(_vm.logs.draw)?[_vm._v(" "+_vm._s(_vm.logs.draw.eng)+" ")]:[_vm._v(" "+_vm._s(_vm.logs.info.Draw.name)+" ")]]:_vm._e(),(_vm.lang === 'kr')?[(_vm.logs.draw)?[_vm._v(" "+_vm._s(_vm.logs.draw && _vm.logs.draw.kor ? _vm.logs.draw.kor : _vm.logs.draw.eng)+" ")]:[_vm._v(" "+_vm._s(_vm.translate(_vm.translations, _vm.logs.info.Draw.name))+" ")]]:_vm._e()],2),_c('div',{staticClass:"bet__items-odds"},[(_vm.logs.info.Draw.odd)?[_vm._v(" "+_vm._s(_vm._f("FormatOdds")(_vm.logs.info.Draw.odd,_vm.logs.game_type.eng))+" ")]:_vm._e()],2)]],2),_c('div',{staticClass:"bet__items bet__items--bettables bet__items--away",class:{
      '-selected' : _vm.logs.info.Away.sel === 1 || _vm.logs.area2 === 'Under' || _vm.logs.area2 === 'Away'
    }},[_c('div',{staticClass:"bet__items-odds odds-lbl odds-lbl--away",class:{ 'has-icon' : _vm.logs.game_type.eng.indexOf('Handicap') !== -1 || _vm.logs.game_type.eng.indexOf('Over/Under') !== -1 }},[(_vm.logs.info.Away.odd)?[_vm._v(" "+_vm._s(_vm.logs.info.Away.odd)+" ")]:[_vm._v(" "+_vm._s(_vm.showOdds(_vm.logs.bet_odds, _vm.logs.area1 || _vm.logs.match_game_type, 'away'))+" ")],(_vm.logs.game_type.eng.indexOf('Handicap') !== -1)?_c('i',{staticClass:"ico--handicap"}):_vm._e(),(_vm.logs.game_type.eng.indexOf('Over/Under') !== -1)?_c('i',{staticClass:"ico--over-under is-under"}):_vm._e()],2),_c('div',{staticClass:"bet__items-team"},[(_vm.lang === 'en')?_c('span',{domProps:{"innerHTML":_vm._s(_vm.logs.away.eng)}}):_vm._e(),(_vm.lang === 'kr')?_c('span',{domProps:{"innerHTML":_vm._s(_vm.logs.away.kor ? _vm.logs.away.kor : _vm.logs.away.eng)}}):_vm._e()])]),_c('div',{staticClass:"bet__items bet__items--result"},[_c('div',{staticClass:"bet__result bet__result--score"},[(_vm.logs.home_score && _vm.logs.away_score)?[_vm._v(" "+_vm._s(_vm.logs.home_score)+" : "+_vm._s(_vm.logs.away_score)+" ")]:[_vm._v(" - ")]],2),_c('div',{staticClass:"bet__result bet__result--status",class:_vm.logs.status},[_vm._v(" "+_vm._s(_vm.translate(_vm.translations, _vm.logs.status))+" ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }