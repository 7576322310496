import axios from 'axios'

export default {
  namespaced: true,

  state: {
    accountsAll: [],
    accountsPoints: [],
    accountsBet: []
  },

  mutations: {
    SET_ACCOUNT_ALL (state, data) {
      state.accountsAll =  data
    },

    SET_ACCOUNT_POINTS (state, data) {
      state.accountsPoints =  data
    },

    SET_ACCOUNT_BETS (state, data) {
      state.accountsBet =  data
    }
  },

  getters: {},

  actions: {
    async getAll ({ state, commit }, {params}) {
      try {
        let response = await axios.get('/accounts/all', { params })
        commit('SET_ACCOUNT_ALL', response.data)
      }  catch (err) {
        console.log(err)
      }
    },

    async getPoints ({ state, commit }, {params}) {
      try {
        let response = await axios.get('/accounts/point', { params })
        commit('SET_ACCOUNT_POINTS', response.data)
      }  catch (err) {
        console.log(err)
      }
    },

    async getBets ({ state, commit }, {type, params}) {
      try {
        console.log(type, params)

        let response = await axios.get(`/accounts/bet/${type}`, { params })
        commit('SET_ACCOUNT_BETS', response.data)
      }  catch (err) {
        console.log(err)
      }
    }
  }
}
