import { mapState, mapActions } from 'vuex'

export var checkColumns = {
  computed: {
    ...mapState('config', ['config', 'modConfig'])
  },

  methods: {
    ...mapActions('config', { patch: 'update' }),

    checkCol (val, key) {
      if (!Object.keys(this.config).length || this.config.tableConfig[key] === undefined) {
        return true
      }
      return this.config.tableConfig[key].indexOf(val) > -1
    },

    updateConfig () {
      this.patch(this.modConfig)
    },

    showLoad () {
      let table = this.$refs['tbl']

      this.isDataLoading = true

      setTimeout(() => {
        this.isDataLoading = false
      }, 100)
    }
  }
}
