import axios from 'axios'

export default {
  namespaced: true,
  state: {
    menu: []
  },

  mutations: {
    SET_MENU (state, data) {
      data = data.filter((x) => {
        let list = ['results', 'boards', 'support', 'bank']

        if (list.indexOf(x.menu) === -1) {
          return x
        }
      })

      state.menu = data
    }
  },

  getters: {},

  actions: {
    async get ({ commit }) {
      let data = await axios.get('/settings/menu')
      commit('SET_MENU', data.data)
    },

    async update ({ commit }, { id, data }) {
      return axios.patch(`/settings/menu/${id}`, { data })
    }
  }
}
