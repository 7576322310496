import Vue from 'vue'
import Vuex from 'vuex'

import _kebabCase from 'lodash/kebabCase'

//= modules
import freeBoard from '@/modules/free-board'
import freeBoardDetails from '@/modules/freeboard-details'
import user from '@/modules/user'
import userExport from '@/modules/user-export'
import userImport from '@/modules/user-import'
import userWallet from '@/modules/userWallet'
import userMemo from '@/modules/userMemo'
import userReferral from '@/modules/userReferrals'
import testUser from '@/modules/test-user'
import transfer from '@/modules/transfer'
import transaction from '@/modules/transaction'
import bank from '@/modules/bank'
import game from '@/modules/game'
import match from '@/modules/match'
import league from '@/modules/league'
import country from '@/modules/country'
import team from '@/modules/team'
import matchGame from '@/modules/match-game'
import depositAccount from '@/modules/deposit-account'
import code from '@/modules/code'
import agent from '@/modules/agent'
import agentWallet from '@/modules/agentWallet'
import agentMemo from '@/modules/agentMemo'
import admin from '@/modules/admin'
import adminLogs from '@/modules/admin-logs'
import betHistory from '@/modules/bet-history'
import betHistoryNew from '@/modules/bet-history-new'
import logins from '@/modules/logins'
import vip from '@/modules/vip'
import level from '@/modules/level'
import config from '@/modules/config'
import providerMatch from '@/modules/provider-match'
import notice from '@/modules/notice'
import faq from '@/modules/faq'
import support from '@/modules/support'
import events from '@/modules/events'
import meta from '@/modules/meta'
import domain from '@/modules/domain'
import ip from '@/modules/ip'
import points from '@/modules/points'
import coupons from '@/modules/coupons'
import settingsDefaults from '@/modules/settingsDefaults'
import settingsMenu from '@/modules/settingsMenu'
import settingsEvents from '@/modules/settingsEvents'
import settingsMarquee from '@/modules/settingsMarquee'
import settingsHome from '@/modules/settingsHome'
import settingsPoints from '@/modules/settingsPoints'
import commission from '@/modules/commission'
import goalserve from '@/modules/goalserve'
import settingsGoalserve from '@/modules/settingsGoalserve'
import settingsOddsGoalServe from '@/modules/settingsOddsGoalServe'
import settingsIndiGoalServe from '@/modules/settingsIndiGoalServe'
import provider from '@/modules/provider'
import settingsMatchGameGoalServe from '@/modules/settingsMatchGameGoalServe'
import settingsRealGameGoalServe from '@/modules/settingsRealGameGoalServe'
import settingsBookmakerGoalServe from '@/modules/settingsBookmakerGoalServe'
import settingsMarketGoalServe from '@/modules/settingsMarketGoalServe'
import settingsMarketInplayGoalServe from '@/modules/settingsMarketInplayGoalServe'
import settingsMini from '@/modules/settingsMini'
import settingsPopup from '@/modules/settingsPopup'
import settingsSound from '@/modules/settingsSound'
import settingsTheme from '@/modules/settingsTheme'
import settingsRegistration from '@/modules/settingsRegistration'
import settingsReply from '@/modules/settingsReply'
import settingsCoupon from '@/modules/settingsCoupon'
import settingBets from '@/modules/settingBets'
import settingsPromotions from '@/modules/settingsPromotions'
import goalserveOddsSettings from '@/modules/goalserveOddsSettings'
import account from '@/modules/account'
import accountDaily from '@/modules/accountDaily'
import accountWeekly from '@/modules/accountWeekly'
import accountMonthly from '@/modules/accountMonthly'
import goalserveInplay from '@/modules/goalserveInplay'
import navTrigger from '@/modules/nav-trigger'
import teams from '@/modules/teams'

const modules = (() => {
  const excludedModules = new Set([
    'template'
  ])

  const files = require.context('./modules', false, /\.js$/)
  const store = {}

  files.keys().forEach(fileName => {
    const moduleName = _kebabCase(fileName.replace(/(\.\/|\.js)/g, ''))

    if (!excludedModules.has(moduleName)) {
      store[moduleName] = files(fileName).default
    }
  })

  return store
})()

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    showStatistics: true,
    showCurrency: false,
    showLanguage: false,
    showFreeboardSettings: false,
    showSupportSettings: false
  },

  mutations: {
    SET_SHOWSTATISTICS (state, data) {
      state.showStatistics = data
    },

    SET_SHOWCURRENCY (state, data) {
      state.showCurrency = data
    },

    SET_SHOWLANGUAGE (state, data) {
      state.showLanguage = data
    },

    SET_SHOWFREEBOARDSETTINGS (state, data) {
      state.showFreeboardSettings = data
    },

    SET_SHOWSUPPORTSETTINGS (state, data) {
      state.showSupportSettings = data
    }
  },

  modules: {
    user,
    'user-export': userExport,
    'user-import': userImport,
    userWallet,
    userMemo,
    userReferral,
    'test-user': testUser,
    transfer,
    transaction,
    bank,
    freeBoard,
    'freeboard-details': freeBoardDetails,
    game,
    match,
    league,
    country,
    team,
    code,
    agent,
    agentWallet,
    agentMemo,
    admin,
    'admin-logs': adminLogs,
    logins,
    'match-game': matchGame,
    'deposit-account': depositAccount,
    'bet-history': betHistory,
    vip,
    level,
    config,
    providerMatch,
    notice,
    faq,
    support,
    events,
    meta,
    domain,
    ip,
    points,
    coupons,
    'settings-menu': settingsMenu,
    'settings-marquee': settingsMarquee,
    'settings-home': settingsHome,
    'settings-points': settingsPoints,
    'settings-events': settingsEvents,
    commission,
    goalserve,
    settingsGoalserve,
    settingsOddsGoalServe,
    settingsIndiGoalServe,
    provider,
    settingsMatchGameGoalServe,
    settingsRealGameGoalServe,
    'settings-mini': settingsMini,
    'settings-popup': settingsPopup,
    'settings-sound': settingsSound,
    'settings-register': settingsRegistration,
    goalserveOddsSettings,
    settingsBookmakerGoalServe,
    settingsMarketGoalServe,
    settingsMarketInplayGoalServe,
    accountDaily,
    accountWeekly,
    accountMonthly,
    'goalserve-inplay': goalserveInplay,
    'settings-coupon': settingsCoupon,
    'settings-defaults': settingsDefaults,
    'settings-reply': settingsReply,
    'settings-theme': settingsTheme,
    'settings-bets': settingBets,
    'bet-history-new': betHistoryNew,
    navTrigger,
    'settings-promotions': settingsPromotions,
    account,
    teams,
    ...modules
  }
})
