export const jp = {
  jp: {
    result: '結果',
    user: 'ユーザー',
    total_bets: 'ベット金額',
    total_odds: '配当率',
    win: 'win',
    status: '状態',
    round: '回転',
    lost: 'lost',
    name: '名前',

    asian_handicap: 'ハンディキャップ',
    match_winner: '勝ち,負け,引き分け',
    '3way_result': '勝ち,負け,引き分け',
    handicap: 'Handicap',
    special_games: 'Special Games',

    ongoing: '試合中',
    waiting: '待機',
    done: '完了',
    void: '試合取消',

    home: 'ホームチーム',
    tie: 'VS',
    tie_1: 'VS',
    away: 'アウェイチーム',
    draw: '引き分け',

    over: 'オーバー',
    under: 'アンダー',

    low: 'ロー',
    high: 'ハイ',

    small: '小',
    middle: '中',
    big: '大',

    yes: 'はい',
    no: 'いいえ',

    odd: '奇数',
    even: '偶数',

    fail: '失敗',
    success: '成功',

    red: '赤',
    blue: '青',
    green: '緑',

    blue_low: '青 ロー',
    blue_high: '青 ハイ',
    blue_odd: '青 奇数',
    blue_even: '青 偶数',
    red_low: '赤 ロー',
    red_high: '赤 ハイ',
    red_odd: '赤 奇数',
    red_even: '赤 偶数',
    blue_pair: '青 ペア',
    red_pair: '赤 ペア',

    left_start: '左スタート',
    right_start: '右スタート',

    ato: '後',
    saki: '先',

    right: '右',
    left: '左',

    no_goal: 'ノーゴール',
    nogoal: 'ノーゴール',
    out: 'アウト',

    power_even: 'パワー偶数',
    power_odd: 'パワー奇数',
    power_over: 'パワー オーバー',
    power_under: 'パワー アンダー',

    finish_first: '1位到着',
    finish_second: '2位到着',
    finish_third: '3位到着',
    finish: '到着',
    place: '上',

    home_run: 'ホームラン',
    or_better: '以上 点数差',
    orbetter: '以上 点数差',
    or_better1: '以上 点数差',
    player: 'プレイヤー',
    set: 'セット',
    sets: 'セット',
    or: 'または',
    goal: 'ゴール',
    goals: 'ゴール',
    to_win: '勝利',
    towin: '勝利',
    after_extra_points: '追加ポイント',
    afterextrapoints: '追加ポイント',
    points: 'ポイント',
    or_more_points: 'または多い',
    svr: 'サブ',
    line_1: '列',
    line_2: '列',
    more: '以上',

    player1: 'プレイヤー 1',
    player2: 'プレイヤー 2',

    to_win_1st_set_and_lost_match: '優勝1セットそして試合負け',
    to_win_2nd_set_and_lost_match: '優勝2セットそして試合負け',
    to_win_3rd_set_and_lost_match: '優勝3セットそして試合負け',

    to_win_1st_set_and_win_match: '優勝1セットそして試合勝ち',
    to_win_2nd_set_and_win_match: '優勝2セットそして試合勝ち',
    to_win_3rd_set_and_win_match: '優勝3セットそして試合勝ち',

    not_to_score_in_1st_half: 'not to score in 1st half',
    not_to_score_in_2nd_half: 'not to score in 2nd half',
    not_to_score_in_3rd_half: 'not to score in 3rd half',

    to_score_in_1st_half: 'to score in 1st half',
    to_score_in_2nd_half: 'to score in 2nd half',
    to_score_in_3rd_half: 'to score in 3rd half',

    to_score: 'ポイント 得点成功',
    not_to_score: 'ポイント 得点失敗',

    run_exactly: 'runs exactly',
    runs_draw: 'runs draw',
    more_runs: 'more runs',

    no_1st_goal: 'ノーゴール',
    no1stgoal: 'ノーゴール',
    no_2nd_goal: 'ノーゴール',
    no2ndgoal: 'ノーゴール',
    no_3rd_goal: 'ノーゴール',
    no3rdgoal: 'ノーゴール',
    no_4th_goal: 'ノーゴール',
    no4thgoal: 'ノーゴール',
    no_5th_goal: 'ノーゴール',
    no5thgoal: 'ノーゴール',
    no_6th_goal: 'ノーゴール',
    no6thgoal: 'ノーゴール',
    no_7th_goal: 'ノーゴール',
    no7thgoal: 'ノーゴール',
    no_8th_goal: 'ノーゴール',
    no8thgoal: 'ノーゴール',
    no_9th_goal: 'ノーゴール',
    no9thgoal: 'ノーゴール',
    no_10th_goal: 'ノーゴール',
    no10thgoal: 'ノーゴール',
    no_11th_goal: 'ノーゴール',
    no11thgoal: 'ノーゴール',

    first_half: '前半戦',
    '1st_half': '前半',
    '1sthalf': '前半',

    second_half: '後半戦',
    '2nd_half': '後半',
    '2ndhalf': '後半',

    half_1set: '前半戦',
    half_2nd: '後半戦',

    half_time: 'ハーフタイム',

    '1st_quarter': '1クォーター',
    '2nd_quarter': '2クォーター',
    '3rd_quarter': '3クォーター',
    '4th_quarter': '4クォーター',
    quarter: 'qtr',

    '1q': '1Q',
    '2q': '2Q',
    '3q': '3Q',
    '4q': '4Q',

    '1q_pause': `
      <span class="game-period__quarter">1Q</span>
      <span class="game-period__pause">F</span>
    `,
    '2q_pause': `
      <span class="game-period__quarter">2Q</span>
      <span class="game-period__pause">F</span>
    `,
    '3q_pause': `
      <span class="game-period__quarter">3Q</span>
      <span class="game-period__pause">F</span>
    `,
    '4q_pause': `
      <span class="game-period__quarter">4Q</span>
      <span class="game-period__pause">F</span>
    `,
    not_started: 'ホーム勝利',
    finished: 'アウェイ勝利',
    '1st half': '1st Half'
  }
}
