import { common } from '@/assets/js/translations/Common'

export const en = {
  en: {
    logout: `logout`,
    cash_in: `cash in`,
    cash_out: `cash out`,
    cash_wait: `cash wait`,
    cash_in_wait: 'cash in wait',
    cash_out_wait: 'cash out wait',
    new_user: `new user`,
    help: `help`,
    wait_user: `wait user`,
    dark: `dark`,
    en: `en`,
    jp: `jp`,
    zh: `zh`,
    kr: `kr`
  }
}
