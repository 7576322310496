import { user } from '@/socket'
import  { mapState, mapActions } from 'vuex'

export default {
	mounted () {
    user.on('logout', (message) => {
      this.setUserInfo('logout')
    })

    user.on('login', (message) => {
      this.setUserInfo('login')
    })

    user.on('register', (message) => {
      this.setMeta('new-user')

      this.setNotif([ { key:'newuser', value:  true } ])
    })
  },

	methods: {
    ...mapActions('transfer', ['get']),
    ...mapActions('meta', { getAllMeta: 'get' }),
    ...mapActions('meta', ['setUserInfo', 'setMeta']),
    ...mapActions('user', {getUsers: 'get' }),

    async search () {
      await this.getUsers({
        status: this.$route.params.status,
        params: {}
      })
    }
	}
}
