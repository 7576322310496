import axios from 'axios'
import ObjToFormData from '@/assets/js/common/ObjToFormData.js'

export default {
  namespaced: true,

  state: {
    settings: null,
    point_default: null,
    odds_default: null,
    language_default: null,
    currency_default: null,
    support_default: null,
    freeboard_default: null,
    cashin_default: null,
    cashout_default: null
  },

  mutations: {
    SET_DATA (state, data) {
      data.forEach((e) => {
        if (typeof e.setting_json === 'string') {
          e.setting_json = JSON.parse(e.setting_json)
        }
        state[e.setting_name] = e.setting_json
      })
      state.settings = data
    }
  },

  getters: {},

  actions: {
    async get ({ commit }, data) {
      let response = await axios.get('/settings/default')

      commit('SET_DATA', response.data)

      return response
    },

    async update ({ commit }, { data, type }) {
      let response = axios.post(`/settings/${type}/default`, data)

      return response
    }
  }
}
