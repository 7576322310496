/**
import FormatOdds from '@/assets/js/filters/FormatOdds'

filters: { FormatOdds }
*/

export default function (data, matchType, route, siteName) {
  let value = data

  if (matchType.indexOf('Over/Under') !== -1 || matchType.indexOf('Under/Over') !== -1 || matchType.indexOf('Total') !== -1) {
    value = value.replace(/[+-]/, '')
  }

  if (siteName === 'military') {
    if (route && route.params.type === 'live') {
      if (value.toLowerCase() === 'odd') {
        value = 'VS'
      }
    }
  }

  return value
}
