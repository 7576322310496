import { common } from '@/assets/js/translations/Common'

export const kr = {
  kr: {
    from: '에서',
    to: '까지',
    created_date: '등록일자',

    all: '구분',
    level: '레벨',
    bet: '배팅 포인트',
    win: '당첨 포인트',
    lost: '낙첨 포인트',
    first_deposit: '가입후 첫 충전 포인트',
    second_deposit: '두번째 ,충전 포인트',
    third_deposit: '세번째 충전 포인트',
    daily_first_deposit: '일일 첫 충전 포인트',
    daily_second_deposit: '일일 두번째 충전 포인트',
    daily_third_deposit: '일일 세번째 충전 포인트',
    deposit: '매회 충전 포인트',
    conversion: '포인트 전환',
    manual_in: '지급',
    cashback: '캐시백',
    events: '이벤트 포인트',
    referral: '추천인 포인트',
    manual_withdraw: '회수',
    manual_out: '회수',
    point_bet: '포인트 배팅',
    point_bet_cancel: '포인트 배팅 취소',
    amount: '금액',

    sum: '합계',

    no: '번호',
    old_point: '이전포인트',
    point: '포인트',
    new_point: '변경 포인트',
    type: '구분',
    status: '상태',
    memo: '메모',
    admin_ip: '관리자 IP',
    admin: '관리자',

    confirmed: '정상',
    deleted: '삭제',
    rejected: '거부',
    rollback: '마감 복원',
    lost_referral: '추천인 낙첨 포인트',
    lost_inplay: '낙첨 인플레이',
    lost_mini: '낙첨 미니게임',
    promo_win: '프로모션 당첨',
    promo_use: '프로모션 사용',
    lost_chance: '더블찬스'
  }
}
