import axios from 'axios'

export default {
  namespaced: true,
  state: {
    list: [],
    count: 1,
    types: []
  },
  mutations: {
    SET_LIST (state, data) {
      state.list = data
    },

    SET_COUNT (state, data) {
      state.count = data.total
    },

    SET_TYPES (state, data) {
      state.types = data
    }
  },
  getters: {},
  actions: {
    async create ({ commit, state }, { uid, data }) {
      return axios.post(`/coupons/${uid}`, { data })
    },

    async get ({ commit, state }, { uid, params, type, status }) {
      let response = null

      if (uid) {
        response = await axios.get(`/coupons/list/${type}/${uid}`, { params })
      } else {
        response = await axios.get(`/coupons/list/${type}`, { params })
      }

      commit('SET_LIST', response.data.list)
      commit('SET_COUNT', response.data.count[0])
    },

    async getTypes ({ commit, state }) {
      let response = await axios.get(`/points/types`)
      commit('SET_TYPES', response.data)
    }

  }
}
