<template>
  <div
    v-if="!loading"
    id="app"
    :class="[
      {
        'is-details' : $route.name === 'user-details-page' || $route.name === 'requests-details-page',
        'is-login' : $route.name === 'login',
        'is-logout' : $route.name === 'logout'
      },
      'lang-' + config.lang
    ]"
    class="body--inner"
  >
    <router-view
      v-if="hideNav"
    />

    <div
      v-if="!hideNav"
      class="contents"
    >
      <div class="contents__items contents__sidebar">
        <info-panel />
        <navigation />
      </div>

      <div class="contents__items contents__body">
        <statistics />

        <div class="wrap">
          <div
            v-if="tabs"
            class="tabs"
          >
            <component
              :is="tab.tag"
              v-for="(tab, i) in tabs"
              :key="i"
              :class="`${tab.tag === 'div' ? 'tabs__item' : 'btn--default'}
              ${tab.active === 'true' ? ' is-active' : ''}`"
            >
              <div
                class="tab__link"
                @click="
                  triggerRefresh(tab.path),
                  $router.push(tab.path)
                "
              >
              </div>

              <span>
                {{ translate(translations, tab.name) }}
              </span>
            </component>
          </div>

          <router-view :key="counter" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'

//= mixins
import { detectDevice } from '@/assets/js/mixins/base/DetectDevice'
import { metaTitle } from '@/assets/js/mixins/base/MetaTitle'
import { vueLocalStorage } from '@/assets/js/mixins/base/VueLocalStorage'

//= components
import InfoPanel from '@/components/layout/InfoPanel'
import Statistics from '@/components/layout/Statistics'
import Navigation from '@/components/layout/navigation/Navigation'

//= translations
import { translations } from '@/assets/js/translations/Navigation'

// lodash
import _isEmpty from 'lodash/isEmpty'

export default {
  name: 'App',

  components: {
    Navigation,
    InfoPanel,
    Statistics
  },

  mixins: [
    detectDevice,
    metaTitle
  ],

  data () {
    return {
      translations,
      loading: true,
      hideNav: ''
    }
  },

  computed: {
    ...mapState('config', ['config']),

    ...mapState('navTrigger', ['counter']),

    tabs () {
      let tabs
      const route = this.$route
      const currPath = this.$route.path

      if (route.name === 'users-page' || route.name === 'test-users-page' || route.name === 'ip-page') {
        tabs = [
          {
            name: 'online',
            tag: 'div',
            path: '/users/online',
            defaultPath: ['/users/online']
          },
          {
            name: 'confirmed',
            tag: 'div',
            path: '/users/confirmed',
            defaultPath: ['/users/confirmed']
          },
          {
            name: 'banned',
            tag: 'div',
            path: '/users/banned',
            defaultPath: ['/users/banned']
          },
          {
            name: 'deleted',
            tag: 'div',
            path: '/users/deleted',
            defaultPath: ['/users/deleted']
          }
        ]
      }

      if (route.name === 'transaction-list-page') {
        tabs = [
          {
            name: 'all',
            tag: 'div',
            path: '/transactions/all/all',
            defaultPath: ['/transactions/all']
          },
          {
            name: 'cash in/out',
            tag: 'div',
            path: '/transactions/cash/all',
            defaultPath: ['/transactions/cash']
          },
          {
            name: 'cash bet',
            tag: 'div',
            path: '/transactions/bet/all',
            defaultPath: ['/transactions/bet']
          },
          {
            name: 'win',
            tag: 'div',
            path: '/transactions/win/all',
            defaultPath: ['/transactions/win']
          }
        ]
      }

      if (route.name === 'points-list-page') {
        tabs = [
          {
            name: 'all',
            tag: 'div',
            path: '/points/all',
            defaultPath: ['/points/all']
          }
        ]
      }

      if (route.name === 'agents-referrals') {
        tabs = [
          {
            name: 'referrals',
            tag: 'div',
            path: '/agents/referrals',
            defaultPath: ['/agents/referrals']
          }
        ]
      }

      if (route.name === 'bet-history-update-type') {
        tabs = [
          {
            name: 'Prematch',
            tag: 'div',
            path: '/bet-history-update/sports/all',
            defaultPath: ['/bet-history-update/sports']
          },
          {
            name: 'Mini Games',
            tag: 'div',
            path: '/bet-history-update/mini/all',
            defaultPath: ['/bet-history-update/mini']
          }
        ]
      }

      if (route.name === 'codes-management-page') {
        tabs = [
          {
            name: 'active',
            tag: 'div',
            path: '/codes/active',
            defaultPath: ['/codes/active']
          },
          {
            name: 'inactive',
            tag: 'div',
            path: '/codes/inactive',
            defaultPath: ['/codes/inactive']
          },
          {
            name: 'deleted',
            tag: 'div',
            path: '/codes/deleted',
            defaultPath: ['/codes/deleted']
          }
        ]
      }

      if (route.name === 'account-all-page' || route.name === 'account-point-page' || route.name === 'account-bet-page') {
        tabs = [
          {
            name: 'total account',
            tag: 'div',
            path: '/account/all',
            defaultPath: ['/account/all']
          }
        ]
      }

      for (const i in tabs) {
        const currTab = tabs[i]
        const dPath = currTab.defaultPath || this.currPath
        tabs[i].active = 'false'

        if (dPath) {
          for (const x in currTab.defaultPath) {
            const thisPath = currTab.defaultPath[x]

            if (thisPath) {
              if (currPath.indexOf(thisPath) > -1) {
                tabs[i].active = 'true'
              }
            }
          }
        }
      }

      return tabs
    }
  },

  watch: {
    '$route' (to, from) {
      this.metaTitle(to)

      this.hideNav = this.handleHideNav()

      if (this.hideNav !== '') {
        this.loading = false
      }
    },

    lang (lang, oldLang) {
      const htmlTag = document.querySelector('html')
      this.metaTitle(this.$route)

      htmlTag.classList.add(`lang-${lang}`)

      if (htmlTag.classList.contains(`lang-${oldLang}`)) {
        htmlTag.classList.remove(`lang-${oldLang}`)
      }
    },

    async hasToken (val) {
      if (val) {
        if (_isEmpty(this.sounds)) {
          await this.getSound()
        }

        await this.getMenu()
      }
    }
  },

  created () {
    this.metaTitle(this.$route)

    const theme = this.config.theme || vueLocalStorage.getItem('theme')
    const body = document.querySelector('body')

    if (theme) {
      body.classList.add(`theme-${theme}`)
    }
  },

  async mounted () {
    this.changeFavicon()

    if (this.$route.name !== 'login-page' && this.$route.name !== 'logout-page') {
      await this.getSound()
      this.metaTitle(this.$route)
    }

    let theme = vueLocalStorage.getItem('theme')
    const htmlTag = document.querySelector('html')

    if (this.hasToken) {
      await this.getConfig()
      await this.getAuth()

      theme = this.config.theme
      vueLocalStorage.setItem('theme', theme)
      await this.getMenu()
    }

    htmlTag.classList.add(`lang-${this.lang}`)

    this.$nextTick(() => {
      this.hideNav = this.handleHideNav()

      if (this.hideNav !== '') {
        this.loading = false
      }
    })
  },

  methods: {
    ...mapActions('config', {
      getConfig: 'get',
      updateConfig: 'update'
    }),

    ...mapActions('auth', {
      getAuth: 'get'
    }),

    ...mapActions('meta', ['getSound']),

    ...mapActions('settings-menu', {
      getMenu: 'get'
    }),

    handleHideNav () {
      this.hideNav = ''

      const route = this.$route
      const hiddenNavs = {
        'requests-details-page': true,
        'account-bet-details': true,
        'user-details-page': true,
        'agent-details-page': true,
        'bet-history-matches-page': true,
        'messages-details-page': true,
        'login-page': true,
        'logout-page': true,
        'freeboard-details': true,
        'bet-history-details': true,
        'manual-history-details': true,
        'agents-users': true
      }

      this.loading = true

      return hiddenNavs[route.name] || false
    },

    changeFavicon () {
      const favicon = document.getElementById('favicon')
      let url

      try {
        const siteName = this.siteName.replace(/[ ]/g, '-')
        url = require(`@/assets/images/favicon/${siteName.toLowerCase()}.png`)
      } catch (e) {
        url = require('@/assets/images/favicon/default.png')
      }

      if (favicon) {
        favicon.href = url
      }
    }
  }
}
</script>
