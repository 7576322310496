import { common } from '@/assets/js/translations/Common'

export const en = {
  en: {
    login: 'Login',
    admin: 'Admin',
    invalid_credentials: 'Invalid credentials.',

    ip_not_found: 'Login error, ip not found',
    ip_is_not_active: 'Login error, ip is not active.'
  }
}
