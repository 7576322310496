import axios from 'axios'

export default {
  namespaced: true,
  state: {
    memos: [],
    count: 1
  },
  mutations: {
  },
  getters: {
  },
  actions: {
    async get({ commit, state }, { id, params, status }) {
      try  {
        // let p = _.map(data.params,(v,k)=>encodeURIComponent(k) + '=' + encodeURIComponent(v)).join('&');
        let response =  await axios.get(`/agents/${id}/memo/${status}`, { params })
        state.memos = response.data.data
        state.count = response.data.count[0].total
      } catch (err) {
        console.log(err)
      }
    },
    async create ({ commit, state }, { id, memo }) {
      console.log(`/agents/${id}/memo`, { memo })
      try {
        await axios.post(`/agents/${id}/memo`, { memo })
      } catch (err) {
        console.log(err)
      }
    },
    getCount () {

    },
    async update ({ commit, state }, {uid, mid, data}) {
      try {
        console.log(data)
        await axios.patch(`/agents/${uid}/memo/${mid}`, { data })
      } catch (err) {
        console.log(err)
      }
    },
    async delete ({ commit, state }, { uid, mid }) {
      try {
        await axios.delete(`/agents/${uid}/memo/${mid}`)
      } catch (err) {
        console.log(err)
      }
    }
  }
}
