import axios from 'axios'
import ObjToFormData from '@/assets/js/common/ObjToFormData.js'

export default {
  namespaced: true,
  state: {
    list: [],
    count: 0
  },

  mutations: {
    SET_LIST (state, data) {
      state.list = data
    },

    SET_COUNT (state, data) {
      state.count = data[0].total
    }
  },

  getters: {},

  actions: {
    async get ({ commit }, { params }) {
      let result = await axios
        .get('/settings/mini', { params })

      let count = await axios
        .get('/settings/mini/count', { params })

      commit('SET_LIST', result.data)
      commit('SET_COUNT', count.data)
    },

    async patch ({ commit }, { id, data }) {
      let result = await axios
        .patch(`/settings/mini/${id}`, ObjToFormData(data))

      return result
    }
  }
}
