import axios from 'axios'
import ObjToFormData from '@/assets/js/common/ObjToFormData.js'

export default {
  namespaced: true,
  state: {
    replies: [],
    autoReplyContent: ''
  },

  mutations: {
    SET_DATA (state, data) {
      state.replies = data
    },
    SET_CONTENT(state, data) {
      state.autoReplyContent = data
    }
  },

  getters: {},

  actions: {
    async get ({ commit }, params) {
      let response = await axios.get('/settings/reply', { params })
      commit('SET_DATA', response.data)
    },

    add ({ commit }, data) {
      return axios.post('/settings/reply', data)
    },

    delete({ commit }, data) {
      if (data instanceof Array) {
        return axios.delete('/settings/reply', { data })
      }
      return axios.delete(`/settings/reply/${data}`)
    },

    update({ commit }, data) {
        return axios.patch(`/settings/reply/${data.id}`, data)
    }
    // ,

    // async update ({ commit }, { data, type }) {
    //   return axios.post(`/settings/${type}/default`, data)
    // }
  }
}
