import axios from 'axios'
import ObjToFormData from '@/assets/js/common/ObjToFormData.js'
import _ from 'lodash'

export default {
  namespaced: true,
  state: {
    sounds: []
  },

  mutations: {
    SET_SOUNDS (state, data) {
      data.forEach((e) => {
        e.soundData = null
      })
      state.sounds = data
    }
  },

  getters: {},

  actions: {
    async get ({ commit }, type) {
      commit('SET_SOUNDS', [])
      let data = await axios.get(`/settings/sound/${type}`)
      commit('SET_SOUNDS', data.data)
    },

    async update({ commit }, data) {
      try {
        await axios.patch(`/settings/sound/${data.id}`, ObjToFormData(data))
      } catch (err) {
        console.log(err)
      }
    }
  }
}
