import axios from 'axios'
import _ from 'lodash'

export default {
  namespaced: true,

  state: {
    settings: []
  },

  getters: {
    grouped (state) {
      return state.settings
    },
  },

  mutations: {
    SET_SETTINGS (state, settings) {
      if (settings instanceof Array) {
        state.settings = settings
      } else {
        state.settings.push(settings)
      }
    }
  },

  actions: {
    async get ({ commit }, params) {
      let { data } = await axios.get(`/settings/goalservebookmaker`, {params})
      commit('SET_SETTINGS', data)
    },

    async update({ commit }, params) {
      await axios.patch(`/settings/goalservebookmaker/${params.id}`, params)
    },
  }
}