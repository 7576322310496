export default {
  computed: {
    basePath () {
      return process.env.VUE_APP_AWS_URL || process.env.VUE_APP_STATIC_ASSET_PATH || '/'
    }
  },

  data () {
    let params

    if (this.$route) {
      params = this.generate(this.$route.query)
    }

    return {
      params
    }
  },

  methods: {
    generate (obj) {
      let params = Object.assign(
        {
          rows: 50,
          page: 1,
          filter_by: '',
          q: '',
          date_by: '',
          date: '',
          sort_by: 'id',
          sort: 'desc',
          date_from: '',
          date_to: ''
        },
        obj
      )

      for (const key in params) {
        if (params.hasOwnProperty(key) && !isNaN(parseFloat(params[key]))) {
          // if (key !== 'date_from' && key !== 'date_to') {
          if (key === 'page' || key === 'rows') {
            params[key] = parseFloat(params[key])
          } else {
            params[key] = params[key]
          }
        }
      }
      return params
    }
  }
}
