import { common } from '@/assets/js/translations/Common'

export const jp = {
  jp: {
    logout: `ログアウト`,
    cash_in: `入金`,
    cash_out: `出金`,
    cash_wait: `入金待機`,
    cash_in_wait: '出金待機',
    cash_out_wait: '入出金待機',
    new_user: `新規ユーザー`,
    help: `ヘルプ`,
    wait_user: `加入待機ユーザー`,
    dark: `暗いテーマ`,
    en: `en`,
    jp: `jp`,
    zh: `zh`,
    kr: `kr`
  }
}
