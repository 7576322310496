import axios from 'axios'

export default {
  namespaced: true,
  state: {
    referrals: [],
    refCount: 1
  },
  mutations: {
    SET_REFS (state, { refs, count }) {
      state.referrals = refs
      state.refCount = count
    }
  },
  getters: {
  },
  actions: {
    async get ({ commit }, { id, params, status }) {
      let refs = await axios.get(`/users/${id}/referrals/${status}`, { params })
      console.log('PARAMS=>',params)
      let count = await axios.get(`/users/${id}/referrals/count/${status}`, { params })
      commit('SET_REFS', { refs: refs.data, count: count.data })
    },
    async create ({ commit }, data) {
    },
    async update ({ commit }, params) {
    },
    async delete ({ commit }, params) {
    }
  }
}
