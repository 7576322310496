/**
  @Description:
  import { metaTitle } from '@/assets/js/mixins/base/MetaTitle'
**/

import { translations } from '@/assets/js/translations/Navigation'

export const metaTitle = {
  data () {
    return {
      translations
    }
  },

  methods: {
    metaTitle (route) {
      let subTitle = ''

      if (route.name === 'users-page') {
        subTitle = this.translate(translations, 'user list')
      }

      if (route.name === 'user-details-page') {
        subTitle = this.translate(translations, 'user detail info')
      }

      if (route.name === 'users-access-logs') {
        subTitle = this.translate(translations, 'access logs')
      }

      if (route.name === 'agents-management-page') {
        subTitle = this.translate(translations, 'agent list')
      }

      if (route.name === 'codes-management-page') {
        subTitle = this.translate(translations, 'codes management')
      }

      if (route.name === 'transfers-list-page') {
        subTitle = this.translate(translations, 'transfers management')
      }

      if (route.name === 'transaction-list-page') {
        subTitle = this.translate(translations, 'cash history')
      }

      if (route.name === 'points-list-page') {
        subTitle = this.translate(translations, 'point history')
      }

      if (route.name === 'coupons-list-page') {
        subTitle = this.translate(translations, 'coupon history')
      }

      if (route.name === 'matches-page' && route.params.game === 'inplay') {
        subTitle = this.translate(translations, 'in play')
      }

      if (route.name === 'matches-page' && route.params.game === 'sport') {
        subTitle = this.translate(translations, 'sports')
      }

      if (route.name === 'matches-page' && route.params.game === 'mini') {
        subTitle = this.translate(translations, 'mini games')
      }

      if (route.name === 'bet-history-update-type') {
        subTitle = this.translate(translations, 'bet management')
      }

      if (route.name === 'account-all-page') {
        subTitle = this.translate(translations, 'account management')
      }

      if (route.name === 'requests-page') {
        subTitle = this.translate(translations, 'helpdesk')
      }

      if (route.name === 'messages-page') {
        subTitle = this.translate(translations, 'messages')
      }

      if (route.name === 'faqs-page') {
        subTitle = this.translate(translations, 'faqs')
      }

      if (route.name === 'freeboards-page') {
        subTitle = this.translate(translations, 'freeboards')
      }

      if (route.name === 'notices-page') {
        subTitle = this.translate(translations, 'notices')
      }

      if (route.name === 'events') {
        subTitle = this.translate(translations, 'events')
      }

      if (route.name === 'setting-bets') {
        subTitle = this.translate(translations, 'game setup')
      }

      if (route.name === 'themes-page') {
        subTitle = this.translate(translations, 'home page setup')
      }

      if (route.name === 'setting-coupon') {
        subTitle = this.translate(translations, 'events & promotions')
      }

      document.title = subTitle
    }
  }
}
