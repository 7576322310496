import axios from 'axios'

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    async post({ commit }, data) {
      console.log('created  at')
      await axios.post('/logs', data)
    }
  }
}
