import axios from 'axios'

export default {
  namespaced: true,
  state: {
    users: [],
    user: null,
    referrals: [],
    count: 0,
    refCount: 0
  },
  mutations: {
    SET_USERS (state, users) {
      state.users = users
    },
    SET_USERCOUNT (state, count) {
      state.count = count
    },
    SET_USER (state, user) {
      state.user = user
    },
    SET_REFS (state, { refs, count }) {
      state.referrals = refs
      state.refCount = count
    }
  },
  getters: {},
  actions: {
    async get ({ commit }, { status, params }) {
      commit('SET_USERS', [])
      try {
        let users = await axios.get(`/test-users/${status}`, { params })
        commit('SET_USERS', users.data)
      } catch (err) {
      }
    },

    async getCount ({ commit }, { status, params }) {
      try {
        let count = await axios.get(`/test-users/${status}/count`, { params })
        commit('SET_USERCOUNT', count.data)
      } catch (err) {
      }
    },

    async find ({ commit }, { id }) {
      try {
        let user = await axios
          .get(`/test-users/${id}/user`)
        commit('SET_USER', user.data[0])
      } catch (err) {
      }
    },

    async getReferrals ({ commit }, { id, params }) {
      let refs = await axios.get(`/test-users/${id}/referrals`, { params })
      let count = await axios.get(`/test-users/${id}/referrals/count`, { params })
      commit('SET_REFS', { refs: refs.data, count: count.data })
    },

    async create ({ commit }, data) {
      return axios.post('/test-users', data)
    },

    async createWallet ({ commit }, data) {
      let response = await axios.post(`/test-users/${data.id}/wallet`, data.data)
      commit('SET_USER', data.user)
      return response
    },

    async update ({ commit }, params) {
      let user = await axios.patch(`/test-users/${params.id}`, params.data)
      commit('SET_USER', params.user)
      return user
    },

    async delete ({ commit }, params) {
      let user = await axios.delete(`/test-users/${params.uid}`, { test: 1 })
      commit('SET_USER', params.user)
      return user
    },

    async deleteWallet ({ commit }, params) {
      let response = await axios.delete(`/test-users/${params.uid}/wallet/${params.wid}`)
      commit('SET_USER', params.user)
      return response
    },

    async updateWallet ({ commit }, params) {
      let response = await axios.patch(`/test-users/${params.uid}/wallet/${params.wid}`, { params: params.data })
      commit('SET_USER', params.user)
      return response
    }
  }
}
