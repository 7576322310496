import axios from 'axios'
import _map from 'lodash/map'

export default {
  namespaced: true,
  state: {
    agents: [],
    count: 0,
    agentCodes: [],
    agentCodesCnt: 1,

    agent: null,

    agentUsers: [],
    agentUsersCnt: 1,

    agentArchive: [],

    referralList: []
  },

  mutations: {
    SET_AGENTS (state, agents) {
      state.agents = agents
    },

    SET_COUNT (state, count) {
      state.count = count
    },

    SET_AGENT_CODES (state, data) {
      state.agentCodes = data
    },

    SET_AGENT_CODES_CNT (state, data) {
      state.agentCodesCnt = data.total
    },

    SET_AGENT_USERS (state, data) {
      state.agentUsers = data
    },

    SET_AGENT_USERS_CNT (state, data) {
      state.agentUsersCnt = data.total
    },

    SET_AGENT (state, data) {
      state.agent = data
    },

    SET_ARCHIVE (state, data) {
      state.agentArchive = data
    },

    SET_AGENTSAND_CODES (state, data) {
      state.agentsCodesList = data
    },

    SET_STATE (state, payload) {
      state[payload.state] = payload.value
    }
  },

  actions: {
    async get ({ commit }, { params, status }) {
      const agents = await axios.get(`/agents/${status}`, { params })
      const count = await axios.get(`/agents/${status}/count`, { params })

      commit('SET_AGENTS', agents.data)
      commit('SET_COUNT', count.data)
    },

    async getAgentReferrals ({ commit }, params) {
      try {
        const response = await axios.get('/agents/referrals', { params })

        commit('SET_STATE', { state: 'referralList', value: response.data })

        return response.data
      } catch (error) {
        throw error
      }
    },

    create ({ commit }, data) {
      for (let x = 0; x < data.length; x++) {
        if (data[x].referral) {
          data[x].referral_id = data[x].referral.id
          data[x].referral = undefined
        }
      }
      return axios.post('/agents', data)
    },

    update ({ commit }, { id, data }) {
      return axios.patch(`/agents/${id}`, data)
    },

    delete ({ commit }, id) {
      return axios.delete(`/agents/${id}`)
    },

    async archive ({ commit }, { flag, params }) {
      let result

      if (!flag) {
        result = await axios.get('/agents/archive-message')
        commit('SET_AGENTS', result.data)
      } else {
        result = await axios.get('/agents/archive-2', { params })
        commit('SET_ARCHIVE', result.data)
      }
    },

    async find ({ commit }, { id }) {
      try {
        const response = await axios
          .get(`/agents/${id}/agent`)
        commit('SET_AGENT', response.data[0])
      } catch (err) {
        console.log(err)
      }
    },

    async getCodes ({ commit }, { id, params, status }) {
      try {
        const p = _map(params, (v, k) => encodeURIComponent(k) + '=' + encodeURIComponent(v)).join('&')
        const response = await axios.get(`/codes/${id}/agent/${status}?${p}`)
        commit('SET_AGENT_CODES', response.data)

        const count = await axios.get(`/codes/${id}/agent/${status}/count?${p}`)
        commit('SET_AGENT_CODES_CNT', count.data[0])
      } catch (err) {
        console.log(err)
      }
    },

    async  getUserList ({ commit }, { id, params, status, vip }) {
      try {
        vip = (vip === 'all') ? 1 : vip
        const response = await axios.get(`/agents/${status}/${id}/${vip}/user-list`, { params })
        commit('SET_AGENT_USERS', response.data)
        const count = await axios.get(`/agents/${status}/${id}/${vip}/user-list/count`, { params })
        commit('SET_AGENT_USERS_CNT', count.data[0])
      } catch (err) {
        console.log(err)
      }
    },

    async getAgentCode ({ commit }) {
      const response = await axios.get('/agents/archive-code/list', { test: true })
      commit('SET_AGENTSAND_CODES', response.data)
    }
  }
}
