export default {
  methods: {
    querify (obj, prefix) {
      let query = []

      for (const key in obj) {
        if (!obj.hasOwnProperty(key)) {
          continue
        }

        let k = prefix ? `${prefix}[${key}]` : key
        let v = obj[key]

        if (typeof obj[key] === 'object') {
          query.push(this.querify(v, k))
        } else if (v !== '') {
          query.push(`${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
        }
      }

      return query.join('&')
    }
  }
}
