<template>
  <div class="info-panel">
    <div class="logo">
      <h1>{{ loginName }}</h1>

      <button class="btn--logout">
        <router-link to="/logout" />
        <span>
          {{ translate(translations, 'logout') }}
        </span>
      </button>
    </div>

    <div class="notif-logs">
      <div
        v-for="(item, itemKey) in infoPanels"
        :key="itemKey"
        :class="{ 'has-pending': item.hasPending }"
        class="logs-count"
      >
        <div
          v-if="item.to"
          class="logs-count__link"
        >
          <RouterLink :to="item.to" />
        </div>

        <span class="logs-count__lbl">
          {{ item.text }}
        </span>

        <span class="logs-count__data">
          {{ item.value }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'
import { messages, transfers, user, bets } from '@/socket'

//= mixins
import { vueLocalStorage } from '@/assets/js/mixins/base/VueLocalStorage'

//= filters
import FormatAmount from '@/assets/js/filters/FormatAmount'

//= translation
import { translations } from '@/assets/js/translations/InfoPanel'

import _isEmpty from 'lodash/isEmpty'
import _find from 'lodash/find'

export default {
  name: 'InfoPanel',

  filters: {
    FormatAmount
  },

  data () {
    return {
      translations
    }
  },

  computed: {
    ...mapState('auth', {
      authAgent: 'auth'
    }),

    loginName () {
      return vueLocalStorage.getItem('loginName')
    },

    infoPanels () {
      /**
       * HELPERS
       */
      const translate = str => this.translate(translations, str)

      const agent = this.authAgent || {}

      const forwardingCommission = (() => {
        return {
          text: translate('total commission'),
          value: FormatAmount(agent.forwarding_commission || 0, 'whole')
        }
      })()

      const totalWithdrawCommission = (() => {
        return {
          text: translate('withdraw'),
          value: FormatAmount(agent.total_withdraw || 0, 'whole')
        }
      })()

      const currentCommission = (() => {
        return {
          text: translate('current commission'),
          value: FormatAmount(agent.total_commission || 0, 'whole')
        }
      })()

      return [
        forwardingCommission,
        totalWithdrawCommission,
        currentCommission
      ]
    }
  },

  watch: {
    async $route () {
      if (_isEmpty(this.config) && this.hasToken) {
        await this.getConfig()
      }
    }
  },

  async mounted () {
    messages.on('superdropip', data => {
      data = JSON.parse(data)
      if (_find(data, d => (d === vueLocalStorage.getItem('loginId')))) {
        window.history.go()
      }
    })

    messages.on('deleteadmin', data => {
      data = JSON.parse(data)
      if (_find(data, d => (d === vueLocalStorage.getItem('loginId')))) {
        this.$router.push({ path: '/logout' })
      }
    })

    transfers.on('update-status', async data => {
      await this.getMeta()
    })

    user.on('update', async data => {
      await this.getMeta()
    })

    messages.on('update', async data => {
      await this.getMeta()
    })

    if (_isEmpty(this.config)) {
      await this.getConfig()
    }
  },

  beforeDestroy () {
    bets.removeListener('new-bet')
  },

  methods: {
    ...mapActions('config', {
      getConfig: 'get'
    }),

    ...mapActions('meta', {
      getMeta: 'get'
    })
  }
}
</script>
