<!--
  @USE AS:
  import InputAmount from '@/components/base/InputAmount'

  components: { InputAmount }

  <input-amount />
-->

<template lang="html">
  <div class="input-wrap">
    <input
      type="text"
      v-model="amount"
      @keypress="isNumber($event, type)"
    />
  </div>
</template>

<script>
//= mixins
import { isNumber } from '@/assets/js/mixins/base/IsNumber'

export default {
  name: 'InputAmount',

  inheritAttrs: false,

  props: {
    value: [String, Number],
    type: {
      type: [String],
      default: 'float'
    }
  },

  mixins: [
    isNumber
  ],

  data () {
    return {
      amount: 0
    }
  },

  watch: {
    amount (val) {
      this.amount = this.addFormat(val)
    },

    value (val) {
      this.amount = val
    }
  },

  mounted () {
    this.$nextTick(() => {
      let data = this.value

      this.amount = data
    })
  },

  methods: {
    addFormat (data) {
      let number = data,
          decimal = [],
          finalValue = 0

      if (!data || data === '') {
        number = 0
      } else {
        number = typeof number === "string" ? number : number.toString()
        number = number.replace(/[,]/g, "")

        let amount = number.toString().split('.')
        let amountString = number.toString()

        if (number.length > 1) {
          if (parseInt(number) === 0) {
            number = 0

            this.emitValue(number)
            return number
          } else {
            amount[0] = amount[0].replace(/^0+/, '')
          }

          amount[0] = amount[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")

          if (amountString.indexOf('.') != -1) {
            amountString = amountString.split('.')

            if (_.size(amount) > 2) {
              for(let a = _.size(amount); a > 2; a--) {
                amount.pop()
              }
            }

            decimal = amountString[1].split('')

            if (decimal.length > 2) {
              amountString[1] = amountString[1].slice(0, 2)
            }

            amountString[0] = amount[0]
            number = amountString.join('.')
          }

          if (amountString.indexOf('.') == -1) {
            number = amount.join('.')
          }
        }
      }

      finalValue = typeof number === 'string' ? number.replaceAll(',','') : number

      this.emitValue(finalValue)
      return number
    },

    emitValue (value) {
      this.$emit('input', value)
    }
  }
}
</script>
