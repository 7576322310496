import axios from 'axios'

export default {
  namespaced: true,
  state: {
    token: null,
    err: []
  },

  mutations: {
    SET_USER (state, token) {
      state.token = token
    }
  },

  actions: {
    async login ({ state  }, params) {
      try {
        const { data } = await axios.post('/login', params)
        state.token = data
        return data
      } catch (error) {
        throw error
      }
    },

    async logout (_, params) {
      try {
        await axios.post('/login/expire', params)
      } catch (error) {
        throw error
      }
    }
  }
}
