import Vue from 'vue'

import Table from '@/components/base/table/Table.vue'
import TableTr from '@/components/base/table/TableTr'
import TableTd from '@/components/base/table/TableTd'
// import TableInner from '@/components/base/table/TableInner'
// import TableHead from '@/components/base/table/TableHead'
// import TableBody from '@/components/base/table/TableBody'
// import TableFooter from '@/components/base/table/TableFooter'

Vue.component(Table.name, Table)
Vue.component(TableTr.name, TableTr)
Vue.component(TableTd.name, TableTd)
// Vue.component(TableInner.name, TableInner)
// Vue.component(TableHead.name, TableHead)
// Vue.component(TableBody.name, TableBody)
// Vue.component(TableFooter.name, TableFooter)
