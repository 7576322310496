<!--
  @USE AS:
  import Modal from '@/components/base/Modal'

  components: { Modal }

  <button @click="test = true">MODAL</button>

  <modal
    title="test"
    v-if="test"
    :activate="test"
    :draggable="true"
    @activate="test = $event"
  >
    <template slot="toolbar">
      toolbar
    </template>

    <template slot="body">
      body
    </template>

    <template slot="footer">
      footer
    </template>
  </modal>
-->

<template>
  <transition
    v-if="show"
    name="fade"
  >
    <div
      class="modal"
      :class="customClass"
    >
      <div
        ref="modalInner"
        v-dragged="onDragged"
        class="modal--inner"
        :style="style"
      >
        <!-- .modal--inner -->
        <div
          ref="modalHeader"
          class="modal-header"
          :class="'-' + headerClass"
        >
          <!-- .modal-header -->
          <div class="modal-header--inner">
            <h3
              v-if="title"
              ref="modalTitle"
              class="modal__title"
              :style="`width: ${title_w}px`"
              v-html="title"
            >
            </h3>

            <div
              v-if="hasToolbar"
              ref="modalToolbar"
              class="modal__toolbar"
              :style="`width: ${toolbar_w}px`"
            >
              <slot name="toolbar"></slot>
            </div>

            <div
              ref="modalButtons"
              class="modal__buttons"
            >
              <button
                v-if="isChatbox"
                class="btn btn--minimize"
                @click="closeModal()"
              >
                <i class="fas fa-window-minimize"></i>
              </button>

              <button
                class="btn--popup-close"
                @click="closeModal()"
              >
                <i class="ico--popup-close"></i>
              </button>
            </div>
          </div>
        </div> <!-- // .modal-header -->

        <div
          v-if="hasBody"
          ref="modalBody"
          class="modal-body"
          :style="setModalBodyHeight"
        >
          <!-- .modal-body -->
          <div class="modal-body--inner">
            <slot name="body"></slot>
          </div>
        </div> <!-- // .modal-body -->

        <div
          v-if="hasFooter"
          ref="modalFooter"
          class="modal-footer"
        >
          <div class="modal-footer--inner">
            <slot name="footer"></slot>
          </div>
        </div>
      </div> <!-- // .modal--inner -->
    </div>
  </transition>
</template>

<script>
import Vue from 'vue'

//= jquery
import $ from 'jquery'

//= mixins
import { detectDevice } from '@/assets/js/mixins/base/DetectDevice'

import VDragged from 'v-dragged'
Vue.use(VDragged)

export default {
  name: 'Modal',

  mixins: [
    detectDevice
  ],

  props: {
    activate: [Boolean, Number],

    title: String,

    customClass: String,

    draggable: {
      type: [String, Boolean],
      default: false
    },

    bodyOverflow: {
      type: Boolean,
      default: false
    },

    isChatbox: {
      type: Boolean,
      default: false
    },

    width: {
      type: [Number, String]
    }
  },

  data () {
    return {
      isDraggable: false,
      show: false,

      headerClass: '',
      title_w: '',
      toolbar_w: 'auto',

      modalBody: 'auto'
    }
  },

  computed: {
    hasToolbar () {
      return this.$slots.toolbar
    },

    hasBody () {
      return this.$slots.body
    },

    hasFooter () {
      return this.$slots.footer
    },

    setModalBodyHeight () {
      return { 'max-height': this.modalBody + 'px' }
    },

    style () {
      const styles = {}

      if (this.width) {
        styles.maxWidth = `${this.width}px`
      }

      return styles
    }
  },

  watch: {
    activate () {
      if (this.activate) {
        this.modalActive()
      }

      if (!this.activate) {
        this.closeModal()
      }
    }
  },

  mounted () {
    this.$nextTick(function () {
      window.addEventListener('resize', this.getDraggable)
      this.getDraggable()

      window.addEventListener('resize', this.setWidth)
      this.setWidth()

      window.addEventListener('resize', this.getModalBodyHeight)
      this.getModalBodyHeight()

      this.setHeaderClass()
    })

    if (this.activate) {
      this.modalActive()
    }
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.getDraggable)
    window.removeEventListener('resize', this.setWidth)
    window.removeEventListener('resize', this.getModalBodyHeight)
  },

  methods: {
    setHeaderClass () {
      const title = $('.modal__title').outerWidth()
      const toolbar = $('.modal__toolbar').outerWidth()
      const buttons = $('.modal__buttons').outerWidth()

      if (typeof title !== 'undefined' && typeof toolbar !== 'undefined' && typeof buttons !== 'undefined') {
        this.headerClass = 'title-button-toolbar'
      }

      if (typeof title !== 'undefined' && typeof toolbar === 'undefined' && typeof buttons !== 'undefined') {
        this.headerClass = 'title-button'
      }

      if (typeof title === 'undefined' && typeof toolbar !== 'undefined' && typeof buttons !== 'undefined') {
        this.headerClass = 'button-toolbar'
      }

      if (typeof title === 'undefined' && typeof toolbar === 'undefined' && typeof buttons !== 'undefined') {
        this.headerClass = 'button'
      }
    },

    getModalBodyHeight () {
      this.$nextTick(() => {
        const modalHeader = this.$refs.modalHeader.offsetHeight
        const browserHeight = this.device_height
        const padding = 65

        if (this.device_height > 799) {
          if (this.device_width > 991) {
            if (this.$refs.modalFooter) {
              const modalFooter = this.$refs.modalFooter.offsetHeight
              this.modalBody = browserHeight - modalHeader - modalFooter - padding
            } else {
              this.modalBody = browserHeight - modalHeader - padding
            }
          }
        }
      })
    },

    setWidth () {
      this.$nextTick(() => {
        const modalWidth = this.$refs.modalInner
        const title = this.$refs.modalTitle
        const toolbar = this.$refs.modalToolbar
        const buttons = this.$refs.modalButtons

        if (title && !buttons && !toolbar) {
          this.title_w = modalWidth.offsetWidth
        }

        if (title && buttons && !toolbar) {
          this.title_w = modalWidth.offsetWidth - buttons.offsetWidth - 4
        }

        if (title && buttons && toolbar) {
          this.title_w = modalWidth.offsetWidth - buttons.offsetWidth - toolbar.offsetWidth - (4 * 2)
        }

        if (!title && !toolbar && buttons) {
          this.title_w = ''
        }

        if (!title && toolbar && buttons) {
          this.toolbar_w = modalWidth.offsetWidth - buttons.offsetWidth - 4
        }
      })
    },

    getDraggable () {
      const cm = this.$refs.modalBody

      this.setDraggable()

      cm.addEventListener('mouseover', () => { this.isDraggable = false })
      cm.addEventListener('mouseout', () => { this.setDraggable() })
    },

    setDraggable () {
      const drag = this.draggable

      if (drag === false) {
        this.isDraggable = false
      } else {
        if (this.device_width > 991) {
          this.isDraggable = true
        } else {
          $('.modal--inner').removeAttr('style')
          this.isDraggable = false
        }
      }
    },

    closeModal () {
      this.show = false
      this.$emit('activate', this.show)
      $('body').removeAttr('style')
      $('body').removeClass('modal--open')
    },

    modalActive () {
      this.show = true
      $('body').addClass('modal--open')

      if (this.bodyOverflow === false) {
        $('body').css({ overflow: 'hidden' })
      }
    },

    onDragged ({ el, deltaX, deltaY, offsetX, offsetY, clientX, clientY, first, last }) {
      if (this.isDraggable) {
        const l = +window.getComputedStyle(el).left.slice(0, -2) || 0
        const t = +window.getComputedStyle(el).top.slice(0, -2) || 0
        el.style.left = l + deltaX + 'px'
        el.style.top = t + deltaY + 'px'
      }
    }
  }
}
</script>
