/* eslint-disable no-dupe-keys */
import { common } from '@/assets/js/translations/Common'

export const en = {
  en: {
    user_management: `user management`,
    online: `online`,
    confirmed: `confirmed`,
    banned: `banned`,
    deleted: `deleted`,
    requests: `requests`,
    testers: `testers`,
    ip_management: `ip management`,
    access_logs: `access logs`,

    agent_management: `agent management`,
    agent_list: `agent list`,
    codes_management: `codes management`,
    active: `active`,
    inactive: `inactive`,

    transfers: `transfers notice`,
    transfers_management: `transfers management`,
    cash_in: `cash in`,
    cash_out: `cash out`,
    all: `all`,
    deposit_accounts: `deposit accounts`,

    'cash_&_point_history': `cash & point history`,
    cash_history: `cash history`,
    'cash_in/out': `cash in/out`,
    'manual_in/out': `manual in/out`,
    conversion: `conversion`,
    cash_bet: `bet`,
    win: `win`,
    rollback: `rollback`,
    commission: `commission`,
    point_history: `point history`,
    auto_in: `auto in`,
    coupon_history: `coupon history`,

    game_management: `game management`,
    in_play: `in play`,
    waiting: `waiting`,
    bet: `bet`,
    betting: `betting`,
    done: `done`,
    games_setup: `games setup`,
    leagues_setup: `leagues setup`,
    game_type_setup: `game type setup`,

    sports: `sports`,
    release: `release`,
    ongoing: `ongoing`,
    event_ongoing: `ongoing`,

    mini_games: `mini games`,
    game_list: `game list`,

    bet_management: `bet management`,
    bet_cancel: `bet cancel`,

    account_management: `account management`,
    total_account: `total account`,
    bet_account: `bet account`,
    point: `point`,
    account_commission: `commission`,
    cash_back: `cash back`,

    customer_management: `customer management`,
    helpdesk: `helpdesk`,
    pending: `pending`,
    resolved: `resolved`,
    quick_reply: `quick reply`,

    messages: `messages`,
    faq: `FAQs`,
    freeboards: `freeboards`,
    notices: `notices`,
    events: `events`,
    completed: `completed`,

    settings: `settings`,
    game_setup: `game setup`,
    bet_limits: `bet limits`,

    odds_maker_setup: `odds maker setup`,
    odds_setup: `odds setup`,
    countries: `countries`,

    home_page_setup: `home page setup`,
    themes: `themes`,
    navigation: `navigation`,
    home_page: `home page`,
    events_page: `events page`,
    level_icons: `level icons`,
    domains: `domains`,
    ticker: `ticker`,
    popups: `popups`,
    sounds: `sounds`,
    language: `language`,
    currency: `currency`,
    bank_accounts: `bank accounts`,
    deposit_accounts: `deposit accounts`,

    'events_&_promotions': `events & promotions`,
    coupon: `coupon`,
    points: `points`,
    promotions: `promotions`,

    manage_promotions: `Manage Promotional Games`,
    manage_coupon: `Set up coupons and events`,
    manage_points: `Set up Points`,

    modify: `modify`,
    prematch: `prematch`,
    inplay: `inplay`,
    teams_setup: `teams setup`,
    test_setup: `test setup`,

    user_detail_info: `user detail info`,
    agent_detail_info: `agent detail info`,
    stamp_history: `stamp history`,
    code_management: `code management`,

    // Bet Delay
    bet_delay: `bet delay`,
    default_settings: `criteria value setting`,
    game_settings: `odds setting`,

    admin: 'admin',
    login: 'login',

    freeboard_settings: `freeboard settings`,
    support_settings: `support settings`,
    post_html: `post html`,
    upload_image: `upload image`,
    enabled: `enabled`,
    disabled: `disabled`,

    user_online_session: 'online',

    bet365_setting: 'setting',
    token_setting: 'setting',
    new_done: 'done',
    new_game_list: 'game list',

    new_online: 'online',

    new_result: 'result'
  }
}
