import { common } from '@/assets/js/translations/Common'

export const kr = {
  kr: {
    total_info: '전체 정보',
    user_info: '사용자 정보',
    cash_info: '입출금정보',
    betting_info: '배팅 정보',
    point_info: '포인트 정보',
    manual_info: '지급정보',
    comission_info: '커미션 정보',
    sports_info: '스포츠 정보',
    mini_games_info: '미니 게임 정보',
    in_play_info: '인플레이 정보',
    virtual_sports_info: 'virtual sports info',
    life_info: 'life info',

    cash_net: '충/환 손익',
    manual_net: '수동 손익',
    betting_net: '배팅 수익',

    balance: '보유금',
    point: '포인트',
    users: '회원수',
    online: '온라인 유저',
    new: '신규 유저',

    waiting: '대기',
    in: '충전',
    out: '환전',
    net: '손익',
    bet: '배팅',
    win: '당첨',
    auto: '자동',
    give: '수동지급',
    refund: '수동회수',

    agent: '매장 수수료',
    referral: '추천 수수료',
    betting: '배팅금액',
    betting_ctn: '배팅건수',
    bet_waiting: '배팅 대기금',
    bet_users: '배팅 유저',
    win_amount: '당첨금',
    manual_in: '수동 지급',
    manual_out: '수동 회수',
    point_manual: '수동 포인트',
    point_auto: '자동 포인트',

    new_online: '현재 접속자'
  }
}
