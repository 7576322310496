<template>
  <li
    class="suggestion-list-items"
    @click="selectItem"
  >
    <span><slot></slot></span>
  </li>
</template>

<script>
export default {
  name: 'suggestion',

  props: ['value'],

  methods: {
    selectItem () {
      this.$parent.$emit('selected', this.value)
    }
  }
}
</script>
