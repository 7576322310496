import axios from 'axios'

export default {
  namespaced: true,

  state: {
    counter: 0
  },

  mutations: {
    TRIGGER_COUNT (state) {
      state.counter++
    }
  }
}
