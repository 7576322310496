import axios from 'axios'
import ObjToFormData from '@/assets/js/common/ObjToFormData.js'

export default {
  namespaced: true,
  state: {
    datas: []
  },

  mutations: {
    SET_DATA (state, data) {
      state.datas = data
    }
  },

  getters: {},

  actions: {
    async get ({ commit }, data) {
      let response = await axios.get('/settings/home')
      commit('SET_DATA', response.data)
    },

    async create ({ commit }, data) {
      let response = await axios.post('/settings/home', ObjToFormData(data))
    },

    async update ({ commit }, { id, data }) {
      return axios.patch(`/settings/home/${id}`, ObjToFormData(data))
    },

    async delete ({ commit }, id) {
      return axios.delete(`/settings/home/${id}`)
    }
  }
}
