import axios from 'axios'

export default {
  namespaced: true,
  state: {
    count: 1,
    commissions: [],

    types: []
  },
  mutations: {
    SET_DATA (state, data) {
      state.commissions = data
    },
    SET_TYPES (state, data) {
      state.types = data
    }
  },
  getters: {},
  actions: {
    async get ({ commit, state }, { id, type, status, params }) {
      let findType = type !== 'all' ?  (state.types.find((e) => { return e.name ===  type })).id : 'all'
      let response = null

      if (id) {
        response = await axios.get(`/commissions/${findType}/${status}/${id}`, { params })
      } else {
        response = await axios.get(`/commissions/${findType}/${status}`, { params })
      }
      commit('SET_DATA', response.data)
    },

    async getTypes ({ commit }) {
      let response = await axios.get(`/commissions/types`)
      commit('SET_TYPES', response.data)
    },

    async create ({ commmit }, data) {
    },

    async update ({ commit }, data) {
    }
  }
}
