export var ready = {
  methods: {
    $ready (fn) {
      setTimeout(() => {
        this.$nextTick(fn)
      }, 300)
    },

    $loaded (fn, timeout) {
      let load = setInterval(() => {
        this.$nextTick(fn)
      }, 0)

      setTimeout(() => {
        clearInterval(load)
      }, timeout)
    }
  } // methods
} // ready
