<!--
  @Description:
  import DateTimePicker from '@/components/base/DateTimePicker'

  components: { DateTimePicker }

  <datetime-picker
    mode='test'
    range='true'
    placeholder='sample'
    type='datetime'
    :setMax='dateEnd || '''
    @get-test='dateEnd = $event'
  >
  </datetime-picker>

  //// RANGE

  <datetime-picker
    mode='from'
    range='true'
    placeholder='sample'
    type='date'
    :setMin='dateStart || '''
    @get-from='dateEnd = $event'
  >
  </datetime-picker>

  <datetime-picker
    mode='to'
    range='true'
    placeholder='sample'
    type='date'
    :setMin='dateStart || '''
    @get-to='dateEnd = $event'
  >
  </datetime-picker>

  type
    - date = Y/m/d
    - datetime = Y/m/d H:i:s
    - month - Y/m
    - time - H:i:s
-->

<template>
  <div class="input-wrap -date">
    <i class="ico--calendar"></i>
    <input
      type="text"
      :ref="mode"
      :placeholder="placeholder"
      :readonly="readonly"
      :value="date"
    />

    <i
      v-if="date && withReset"
      class="ico--reset-data"
      @click="resetData()"
    >
    </i>
  </div>
</template>

<script>
import Vue from 'vue'
import $ from 'jquery'
import datetimepickerFactory from 'jquery-datetimepicker'
import datepickerFactory from 'jquery-datepicker'
import moment from 'moment'
Vue.use(datetimepickerFactory)
datepickerFactory($)

//= mixins
import { ready } from '@/assets/js/mixins/base/Ready'

export default {
  name: 'DateTimePicker',

  mixins: [
    ready
  ],

  props: {
    type: {
      type: [String],
      default: 'datetime'
    },

    customId: {
      String
    },

    customClass: {
      String
    },

    mode: {
      String
    },

    setMin: {
      String,
      Number
    },

    setMax: {
      String,
      Number
    },

    setDate: {
      String,
      Number
    },

    value: {
      String,
      Number
    },

    range: {
      type: Boolean,
      default: false
    },

    placeholder: {
      String
    },

    readonly: {
      type: [
        String,
        Boolean
      ],
      default: false
    },

    withReset: {
      type: [
        Boolean,
        String
      ],
      default: true
    }
  },

  data () {
    return {
      date: '',
      min: '',
      max: '',

      reset: false
    }
  },

  watch: {
    reset () {
      let self = this
      let dateText = ''

      self.$emit('input', dateText)
      this.reset = false
    },

    'config.lang': function () {
      this.$nextTick(() => {
        this.changeLanguage(this.type)
      })
    }
  },

  mounted () {
    this.$nextTick(() => {
      this.changeLanguage(this.type)
    })

    this.setDatepicker()
  },

  beforeDestroy () {
    $('.xdsoft_datetimepicker').remove()
  },

  methods: {
    changeLanguage (type) {
      if (type === 'datetime') {
        if (this.config.lang === 'en') {
          $.datetimepicker.setLocale('en')
        }

        if (this.config.lang === 'kr') {
          $.datetimepicker.setLocale('kr')
        }
      }

      if (type === 'date') {
        if (this.config.lang === 'en') {
          $.datepicker.regional['en']
        }

        if (this.config.lang === 'kr') {
          $.datepicker.regional['kr']
        }
      }
    },

    resetData () {
      this.date = ''
      this.reset = true
      this.$emit('input', '')
    },

    reInitializeDatepicker () {
      if (this.type === 'month') {
        $(this.$refs[this.mode]).datepicker('destroy')
      } else {
        $(this.$refs[this.mode]).datetimepicker('destroy')
      }

      this.setDatepicker()
    },

    setDatepicker () {
      var settings = {
        changeMonth: true,
        changeYear: true,
        yearRange: 'c-80:c+5'
      }

      if (this.range === true) {
        if (this.type === 'month') {
          $(this.$refs.from).datetimepicker('destroy')
          $(this.$refs.to).datetimepicker('destroy')
        } else {
          $(this.$refs.from).datepicker('destroy')
          $(this.$refs.to).datepicker('destroy')
        }
      } else {
        if (this.type === 'month') {
          $(this.$refs[this.mode]).datetimepicker('destroy')
        } else {
          $(this.$refs[this.mode]).datepicker('destroy')
        }
      }

      this.getPicker(settings)
    },

    getPicker (settings) {
      switch (this.type) {
        case 'datetime':
          this.dateTimePicker(settings)
          break

        case 'date':
          this.datePicker(settings)
          break

        case 'time':
          this.timePicker(settings)
          break

        case 'month':
          this.monthPicker(settings)
          break

        default:
          this.dateTimePicker(settings)
      }
    },

    dateTimePicker (settings) {
      if (this.range === true) {
        $(this.$refs.from).datetimepicker({
          format: 'Y/m/d H:i:s',
          settings,
          defaultTime: '00:00',

          onShow: ct => {
            if (this.setMax) {
              $(this.$refs.from).datetimepicker('setOptions', { maxDate: this.setMax })
            }
          },

          onChangeDateTime: date => {
            if (date) {
              this.setChangeDateTime('from')
            }
          },

          onClose: (currentTime, $input) => {
            this.setOnClose('from')
          }
        }) // this.$refs.from

        $(this.$refs.to).datetimepicker({
          format: 'Y/m/d H:i:59',
          settings,
          defaultTime: '23:59',

          onShow: ct => {
            if (this.setMin) {
              $(this.$refs.to).datetimepicker('setOptions', { minDate: this.setMin })
            }
          },

          onChangeDateTime: date => {
            if (date) {
              this.setChangeDateTime('to')
            }
          },
          onClose: (currentTime, $input) => { this.setOnClose('to') }
        }) // this.$refs.to
      } else {
        $(this.$refs[this.mode]).datetimepicker({
          format: 'Y/m/d H:i:s',
          settings,
          defaultTime: '00:00',

          onChangeDateTime: date => {
            if (date) {
              this.setChangeDateTime()
            }
          },
          onClose: (currentTime, $input) => { this.setOnClose() }
        }, this.value ? new Date(this.value) : undefined)

        if (this.value) {
          // this.date = moment.utc(new Date(this.value))
          this.date = moment(new Date(this.value))
          .format('YYYY/MM/DD HH:mm:ss')
        }
      } // false
    }, // getDateTime

    datePicker (settings) {
      if (this.range === true) {
        $(this.$refs.from).datetimepicker({
          format: 'Y/m/d',
          timepicker: false,
          settings,

          onShow: ct => {
            if (this.setMax) {
              $(this.$refs.from).datetimepicker('setOptions', { maxDate: this.setMax })
            }
          },

          onChangeDateTime: date => {
            if (date) {
              this.setChangeDateTime('from')
            }
          },

          onClose: (currentTime, $input) => {
            this.setOnClose('from')
          }
        }) // this.$refs.from

        $(this.$refs.to).datetimepicker({
          format: 'Y/m/d',
          timepicker: false,
          settings,

          onShow: ct => {
            if (this.setMin) {
              $(this.$refs.to).datetimepicker('setOptions', { minDate: this.setMin })
            }
          },

          onChangeDateTime: date => {
            if (date) {
              this.setChangeDateTime('to')
            }
          },
          onClose: (currentTime, $input) => { this.setOnClose('to') }
        }) // this.$refs.to
      } else {
        $(this.$refs[this.mode]).datetimepicker({
          format: 'Y/m/d',
          timepicker: false,
          settings,

          onChangeDateTime: date => {
            if (date) {
              this.setChangeDateTime()
            }
          },
          onClose: (currentTime, $input) => { this.setOnClose() }
        })
      } // else
    }, // getDatePicker

    timePicker (settings) {
      if (this.range === true) {
        $(this.$refs.from).datetimepicker({
          format: 'H:i',
          settings,
          datepicker: false,

          onShow: ct => {
            if (this.setMax) {
              $(this.$refs.from).datetimepicker('setOptions', { maxDate: this.setMax })
            }
          },

          onChangeDateTime: date => {
            if (date) {
              this.setChangeDateTime('from')
            }
          },
          onClose: (currentTime, $input) => { this.setOnClose('from') }
        }) // this.$refs.from

        $(this.$refs.to).datetimepicker({
          format: 'H:i',
          settings,
          datepicker: false,

          onShow: ct => {
            if (this.setMin) {
              $(this.$refs.to).datetimepicker('setOptions', { minDate: this.setMin })
            }
          },

          onChangeDateTime: date => {
            if (date) {
              this.setChangeDateTime('to')
            }
          },
          onClose: (currentTime, $input) => { this.setOnClose('to') }
        }) // this.$refs.to
      } else {
        $(this.$refs[this.mode]).datetimepicker({
          format: 'H:i',
          datepicker: false,
          settings,

          onChangeDateTime: date => { if (date) { this.setChangeDateTime() } },
          onClose: (currentTime, $input) => { this.setOnClose() }
        })
      } // false
    }, // timePicker

    monthPicker (settings) {
      var format = 'Y-m'

      if (this.range === true) {
        $(this.$refs.from).datepicker({
          dateFormat: format,
          changeMonth: true,
          changeYear: true,
          yearRange: 'c-80:c+5',
          showButtonPanel: true,

          onClose: (dateText, inst) => { this.setOnClose('from', dateText) }
        })

        $(this.$refs.from).focus(function () {
          $('.ui-datepicker-calendar').hide()
        })

        $(this.$refs.to).datepicker({
          dateFormat: format,
          changeMonth: true,
          changeYear: true,
          yearRange: 'c-80:c+5',
          showButtonPanel: true,

          onClose: (dateText, inst) => { this.setOnClose('to', dateText) }
        })

        $(this.$refs.to).focus(function () {
          $('.ui-datepicker-calendar').hide()
        })
      } else {
        $(this.$refs[this.mode]).datepicker({
          dateFormat: format,
          changeMonth: true,
          changeYear: true,
          yearRange: 'c-80:c+5',
          showButtonPanel: true,

          onClose: (dateText, inst) => { this.setOnClose('', dateText) }
        }, this.val)

        $(this.$refs[this.mode]).focus(function () {
          $('.ui-datepicker-calendar').hide()
        })
      } // false
    }, // monthPicker

    setChangeDateTime (type, value) {
      var self = this
      var dateText

      switch (type) {
        case 'from':
          dateText = $(this.$refs.from).val()
          break

        case 'to':
          dateText = $(this.$refs.to).val()
          break

        default:
          dateText = $(this.$refs[this.mode]).val()
      }

      this.reInitializeDatepicker()
      this.date = dateText

      self.$emit('input', dateText)
    }, // setChangeDateTime

    setOnClose (type) {
      var self = this
      var dateText = ''

      switch (type) {
        case 'from':
          dateText = $(this.$refs.from).val()
          self.$emit('minDate', dateText)
          break

        case 'to':
          dateText = $(this.$refs.to).val()
          self.$emit('maxDate', dateText)
          break

        default:
          dateText = $(this.$refs[this.mode]).val()
      }

      this.reInitializeDatepicker()
      this.date = dateText
      self.$emit('input', dateText)
    } // setOnClose
  } // methods
}
</script>
