export const kr = {
  kr: {
    from: '에서',
    to: '까지',

    user_detail_info: '유저 상세정보',
    created_date: '등록일자',
    deleted_date: '삭제일자',
    last_login: '마지막 로그인',
    updated_date: '수정일자',

    // level
    lv: '레벨',
    admin: '관리자',

    //= status
    stat_p: '요청',
    stat_c: '정상',
    stat_b: '중지',
    stat_r: '거부',
    stat_d: '삭제',
    stat_w: '대기',

    //= sidenav
    referral: '추천인',
    code: '코드',
    agent: '매장',
    login_id: '아이디',
    login_name: '닉네임',
    level: '레벨',
    phone: '연락처',
    password: '비밀번호',
    status: '상태',
    money: '보유금액',
    cash_in: '입금',
    cash_out: '출금',
    cash_net: '입/출 손익',
    manual_in1: '머니 수동 지급',
    manual_in: '지급',
    manual_out: '회수',
    manual_net: '지급/회수 손익',
    point: '포인트',
    point_in1: '포인트 수동 지급',
    point_in: '포인트 지급',
    point_out: '포인트 회수',
    point_conversion: '포인트 전환',
    total_betting: '배팅',
    now_betting: '배팅 결과대기',
    bet_net: '배팅 수익',
    total_win: '당첨',
    commission_type: '커미션 타입',
    commission: '커미션',

    // commission type
    cash: '입금',
    bet: '배팅',
    lost: '미당첨',

    //= commission
    manual_commission: '커미션 비율 변경',
    new_commission: '신규 커미션',
    value_in_decimal: '커미션',

    //= tabs
    info: '정보',
    transaction_history: '보유금 내역',
    cash_history: '입/출 내역',
    manual_history: '지급/회수 내역',
    point_history: '포인트 내역',
    bet_history: '배팅 내역',
    help_history: '문의 내역',
    message_history: '쪽지 내역',
    referral_list: '추천인 목록',
    referral_code: '추천인 코드 발급',
    //= code details
    code_details: '코드 정보',

    users_count: '유저 수',
    banned: '중지',
    confirmed: '정상',
    pending: '요청',
    waiting: '대기',
    deleted: '삭제',
    rejected: '거부',
    total: '전체',

    confirm: '정상처리',
    reject: '거부처리',
    ban: '중지처리',
    delete: '삭제처리',

    bank_details: '은행 정보',
    bank_account: '예금주',
    bank_number: '계좌번호',
    bank_name: '은행명',

    //= modify phone
    modify_phone: '연락처 수정',
    current_phone: '현재 연락처',
    new_phone: '신규 연락처',

    //= modify password
    modify_password: '비밀번호 수정',
    current_password: '현재 비밀번호',
    new_password: '신규 비밀번호',

    //= manual cash
    manual_cash: '수동 지급 (지급/회수)',
    current_balance: '현재 보유금액',
    amount: '금액',
    memo: '메모',
    content_here: '여기에 메모 내용을 적으세요.',

    //= edit commission
    edit_commission: '커미션 수정',
    current_point: '현재 포인트',

    //= total betting
    bet_sport: '배팅 스포츠게임',
    bet_mini: '배팅 미니게임',
    bet_casino: '배팅 카지노게임',
    bet_slot: '배팅 슬롯게임',
    bet_virtual: '배팅 가상게임',
    bet_token: '배팅 토큰게임',
    bet_365: '배팅 365',

    //= now betting
    betting_sport: '배팅 스포츠게임',
    betting_mini: '배팅 미니게임',
    betting_casino: '배팅 카지노게임',
    betting_slot: '배팅 슬롯게임',
    betting_virtual: '배팅 가상게임',
    betting_token: '배팅 토큰게임',
    betting_365: '배팅 365',

    //= total win
    win_sport: '당첨 스포츠게임',
    win_mini: '당첨 미니게임',
    win_casino: '당첨 카지노게임',
    win_slot: '당첨 슬롯게임',
    win_virtual: '당첨 가상게임',
    win_token: '당첨 토큰게임',
    win_365: '당첨 365',

    error_amount: '입력값이 0이 아닌지 확인하십시오.',
    not_enough_money: '보유금액이 부족합니다.',
    not_enough_points: '포인트가 부족합니다.',

    success_manual_transaction: '처리를 완료 하였습니다.',
    continue_manual_transaction: '처리를 진행 하시겠습니까?',
    deposit: '지급',
    withdraw: '회수',
    clear: '정정',
    save: '저장',
    title_point_inout: '포인트 지급/회수',
    title_cash_inout: '지급/회수',
    user_delete: '회원 삭제',
    user_modify: '회원 수정',
    coupon_history: '쿠폰 내역',
    settings: '회원별 설정',
    coupon: '쿠폰',
    manual_coupon: '쿠폰 수동 지급/회수',
    stamp_history: '출석 내역',
    logout: '로그아웃',
    force_logout: '강제 로그아웃',

    current_coupon: '보유쿠폰',
    referral_points: '추천인 포인트',

    access_logs_history: '접속 로그 내역',

    login_name_min: '닉네임을 3자 이상 입력해 주세요.',
    login_name_char: '닉네임은 한글(영문) 또는 한글(영문)+숫자 조합만 가능합니다.',

    login_name_exist: '이미 사용중인 닉네임입니다.',

    current_login_name: '현재 닉네임',
    new_login_name: '변경 닉네임',
    modify_login_name: '수정 닉네임',

    transaction_details: '보유금액 상세내역',
    account: '정산',

    sum: '합계',

    user_style: '회원성향',
    group: '그룹',
    bet_history_details: '배팅내역',

    restore_user: '유저복원'
  }
}
