import axios from 'axios'
import _ from 'lodash'
const audio = new Audio('/snd/notif.mp3')
const path = process.env.VUE_APP_AWS_URL || process.env.VUE_APP_STATIC_ASSET_PATH || '/'

export default {
  namespaced: true,
  state: {
    allTotal: [],
    cashInfo: null,
    usersInfo: null,
    manualCashInfo: null,
    betInfo: null,
    pointsInfo: null,
    sportsInfo: null,
    miniInfo: null,
    totalInfo: null,

    notif: {
      cashin: false,
      cashout: false,
      cashwait: false,
      newuser: false,
      help: false,
      waituser: false
    },

    sounds: []
  },
  getters: {
  },
  mutations: {
    SET_META (state, data) {
      state.allTotal = data
    },

    SET_CASHINFO (state, data) {
      data.net = parseInt(data.total_cash_in - data.total_cash_out)
      state.cashInfo = data
    },

    SET_USERSINFO (state, data) {
      state.usersInfo = data
    },

    SET_MANUALCASHINFO (state, data) {
      data.net = parseInt(data.total_cash_in - data.total_cash_out)
      state.manualCashInfo = data
    },

    SET_BETINFO (state, data) {
      data.net = parseInt(data.total_bet_done - data.total_win_amount)
      state.betInfo = data
    },

    SET_POINTS (state, data) {
      // data.net = parseInt(data.total_bet_amount - data.total_win_amount)
      state.pointsInfo = data
    },

    SET_SPORTS (state, data) {
      data.net = parseInt(data.total_bet_done - data.total_win)
      state.sportsInfo = data
    },

    SET_MINI (state, data) {
      data.net = parseInt(data.total_bet_done - data.total_win)
      state.miniInfo = data
    },

    SET_TOTAL (state, data) {
      const nets = {}

      nets.cash_net = parseInt(data.total_cash_in - data.total_cash_out)
      nets.manual_net = parseInt(data.total_manual_in - data.total_manual_out)
      nets.betting_net = parseInt(data.total_bet_done - data.total_win_amount)

      state.totalInfo = nets
    }
  },

  actions: {
    async get ({ commit }) {
      try {
        const response = await axios.get('/meta/all')
        commit('SET_META', response.data)
      } catch (err) {

      }
    },

    async  getCashInfo ({ commit }) {
      try {
        const response = await axios.get('/meta/cash')
        commit('SET_CASHINFO', response.data)
      } catch (err) {
        console.log(err)
      }
    },

    async  getManualInfo ({ commit }) {
      try {
        const response = await axios.get('/meta/manual')
        commit('SET_MANUALCASHINFO', response.data)
      } catch (err) {
        console.log(err)
      }
    },

    async  getUsersInfo ({ commit }) {
      try {
        const response = await axios.get('/meta/users')
        commit('SET_USERSINFO', response.data)
      } catch (err) {
        console.log(err)
      }
    },

    async getBetInfo ({ commit }) {
      try {
        const response = await axios.get('/meta/bets')
        commit('SET_BETINFO', response.data)
      } catch (err) {
        console.log(err)
      }
    },

    async getPointsInfo ({ commit }) {
      try {
        const response = await axios.get('/meta/points')
        commit('SET_POINTS', response.data)
      } catch (err) {
        console.log(err)
      }
    },

    async getSportsInfo ({ commit }) {
      try {
        const response = await axios.get('/meta/sports')
        commit('SET_SPORTS', response.data)
      } catch (err) {
        console.log(err)
      }
    },

    async getMiniInfo ({ commit }) {
      try {
        const response = await axios.get('/meta/mini')
        commit('SET_MINI', response.data)
      } catch (err) {
        console.log(err)
      }
    },

    async getTotalInfo ({ commit }) {
      try {
        const response = await axios.get('/meta/total')
        commit('SET_TOTAL', response.data)
      } catch (err) {
        console.log(err)
      }
    },

    async getSound ({ commit, state }) {
      try {
        const response = await axios.get('/settings/sound/admin')
        const data = response.data
        const obj = {}
        data.forEach(e => {
          if (e.status) {
            obj[e.key] = new Audio(`${path}${e.sound}`)
            obj[e.key].preload = true
          }
        })
        state.sounds = obj
      } catch (err) {
        console.log(err)
      }
    },

    setMeta ({ commit, state }, event) {
      if (event === 'cashin') {
        state.allTotal.total_cash_in += 1
      }
      if (event === 'cashout') {
        state.allTotal.total_cash_out += 1
      }
      if (event === 'new-user') {
        state.allTotal.total_pending_users += 1
      }
      if (event === 'new-request') {
        state.allTotal.total_pending_help += 1
      }
    },

    setUserInfo ({ commit, state }, event) {
      if (event === 'login') {
        // state.usersInfo.total_online += 1
      }
      if (event === 'logout') {
        // state.usersInfo.total_online -= 1
      }

      if (event === 'new-user') {
        state.usersInfo.total_new += 1
        state.usersInfo.total += 1
        // state.usersInfo.total_pending_users += 1
      }

      if (event.event === 'confirm-cash') {
        // state.usersInfo.total_balance += event.amount

        // if (state.usersInfo.with_money_user_id.indexOf(event.user) === -1) {
        //   state.usersInfo.with_money_user_id.push(event.user)
        // }
      }
    },

    setBetInfo ({ commit, state }, { event, data }) {
      if (event === 'new-bet') {
        data.forEach(item => {
          if (item.userDetails && item.userDetails.isTester) {
            // do not credit isTester users
            return
          }
          state.betInfo.total_bet += item.bet_amount
          state.betInfo.total_net += item.bet_amount
          state.betInfo.total_bet_waiting += item.bet_amount

          // if (parseInt(item.game_type_id) === 1) {
          //   state.sportsInfo.total_bet_waiting += item.bet_amount
          //   if (state.sportsInfo.bet_waiting_user_id.indexOf(item.user_id) === -1) {
          //     state.sportsInfo.bet_waiting_user_id.push(item.user_id)
          //   }
          // }

          // if (parseInt(item.game_type_id) === 2) {
          //   state.miniInfo.total_bet_waiting += item.bet_amount
          // }
          // if (state.betInfo.bet_waiting_user_id.indexOf(item.user_id) === -1) {
          //   state.betInfo.bet_waiting_user_id.push(item.user_id)
          // }
        })
        state.betInfo.net = parseInt(state.betInfo.total_bet_done - state.betInfo.total_win_amount)
      }
    },

    setCashInfo ({ state }, data) {
      // state.cashInfo.total_cash_waiting += data.amount

      // if (state.cashInfo && !state.cashInfo.cash_waiting_user_id) {
      //   state.cashInfo.cash_waiting_user_id = []
      // }

      // if (state.cashInfo && state.cashInfo.cash_waiting_user_id.indexOf(data.user.id) === -1) {
      //   state.cashInfo.cash_waiting_user_id.push(data.user.id)
      // }

      // state.cashInfo.count_users_waiting += 1
    },

    async setNotif ({ commit, state }, data) {
      data.forEach(e => {
        state.notif[e.key] = e.value
      })
      if (state.sounds[data[0].key] === undefined) return
      const playPromise = state.sounds[data[0].key].play()
      try {
        await playPromise
        state.sounds[data[0].key].play()
      } catch (err) {

      }
      // audio.play()
    }
  }
}
