import Vue from 'vue'

//= components
import TableSettings from '@/components/common/table-settings/TableSettings.vue'

//= mixins
import { checkColumns } from '@/components/common/table-settings/CheckColumns'

Vue.component(TableSettings.name, TableSettings)
Vue.mixin(checkColumns)
