/* eslint-disable no-dupe-keys */
export const kr = {
  kr: {
    user_management: '유저 관리',
    user_list: '유저 목록',
    online: '온라인',
    confirmed: '정상',
    banned: '중지',
    deleted: '삭제',
    requests: '가입 요청',
    testers: '테스터',
    ip_management: 'IP 관리',
    access_logs: '접속 로그',

    referrals: '추천인',
    referrals_list: '하부 목록',

    agent_management: '매장 관리',
    agent_list: '매장목록',
    codes_management: '매장코드',
    active: '활성',
    inactive: '중지',
    rejected: '거부',
    back: '목록으로',

    commission_history: '수수료내역',

    transfers: '입/출/포인트 안내문',

    transfers_management: '입/출 관리',
    // cash_in: '입금 요청',
    // cash_out: '출금 요청',
    all: '전체',
    deposit_accounts: '입금 은행 관리',

    'cash_&_point_history': '보유금 및 포인트 내역',
    cash_history: '보유금 내역',
    'cash_in/out': '입금/출금',
    'manual_in/out': '지급/회수',
    conversion: '포인트 전환',
    cash_bet: '배팅',
    win: '당첨',
    rollback: '복원',
    commission: '커미션',
    point_history: '포인트 내역',
    auto_in: '자동 지급',
    coupon_history: '쿠폰 내역',

    game_management: '게임 등록 및 관리',
    in_play: '인플레이',
    waiting: '발매 대기',
    bet: '배팅중',
    betting: '배팅중',
    done: '경기 종료',
    games_setup: '경기 종목 등록 관리',
    leagues_setup: '리그별 등록 설정',
    game_type_setup: '종목별 세부 항목 설정',

    sports: '스포츠',
    release: '받은 경기',
    ongoing: '경기중',

    event_ongoing: '진행중',

    mini_games: '미니게임',
    game_list: '경기 목록',

    casino: '카지노게임',
    token: '토큰게임',
    token_time: '로그인 유지시간',

    bet_management: '배팅 현황 관리',
    bet_cancel: '배팅취소',

    account_management: '정산 관리',
    total_account: '매출집계',
    point: '포인트 집계',
    account_commission: '커미션 집계',
    cash_back: '캐시백 집계',
    bet_account: '분류별 수익 집계',

    customer_management: '고객센터 및 게시판 관리',
    helpdesk: '고객센터',
    pending: '요청',
    resolved: '완료',
    quick_reply: '빠른 답변',

    messages: '쪽지 관리',
    faq: 'FAQ',
    freeboards: '자유 게시판',
    notices: '공지 게시판',
    events: '이벤트 게시판',
    completed: '완료',

    settings: '설정',
    game_setup: '게임 기타 관리 설정',
    bet_limits: '배팅 한도',

    odds_maker_setup: '배당 공급 싸이트 설정',
    odds_setup: '기준점 설정 및 종목별 배당 관리',
    countries: '개최지 관리',

    home_page_setup: '홈페이지 설정',
    themes: '사이트 테마',
    navigation: '메인 메뉴',
    home_page: '메인 페이지',
    events_page: '이벤트 페이지',
    level_icons: '레벨설정',
    domains: '도메인',
    ticker: '흐름 공지',
    popups: '팝업 관리',
    sounds: '알림 소리',
    language: '기본 언어',
    currency: '기본 통화',
    bank_accounts: '은행 목록',
    deposit_accounts: '입금은행 관리',

    'events_&_promotions': '이벤트 및 프로모 설정',
    coupon: '쿠폰 설정 및 관리',
    points: '포인트 설정 및 관리',
    promotions: '프로모션 게임 관리',

    manage_promotions: '프로모션 게임 관리',
    manage_coupon: '쿠폰 및 이벤트 설정',
    manage_points: '포인트 설정',

    modify: '수정',
    prematch: '스포츠',
    inplay: '인플레이',
    teams_setup: '팀명 관리',
    test_setup: '테스트 관리',

    user_detail_info: '유저 상세정보',
    agent_detail_info: '매장 상세 정보',
    stamp_history: '출석 내역',
    code_management: '코드 관리',

    // Bet Delay
    bet_delay: '배팅 딜레이 설정',
    default_settings: '기준점 설정',
    game_settings: '종목별 배당 설정',

    admin: '관리자',
    login: '로그인',

    freeboard_settings: '자유 게시판 설정',
    support_settings: '업로드 설정',
    post_html: 'html 허용',
    upload_image: '이미지 업로드 허용',
    enabled: '사용',
    disabled: '중지',

    setting: '설정',
    no_result: '대기중',

    list: '공지 목록',

    faqs: 'FAQ',
    user: '유저',

    total_user: '총 유저수',
    total: '전체',
    manual_release: '수동발매',

    registration: '회원가입',

    session_duration: '세션 관리',
    change_session_duration: '세션 시간 수정',
    duation: '타임 값',
    s: '초',
    m: '분',
    h: '시',
    d: '일',

    display_setting: '표시설정',
    countries_setup: '개최지 관리',

    manual_in: '지급',
    manual_out: '회수',
    cash_in: '입금완료',
    cash_out: '출금완료',

    user_cancel: '배팅 취소',

    cash_in_request: '입금신청',
    cash_out_request: '출금신청',

    user_style: '회원성향',

    welcome_message_setting: '가입 환영 메세지',

    user_online_session: '온라인 유저 체크 시간',

    bet365_setting: '게임설정',
    token_setting: '게임설정',
    new_done: '게임종료',
    new_game_list: '게임설정',

    new_online: '현재 접속자',

    new_result: '게임 종료'
  }
}
