import axios from 'axios'
import ObjToFormData from '@/assets/js/common/ObjToFormData.js'

export default {
  namespaced: true,
  state: {
    boards: [],
    listBoards: [],
    comments: [],
    count: 1,
    ads: []
  },
  getters: {
    boards (state) {
      return state.boards
    },

    count (state) {
      return state.count
    },

    comments (state) {
      return state.comments
    }
  },
  mutations: {
    SET_BOARDS (state, boards) {
      state.boards = boards
    },

    LIST_BOARDS (state, boards) {
      state.listBoards = boards
    },

    UPDATE_BOARD (state, { id, data }) {
      let index = state.boards
        .findIndex((row) => { return id === row.id })
      state.boards[index] = Object.assign(state.boards[index], data)
    },

    SET_COUNT (state, count) {
      state.count = count
    },

    SET_COMMENTS (state, comments) {
      state.comments = comments instanceof Array ? comments: []
    },

    UPDATE_COMMENT (state, comment) {
      let index = state.comments
        .findIndex((row) => { return row.id === comment.id })

      state.comments[index] = comment
    },

    CLEAR_COMMENTS (state) {
      state.comments = []
    },

    SET_NEW_BOARD (state, data) {
      let newBoard = JSON.parse(data)

      if (state.count === 50) {
        state.boards.pop()
      }
      state.boards.unshift(newBoard)
    }
  },

  actions: {
    async get ({ commit }, { params, type }) {
      let boards = await axios.get(`/freeboards/${type}`, { params })
      commit('SET_BOARDS', boards.data)
    },

    async list ({ commit }, { params, type }) {
      let boards = await axios.get(`/freeboards/${type}`, { params })
      commit('LIST_BOARDS', boards.data)
    },

    async delete ({ commit }, id) {
      await axios.delete(`/freeboards/${id}`)
    },

    async update ({ commit }, { id, data }) {
      await axios.patch(`/freeboards/${id}`, ObjToFormData(data))
      commit('CLEAR_COMMENTS', {id, data})
      commit('UPDATE_BOARD', {id, data})
    },

    async getCount ({ commit }, { params, type }) {
      commit('SET_COUNT', 1)
      let count = await axios.get(`/freeboards/count/${type}`, { params })
      commit('SET_COUNT', count.data)
    },

    async createComment ({ commit }, params) {
      let data = await axios
        .post(`/freeboards/${params.id}/comments`, params.comments)

      return data.data
    },

    async updateComment ({ commit }, comment) {
      await axios.patch(
        `/freeboards/${comment.freeboard_id}/comments/${comment.id}`,
        comment
      )
      commit('UPDATE_COMMENT')
    },

    async getComments ({ commit }, params) {
      let data = await axios.get(`/freeboards/${params.id}/comments`)
      commit('SET_COMMENTS', data.data)
    },

    async deleteComment ({ commit }, params) {
      await axios
        .delete(`/freeboards/${params.board_id}/comments/${params.id}`)
    },

    async createAdvertisement ({ commit }, params) {
      let response = await axios.post(`/freeboard-ads`, params)
    },

    async getAdvertisement ({ commit, state }) {
      let response = await axios.get(`/freeboard-ads`)
      state.ads = response.data
    },

    async deleteAdvertisement ({ commit, state }, id) {
      await axios.delete(`/freeboard-ads/${id}`)
    },

    async updateAdvertisement ({ commit, state }, { id, params }) {
      return axios.patch(`/freeboard-ads/${id}`, params)
    }
  }
}
