/**
  Description:
  import { translate } from '@/assets/js/translations/Translate'

  mixins: [ translate ]
**/

import { vueLocalStorage } from '@/assets/js/mixins/base/VueLocalStorage'

import { getKey } from '@/assets/js/common/Helpers'

export const translate = {
  computed: {
    lang () {
      return this.config.lang || vueLocalStorage.getItem('adminLang') || 'kr'
    }
  },

  methods: {
    translateDB (payload, { en, kr, jp }) {
      return getKey(this.lang, {
        en: payload[en],
        kr: payload[kr] || payload[en],
        jp: payload[jp] || payload[en],
        default: payload[en]
      })
    },

    translateData (payload, en, kr, jp) {
      return getKey(this.lang, {
        en: payload[en],
        kr: payload[kr] || payload[en],
        jp: payload[jp] || payload[en],
        default: payload[en]
      })
    },

    translate (location, name, filterType = null) {
      const lang = this.lang
      let data
      let newName
      let oldName

      if (typeof name === 'string') {
        name = name.replace(/[(]/g, ' ')
        name = name.replace(/[)]/g, '')
        name = name.toLowerCase().replace(/[_]/g, ' ')

        oldName = name

        if (lang) {
          if (filterType) {
            name = this.$root.$options.filters[filterType](name)
            name = location[lang][name] ? location[lang][name] : name
          }

          const matchLevel = name.match(/^(level|lv|lvl)(.|)( |)(([0-9]|)([0-9]|)|)/)

          if (matchLevel && name !== 'level id') {
            if (matchLevel[0] === 'level') {
              name = name.replace('level', location[lang].level || name)
            }

            if (matchLevel[0] === 'lvl') {
              name = name.replace('lvl', location[lang].lvl || name)
            }

            if (matchLevel[0] === 'lv') {
              name = name.replace('lv', location[lang].lv || name)
            }

            if (lang === 'kr') {
              name = name.replace(/[.]/g, ' ')
            }
          }

          if (this.$route.name === 'bet-history-page' || this.$route.params.tab === 'bet-history' || this.$route.name === 'setting-mini') {
            if (name.match(/^(run out|blue low|blue high|red low|red high|blue odd|blue even|blue pair|red odd|red even|red pair|left start|right start|power odd|power even|power over|power under|small|middle|big|goal|no goal|home run|out|success|fail|ato|saki|home|away|draw|no|yes|over|under|left|right|low|high|odd|even|red|blue)(\/| \/ | - |-| or |or| |)(run out|blue low|blue high|red low|red high|blue odd|blue even|blue pair|red odd|red even|red pair|left start|right start|power odd|power even|power over|power under|small|middle|big|goal|no goal|home run|out|success|fail|ato|saki|home|away|draw|no|yes|over|under|left|right|low|high|odd|even|red|blue|)$/)) {
              // val - val || val-val
              // val/val || val / val
              // val or val

              name = name.replace(('or'), location[lang].or)

              name = name.replace(('home/run out'), location[lang].home_run + '/' + location[lang].out)

              name = name.replace(/blue low/, location[lang].blue_low)
              name = name.replace(/blue high/, location[lang].blue_high)
              name = name.replace(/red low/, location[lang].red_low)
              name = name.replace(/red high/, location[lang].red_high)

              name = name.replace(/blue odd/, location[lang].blue_odd)
              name = name.replace(/blue even/, location[lang].blue_even)
              name = name.replace(/blue pair/, location[lang].blue_pair)

              name = name.replace(/red odd/, location[lang].red_odd)
              name = name.replace(/red even/, location[lang].red_even)
              name = name.replace(/red pair/, location[lang].red_pair)

              name = name.replace(/left start/, location[lang].left_start)
              name = name.replace(/right start/, location[lang].right_start)

              name = name.replace(/power odd/, location[lang].power_odd)
              name = name.replace(/power even/, location[lang].power_even)
              name = name.replace(/power over/, location[lang].power_over)
              name = name.replace(/power under/, location[lang].power_under)

              name = name.replace(('small'), location[lang].small)
              name = name.replace(('middle'), location[lang].middle)
              name = name.replace(('big'), location[lang].big)

              name = name.replace(('no goal'), location[lang].no_goal)
              name = name.replace(('goal'), location[lang].goal)
              name = name.replace(('no'), location[lang].no)

              name = name.replace(('home run'), location[lang].home_run)

              name = name.replace((/away/gi), location[lang].away)
              name = name.replace(/home/gi, location[lang].home)
              name = name.replace((/draw/gi), location[lang].draw)

              name = name.replace(('yes'), location[lang].yes)
              name = name.replace(('over'), location[lang].over)
              name = name.replace(('under'), location[lang].under)
              name = name.replace(('left'), location[lang].left)
              name = name.replace(('right'), location[lang].right)
              name = name.replace(('low'), location[lang].low)
              name = name.replace(('high'), location[lang].high)
              name = name.replace(('odd'), location[lang].odd)
              name = name.replace(('even'), location[lang].even)
              name = name.replace(('red'), location[lang].red)
              name = name.replace(('blue'), location[lang].blue)
              name = name.replace(('out'), location[lang].out)
              name = name.replace(('success'), location[lang].success)
              name = name.replace(('fail'), location[lang].fail)
              name = name.replace(('ato'), location[lang].ato)
              name = name.replace(('saki'), location[lang].saki)
            }

            if (name.match(/^blue tie red$/)) {
              name = name.replace('blue', location[lang].blue)
              name = name.replace('tie', location[lang].tie_1)
              name = name.replace('red', location[lang].red)
            }

            if (name.match(/^(small|middle|big)\/(small|middle|big)\/(small|middle|big)$/)) {
              name = name.replace('small', location[lang].small)
              name = name.replace('middle', location[lang].middle)
              name = name.replace('big', location[lang].big)
            }

            if (name.match(/^no [1-9](st|nd|rd|th) goal$/)) {
              name = name.replace('no 1st goal', location[lang].no_1st_goal)
              name = name.replace('no 2nd goal', location[lang].no_2nd_goal)
              name = name.replace('no 3rd goal', location[lang].no_3rd_goal)
              name = name.replace('no 4th goal', location[lang].no_4th_goal)
              name = name.replace('no 5th goal', location[lang].no_5th_goal)
              name = name.replace('no 6th goal', location[lang].no_6th_goal)
              name = name.replace('no 7th goal', location[lang].no_7th_goal)
              name = name.replace('no 8th goal', location[lang].no_8th_goal)
              name = name.replace('no 9th goal', location[lang].no_9th_goal)
              name = name.replace('no 10th goal', location[lang].no_10th_goal)
            }

            if (name.match(/^finish ((first|second|third|fourth|fifth|sixth|seventh|eighth|ninth|tenth|1st|2nd|3rd|4th|5th|6th|7th|8th|9th|10th))$/)) {
              if (lang === 'en') {
                name = name
              }

              if (lang === 'kr') {
                let number
                if (name.match(/first|second|third|fourth|fifth|sixth|seventh|eighth|ninth|tenth|1st|2nd|3rd|4th|5th|6th|7th|8th|9th|10th/)) {
                  if (name.includes('first') | name.includes('1st')) {
                    number = '1'
                    name = name.replace(/(first|1st)/, '')
                  }

                  if (name.includes('second') | name.includes('2nd')) {
                    number = '2'
                    name = name.replace(/(second|2nd)/, '')
                  }

                  if (name.includes('third') | name.includes('3rd')) {
                    number = '3'
                    name = name.replace(/(third|3rd)/, '')
                  }

                  if (name.includes('fourth') | name.includes('4th')) {
                    number = '4'
                    name = name.replace(/(fourth|4th)/, '')
                  }

                  if (name.includes('fifth') | name.includes('5th')) {
                    number = '5'
                    name = name.replace(/(fifth|5th)/, '')
                  }

                  if (name.includes('sixth') | name.includes('6th')) {
                    number = '6'
                    name = name.replace(/(sixth|6th)/, '')
                  }

                  if (name.includes('seventh') | name.includes('7th')) {
                    number = '7'
                    name = name.replace(/(seventh|7th)/, '')
                  }

                  if (name.includes('eighth') | name.includes('8th')) {
                    number = '8'
                    name = name.replace(/(eighth|8th)/, '')
                  }

                  if (name.includes('ninth') | name.includes('9th')) {
                    number = '9'
                    name = name.replace(/(ninth|9th)/, '')
                  }

                  if (name.includes('tenth') | name.includes('10th')) {
                    number = '10'
                    name = name.replace(/(tenth|10th)/, '')
                  }
                }

                name = name.replace('finish', location[lang].finish + number + location[lang].place)
              }
            }

            if (name.match(/^(home|away|draw)( |)(not|)( |)to score( |)([0-9][0-9]|[0-9]|)(in|)( |)(1st|2nd|3rd|4th|)( |)(half|)$/)) {
              if (lang === 'kr') {
                let number = name.match(/\d/g)

                name = name.replace(/\d/g, '')

                if (number) {
                  number = number.join('') || ''
                  name = name.replace('home', location[lang].home + ' ' + number)
                  name = name.replace('away', location[lang].away + ' ' + number)
                  name = name.replace('draw', location[lang].draw + ' ' + number)
                }
              }

              name = name.replace('not to score in 1st half', location[lang].not_to_score_in_1st_half)
              name = name.replace('not to score in 2nd half', location[lang].not_to_score_in_2nd_half)
              name = name.replace('not to score in 3rd half', location[lang].not_to_score_in_3rd_half)

              name = name.replace('to score in 1st half', location[lang].to_score_in_1st_half)
              name = name.replace('to score in 2nd half', location[lang].to_score_in_2nd_half)
              name = name.replace('to score in 3rd half', location[lang].to_score_in_3rd_half)

              name = name.replace('not to score', location[lang].not_to_score)
              name = name.replace('to score', location[lang].to_score)

              name = name.replace('home', location[lang].home)
              name = name.replace('away', location[lang].away)
              name = name.replace('draw', location[lang].draw)
            }

            if (name.match(/^(home|away|draw|over|under|odd|even|player[1-2]|[1-2]player|plyer[1-2]|[1-2]plyer)( |)(to win|)( |)((0[0-9]|1[0-9]|2[0-9]|[0-9])(-|:|.)(0[0-9]|1[0-9]|2[0-9]|[0-9])|)([0-9][0-9]|[0-9]|)( |)(or better|)(or more points|)(after extra points|)(points|pts|)$/)) {
              // home 0-1 or better || home 00:00 or better
              // home 0-1
              // home 00:00 || home 0.0
              // home 12 or more points
              // home 12 points
              // home 12 after extra points

              name = name.replace('home', location[lang].home)
              name = name.replace('away', location[lang].away)
              name = name.replace('draw', location[lang].draw)
              name = name.replace('over', location[lang].over)
              name = name.replace('under', location[lang].under)
              name = name.replace('odd', location[lang].odd)
              name = name.replace('even', location[lang].even)
              name = name.replace('or better', location[lang].or_better)
              name = name.replace('to win', location[lang].to_win)
              name = name.replace('or more points', location[lang].or_more_points)
              name = name.replace('after extra points', location[lang].after_extra_points)
              name = name.replace('points', location[lang].points)
              name = name.replace('pts', location[lang].points)
            }

            if (name.match(/((player[1-2])|([1-2]player)|([1-2]plyer)|(plyer[1-2]))( |)((0[0-9]|1[0-9]|2[0-9]|[0-9])(-|:|.)(0[0-9]|1[0-9]|2[0-9]|[0-9])|)(\/|\ - | or |)((player[1-2])|([1-2]player)|([1-2]plyer)|(plyer[1-2])|)(svr|\(([svr)]+)\)|)(to win (1st|2nd|3rd) set and (lose|lost|win) match|)/)) {
              // player 0-1 || player 1.0 || player 1:0
              // player1/2player || player2/1plyer || player1 - player2 || player1 or player 2
              // player1 svr || player (svr)
              // player2 to win 1st set and win match
              // player2 to win 1st set and (lose|lost) match

              name = name.replace('svr', location[lang].svr)
              name = name.replace('player1', location[lang].player1)
              name = name.replace('plyer1', location[lang].player1)
              name = name.replace('1player', location[lang].player1)
              name = name.replace('1plyer', location[lang].player1)

              name = name.replace('player2', location[lang].player2)
              name = name.replace('plyer2', location[lang].player2)
              name = name.replace('2player', location[lang].player2)
              name = name.replace('2plyer', location[lang].player2)

              name = name.replace('to win 1st set and lost match', location[lang].to_win_1st_set_and_lost_match)
              name = name.replace('to win 2nd set and lost match', location[lang].to_win_2nd_set_and_lost_match)
              name = name.replace('to win 3rd set and lost match', location[lang].to_win_3rd_set_and_lost_match)

              name = name.replace('to win 1st set and lose match', location[lang].to_win_1st_set_and_lost_match)
              name = name.replace('to win 2nd set and lose match', location[lang].to_win_2nd_set_and_lost_match)
              name = name.replace('to win 3rd set and lose match', location[lang].to_win_3rd_set_and_lost_match)

              name = name.replace('to win 1st set and win match', location[lang].to_win_1st_set_and_win_match)
              name = name.replace('to win 2nd set and win match', location[lang].to_win_2nd_set_and_win_match)
              name = name.replace('to win 3rd set and win match', location[lang].to_win_3rd_set_and_win_match)
            }

            if (name.match(/([1-9])( |)((sets|set|more))/)) {
              // 1 sets/set
              // 1 more
              name = name.replace(('more'), location[lang].more)
              name = name.replace(('sets'), location[lang].sets)
              name = name.replace(('set'), location[lang].sets)
            }

            if (name.match(/^([1-9]|)( |)line( |)([1-9]|)((\/| \/ |-| - |or| or |))(([1-9]|)( |)line( |)([1-9]|)|)$/)) {
              // 3line/4line || 3 line / 4 line
              // line3/line4 || line 3 / line 4
              name = name.replace(/line/gi, location[lang].line_1)
            }

            if (name.match(/^((left|right)( |)[1-9]line)(\/| \/ |-| - |or| or |)((left|right)( |)[1-9]line|)$/)) {
              // left3line
              // left3line/right3line
              name = name.replace(/left/gi, location[lang].left)
              name = name.replace(/right/gi, location[lang].right)
              name = name.replace(/line/gi, location[lang].line_2)
            }

            if (name.match(/(([0-9]|[+]))( |)(goal|goals)$/)) {
              // 1 goal || 2 goals
              // 3+ goals
              name = name.replace(/goals/gi, location[lang].goals)
              name = name.replace(/goal/gi, location[lang].goal)
            }

            if (name.match(/^(home|away|draw) ([0-9]|[0-9][0-9]) (runs draw|more runs|run exactly)$/)) {
              // home 3 run exactly
              // away 3 runs draw
              // draw 3 more runs

              name = name.replace('home', location[lang].home)
              name = name.replace('away', location[lang].away)
              name = name.replace('draw', location[lang].draw)

              name = name.replace('run exactly', location[lang].run_exactly)
              name = name.replace('runs draw', location[lang].runs_draw)
              name = name.replace('more runs', location[lang].more_runs)
            }
          }

          if (String(name) === 'undefined') {
            name = oldName
          }

          if (lang === 'undefined') {
            lang = 'kr'
          }

          newName = oldName.split(' ').join('_')

          data = typeof location[lang][newName] !== undefined ? location[lang][newName] : oldName

          if (typeof data === 'undefined') {
            data = name
          }
        } else {
          data = name
        }
      } else if (Array.isArray(name)) {
        data = []

        name.forEach(n => {
          n = n.split('.').join(' ')
          n = n.toLowerCase().replace(/[_-]/g, ' ')
          newName = n.split(' ').join('_')

          if (lang) {
            newName = location[lang][newName]

            if (!newName) {
              data.push(n)
            } else {
              data.push(newName)
            }
          } else {
            data.push(n)
          }
        })

        data = data.join('/')
      } else {
        data = name
      }

      return data
    }
  }
}
