import axios from 'axios'

export default {
  namespaced: true,
  state: {
    events: [],
    listEvents: [],
    event: null,
    count: 1,
    comments: [],
    comments_cnt: 1
  },

  mutations: {
    SET_EVENTS (state, data) {
      data.forEach(e => {
        e.isEdit = false

        if (!e.choices) {
          e.choices = []
        }
        if (e.choices && typeof e.choices === 'string') {
          e.choices = e.choices.split('|')//JSON.parse(e.choices)
        }
      })
      state.events = data
    },

    SET_EVENTS_COMMENTS (state, data) {
      state.comments = data
    },

    SET_EVENTS_COMMENTS_COUNT (state, data) {
      state.comments_cnt = data.total
    },

    UPDATE_EVENTS (state, event) {
      let index = state.events.findIndex((row) => { return row.id === event.id })

      state.events[index].is_hidden = event.data.is_hidden
    }
  },

  getters: {
  },

  actions: {
    async get ({ state, commit }, { params, status }) {
      let data = await axios.get(`/events/status/${status}`, { params })
      commit('SET_EVENTS', data.data)
    },

    async list ({ state }, { params, status }) {
      let data = await axios.get(`/events/status/${status}`, { params })
      state.listEvents = data.data
    },

    async create ({ commit,  state}, data) {
      await axios.post('/events', data)
    },

    async update ({ state, commit }, {id, data}) {
      await axios.patch(`/events/${id}`, data)
      commit('UPDATE_EVENTS', { id, data })
    },

    async delete ({ state }, id) {
      await axios.delete(`/events/${id}`)
    },

    async setResult ({ state }, id) {
      await axios.post(`/events/${id}/result`, { id })
    },

    async setRandomComment ({ state }, id) {
      await axios.post(`/events/${id}/random-comment`, { id })
    },

    async getCount ({ state }, { params, status }) {
      let data = await  axios.get(`/events/count/status/${status}`, { params })
      state.count = data.data[0].total
    },

    async getComments ({ commit, state }, { id, params }) {
      let data = await  axios.get(`/events/${id}/comments`, { params })
      commit('SET_EVENTS_COMMENTS', data.data.list)
      commit('SET_EVENTS_COMMENTS_COUNT', data.data.total)
    },

    setEvent ({ state }, id) {
      state.event = state.events.find((e) => {
        return e.id === id
      })
    }
  }
}
