import { common } from '@/assets/js/translations/Common'

export const kr = {
  kr: {
    delete: `삭제`,
    modify: `알림`,
    restore: `복원`,
    create: `생성`,
    success: `성공`,
    error: `오류`,
    remove: `삭제`,
    cancel: `취소`,

    proceed: `정말 계속 진행 하시겠습니까?`,
    success_modify: `수정에 성공 하였습니다.`,
    success_create: `생성에 성공 하였습니다.`,
    success_delete: `삭제에 성공하였습니다.`,
    success_restore: `복원에 성공하였습니다.`,
    success_remove: `삭제에 성공하였습니다.`,
    success_cancel: `삭제 처리하였습니다..`,

    try_again: `다시 시도해 주세요.`,
    data_exist: `데이터가 이미 존재합니다.`,
    fields_required: `모든 항목을 입력해주세요`,

    dual_logged_in: `다른곳에서 같은 계정으로 로그인하였습니다.`,
    account_banned: `계정에 문제가 있습니다. 고객센터에 문의하세요`,
    account_deleted: `계정에 문제가 있습니다. 고객센터에 문의하세요`,
    back_to_login: `로그인으로 돌아가다`,

    yes: `예`,
    confirm: `확인`,
    cancel: `아니오`
  }
}
