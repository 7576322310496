import axios from 'axios'

export default {
  namespaced: true,

  state: {
    ips: [],
    listIps: [],
    count: 0
  },

  getters: {},

  mutations: {
    SET_USER_IPS (state, data) {
      state.ips = data
    },

    LIST_USER_IPS (state, data) {
      state.listIps = data
    },

    SET_COUNT (state, data) {
      state.count = data
    }
  },

  actions: {
    async get ({ commit }, { status, params }) {
      let result = await axios
        .get(`/user-ips/${status}`, { params: {...params } })
      let count = await axios
        .get(`/user-ips/${status}/count`, { params: {...params} })

      commit('SET_USER_IPS', result.data)
      commit('SET_COUNT', count.data)
    },

    async list ({ commit }, { status, params }) {
      let result = await axios
        .get(`/user-ips/${status}`, { params: {...params } })

      commit('LIST_USER_IPS', result.data)
    },

    async create ({ commit }, ip) {
      return axios.post(`/user-ips`, ip)
    },

    async update ({ commit }, { id, data }) {
      return axios.patch(`/user-ips/${id}`, data)
    },

    async delete ({ commit }, params) {
      return axios.delete(`/user-ips`, params)
    },

    async restore ({ commit }, { id, data }) {
      return axios.patch(`/user-ips/${id}`, data)
    },

    async bulkRestore ({ commit }, params) {
      return axios.patch('/user-ips/restore/ips', params)
    }
  }
}
