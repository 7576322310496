<template>
  <div class="pagination">
    <ul v-show="total > 0">
      <li v-show="max > 1" @click="change(1)">&#x226A;</li>
      <li v-show="max > 1" @click="prev">&lt;</li>
      <li :class="{ active: i === currentPage }" @click="change(i)" :key="i" v-for="i in pages">{{ i }}</li>
      <li v-show="max > 1" @click="next">&gt;</li>
      <li v-show="max > 1" @click="change(max)">&#x226B;</li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number]
    },

    total: {
      type: [String, Number],
      required: true
    },

    page: {
      type: [String, Number],
      required: false,
      default: 1
    },

    rows: {
      type: [String, Number],
      required: false,
      default: 50
    }
  },

  computed: {
    max () {
      return Math.ceil(this.total / this.rows)
    },

    currentPage () {
      return parseInt(this.value)
    },

    pages () {
      let pages = []

      let i = Math.floor(this.value / 10) * 10 || 1
      let max = this.max < (i + 10) ? this.max : i + 10

      if (max === i && max > 10) {
        i -= 10
      }

      for (; i <= max; i++) {
        pages.push(i)
      }

      return pages
    }
  },

  methods: {
    prev () {
      if (this.value > 1) {
        this.change(this.value - 1)
      }
    },

    next () {
      if (Math.ceil(this.total / this.rows) > this.value) {
        this.change(this.value + 1)
      }
    },

    change (page) {
      this.$emit('input', page)
      this.$emit('page')
    }
  }
}
</script>
