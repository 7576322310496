import { messages } from '@/socket'

export default {
  mounted () {
    messages.on('request', (data) => {
      this.$store.dispatch('meta/setMeta', 'new-request')

      this.$store.dispatch('meta/setNotif', [ { key:'help', value:  true } ])
    })
  }
}
