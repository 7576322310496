import axios from 'axios'

export default {
  namespaced: true,

  state: {
    betHistory: [],
    count: 0
  },

  getters: {},

  mutations: {
    SET_BETSLIP (state, data) {
      if (!data) {
        state.betHistory = []
        return
      }

      data.forEach(e => {
        if (e.bet_info) {
          e.bet_info.forEach(binfo => {
            if (['ntry', 'named'].indexOf(binfo.game.eng) !== -1) {
              const areas = binfo.game_type.eng.split('/')
              const areasKor = binfo.game_type.kor.split('/')

              if (areas.length === 3) {
                binfo.home = {
                  eng: areas[0],
                  kor: areasKor[0]
                }
                binfo.away = {
                  eng: areas[2],
                  kor: areasKor[2]
                }
              }
            }
          })
        }
      })

      state.betHistory = data
    },

    SET_BETSLIP_COUNT (state, data) {
      state.count = data
    },

    CANCEL_BETSLIP (state, data) {
      state.betHistory.forEach(betslip => {
        if (betslip.id === data.id) {
          betslip.isCancel = data.isCancel
        }
      })
    },

    DELETE_BETSLIP (state, data) {
      state.betHistory.forEach(betslip => {
        if (betslip.id === data.id) {
          betslip.isHide = 1
        }
      })
    },

    RESTORE_BETSLIP (state, data) {
      state.betHistory.forEach(betslip => {
        if (betslip.id === data.id) {
          betslip.isHide = data.isHide
        }
      })
    }
  },

  actions: {
    async get ({ commit }, { type, status, params, country, game, league }) {
      const { data } = await axios.get(`/bet-history/type/${type}/status/${status}`, { params })

      commit('SET_BETSLIP', data.list)
      commit('SET_BETSLIP_COUNT', data.count)
    },

    async getByAgent ({ commit }, { id, params, type, status }) {
      const url = `/bet-history/agent/${id}/type/${type}/status/${status}`
      const result = await axios.get(url, { params })
      commit('SET_BETSLIP', result.data.data)
      commit('SET_BETSLIP_COUNT', result.data.count)
    },

    async cancel ({ commit }, params) {
      await axios.patch(`/bet-history/${params.id}/cancel`, params)
      commit('CANCEL_BETSLIP', params)
    },

    async delete ({ commit }, params) {
      await axios
        .delete(`/bet-history/${params.id}`)

      commit('DELETE_BETSLIP', params)
    },

    async generateLeagues ({ commit }, params) {
      const allLeague = await axios.get('/bet-history/allleagues?', { params })

      return allLeague ? allLeague.data : []
    },

    async generateAutoComplete () {
      const allgames = await axios.get('/bet-history/allgames/1')
      const allcountries = await axios.get('/bet-history/allcountries/1')
      const allLeague = await axios.get('/bet-history/allleagues/1?', { country: '', game: '' })
      return [
        {
          key: 'country',
          data: allcountries ? allcountries.data : []
        },
        {
          key: 'game_name',
          data: allgames ? allgames.data : []
        },
        {
          key: 'league',
          data: allLeague ? allLeague.data : []
        }
      ]
    },

    async restore ({ commit }, params) {
      await axios
        .patch(`/bet-history/${params.id}/restore`, params)

      commit('RESTORE_BETSLIP', params)
    },

    async getAttachBetslip ({ commit }, params) {
      const result = await axios
        .get('/bet-history/helpdesk', { params })

      commit('SET_BETSLIP', result.data)
    }
  }
}
