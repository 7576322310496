import { common } from '@/assets/js/translations/Common'

export const en = {
  en: {
    from: `from`,
    to: `to`,

    select_cash: `type`,
    select_status: `status`,
    cash_out: `cash out`,
    cash_in: `cash in`,
    bet: `bet`,
    point: `point`,
    rollback: `rollback`,
    win: `win`,
    commission: `commission`,
    manual_in: `manual in`,
    manual_out: `manual out`,
    point_bet: `point bet`,
    point_bet_cancel: `point bet cancel`,
    confirmed: `confirmed`,
    pending: `pending`,
    rejected: `rejected`,
    waiting: `waiting`,
    deleted: `deleted`,

    ip: `ip`,

    no: `no`,
    request_date: `request date`,
    before_transfer: `before transfer`,
    amount: `amount`,
    new_balance: `new balance`,
    type: `type`,
    status: `status`,
    confirm_date: `confirm date`,
    user_ip: `user ip`,
    admin: `admin`,
    match_rollback: `match rollback`,
    true: `attend`,
    created_date: `created`,
    login_id: `login id`,
    login_name: `login name`
  }
}
