import axios from 'axios'
import _ from 'lodash'

export default {
  namespaced: true,
  state: {
    tab: 1,
    count: 0,
    filter: null,
    createFlag: false,
    permissionCreateFlag: false,
    admins: [],
    permissions: [],
    tempPermission: {
      id: null,
      level_id: null,
      authority: null,
      permission: {
        admins: null,
        banks: null,
        games: null,
        users: null,
        domains: null,
        events: null,
        transfers: null,
        bet_history: null,
        codes: null,
        ip: null,
        agents: null,
        supports: null,
        boards: null
      }
    },
    whitelists: [],
    tempWhitelist: {
      id: null,
      ip: null,
      description: null
    },
    whitelistCreateFlag: false,
    currentAdmin: {
      active: null,
      created_at: null,
      deleted_at: null,
      id: null,
      level_id: null,
      login_id: null,
      login_name: null,
      password: null
    },
    partialAdmin: {
      active: null,
      level_id: null,
      login_id: null,
      login_name: null,
      password: null
    },
    page: 1,
    row: 1,
    sortBy: 'id',
    sort: 'asc',
    filterBy: null
  },
  getters: {
    getPermissions (state) {
      return state.permissions
    }
  },
  mutations: {
    SET_ADMINS (state, admins) {
      state.admins = admins
    },
    SET_TEMPPERMISSIONS (state, value) {
      let p = _.map(value, per => {
        return {
          level_id: per.level_id,
          permission: JSON.parse(per.permission)
        }
      })
      state.tempPermission = p
    },
    SET_PERMISSIONS (state, permissions) {
      let p = _.map(permissions, per => {
        return {
          id: per.id,
          level_id: per.level_id,
          authority: per.authority,
          permission: JSON.parse(per.permission)
        }
      })
      state.permissions = p
    },
    SET_PERMISSIONS_RAW (state, params) {
      _.remove(state.permissions, sperm => {
        return params.includes(sperm.id)
      })
    },
    SET_WHITELISTS (state, whitelists) {
      state.whitelists = whitelists
    },
    SET_TEMPWHITELIST (state, value) {
      state.tempWhitelist = value
    },
    SET_TAB (state, admins) {
      state.tab = admins
    },
    SET_PERMISSIONCREATEFLAG (state, value) {
      state.permissionCreateFlag = value
    },
    SET_WHITELISTCREATEFLAG (state, value) {
      state.whitelistCreateFlag = value
    },
    SET_CREATEFLAG (state, value) {
      state.createFlag = value
    },
    SET_CURRENTADMIN (state, value) {
      state.currentAdmin = value
    },
    SET_PARTIALADMIN (state, value) {
      state.partialAdmin = value
    },
    SET_PAGE (state, value) {
      state.page = value
    },
    SET_COUNT (state, value) {
      state.count = value
    },
    SET_ROW (state, value) {
      state.row = value
    },
    SET_SORTBY (state, value) {
      state.sortBy = value
    },
    SET_SORT (state, value) {
      state.sort = value
    },
    SET_FILTER (state, value) {
      state.filter = value
    },
    SET_FILTERBY (state, value) {
      state.filterBy = value
    },
    GET_ADMINS (state) {
      return state.admins
    }
  },

  actions: {
    async setFilter ({ commit }, value) {
      commit('SET_FILTER', value)
    },
    async setTab ({ commit }, value) {
      commit('SET_TAB', value)
    },
    async setFilterBy ({ commit }, value) {
      commit('SET_FILTERBY', value)
    },
    async setPage ({ commit }, value) {
      commit('SET_PAGE', value)
    },
    async setCreateFlag ({ commit }, value) {
      commit('SET_CREATEFLAG', value)
    },
    async setPermissionCreateFlag ({ commit }, value) {
      commit('SET_PERMISSIONCREATEFLAG', value)
    },
    async setWhitelistCreateFlag ({ commit }, value) {
      commit('SET_WHITELISTCREATEFLAG', value)
    },
    async setCurrentAdmin ({ commit }, value) {
      commit('SET_CURRENTADMIN', value)
    },
    async setPartialAdmin ({ commit }, value) {
      commit('SET_PARTIALADMIN', value)
    },
    async setTempPermission ({ commit }, value) {
      commit('SET_TEMPPERMISSIONS', value)
    },
    async setTempWhitelist ({ commit }, value) {
      commit('SET_TEMPWHITELIST', value)
    },
    async createWhitelist ({ commit, state, dispatch }) {
      try {
        await axios
          .post('/admins/whitelist/add', {
            ip: state.tempWhitelist.ip,
            description: state.tempWhitelist.description
          })

        dispatch('set', {
          page: state.page,
          row: state.row || 10,
          sortBy: state.sortBy || 'id',
          sort: state.sort || 'asc',
          filter: state.filter || '',
          filterBy: state.filterBy || ''
        })


      } catch (err) {
        console.log(err)
      }
    },
    async deletePermissions ({ commit, state, dispatch }, params) {
      try {
        await axios
          .delete('/admins/permission/', { data: params })
        commit('SET_PERMISSIONS_RAW', params)
      } catch (err) {
        console.log(err)
      }
    },
    async editWhitelist ({ commit, state, dispatch }, params) {
      try {
        await axios
          .delete('/admins/whitelist/drop?ip=' + params)
        dispatch('set', {
          page: state.page,
          row: state.row || 10,
          sortBy: state.sortBy || 'id',
          sort: state.sort || 'asc',
          filter: state.filter || '',
          filterBy: state.filterBy || ''
        })
      } catch (err) {
        console.log(err)
      }
    },
    async deleteAdmin ({ commit, state, dispatch }, params) {
      try {
        await axios
          .delete('/admins/' + params.id)
        dispatch('set', {
          page: state.page,
          row: state.row || 10,
          sortBy: state.sortBy || 'id',
          sort: state.sort || 'asc',
          filter: state.filter || '',
          filterBy: state.filterBy || ''
        })
      } catch (err) {
        console.log(err)
      }
    },
    async createAdmin ({ commit }, params) {
      try {
        await axios
          .post('/admins/', {
            login_id: params.login_id,
            login_name: params.login_name,
            level_id: params.level_id,
            password: params.password,
            active: params.active
          })
      } catch (err) {
        console.log(err)
      }
    },
    async createPermissions ({ commit, state }, params) {
      // commit('SET_ADMINS', data)
      try {
        await axios
          .post('/admins/permission', {
            level_id: state.tempPermission.level_id,
            permission: JSON.stringify(state.tempPermission.permission)
          })
      } catch (err) {
        console.log(err)
      }
    },
    async editAdmin ({ commit }, params) {
      // commit('SET_ADMINS', data)
      try {
        await axios
          .patch('/admins/' + params.id, {
            login_name: params.login_name,
            level_id: params.level_id,
            password: params.password,
            active: params.active
          })
      } catch (err) {
        console.log(err)
      }
    },
    async editPermissions ({ commit, state }, params) {
      try {
        if (!params.isDelete) {
          let permissionData = _.find(state.permissions, perm => perm.id === params.id)
          await axios
            .patch('/admins/permission/' + permissionData.id, {
              permission: JSON.stringify(permissionData.permission),
              authority: permissionData.authority
            })
        } else {
          await axios
            .delete('/admins/permission/' + params.id)
        }
      } catch (err) {
        console.log(err)
      }
    },
    async set ({ commit }, params) {
      try {
        let admins = await axios
          .get('/admins/?page=' + params.page +
            '&row=' + params.row +
            '&sort_by=' + params.sortBy +
            '&sort=' + params.sort +
            '&filter=' + params.filter +
            '&filter_by=' + params.filterBy)

        commit('SET_ADMINS', admins.data.list)
        commit('SET_PAGE', params.page)
        commit('SET_COUNT', admins.data.count)
        commit('SET_ROW', params.row)
        commit('SET_SORTBY', params.sortBy)
        commit('SET_SORT', params.sort)
        commit('SET_FILTER', params.filter)
        commit('SET_FILTERBY', params.filterBy)

        let permissions = await axios
          .get('/admins/permission')
        commit('SET_PERMISSIONS', permissions.data)
        let whitelist = await axios
          .get('/admins/whitelist')
        commit('SET_WHITELISTS', whitelist.data)
      } catch (err) {
        console.log(err)
      }
    }
  }
}
