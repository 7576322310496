import { common } from '@/assets/js/translations/Common'

export const kr = {
  kr: {
    login: '로그인',
    admin: '관리자',
    invalid_credentials: 'Invalid credentials.',

    ip_not_found: '로그인 실패, 접근 권한이 없습니다.',
    ip_inactive: '로그인 실패, IP가차단된 상태입니다.'
  }
}
