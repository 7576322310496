/* eslint-disable no-dupe-keys */
import { common } from '@/assets/js/translations/Common'

export const jp = {
  jp: {
    user_management: `ユーザー管理`,
    online: `オンライン`,
    confirmed: `正常`,
    banned: `中止`,
    deleted: `削除`,
    requests: `加入リクエスト`,
    testers: `テスター`,
    ip_management: `IP管理`,
    access_logs: `接続ログ`,

    agent_management: `店舗管理`,
    agent_list: `店舗リスト`,
    codes_management: `店舗コード`,
    active: `正常`,
    inactive: `中止`,

    transfers: `入/出/ポイント 案内文`,
    transfers_management: `入/出 管理`,
    cash_in: `入金リクエスト`,
    cash_out: `出金リクエスト`,
    all: `全体`,
    deposit_accounts: `入金銀行管理`,

    'cash_&_point_history': `保有金額およびポイント内訳`,
    cash_history: `保有金額 内訳`,
    'cash_in/out': `入金/出金`,
    'manual_in/out': `支給/回収`,
    conversion: `ポイント転換`,
    cash_bet: `ベッティング`,
    win: `勝ち`,
    rollback: `回転`,
    commission: `コミッション`,
    point_history: `ポイント 内訳`,
    auto_in: `自動支給`,
    coupon_history: `クーポン 内訳`,

    game_management: `ゲーム登録および管理`,
    in_play: `インプレー`,
    waiting: `発売待機`,
    bet: `ベッティング中`,
    betting: `ベッティング中`,
    done: `試合終了`,
    games_setup: `試合種目登録 管理`,
    leagues_setup: `リーグ別 登録設定`,
    game_type_setup: `種目別　詳細項目設定`,

    sports: `スポーツ`,
    release: `試合発売`,
    ongoing: `試合中`,
    event_ongoing: `進行中`,

    mini_games: `ミニゲーム`,
    game_list: `ゲーム種目`,

    bet_management: `ベッティング状況の管理`,
    bet_cancel: `ベッティング取り消し`,

    account_management: `精算 管理`,
    total_account: `売上集計`,
    bet_account: `分類別　利益集計　`,
    point: `ポイント`,
    account_commission: `コミッション集計`,
    cash_back: `キャッシュバック集計`,

    customer_management: `顧客センターおよび掲示板管理`,
    helpdesk: `顧客センター`,
    pending: `要請`,
    resolved: `完了`,
    quick_reply: `素早い返信`,

    messages: `メッセージ管理`,
    faq: `FAQs`,
    freeboards: `自由掲示板`,
    notices: `お知らせ掲示板`,
    events: `イベント掲示板`,
    completed: `完了`,

    settings: `設定`,
    game_setup: `ゲームおよび管理設定p`,
    bet_limits: `ベッティング限度`,

    odds_maker_setup: `オッズ供給サイト設定`,
    odds_setup: `基準点設定および種目別配当管理`,
    countries: `開催地管理`,

    home_page_setup: `ホームページ設定`,
    themes: `サイトのテーマ`,
    navigation: `メインメニュー`,
    home_page: `メインページ`,
    events_page: `イベントページ`,
    level_icons: `レベル アイコン`,
    domains: `ドメイン`,
    ticker: `お知らせ`,
    popups: `ポップアップ管理`,
    sounds: `お知らせ音`,
    language: `基本言語`,
    currency: `基本通貨`,
    bank_accounts: `銀行リスト`,
    deposit_accounts: `入金銀行管理`,

    'events_&_promotions': `イベントおよびプロモ設定`,
    coupon: `クーポン設定および管理`,
    points: `ポイント設定および管理`,
    promotions: `プロモ ゲーム管理`,

    manage_promotions: `プロモ ゲーム管理`,
    manage_coupon: `クーポンおよびイベント設定`,
    manage_points: `ポイント設定`,

    modify: `修正`,
    prematch: `スポーツ`,
    inplay: `インプレー`,
    teams_setup: `チーム名管理`,
    test_setup: `テスト管理`,

    user_detail_info: `ユーザー詳細情報`,
    agent_detail_info: `店舗詳細情報`,
    stamp_history: `出席内訳`,
    code_management: `コード管理`,

    // Bet Delay
    bet_delay: `ベッティング遅延設定`,
    default_settings: `基準点設定`,
    game_settings: `種目別配当設定`
  }
}
