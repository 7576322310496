import axios from 'axios'
import ObjToFormData from '@/assets/js/common/ObjToFormData.js'

export default {
  namespaced: true,
  state: {
    betLimits: {},
    betAmounts: {},

    betLimitsByLevel: {},
    duration: null,
    durationType: null,

    betRules: {},

    cancelTime: {},
    isTest: false,

    allowedGames: {}
  },

  mutations: {
    SET_BET_SETTINGS (state, data) {
      state.betAmounts = typeof data.bet_amounts === 'string' ? JSON.parse(data.bet_amounts) : data.bet_amounts
      let defaults = new Array(6).fill({"display":"0","amount":0})
      state.betAmounts = state.betAmounts ? state.betAmounts : defaults
      state.betLimits = {
        minBet: data.min_bet || 0,
        maxBet: data.max_bet || 0,
        minWin: data.min_win || 0,
        maxWin: data.max_win || 0
      }
    },

    SET_BET_SETTINGS2 (state, data) {
      state.betLimitsByLevel = typeof data.bet_limits === 'string' ? JSON.parse(data.bet_limits) : data.bet_limits
      state.durationType = data.time_type
      state.duration = data.time

      state.cancelTime.after_bet = data.ca_bet_time
      state.cancelTime.before_ongoing = data.cb_ongoing_time
    },

    SET_BET_RULES (state, data) {
      state.isTest = data.is_testuser
      state.betRules = typeof data.bet_rules === 'string' ? JSON.parse(data.bet_rules) : data.bet_rules
    },

    SET_ALLOWED_GAMES (state, data) {
      state.allowedGames = typeof data.games_test === 'string' ? JSON.parse(data.games_test) : data.games_test
    }
  },

  getters: {},

  actions: {
    async get ({ commit }, { type }) {
      let response = await axios.get(`/settings/betting-limit/${type}`)

      commit('SET_BET_SETTINGS', response.data)
      commit('SET_BET_SETTINGS2', response.data)
      commit('SET_BET_RULES', response.data)
      commit('SET_ALLOWED_GAMES', response.data)
    },

    async update ({ commit }, data) {
      return axios.patch(`/settings/betting-limit`, data)
    }
  }
}
