import axios from 'axios'

export default {
  namespaced: true,

  state: {
    depositAccounts: [],
    count: 0
  },

  getters: {},

  mutations: {
    SET_ACCOUNTS (state, depositAccounts) {
      state.depositAccounts = depositAccounts
    },

    SET_COUNT (state, depositCount) {
      state.count = depositCount[0].total
    },

    UPDATE_ACCOUNT (state, accounts) {
      state.depositAccounts.forEach(data => {
        accounts.forEach(account => {
          if (data.id === account.id) {
            data.name = account.name
            data.currency_id = account.currency_id
            data.bank_id = account.bank_id
            data.bank_account_name = account.bank_account_name
            data.bank_number = account.bank_number
            data.active = account.active === 1 ? 'true' : 'false'
          }
        })
      })
    },

    DELETE_ACCOUNT (state, ids) {
      state.depositAccounts.forEach(data => {
        ids.forEach((v, k) => {
          if (data.id === v) {
            data.deleted_at = new Date()
          }
        })
      })
    },

    RESTORE_ACCOUNT (state, accounts) {
      state.depositAccounts.forEach(data => {
        accounts.forEach(account => {
          if (data.id === account.id) {
            data.deleted_at = null
          }
        })
      })
    }
  },

  actions: {
    async get ({ commit }, { status, type, params }) {
      const accounts = await axios
        .get(`/deposits/${status}/${type}`, { params })

      const count = await axios
        .get(`/deposits/${status}/${type}/count`, { params })

      commit('SET_ACCOUNTS', accounts.data)
      commit('SET_COUNT', count.data)
    },

    async create ({ commit }, account) {
      const result = await axios
        .post('/deposits', account)

      return result
    },

    async update ({ commit }, accounts) {
      let result
      for (let a = 0; a < accounts.length; a++) {
        result = await axios
          .patch(`/deposits/${accounts[a].id}`, accounts[a])
      }
      return result
    },

    async delete ({ commit }, ids) {
      let result
      for (let a = 0; a < ids.length; a++) {
        result = await axios.delete(`/deposits/${ids[a]}`)
      }
      return result
    },

    async recover ({ commit }, accounts) {
      let result
      for (let a = 0; a < accounts.length; a++) {
        result = await axios.patch(`/deposits/${accounts[a].id}`, accounts[a].data)
      }
      return result
    },

    async archive ({ commit }) {
      const result = await axios.get('/deposits/archive')

      commit('SET_ACCOUNTS', result.data)
    }
  }
}
