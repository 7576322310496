import axios from 'axios'

export default {
  namespaced: true,

  state: {
    matches: [],
    types: [],
    match: {},
    count: 0,
    errs: []
  },

  getters: {
    all (state) {
      return state.matches
    },

    match (state) {
      return state.match
    },

    types (state) {
      return state.types
    },

    keyedTypes (state) {
      return _.keyBy(state.types, 'id')
    },

    count (state) {
      return state.count
    },

    errs (state) {
      return state.errs
    }
  },

  mutations: {
    SET_MATCHES (state, matches) {
      state.matches = matches
    },

    SET_ERRORS (state, data) {
      state.errs = data
    },

    UPDATE_MATCHES (state, data) {
      console.log("UPDATE_MATCHES")
      console.log(data)
      if (data.type === 'bet') {
        state.matches.forEach((e) => {
          let match = data.data.find((d) => {
            return parseInt(d.match_id) === parseInt(e.id)
          })

          if (match) {
            e.matches.bet_amount += Math.abs(match.bet_amount)
          }
        })
      }
    },

    SET_MATCH (state, match) {
      match.score = typeof match.score === 'string' ? JSON.parse(match.score) : match.score
      state.match = match
    },

    UPDATE_MATCH (state, match) {
      let index = state.matches
        .findIndex((row) => { return row.id === match.id })
      if (index !== -1) {
        state.matches[index] = match
      }
    },

    UPDATE_MATCHES (state, { id, active }) {
      for (let x = 0; x < state.matches.length; x++) {
        if (id && id.indexOf(state.matches[x].id) !== -1) {
          state.matches[x].active = parseInt(active) === 1
        }
      }
    },

    DELETE_MATCH (state, id) {
      let index = state.matches
        .findIndex((row) => { return row.id === id })

      if (index !== -1) {
        state.matches.splice(index, 1)
      }
    },

    SET_COUNT (state, count) {
      state.count = count
    },

    SET_TYPES (state, types) {
      state.types = types
    }
  },

  actions: {
    async get ({ commit }, { status, params, mode, game }) {
      let matches = await axios.get(`/matches/status/${status}/mode/${mode}/game/${game}`, { params })
      commit('SET_MATCHES', matches.data.list)
      commit('SET_COUNT', matches.data.count)
    },

    async getMini ({ commit }, { status, params, mode, game }) {
      let matches = await axios.get(`/matches/mini/status/${status}/mode/${mode}/game/${game}`, { params })
      commit('SET_MATCHES', matches.data.list)
      commit('SET_COUNT', matches.data.count)
    },

    async getInplay ({ commit }, { status, params, mode, game}) {
      let matches = await axios.get(`/matches/inplay/status/${status}/mode/${mode}/game/${game}`, { params })
      commit('SET_MATCHES', matches.data.list)
      commit('SET_COUNT', matches.data.count)
    },

    async find ({ commit }, id) {
      commit('SET_MATCH', {})
      let match = await axios.get(`/matches/${id}`)
      await commit('SET_MATCH', match.data)
    },

    async findInplay ({ commit }, id) {
      commit('SET_MATCH', {})
      let match = await axios.get(`/matches/inplay/${id}`)
      await commit('SET_MATCH', match.data)
    },

    async create ({ commit }, matches) {
      await axios.post('/matches', matches)
    },

    async update ({ commit }, match ) {
      if(!match.id){
        match = match.match
      }
      await axios.patch(`/matches/${match.id}`,
          Object.assign({}, match, { id: undefined })
        )
      commit('UPDATE_MATCH', match)
    },

    async bulkUpdate({ commit, state }, { params }) {
      try {
        if(params.game === 'inplay'){
            await axios.patch(`/matches/inplay/copy`, params)
        }else{
            await axios.patch(`/matches`, params)
        }

        commit('UPDATE_MATCHES', { id: params.ids, active: params.mode })
      } catch (err) {
        console.log(err, '------')
      }
    },
    async bulkUpdateInplay({ commit, state }, { params }) {
      try {
        await axios.patch(`/matches/inplay/copy`, params)

        commit('UPDATE_MATCHES', { id: params.ids, active: params.mode })
      } catch (err) {
        console.log(err, '------')
      }
    },
    async updateMatchInplay ({ commit }, { match, manual }) {
      if (manual) {
        await axios
          .patch(`/matches/inplay/${match.id}/disable-enable/odds/update`,
            Object.assign({}, match, { id: undefined })
          )
      } else {
        await axios
          .patch(`/matches/inplay/${match.id}`,
            Object.assign({}, match, { id: undefined })
          )
      }

      commit('UPDATE_MATCH', match)
    },

    async delete ({ commit }, id,game) {
      if(game === 'inplay'){
        await axios.delete(`/matches/inplay/${id}`)
      }else{
        await axios.delete(`/matches/${id}`)
      }
      commit('DELETE_MATCH', id)
    },

    async deleteInplay ({ commit }, id) {
      await axios.delete(`/matches/inplay/${id}`)
      commit('DELETE_MATCH', id)
    },

    async getCount ({ commit }, { status, params, mode, game }) {
    //  let count = await axios.get(`/matches/status/${status}/mode/${mode}/game/${game}/count/`, { params })
    //  commit('SET_COUNT', count.data)
    },

    async getCountInplay ({ commit }, { status, params, mode, game }) {
    //  let count = await axios.get(`/matches/inplay/status/${status}/mode/${mode}/game/${game}/count/`, { params })
    //  commit('SET_COUNT', count.data)
    },

    async getTypes ({ commit }, { id }) {
      let { data } = await axios.get(`/matches/types/${id ? id : ''}`)
      commit('SET_TYPES', data)
    },

    async getTypesInplay ({ commit }, { id }) {
      let { data } = await axios.get(`/matches/inplay/types/${id ? id : ''}`)
      commit('SET_TYPES', data)
    },

    async rollback ({ commit }, { id, period, types } ) {
      if (period) {
        return axios.patch(`/matches/rollback/${id}/${period}?types[]=${types}` )
      }
      await axios.patch(`/matches/rollback/${id}`)
    },

    async result ({ commit }, { id, period, types }) {
      return axios.get(`/matches/result/${id}/${period}?types[]=${types}` )
    },

    /** bulk **/
    async bulkResult ({ commit }, { id }) {
      let response = await axios.post(`/matches/bulkresult/${id}`, { params: { id } })

      if (response.data.length) {
        commit('SET_ERRORS', response.data)
      }
    },

    async bulkResultInplay ({ commit }, { id }) {
      let response = await axios.post(`/matches/inplay/bulkresult/${id}`, { params: { id } })

      if (response.data.length) {
        commit('SET_ERRORS', response.data)
      }
    },

    async bulkResultMini ({ commit, state }, { id, lid, data }) {
      await axios.post(`/matches/mini/bulkresult/league/${lid}/match/${id}`, { data })
    },

    async bulkUpdateMini ({ commit, state }, { id, data }) {
      await axios.patch(`/matches/mini/${id}`, { data })
    },

    async bulkVoidCancel ({ commit }, { id }) {
      return axios.post(`/matches/bulkvoidcancel/${id}`, { params: { id } })
    },

    async bulkVoidCancelInplay ({ commit }, { id }) {
      return axios.post(`/matches/inplay/bulkvoidcancel/${id}`, { params: { id } })
    },

    async bulkPayout ({ commit }, { id }) {
      return axios.post(`/matches/bulkpayout/${id}`, { params: { id } })
    },

    async bulkPayoutInplay ({ commit }, { id }) {
      return axios.post(`/matches/inplay/bulkpayout/${id}`, { params: { id } })
    },

    async bulkPayoutMini ({ commit }, { id, lid, rounds }) {
      return axios.post(`/matches/mini/bulkpayout/league/${lid}/match/${id}`, { rounds })
    },

    async bulkRollback({ commit }, { id }) {
      return axios.post(`/matches/bulkrollback/${id}`, { params: { id } })
    },

    async bulkRollbackInplay ({ commit }, { id }) {
      return axios.post(`/matches/inplay/bulkrollback/${id}`, { params: { id } })
    },

    async bulkRollbackMini ({ commit }, { id, lid, rounds }) {
      return axios.post(`/matches/mini/bulkrollback/${id}/league/${lid}`, { rounds })
    },

    async bulkAction({ commit }, { id, type }) {
      return axios.post(`/matches/bulkaction/${type}/${id}`, { params: { id } })
    },

    async bulkActionInplay({ commit }, { id, type }) {
      return axios.post(`/matches/inplay/bulkaction/${type}/${id}`, { params: { id } })
    },

    async voidMatchGame ({ commit }, { id }) {
      return axios.patch(`/match-games/void/${id}`, { params: { id } })
    },

    async voidMatchGameInplay ({ commit }, { id }) {
      return axios.patch(`/match-games/inplay/void/${id}`, { params: { id } })
    },

    async voidMatchMini ({ commit }, { id,lid,data }) {
     return axios.patch(`/match-games/mini/void/${id}/league/${lid}`, { params: { data } })
     //return axios.patch(`/matches/mini/bulkvoidcancel/${id}`, { params: { data } })
    },

    async newMatchGame ({commit, dispatch}, obj) {
      let response = await axios.post('/matches/new', {
        game_name_id: obj.game.id,
        venue_id: obj.venue.id,
        league_id: obj.league.id,
        play_date: obj.playDate,
        away_team: obj.awayTeam,
        home_team: obj.homeTeam,
        match_types: obj.matchTypes,
      })

      return response
    }
  }
}
