import axios from 'axios'
import ObjToFormData from '@/assets/js/common/ObjToFormData.js'

export default {
  namespaced: true,
  state: {
    list: [],
    count: 0
  },

  mutations: {
    SET_LIST (state, data) {
      state.list =  data
    },

    SET_COUNT (state, data) {
      state.count = data[0].total
    }
  },

  getters: {},

  actions: {
    async post ({ commit }, data) {
      let result = await axios
        .post('/settings/popup', ObjToFormData(data))

      return result
    },

    async get ({ commit }, { status, params }) {
      let result = await axios
        .get(`/settings/popup/${status}`, { params })

      let count = await axios
        .get(`/settings/popup/${status}/count`, { params })

      commit('SET_LIST', result.data)
      commit('SET_COUNT', count.data)
    },

    async patch ({ commit }, { id, data }) {
      let result = await axios
        .patch(`/settings/popup/${id}`, ObjToFormData(data))

      return result
    },

    async delete ({ commit }, ids) {
      let result
      for (let a = 0; a < ids.length; a++) {
        result = await axios.delete(`/settings/popup/${ids[a]}`)
      }
      return result
    },

    async recover ({ commit }, ids) {
      let result
      for (let a = 0; a < ids.length; a++) {
        result = await axios
          .patch(`/settings/popup/${ids[a]}/restore`)
      }
      return result
    },

    async patchStatus ({ commit }, { id, data }) {
      let result = await axios
        .patch(`/settings/popup/${id}/status`, data)

      return result
    }
  }
}
