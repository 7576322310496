import { common } from '@/assets/js/translations/Common'

export const kr = {
  kr: {
    from: `에서`,
    to: `까지`,

    select_cash: `구분`,
    select_status: `상태`,
    cash_out: `출금`,
    cash_in: `입금`,
    bet: `배팅`,
    point: `포인트`,
    rollback: `롤백`,
    win: `당첨`,
    commission: `커미션`,
    manual_in: `지급`,
    manual_out: `회수`,

    sum: '합계',

    confirmed: `정상`,
    pending: `요청`,
    rejected: `거부`,
    waiting: `대기`,
    deleted: `삭제`,

    ip: `IP`,

    no: `번호`,
    request_date: `요청일자`,
    before_transfer: `이전금액`,
    amount: `금액`,
    new_balance: `변경금액`,
    type: `구분`,
    status: `상태`,
    confirm_date: `승인일자`,
    user_ip: `유저 IP`,
    admin: `관리자`,
    match_rollback: `결과 복원`,
    true: `확인`,
    created_date: `출석일자`,
    login_id: `아이디`,
    login_name: `닉네임`
  }
}
