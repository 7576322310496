import Vue from 'vue'

//= lodash
import _ from 'lodash'
import VueLodash from 'vue-lodash'

import { en } from '@/assets/js/translations/users-point-history/En'
import { kr } from '@/assets/js/translations/users-point-history/Kr'
import { jp } from '@/assets/js/translations/users-point-history/Jp'

Vue.use(VueLodash, _)

export const translations = _.merge(en, kr, jp)
