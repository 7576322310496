<template>
  <section class="search-bar">
    <form
      class="search-bar__inner"
      autocomplete="off"
      @submit.prevent="trigger"
    >
      <button
        type="button"
        class="btn--refresh"
        @click="refresh"
      >
        <i class="ico--refresh"></i>
      </button>

      <template v-if="inputSuggestion.length">
        <div :class="`search-bar__item search-bar__item--filterby-dp ${checkRoutes ? 'sports' : ''}`">
          <select2
            ref="input-suggest-select"
            v-model="searchKeyword"
            @input="changeKeyword($event, 'filter')"
          >
            <option2
              v-for="col in inputSuggestion"
              :key="col.value"
              :value="col.value"
              :selected="col.value === ($route.query.filter_by || '')"
            >
              <template v-if="translations">
                {{ translate(translations, col.display) }}
              </template>

              <template v-else>
                {{ col.display }}
              </template>
            </option2>
          </select2>
        </div>

        <div class="search-bar__item search-bar__item--input-suggestion">
          <input-suggestion
            v-if="suggestionType === 'user'"
            ref="input-suggest"
            v-model="searchValue"
            @search="searchSpecific($event, 'filter')"
            @input="updateSearch"
          >
            <span slot="selected">
              {{ searchLbl }}
            </span>

            <suggestion
              v-for="data in suggestionList"
              slot="suggestion"
              :key="data.id"
              :value="data.id"
            >
              {{ data.login_name }}
            </suggestion>
          </input-suggestion>

          <input-suggestion
            v-else
            ref="input-suggest"
            v-model="searchValue"
            @search="searchSpecific($event, 'filter')"
            @input="updateSearch"
          >
            <span slot="selected">
              <template v-if="searchValue">
                <template v-if="language.indexOf('kr') > -1">
                  <template v-if="searchValue.kor">
                    <span v-html="searchValue.kor">
                    </span>
                  </template>

                  <template v-else>
                    <span v-html="searchValue.name">
                    </span>
                  </template>
                </template>

                <template v-else>
                  <span v-html="searchValue.name">
                  </span>
                </template>
              </template>

              <template v-else>
              </template>
              <!-- {{ searchValue ? (language.indexOf('kr') > -1 ? (searchValue.kor ? searchValue.kor : searchValue.name) : searchValue.name) : '' }} ?????? -->
            </span>

            <suggestion
              v-for="data in suggestionList"
              slot="suggestion"
              :key="data.id"
              :value="data"
            >
              <template v-if="language.indexOf('kr') > -1">
                <template v-if="data.kor">
                  <span v-html="data.kor">
                  </span>
                </template>

                <template v-else>
                  <span v-html="data.name">
                  </span>
                </template>
              </template>

              <template v-else>
                <span v-html="data.name">
                </span>
              </template>
              <!-- {{ language.indexOf('kr') > -1 ? (data.kor ? data.kor : data.name) : data.name }} -->
            </suggestion>
          </input-suggestion>
        </div>
      </template>

      <!-- leagues setup && games setup -->
      <template v-if="checkMultiFilter">
        <div
          v-if="['sports', 'inplay'].includes($route.params.type)"
          class="search-bar__item search-bar__item--filterby-dp-input"
        >
          <div class="input-wrap">
            <input
              ref="countryQ"
              v-model="keywords.country"
              type="text"
              :placeholder="translate(translations, 'country')"
            >
          </div>
        </div>

        <div
          v-if="['sports', 'inplay'].includes($route.params.type)"
          class="search-bar__item search-bar__item--filterby-dp-input"
        >
          <div class="input-wrap">
            <input
              ref="leagueQ"
              v-model="keywords.league"
              type="text"
              :placeholder="translate(translations, 'leagues')"
            >
          </div>
        </div>

        <div
          v-if="['teams-page'].includes($route.name)"
          class="search-bar__item search-bar__item--filterby-dp-input"
        >
          <div class="input-wrap">
            <input
              ref="teamQ"
              v-model="keywords.team"
              type="text"
              :placeholder="translate(translations, 'team')"
            >
          </div>
        </div>
      </template>

      <!-- bet history update -->
      <template v-if="betHistory">
        <div
          v-if="['sports', 'inplay', 'mini'].includes($route.params.game)"
          class="search-bar__item search-bar__item--filterby-dp-input"
        >
          <div class="input-wrap">
            <input
              ref="gameQ"
              v-model="keywords.game"
              type="text"
              :placeholder="translate(translations, 'game')"
            >
          </div>
        </div>

        <div
          v-if="['sports', 'inplay'].includes($route.params.game)"
          class="search-bar__item search-bar__item--filterby-dp-input"
        >
          <div class="input-wrap">
            <input
              ref="countryQ"
              v-model="keywords.country"
              type="text"
              :placeholder="translate(translations, 'country')"
            >
          </div>
        </div>

        <div
          v-if="['sports', 'inplay'].includes($route.params.game)"
          class="search-bar__item search-bar__item--filterby-dp-input"
        >
          <div class="input-wrap">
            <input
              ref="leagueQ"
              v-model="keywords.league"
              type="text"
              :placeholder="translate(translations, 'leagues')"
            >
          </div>
        </div>

        <div
          v-if="['mini'].includes($route.params.game)"
          class="search-bar__item search-bar__item--filterby-dp-input"
        >
          <div class="input-wrap">
            <input
              ref="gameQ"
              v-model="keywords.round"
              type="text"
              :placeholder="translate(translations, 'round')"
            >
          </div>
        </div>
      </template>

      <template v-if="filterBy.length">
        <div class="search-bar__item search-bar__item--filterby-dp">
          <select2
            id="filter-by"
            ref="filter"
            v-model="temp.filter_by"
            class="select-list--filter"
            @input="checkChangeKeywords"
          >
            <option2
              v-for="col in filterBy"
              :key="col.value"
              :value="col.value"
              :selected="col.value === ($route.query.filter_by || '')"
            >
              <template v-if="translations">
                {{ translate(translations, col.display) }}
              </template>

              <template v-else>
                {{ col.display }}
              </template>
            </option2>
          </select2>
        </div>

        <div class="search-bar__item search-bar__item--filterby-dp-input">
          <div class="input-wrap">
            <input
              id="q"
              v-model="temp.q"
              type="text"
            >
          </div>
        </div>
      </template>

      <template v-if="filterByOtherCol.length && !['mini'].includes($route.params.game)">
        <div class="search-bar__item search-bar__item--filterby-dp">
          <select2
            id="other-filter"
            ref="otherFilter"
            v-model="temp.otherFilter"
            class="select-list--filter"
          >
            <option2
              v-for="col in filterByOtherCol"
              :key="col.value"
              :value="col.value"
              :selected="col.value === ($route.query.otherFilter || '')"
            >
              <template v-if="translations">
                {{ translate(translations, col.display) }}
              </template>

              <template v-else>
                {{ col.display }}
              </template>
            </option2>
          </select2>
        </div>

        <div class="search-bar__item search-bar__item--filterby-dp-input">
          <div class="input-wrap">
            <input
              id="otherQ"
              v-model="temp.otherQ"
              type="text"
            >
          </div>
        </div>
      </template>

      <template v-if="dateBy.length">
        <div
          v-if="!hideDateDropdown"
          :class="`search-bar__item search-bar__item--dateby ${checkRoutes ? 'sports' : ''}`"
        >
          <select2
            id="filter-by"
            ref="filter-by-date"
            v-model="temp.date_by"
            class="select-list--filter"
          >
            <option2
              v-for="col in dateBy"
              :key="col.value"
              :value="col.value"
              :selected="col.value === ($route.query.date_by || '')"
            >
              <template v-if="translations">
                {{ translate(translations, col.display) }}
              </template>

              <template v-else>
                {{ col.display }}
              </template>
            </option2>
          </select2>
        </div>

        <div class="search-bar__item -dateby-input">
          <date-time-picker
            ref="datetimepickerFrom"
            v-model="temp.date_from"
            :placeholder="dateRange ? translate(translations, 'from') : translate(translations, 'date')"
            :type="dateType"
            :range="dateRange"
            :set-max="temp.date_to"
            mode="from"
          />

          <date-time-picker
            v-if="dateRange"
            ref="datetimepickerTo"
            v-model="temp.date_to"
            :placeholder="translate(translations, 'to')"
            :type="dateType"
            :range="dateRange"
            :set-min="temp.date_from"
            mode="to"
          />
        </div>
      </template>

      <button
        class="btn--search"
        type="submit"
      >
        <i class="ico--search"></i>
      </button>

      <button
        class="btn--clear"
        @click="clear"
      >
        <i class="ico--clear"></i>
      </button>
    </form>
  </section>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

//= components
import DateTimePicker from '@/components/base/DateTimePicker'
import { vueLocalStorage } from '@/assets/js/mixins/base/VueLocalStorage'

//= translation
import { translate } from '@/assets/js/translations/Translate'

export default {
  name: 'Search',

  components: {
    DateTimePicker
  },

  mixins: [
    translate
  ],

  props: {
    inputSuggestion: {
      type: Array,
      required: false,
      default: () => {
        return []
      }
    },

    filterByOtherCol: {
      type: Array,
      required: false,
      default: () => {
        return []
      }
    },

    hideDateDropdown: {
      type: Boolean,
      default: false
    },

    suggestionType: {
      type: String,
      required: false,
      default: 'not-user'
    },

    suggestionDefaultKey: {
      type: String,
      required: false,
      default: ''
    },

    otherSuggestionDefaultKey: {
      type: String,
      required: false,
      default: ''
    },

    suggestionList: {
      type: Array,
      required: false,
      default: () => []
    },

    filterBy: {
      type: Array,
      required: false,
      default: () => []
    },

    dateBy: {
      type: Array,
      required: false,
      default: () => []
    },

    dateRange: {
      type: Boolean,
      default: true
    },

    dateType: {
      type: String,
      default: 'datetime'
    },

    disabled: {
      type: Boolean,
      required: false
    },

    rows: {
      type: [Number, String],
      required: false,
      default: ''
    },

    translations: {
      type: [Array, Object, String],
      default: () => []
    }
  },

  data () {
    const temp = this.generate(this.$route.query)

    if (this.dateBy.length) {
      temp.date_by = this.dateBy[0].value
    }

    if (this.filterBy.length) {
      temp.filter_by = this.filterBy[0].value
    }

    if (this.filterByOtherCol.length) {
      temp.otherFilter = this.filterByOtherCol[0].value
    }

    return {
      temp,
      searchKeyword: this.defaultSuggestionKey(),
      searchValue: '',
      searchLbl: '',

      otherSearchValue: '',
      otherSearchKeyword: this.ohterDefaultSuggestionKey(),

      keywords: {
        country: null,
        league: null,
        team: null,
        game: null,
        round: null
      }
    }
  },

  computed: {
    ...mapState('config', ['config']),

    language () {
      return this.config.lang || vueLocalStorage.getItem('adminLang')
    },

    checkMultiFilter () { // this is for leagues setup && teamp setup
      return ['teams-page', 'leagues-page'].includes(this.$route.name)
    },

    checkRoutes () {
      return ['matches-page', 'goal-serve'].includes(this.$route.name)
    },

    betHistory () { // this is for bet history update
      return ['bet-history-update-type'].includes(this.$route.name)
    }
  },

  watch: {
    searchValue (e) {
      if (this.suggestionType === 'user') {
        if (!e || !this.suggestionList.length) {
          return
        }

        this.searchLbl = (this.suggestionList.find(u => {
          return u.id === e
        })).login_name
      }
    },

    searchKeyword (keywordValue) {
      this.$emit('filter', keywordValue)
    }
  },

  created () {
    if (this.suggestionType === 'not-user' && this.$route.query.q) {
      this.searchValue = {
        name: this.$route.query.q,
        kor: this.$route.query.q
      }
    }
  },

  methods: {
    defaultSuggestionKey () {
      return this.suggestionDefaultKey || ''
    },

    ohterDefaultSuggestionKey () {
      return this.otherSuggestionDefaultKey || ''
    },

    clearData () {
      if (this.dateBy.length > 0) {
        this.$refs.datetimepickerFrom.reset = true
        this.$refs.datetimepickerTo.reset = true
      }

      if (this.$refs.datetimepickerFrom) {
        this.$refs.datetimepickerFrom.resetData()
      }

      if (this.$refs.datetimepickerTo) {
        this.$refs.datetimepickerTo.resetData()
      }

      if (this.$refs['input-suggest']) {
        this.searchKeyword = this.defaultSuggestionKey()
        this.$refs['input-suggest'].clear()
        this.$refs['input-suggest'].search = null
        this.$refs['input-suggest-select'].reset = true
      }

      if (this.$refs['input-suggest-other']) {
        this.otherSearchKeyword = this.ohterDefaultSuggestionKey()
        this.$refs['input-suggest-other'].clear()
        this.$refs['input-suggest-other'].search = null
        this.$refs['input-suggest-select-other'].reset = true
      }

      if (this.$refs.filter) {
        this.$refs.filter.reset = true
      }

      if (this.$refs['filter-by-date']) {
        this.$refs['filter-by-date'].reset = true
      }
    },

    clear () {
      if (this.disabled) {
        return
      }

      this.temp = {
        rows: this.rows || 50,
        page: 1,
        filter_by: '',
        q: '',
        otherFilter: '',
        otherQ: '',
        date_by: '',
        sort_by: 'created_at',
        sort: 'desc',
        date_from: '',
        date_to: ''
      }

      this.keywords.country = null
      this.keywords.league = null
      this.keywords.team = null
      this.keywords.game = null
      this.keywords.round = null

      this.clearData()

      this.$emit('clear', this.generate(this.temp))
      this.$emit('trigger', this.generate(this.temp))
    },

    trigger () {
      if (this.disabled) return
      const q = JSON.parse(JSON.stringify(this.$route.query))

      this.validateKeywords()
      this.$emit('trigger', this.generate(Object.assign(q, this.generate(this.temp))))
    },

    refresh () {
      if (this.disabled) {
        return
      }

      this.$emit('refresh')
    },

    validateKeywords () {
      this.$emit('payload', { ...this.keywords })
    },

    checkChangeKeywords (keyword) {
      this.$emit('filter', this.temp.filter_by)
    },

    changeKeyword (data, type) {
      this.$emit('changeKeyword', { data, type })
    },

    updateSearch (data) {
      this.searchValue = data
      this.temp.filter_by = this.searchKeyword

      if (this.suggestionType === 'user') {
        this.temp.q = data || null
      } else {
        this.temp.q = data ? (this.language.indexOf('kr') > -1 ? data.kor : data.name) : ''
      }
    },

    searchSpecific (q, type) {
      if (type === 'filter') {
        const data = {
          filter_by: this.searchKeyword,
          q,
          type: 'filter'
        }

        this.$emit('searchSpecific', data)
        this.temp.filter_by = this.searchKeyword
        this.temp.q = q
      }

      if (type === 'otherFilter') {
        const data = {
          otherFilter: this.otherSearchKeyword,
          otherQ: q,
          type: 'otherFilter'
        }

        this.$emit('searchSpecific', data)
        this.temp.otherFilter = this.otherSearchKeyword
        this.temp.otherQ = q
      }
    }
  }
}
</script>
