import axios from 'axios'
import _ from 'lodash'

export default {
  namespaced: true,

  state: {
    settings: []
  },

  getters: {
    grouped (state) {
      return state.settings
    },
  },

  mutations: {
    SET_SETTINGS (state, settings) {
      if (settings instanceof Array) {
        state.settings = settings
      } else {
        state.settings.push(settings)
      }
    },

    UPDATE_SETTINGS (state, params) {
      let index = state.settings.findIndex((row) => row.id === params.id)
      state.settings[index] = params
    }
  },

  actions: {
    async get ({ commit }, params) {
      let { data } = await axios.get(`/settings/goalserverealgame`, {params})
      commit('SET_SETTINGS', data)
    },

    async create ({ commit }, params) {
      let { data } = await axios.post('/settings/goalserverealgame', params)
    },

    async update({ commit }, params) {
      await axios.patch(`/settings/goalserverealgame/${params.id}`, params)
    },

    async archive({ commit }, params) {
      await axios.patch(`/settings/goalserverealgame/archive/${params.id}`, params)
    }
  }
}