/**
@Description:

import siteDefaults from '@/assets/js/mixins/base/SiteDefaults'

mixins: [
  siteDefaults
]
**/

const siteDefaults = {
  data () {
    return {
      clickConfig: {
        count: 0,
        type: 'click',
        delay: 250,
        timer: null
      }
    }
  },

  methods: {
    clickListener () {
      this.clickConfig.count++

      if (this.clickConfig.count === 1) {
        this.clickConfig.timer = setTimeout(() => {
          this.clickConfig.count = 0
          this.clickConfig.type = 'click'
        }, this.clickConfig.delay)
      } else if (this.clickConfig.count === 2) {
        clearTimeout(this.clickConfig.timer)
        this.clickConfig.count = 0
        this.clickConfig.type = 'dbclick'
      }

      setTimeout(() => {
        this.clickConfig.type = 'click'
      }, 280)
    },

    openUser (id, subtype, type) {
      this.clickListener()

      if (!id) {
        return
      }

      if (this.siteName === 'military' && this.clickConfig.type === 'click') {
        return
      }

      window.open(
        `${this.appUrl}/user/${id}/${subtype}`,
        '',
        `width=${1500},height=${window.innerHeight}`
      )
    },

    serialize (obj) {
      const str = []
      for (const p in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, p)) {
          str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]))
        }
      }
      return str.join('&')
    },

    openNewWindow (route, query) {
      this.clickListener()

      if (this.siteName === 'military' && this.clickConfig.type === 'click') {
        return
      }

      window.open(
        `${this.appUrl}${route}?${this.serialize(query)}`,
        '',
        `width=${1500},height=${window.innerHeight}`
      )
    },

    openAgentPopup (id, subtype) {
      this.clickListener()

      if (this.siteName === 'military' && this.clickConfig.type === 'click') {
        return
      }

      window.open(
        `${this.appUrl}/agent/${id}/${subtype}`,
        '',
        `width=${1500},height=${window.innerHeight}`
      )
    },

    translateData (payload, en, kr, jp) {
      const getKey = (key, obj) => obj[key] || obj.default
      return getKey(this.lang, {
        en: payload[en],
        kr: payload[kr],
        jp: payload[jp],
        default: payload[en]
      })
    },

    getTransDetails (type, id) {
      this.clickListener()

      if (this.siteName === 'military' && this.clickConfig.type === 'click') {
        return
      }

      window.open(
        `${this.appUrl}/transfers/${type}/user/${id}`,
        '',
        `width=${1500},height=${window.innerHeight}`
      )
    }
  }
}

export default siteDefaults
