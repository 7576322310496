<template>
  <div class="wrap__contents user-point-history">
    <div class="toolbar">
      <div class="toolbar__items select">
        <select2 v-model="selectedType">
          <option2
            value="all"
            :selected="selectedType === 'all'"
          >
            {{ translate(translations, 'all') }}
          </option2>

          <option2
            v-for="type in types"
            :value="type.name"
            :key="type.id"
            :selected="selectedType === type.id"
          >
            {{ translate(translations, type.name.replace(/[-]/g, " ")) }}

          </option2>
        </select2>
      </div>

      <div class="toolbar__items search">
        <search
          :translations="translations"
          :filterBy="filterBy"
          :dateBy="dateBy"
          @trigger="trigger"
          @refresh="search"
          @clear="search"
        >
        </search>
      </div>
    </div>

    <div class="wrap__inner">
      <check-data-list
        v-if="isDataLoading || isEmpty"
        :isLoading="isDataLoading"
        :isEmpty="isEmpty"
      >
      </check-data-list>

      <tbl
        ref="tbl"
        :class="{'data-loading': showDataLoading}"
        :dataLoading="isDataLoading"
        v-else
      >
        <template slot="head">
          <tbl-td
            name="no"
            :sort="true"
            @click.native="sort('id')"
          >
            {{ translate(translations, 'no') }}
          </tbl-td>

          <tbl-td
            name="created-date"
            :sort="true"
            @click.native="sort('created_at')"
          >
            {{ translate(translations, 'created date') }}
          </tbl-td>

          <tbl-td
            name="old-point"
            :sort="true"
            @click.native="sort('old_point')"
          >
            {{ translate(translations, 'old point') }}
          </tbl-td>

          <tbl-td
            name="amount"
            :sort="true"
            @click.native="sort('amount')"
          >
            {{ translate(translations, 'point') }}
          </tbl-td>

          <tbl-td
            name="new-point"
            :sort="true"
            @click.native="sort('amount')"
          >
            {{ translate(translations, 'new point') }}
          </tbl-td>

          <tbl-td
            name="division"
            :sort="true"
            @click.native="sort('type_id')"
          >
            {{ translate(translations, 'type') }}
          </tbl-td>

          <tbl-td
            name="result"
            :sort="true"
            @click.native="sort('status')"
          >
            {{ translate(translations, 'status') }}
          </tbl-td>

          <tbl-td
            name="memo"
            :sort="true"
            @click.native="sort('memo')"
          >
            {{ translate(translations, 'memo') }}
          </tbl-td>

        </template>

        <template slot="body">
          <tbl-row
            v-for="(item, x) in pointList"
            :key="item.id"
          >
            <tbl-td name="no">
              {{ generateNumber(x, params.page, params.rows, params.sort, count || 0) }}
            </tbl-td>

            <tbl-td name="created-date">
              {{ item.created_at | date }}
            </tbl-td>

            <tbl-td name="old-point">
              {{ Math.abs(item.old_point) | FormatAmount('whole')}}
            </tbl-td>

            <tbl-td name="amount">
              {{ Math.abs(item.amount) | FormatAmount('whole')}}
            </tbl-td>

            <tbl-td name="new-point">
              {{ Math.abs(item.new_point) | FormatAmount('whole') }}
            </tbl-td>

            <tbl-td name="division">
              {{ translate(translations, item.type.replace(/[-]/g, " ")) }}
            </tbl-td>

            <tbl-td name="result">
              {{ translate(translations, item.status , 'Status') }}
            </tbl-td>

            <tbl-td
              name="memo"
              :isHtml="true"
              :logs="item.memo"
            >
            </tbl-td>

          </tbl-row>
        </template>

        <template slot="footer">
          <pagination
            :total="count"
            v-model="params.page"
            :rows="params.rows"
            @page="trigger"
          >
          </pagination>
        </template>
      </tbl>
    </div>
  </div>
</template>

<script>
import { points } from '@/socket'
import { mapState, mapActions } from 'vuex'

//= mixins
import { getListStatus } from '@/assets/js/mixins/common/GetListStatus'

//= components
import Pagination from '@/components/base/Pagination'
import Search from '@/components/base/search/Search'
import CheckDataList from '@/components/base/CheckDataList'

//= filters
import FormatAmount from '@/assets/js/filters/FormatAmount'
import RemoveNonAlphaNum from '@/assets/js/filters/RemoveNonAlphaNum'
import Status from '@/assets/js/filters/Status'

//= translation
import { translations } from '@/assets/js/translations/UsersPointHistory'

export default {
  name: 'UserPointHistory',

  components: {
    Search,
    Pagination,
    CheckDataList
  },

  mixins: [
    getListStatus
  ],

  filters: {
    FormatAmount,
    RemoveNonAlphaNum,
    Status
  },

  data () {
    let params = Object.assign(
      {
        rows: 50,
        page: 1,
        filter_by: '',
        q: '',
        sort_by: 'id',
        sort: 'desc',
        date_from: '',
        date_to: ''
      },
      this.$route.query
    )

    return {
      translations,
      params,

      isDataLoading: true,
      isEmpty: false,
      showDataLoading: false,

      filterBy: [
      /*
        {
          value: 'amount',
          display: 'amount'
        },
        {
          value: 'ip',
          display: 'admin ip'
        },
        {
          value: 'admins.login_id',
          display: 'admin'
        },
      */
        {
          value: 'memo',
          display: 'memo'
        }
      ],

      dateBy: [
        {
          value: 'created_at',
          display: 'created date'
        }
      ]
    }
  },

  watch: {
    $route ($router) {
      if (!this.showDataLoading) {
        this.showDataLoading = true
      }
      this.params = this.generate($router.query)
      this.params.rows = 10
      this.search()
    }
  },

  computed: {
    ...mapState('points', ['pointList', 'count', 'types'])
  },

  async created () {
    await this.getTypes()
    this.search()

    points.on('convert', (data) => {
      this.search()
    })
  },

  methods: {
    ...mapActions('points', {
      getPoints: 'get',
      getTypes: 'getTypes'
    }),

    trigger (query) {
      this.params = query || this.params
      this.$router.push({ query: this.params })
    },

    async sort (sortBy) {
      if (this.params.sort === 'asc') {
        this.params.sort = 'desc'
      } else {
        this.params.sort = 'asc'
      }

      this.params.sort_by = sortBy

      if (!this.showDataLoading) {
        this.showDataLoading = true
      }

      this.$router.push({ query: this.params })
    },

    async search (query) {
      this.params = query || this.params

      // == flags reset
      this.isEmpty = false
      if (!this.showDataLoading) {
        this.isDataLoading = true
      }

      let type = this.$route.params.subtype

      this.params.rows = 50

      await this.getPoints({
        uid: this.$route.params.id,
        params: this.params,
        type: type
      })

      this.isDataLoading = false
      this.showDataLoading = false
      if (!this.pointList.length) {
        this.isEmpty = true
      }
    },

    clear () {}
  }
}
</script>
