import axios from 'axios'

export default {
  namespaced: true,

  state: {
    themes: []
  },

  mutations: {
    SET_THEMES (state, data) {
      state.themes =  data
    }
  },

  getters: {},

  actions: {
    async get ({ state, commit }, params) {
      try {
        let response = await axios.get('/settings/themes')
        commit('SET_THEMES', response.data)
      }  catch (err) {
        console.log(err)
      }
    },

    async patch ({ commit }, { id, data }) {
      let result = await axios
        .patch(`/settings/themes/${id}`, {data})

      console.log(result)

      return result
    }
  }
}
