import Vue from 'vue'
import Router from 'vue-router'

import Login from '@/views/login/Login'
import UserDetails from '@/views/users-management/UserDetails'

import { checkPermission } from '@/assets/js/common/CheckPermission.js'
import { vueLocalStorage } from '@/assets/js/mixins/base/VueLocalStorage'

/* eslint-disable-next-line */
const lazyLoad = component => import(/* webpackChunkName: "[request]" */ `@/${component}.vue`)

Vue.use(Router)

const routes = [
  {
    path: '/login',
    name: 'login-page',
    meta: {
      dbKey: ''
    },
    component: Login
  },
  {
    path: '/logout',
    name: 'logout-page',
    component: () => lazyLoad('views/login/Logout')
  },
  {
    path: '/',
    name: 'home-page',
    meta: {
      dbKey: ''
    },
    component: () => lazyLoad('views/Home')
  },
  {
    path: '/users/:status?',
    name: 'users-page',
    meta: {
      dbKey: 'users'
    },
    component: () => lazyLoad('views/users-management/Users'),
    beforeEnter: checkPermission
  },
  {
    path: '/agent/:id/users/:status',
    name: 'agents-users',
    meta: {
      dbKey: 'users'
    },
    component: () => lazyLoad('views/users-management/Users'),
    beforeEnter: checkPermission
  },
  {
    path: '/agents/referrals',
    name: 'agents-referrals',
    meta: {
      dbKey: 'users'
    },
    component: () => lazyLoad('views/agents-management/AgentReferrals'),
    beforeEnter: checkPermission
  },
  {
    path: '/user/:id/:tab?/:subtype?/:status?',
    name: 'user-details-page',
    meta: {
      dbKey: 'users'
    },
    component: UserDetails,
    beforeEnter: checkPermission
  },
  {
    path: '/bet-history/:type/:status',
    name: 'bet-history-page',
    meta: {
      dbKey: 'bet_history'
    },
    component: () => lazyLoad('views/bet-history/BetHistory'),
    beforeEnter: checkPermission
  },
  {
    path: '/bet-history/:details/:game/:status',
    name: 'bet-history-details',
    meta: {
      dbKey: 'bet_history'
    },
    component: () => lazyLoad('views/bet-history/BetHistoryDetails'),
    beforeEnter: checkPermission
  },
  {
    path: '/manual-history/details/:game/:match_id',
    name: 'manual-history-details',
    meta: {
      dbKey: 'bet_history'
    },
    component: () => lazyLoad('views/bet-history/ManualHistoryDetails'),
    beforeEnter: checkPermission
  },
  {
    path: '/bet-history/matches/:status?',
    name: 'bet-history-matches-page',
    meta: {
      dbKey: 'bet_history'
    },
    component: () => lazyLoad('views/bet-history/BetHistory')
  },
  {
    path: '/bet-history-update/:game/:status?',
    name: 'bet-history-update-type',
    meta: {
      dbKey: 'bet_history'
    },
    component: () => lazyLoad('views/bet-history/BetHistoryNew')
  },
  {
    path: '/bet-history-update/sports/list',
    name: 'bet-history-update-type-list',
    meta: {
      dbKey: 'bet_history'
    },
    component: () => lazyLoad('@/views/bet-history/BetListNew')
  },
  {
    path: '/transfers/:type/user/:uid',
    name: 'transfers-details-page',
    meta: {
      dbKey: 'transfers'
    },
    component: () => lazyLoad('views/transfer-management/TransferDetails'),
    beforeEnter: checkPermission
  },
  {
    path: '/transactions/:type?/:status?/:transType?',
    name: 'transaction-list-page',
    meta: {
      dbKey: 'transactions'
    },
    component: () => lazyLoad('views/transaction-management/TransactionList')
  },
  {
    path: '/points/:type?/:status?',
    name: 'points-list-page',
    meta: {
      dbKey: 'points'
    },
    component: () => lazyLoad('views/points-management/PointsList'),
    beforeEnter: checkPermission
  },
  {
    path: '/codes/:status?',
    name: 'codes-management-page',
    meta: {
      dbKey: 'codes'
    },
    component: () => lazyLoad('views/codes-management/Codes'),
    beforeEnter: checkPermission
  },
  {
    path: '/account/all',
    name: 'account-all-page',
    meta: {
      dbNavMain: 'account_mgm',
      dbNavSub: 'show'
    },
    component: () => lazyLoad('views/account/AccountAll')
  },
  {
    path: '/account/point',
    name: 'account-point-page',
    meta: {
      dbNavMain: 'account_mgm',
      dbNavSub: 'show'
    },
    component: () => lazyLoad('views/account/AccountPoint')
  },
  {
    path: '/account/bet/:type?',
    name: 'account-bet-page',
    meta: {
      dbNavMain: 'account_mgm',
      dbNavSub: 'show'
    },
    component: () => lazyLoad('views/account/AccountBet')
  },
  {
    path: '/commission-history',
    name: 'commission-history',
    meta: {
      dbNavMain: 'account_mgm',
      dbNavSub: 'show'
    },
    component: () => lazyLoad('views/agents-management/AgentCommissionHistory')
  },
  {
    path: '/account/bet/details/:game_name/:date/:table_id?',
    name: 'account-bet-details',
    meta: {
      dbNavMain: 'account_mgm',
      dbNavSub: 'show'
    },
    component: () => lazyLoad('views/account/AccountBetDetails')
  },
  {
    path: '*',
    name: '404-page',
    meta: {
      dbKey: ''
    },
    component: () => lazyLoad('views/errors/PageNotFound')
  }
]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if ((vueLocalStorage.getItem('token') === '' || !vueLocalStorage.getItem('token'))) {
    if (to.name !== 'login-page') {
      next({ name: 'login-page' })
    } else {
      next()
    }
  } else {
    if (to.name === 'home-page') {
      next('/users/confirmed')
    }

    if (to.name === 'login-page') {
      next('/users/confirmed')
    } else {
      next()
    }
  }
})

router.beforeResolve((to, from, next) => {
  let toPage, fromPage

  if (to.name !== 'page-not-found') {
    toPage = to.name
    fromPage = from.name
  } else {
    toPage = to.name
    fromPage = from.name
  }

  if (toPage !== fromPage) {
    document.body.classList.remove(fromPage)
  }

  if (document.body.classList.contains(toPage)) {
    document.body.classList.add(toPage)
  }

  next()
})

const originalPush = Router.prototype.push
Router.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
