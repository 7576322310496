import axios from 'axios'
import _orderBy from 'lodash/orderBy'
import _pickBy from 'lodash/pickBy'

export default {
  namespaced: true,

  state: {
    betHistory: [],
    count: 0,

    matches: [],

    formatted: [],
    totalBetCount: 0
  },

  getters: {},

  mutations: {
    SET_MATCHES (state, data) {
      state.formatted = data

      state.totalBetCount = 0

      if (Array.isArray(data)) {
        data.forEach(row => {
          state.totalBetCount += row.bet_count || row.total || 0
        })
      } else {
        if (typeof data === 'object' && data) {
          Object.keys(data).forEach(key => {
            state.totalBetCount += data[key].bet_count || 0
          })
        }
      }
    },

    SET_BET_LIST (state, { data, gameType }) {
      if (!data) {
        state.betHistory = []
        return
      }

      if (gameType) {
        state.betHistory = data
        return
      }

      data.forEach(e => {
        e.bet_info.forEach(binfo => {
          if (['ntry', 'named'].indexOf(binfo.game.eng) !== -1) {
            const areas = binfo.game_type.eng.split('/')
            const areasKor = binfo.game_type.kor.split('/')

            if (areas.length === 3) {
              binfo.home = {
                eng: areas[0],
                kor: areasKor[0]
              }
              binfo.away = {
                eng: areas[2],
                kor: areasKor[2]
              }
            }
          }
        })
      })

      if (data && data.length) {
        data.forEach(e => (e.bet_info = _orderBy(e.bet_info, ['play_date', 'league.eng'], ['asc', 'asc'])))
      }

      state.betHistory = data
    }
  },

  actions: {
    async get ({ commit }, { type, status, params }) {
      params.query = _pickBy({
        ...params.country && { country: params.country },
        ...params.league && { league: params.league },
        ...params.round && { round: params.round },
        ...params.game && { game: params.game },
        ...params.query
      }, Boolean)

      const result = await axios.get(`bet-history/match-game/type/${type}/status/${status}`, {
        params: _pickBy(params, Boolean)
      })
      commit('SET_MATCHES', result.data)
    },

    async getBetList ({ commit }, { params, gameType, gameID, prdID = '', ...rest }) {
      try {
        const url = gameType && gameID
          ? `bet-history/match-game/bet-list/${gameType}/${gameID}/${prdID}`
          : 'bet-history/match-game/bet-list'

        const { data } = await axios.get(url, { params: { ...params, ...rest } })

        commit('SET_BET_LIST', { data, gameType })
      } catch (e) {
        console.log(e)
      }
    },

    async modifyMatchSingle ({ commit }, { params }) {
      try {
        if (!params) {
          return
        }

        await axios.patch(`bet-history/match-game/${params.id}/modify`, { params })
      } catch (e) {
        console.log(e)
      }
    },

    async modifyMatchBulk ({ commit }, { params }) {
      try {
        if (!params.length) {
          return
        }

        await axios.patch('bet-history/match-game/bulkmodify', { params })
      } catch (e) {
        console.log(e)
      }
    }
  }
}
