import axios from 'axios'

export default {
  namespaced: true,
  state: {
    wallets: []
  },
  mutations: {
    SET_WALLET (state, data) {
      state.wallets = data
    }
  },
  getters: {
  },
  actions: {
    async get ({ commit }, { id, params, status }) {
      let p = _.map(params,(v,k)=>encodeURIComponent(k) + '=' + encodeURIComponent(v)).join('&');
      let response = await axios.get(`/users/${id}/wallet/${status}?${p}`)
      commit('SET_WALLET', response.data)
    },
    async create ({ commit }, data) {
      return axios.post(`/users/${data.id}/wallet`, data.data)
    },
    async update ({ commit }, params) {
      let response = await axios.patch(`/users/${params.uid}/wallet/${params.wid}`, { params: params.data })
      return response
    },
    async delete ({ commit }, params) {
      let response = await axios.delete(`/users/${params.uid}/wallet/${params.wid}`)
      return response
    }
  }
}
