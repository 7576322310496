/**
  Description:
  formats date depending on what format is passed,
  if there is no date returns "-""
**/

import moment from 'moment'

export default {
  filters: {
    date (date, format = 'YYYY-MM-DD HH:mm:ss', type = null) {
      if (!(date == null)) {
        return moment(date).format(format)
      } else {
        return '-'
      }
    }
  }
}
