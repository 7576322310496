import { common } from '@/assets/js/translations/Common'

export const en = {
  en: {
    total_info: `total info`,
    user_info: `user info`,
    cash_info: `cash info`,
    betting_info: `betting info`,
    point_info: `point info`,
    manual_info: `manual info`,
    comission_info: `comission info`,
    sports_info: `sports info`,
    mini_games_info: `mini games info`,
    in_play_info: `in play info`,
    virtual_sports_info: `virtual sports info`,
    life_info: `life info`,

    cash_net: `cash net`,
    manual_net: `manual net`,
    betting_net: `betting net`,

    balance: `balance`,
    point: `point`,
    users: `users`,
    online: `online`,
    new: `new`,

    waiting: `waiting`,
    in: `in`,
    out: `out`,
    net: `net`,
    bet: `bet`,
    win: `win`,
    auto: `auto`,
    give: `give`,
    refund: `refund`,

    agent: `agent`,
    referral: `referral`,
    betting: `betting`,
    betting_ctn: `bet count`,
    bet_waiting: `bet waiting`,
    bet_users: `bet users`,
    win_amount: `win amount`,
    manual_in: `manual in`,
    manual_out: `manual out`,

    new_online: 'online'
  }
}
