import axios from 'axios'
import ObjToFormData from '@/assets/js/common/ObjToFormData.js'
import _ from 'lodash'

export default {
  namespaced: true,
  state: {
    points: [],
    arranged: [],
    pointTypes: [],
    vips: [],
    levels: []
  },

  mutations: {
    SET_DATA (state, data) {
      state.points = data

      let levels = state.levels

      data = JSON.parse(JSON.stringify(data))

      let test = new Array(state.pointTypes.length).fill(null).map((n, x) => {

        let active = data.find((d) => {
          return parseInt(state.pointTypes[x].id) === parseInt(d.type_id)
        })

        active = active ? active.active : false

        return {
          type: state.pointTypes[x].name,
          type_kor: state.pointTypes[x].kor,
          active,
          type_id: state.pointTypes[x].id,

          levels: new Array(levels.length).fill(0).map((n, i) => {
            let level = levels[i].name.split('.')[1]
            return {
              level,
              data: data.find((d) => {
                return parseInt(d.level_id) === parseInt(level) &&
                  parseInt(state.pointTypes[x].id) === parseInt(d.type_id)
              }) || { values: 0 }
            }
          }),

          vips: state.vips.map((e) => {
            return {
              vip: e.name,
              id: e.id,
              data: data.find((d) => {
                return parseInt(d.vip_id) === parseInt(e.id) &&
                  parseInt(state.pointTypes[x].id) === parseInt(d.type_id)
              }) || { values: 0}
            }
          })
        }
      })

      state.arranged = test
    },

    SET_TYPES (state, data) {
      state.pointTypes = data
    },

    SET_VIPS (state, data) {
      state.vips = data
    },

    SET_LEVELS (state, data) {
      state.levels = data
    }
  },

  getters: {},

  actions: {
    async get ({ commit }, data) {
      let response = await axios.get('/settings/points')
      let types = await axios.get(`/points/types`)
      let vips = await axios.get(`/vips`)
      let levels = await axios.get(`/levels`)
      response.data.forEach((e) => {
        // e.values *= 100
        e.values = parseInt(e.values * 100)
      })
      levels = levels.data.filter((e) => {
        return e.name.toLowerCase() !== 'admin'
      })

      commit('SET_LEVELS', levels)
      commit('SET_TYPES', types.data)
      commit('SET_VIPS', vips.data.filter((e) => {
        return e.name.toLowerCase() !== 'normal'
      }))
      commit('SET_DATA', response.data)
    },

    async update ({ commit }, { id, data }) {
      // console.log(id, data)
      if (id) {
        return axios.patch(`/settings/points/${id}`, data)
      } else {
        return axios.patch(`/settings/points`, data)
      }
    },

    async create ({ commit }, data) {
      console.log(data, 'create')
      return axios.post(`/settings/points`, data)
    }
  }
}
