import axios from 'axios'
import _ from 'lodash'

export default {
  namespaced: true,

  state: {
    list: [],
    count: 0
  },

  getters: {},

  mutations: {
    SET_LIST (state, data) {
      state.list = data
    },

    SET_COUNT (state, data) {
      state.count = data
    }
  },

  actions: {
    async get ({ commit }, { params }) {
      let result = await axios
        .get('/accounts/weekly', { params })

      commit('SET_LIST', result.data.list)
      commit('SET_COUNT', result.data.count)
    }
  }
}